import React, { Component } from 'react';
import './App.css';
import btn_icon_719711 from './images/btn_icon_719711.png';
import btn_icon_514627 from './images/btn_icon_514627.png';
import btn_icon_948459 from './images/btn_icon_948459.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class MenuItemListItemAdd extends Component {

  // Properties used by this component:
  // name, price, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elIconButtonCopy2719711 = async () => {
    await this.sendData_state0_elIconButtonCopy2719711_to_fb_menuItems();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      fieldCopy: "",
      fieldBasePrice: "",
    });
  
  }
  
  
  onClick_state0_elIconButtonCopy = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  textInputChanged_state0_elFieldBasePrice = (event) => {
    this.setState({fieldBasePrice: event.target.value});
  }
  
  getValue_state0_elFieldBasePrice = () => {
    return this.state.fieldBasePrice !== undefined ? this.state.fieldBasePrice : (this.props.price);
  }
  
  textInputChanged_state0_elFieldCopy = (event) => {
    this.setState({fieldCopy: event.target.value});
  }
  
  getValue_state0_elFieldCopy = () => {
    return this.state.fieldCopy !== undefined ? this.state.fieldCopy : (this.props.name);
  }
  
  onClick_state0_elButtonAddMenuItem2112602370 = async () => {
    await this.sendData_state0_elButtonAddMenuItem2112602370_to_fb_menuItems();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      fieldCopy: "",
      fieldBasePrice: "",
    });
  
  }
  
  
  sendData_state0_elIconButtonCopy2719711_to_fb_menuItems = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItems');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
    };
    row = { ...row, 
      associatedBrand: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''),
      document_ref: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      order: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      associatedMenu: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_menu_key'] : ''),
      imageURL: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_nullvalue'] : ''),
      description: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      name: this.getValue_state0_elFieldCopy(),
      isActive: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      /*
      
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      input.isActive = true;
      
      input.price = Number(this.state.fieldCopy2);
      
      return input;
      */
      
      
      // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      input.isActive = true;
      
      input.price = Math.round(100*Number((this.state.fieldBasePrice).replace("$", "")));
      input.description = "";
      input.document_ref = "";
      input.order = this.props.appActions.getDataSheet('fb_menuItem_options').items.length;  //works?
      
      return input;
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItems', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItems', row);
    }
  }
  
  
  sendData_state0_elButtonAddMenuItem2112602370_to_fb_menuItems = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItems');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
    };
    row = { ...row, 
      isActive: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''),
      imageURL: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_nullvalue'] : ''),
      name: this.getValue_state0_elFieldCopy(),
      associatedMenu: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_menu_key'] : ''),
      associatedBrand: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      input.isActive = true;
      
      input.price = Math.round(100*Number((this.state.fieldBasePrice).replace("$", "")));
      input.description = "";
      input.document_ref = "";
      input.order = this.props.appActions.getDataSheet('fb_menuItem_options').items.length;  //works?
      
      return input;
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItems', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItems', row);
    }
  }
  
  
  renderState0() {
    const style_state0_elCard841446 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard841446_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state0_elIconButtonCopy2719711 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_719711+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_514627+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elFieldBasePrice = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state0_elFieldCopy = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state0_elButtonAddMenuItem2112602370 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemListItemAdd">
        <div className="background">
          <div className="state0_elCard841446" style={style_state0_elCard841446_outer}>
            <div className="cardBg" style={style_state0_elCard841446} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elIconButtonCopy2719711" style={style_state0_elIconButtonCopy2719711} onClick={this.onClick_state0_elIconButtonCopy2719711}  />
          <button className="actionFont state0_elIconButtonCopy" style={style_state0_elIconButtonCopy} onClick={this.onClick_state0_elIconButtonCopy}  />
          <input className="baseFont state0_elFieldBasePrice" style={style_state0_elFieldBasePrice} type="text" placeholder={this.props.locStrings.menuitemlistitem2_fieldcopy2_155454} onChange={this.textInputChanged_state0_elFieldBasePrice} value={this.getValue_state0_elFieldBasePrice()}  />
          <input className="baseFont state0_elFieldCopy" style={style_state0_elFieldCopy} type="text" placeholder={this.props.locStrings.menuitemlistitem2_fieldcopy_153392} onChange={this.textInputChanged_state0_elFieldCopy} value={this.getValue_state0_elFieldCopy()}  />
          <Button className="actionFont state0_elButtonAddMenuItem2112602370" style={style_state0_elButtonAddMenuItem2112602370}  color="accent" onClick={this.onClick_state0_elButtonAddMenuItem2112602370} >
            {this.props.locStrings.menuitemlistitemadd_button_2112602370}
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elIconButtonCopy2719711 = async () => {
    await this.sendData_state1_elIconButtonCopy2719711_to_fb_menuItems();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      fieldCopy: "",
      fieldBasePrice: "",
    });
  
  }
  
  
  onClick_state1_elIconButtonCopy = async () => {
    this.setState({visualStateIndexOverride: 0})
  
  }
  
  
  textInputChanged_state1_elFieldCopy = (event) => {
    this.setState({fieldCopy: event.target.value});
  }
  
  getValue_state1_elFieldCopy = () => {
    return this.state.fieldCopy !== undefined ? this.state.fieldCopy : (this.props.name);
  }
  
  onClick_state1_elButtonAddMenuItem2112602370 = async () => {
    await this.sendData_state1_elButtonAddMenuItem2112602370_to_fb_menuItems();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      fieldCopy: "",
      fieldBasePrice: "",
    });
  
  }
  
  
  sendData_state1_elIconButtonCopy2719711_to_fb_menuItems = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItems');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
    };
    row = { ...row, 
      associatedBrand: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''),
      document_ref: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      order: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      associatedMenu: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_menu_key'] : ''),
      imageURL: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_nullvalue'] : ''),
      description: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      name: null /* unable to collect data for 'field copy' */,
      isActive: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      /*
      
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      input.isActive = true;
      
      input.price = Number(this.state.fieldCopy2);
      
      return input;
      */
      
      
      // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      input.isActive = true;
      
      input.price = Math.round(100*Number((this.state.fieldBasePrice).replace("$", "")));
      input.description = "";
      input.document_ref = "";
      input.order = this.props.appActions.getDataSheet('fb_menuItem_options').items.length;  //works?
      
      return input;
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItems', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItems', row);
    }
  }
  
  
  sendData_state1_elButtonAddMenuItem2112602370_to_fb_menuItems = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItems');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
    };
    row = { ...row, 
      isActive: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''),
      imageURL: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_nullvalue'] : ''),
      name: null /* unable to collect data for 'field copy' */,
      associatedMenu: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_menu_key'] : ''),
      associatedBrand: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      input.isActive = true;
      
      input.price = Math.round(100*Number((this.state.fieldBasePrice).replace("$", "")));
      input.description = "";
      input.document_ref = "";
      input.order = this.props.appActions.getDataSheet('fb_menuItem_options').items.length;  //works?
      
      return input;
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItems', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItems', row);
    }
  }
  
  
  renderState1() {
    const style_state1_elCard841446 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard841446_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state1_elIconButtonCopy2719711 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_719711+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_948459+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldCopy = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elButtonAddMenuItem2112602370 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemListItemAdd">
        <div className="background">
          <div className="state1_elCard841446" style={style_state1_elCard841446_outer}>
            <div className="cardBg" style={style_state1_elCard841446} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state1_elIconButtonCopy2719711" style={style_state1_elIconButtonCopy2719711} onClick={this.onClick_state1_elIconButtonCopy2719711}  />
          <button className="actionFont state1_elIconButtonCopy" style={style_state1_elIconButtonCopy} onClick={this.onClick_state1_elIconButtonCopy}  />
          <input className="baseFont state1_elFieldCopy" style={style_state1_elFieldCopy} type="text" placeholder="{header title}" onChange={this.textInputChanged_state1_elFieldCopy} value={this.getValue_state1_elFieldCopy()}  />
          <Button className="actionFont state1_elButtonAddMenuItem2112602370" style={style_state1_elButtonAddMenuItem2112602370}  color="accent" onClick={this.onClick_state1_elButtonAddMenuItem2112602370} >
            {this.props.locStrings.menuitemlistitemadd_button_2112602370}
          </Button>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
