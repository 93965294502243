import React, { Component } from 'react';
import './App.css';
import btn_icon_502215 from './images/btn_icon_502215.png';
import img_elImage2 from './images/DetailsComponent_state1_elRoundimage_743990.png';
import btn_icon_42924 from './images/btn_icon_42924.png';
import Comp_MenuItemDetalils from './Comp_MenuItemDetalils';
import MenuItemCustomizationAdd from './MenuItemCustomizationAdd';
import MenuItemCustomizationListItem from './MenuItemCustomizationListItem';
import BottomNavBar from './BottomNavBar';
import img_elImage3 from './images/DetailsComponent_state1_elRoundimage_743990.png';
import img_elBrandLogoImage from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_elImage from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';
import IPhoneMockupComponent from './IPhoneMockupComponent';
import Footer from './Footer';
import Header from './Header';
import Component4 from './Component4';
import HelpOverlayScreen from './HelpOverlayScreen';

export default class MenuItemDetailsScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, dataSheetRow, brandImageURL, bottomNavIconActiveColor, bottomNavIconColor, iconHomeURL, iconMenuURL, iconProfileURL, iconMoreURL

  constructor(props) {
    super(props);
    
    this.state = {
      textcopy3: (<div>Item Customizations*</div>),
      textcopy3_plainText: "Item Customizations*",
      text3: (<div>*Note - Customizations are things like “size” or “color”  or “protein”.  You will have a chance to add options when you manage a customization.</div>),
      text3_plainText: "*Note - Customizations are things like “size” or “color”  or “protein”.  You will have a chance to add options when you manage a customization.",
      pricetext: (<div>$5.00</div>),
      pricetext_plainText: "$5.00",
      titletext: (<div>Sample Item</div>),
      titletext_plainText: "Sample Item",
      text2: (<div>Start New Order</div>),
      text2_plainText: "Start New Order",
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['fd_menuItemCustomizations'];
      let serviceOptions = this.props.appActions.serviceOptions_fd_menuItemCustomizations;
      if ( !this.props.appActions.dataSheetLoaded['fd_menuItemCustomizations']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems/$slot(ds_selected_menuItem_key)/customizations", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fd_menuItemCustomizations'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menuItem_options'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menuItem_options;
      if ( !this.props.appActions.dataSheetLoaded['fb_menuItem_options']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems/$slot(ds_selected_menuItem_key)/customizations/$slot(ds_selected_menuItemOption_key)/options", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menuItem_options'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menuItems'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menuItems;
      if ( !this.props.appActions.dataSheetLoaded['fb_menuItems']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menuItems'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elIconButton2 = async () => {
    let newVal = "yyyy";
    this.props.appActions.updateDataSlot('ds_selected_menuItem_key', newVal);
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  textInputChanged_elFieldTitle = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_elFieldTitle = () => {
    return this.state.fieldTitle !== undefined ? this.state.fieldTitle : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  onClick_elIconButton = async () => {
  
  
  }
  
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_fb_currentBrand = this.props.dataSheets['fb_currentBrand'];
    // eslint-disable-next-line
    const dataSheet_fd_menuItemCustomizations = this.props.dataSheets['fd_menuItemCustomizations'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    
    const style_elRectangle2 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_elIconButton2 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_502215+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImage2 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedMenuItemImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_textCopy5 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
    
    const style_elTextCopy5 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const placeholderValue_fieldTitle = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_elFieldTitle = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_elFieldTitle_outer = {
      display: 'none',
     };
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_42924+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elIconButton_outer = {
      display: 'none',
     };
    const style_elTextCopy3 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    listComps_list['_tail'] = <MenuItemCustomizationAdd appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    let filterItems_list = items => {
      // This function filters items for the List / Grid element.
      // There is a variable named ‘items’ that provides item values.
      
      /*
      let filteredItems = [];
      let filterStr=this.props.appActions.dataSlots['ds_filter']; // Data slot value
      filterStr=filterStr.toLowerCase(); // Lowercase the filter string
      // Loop through all items and add item to new array if item includes filter string
      items.forEach(item => {;
      if (item['todo'].toLowerCase().includes(filterStr)) {
      filteredItems.push(item);
      }
      });
      items=filteredItems; 
      
      */
      
      return items;
    
    }
    items_list = items_list.concat(filterItems_list(this.props.appActions.getDataSheet('fd_menuItemCustomizations').items));
    items_list.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elText3 = {
      color: '#eb771d',
      textAlign: 'left',
     };
    const style_elTextCopy6 = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    const value_textCopy8 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_menuItem_key'] : ''));
    
    const style_elTextCopy8 = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    const fillColorValue_rectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_elRectangle = {
      background: (fillColorValue_rectangle && fillColorValue_rectangle.length > 0) ? fillColorValue_rectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const style_elBottomNavBar = {
      display: 'none',
     };
    const style_elImage3 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedMenuItemImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      display: 'none',
     };
    
    const style_elRectangleCopy = {
      background: 'rgba(255, 255, 255, 1.000)',
      border: '0.5px solid rgba(43, 79, 255, 0.4500)',
      borderRadius: '6.0px',
      display: 'none',
     };
    const style_elPriceText = {
      color: this.props.dataSheetRow ? this.props.dataSheetRow.textColor : '',
      textAlign: 'center',
      display: 'none',
     };
    let transformColorPropValue_titleText = (input) => {
      // This function modifies the value for property 'color'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      return input;
    }
    const style_elTitleText = {
      fontSize: 31.0,
      color: transformColorPropValue_titleText(this.props.dataSheetRow ? this.props.dataSheetRow.titleColor : ''),
      textAlign: 'center',
      display: 'none',
     };
    const style_elText2 = {
      fontSize: 15.9,
      color: '#0c81fe',
      textAlign: 'center',
      display: 'none',
     };
    const fillColorValue_card2 = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    const style_elCard2 = {
      width: '100%',
      height: '100%',
     };
    const style_elCard2_outer = {
      display: 'none',
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_elBrandLogoImage = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.brandImageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      display: 'none',
     };
    const style_elImage = {
      height: 'auto',
      display: 'none',
     };
    const elComponent = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps elComponent">
        <Component4 ref={(el)=> this._elComponent = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const elHelpOverlayScreen = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_showHelpOverlay'] : '')) ? (
      <div className="hasNestedComps elHelpOverlayScreen">
        <HelpOverlayScreen visualStateIndex={3} ref={(el)=> this._elHelpOverlayScreen = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen MenuItemDetailsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elRectangle2">
            <div style={style_elRectangle2} />
          </div>
          
          <div className="elIconButton2">
            <button className="actionFont" style={style_elIconButton2} onClick={this.onClick_elIconButton2}  />
          </div>
          
          <div className="elImage2">
            <div style={style_elImage2} />
          </div>
          
          <div className="elTextCopy5">
            <div className="font-futuraMedium" style={style_elTextCopy5}>
              <div>{value_textCopy5}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elFieldTitle" style={style_elFieldTitle_outer}>
            <input className="font-futuraMedium" style={style_elFieldTitle} type="text" placeholder={placeholderValue_fieldTitle} onChange={this.textInputChanged_elFieldTitle} value={this.getValue_elFieldTitle()}  />
          </div>
          
          <div className="elIconButton" style={style_elIconButton_outer}>
            <button className="actionFont" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
          
          <div className="hasNestedComps elComp_MenuItemDetalils">
            <div>
              <Comp_MenuItemDetalils {...this.props} ref={(el)=> this._elComp_MenuItemDetalils = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elTextCopy3">
            <div className="font-futuraMedium" style={style_elTextCopy3}>
              <div>{this.state.textcopy3}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elList">
            <ul style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemCustomizationListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fd_menuItemCustomizations'} dataSheetRow={row} {...{ 'name': row['name'], 'sort': row['sort'], 'document_key': row['document_key'], 'priceadd': row['priceadd'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div>
          
          <div className="elText3">
            <div className="actionFont" style={style_elText3}>
              <div>{this.state.text3}</div>
            </div>
          </div>
          
          <div className="elTextCopy6">
            <div className="actionFont" style={style_elTextCopy6}>
              <div>{this.props.locStrings.menuitemdetails_textcopy6_740458509}</div>
            </div>
          </div>
          
          <div className="elTextCopy8">
            <div className="actionFont" style={style_elTextCopy8}>
              <div>{value_textCopy8}</div>
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elRectangle" style={style_elRectangle} />
            <div className="hasNestedComps elBottomNavBar" style={style_elBottomNavBar}>
              <BottomNavBar ref={(el)=> this._elBottomNavBar = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="elImage3" style={style_elImage3} />
            <div className="elRectangleCopy" style={style_elRectangleCopy} />
            <div className="baseFont elPriceText" style={style_elPriceText}>
              <div>{this.state.pricetext}</div>
            </div>
            <div className="systemFontBold  elTitleText" style={style_elTitleText}>
              <div>{this.state.titletext}</div>
            </div>
            <div className="systemFontBold  elText2" style={style_elText2}>
              <div>{this.state.text2}</div>
            </div>
            <div className="elCard2" style={style_elCard2_outer}>
              <div style={style_elCard2} />
            </div>
            
            <div className="elBrandLogoImage" style={style_elBrandLogoImage} />
            <img className="elImage" style={style_elImage} src={img_elImage} alt=""  />
            <div className="hasNestedComps elIPhoneMockupComponent">
              <IPhoneMockupComponent {...dataSheet_fb_currentBrand.items[0]} visualStateIndex={2} ref={(el)=> this._elIPhoneMockupComponent = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps elFooter">
              <Footer ref={(el)=> this._elFooter = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps elHeader">
              <Header ref={(el)=> this._elHeader = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { elComponent }
            { elHelpOverlayScreen }
          </div>
        </div>
      </div>
    )
  }
  
}
