import React, { Component } from 'react';
import './App.css';
import Comp5 from './Comp5';

export default class Comp1 extends Component {

  // Properties used by this component:
  // dataSheetRow, bottomNavIconColor, bottomNavIconActiveColor

  constructor(props) {
    super(props);
    
    this.state = {
      text_brand_detailscopy2: (<div>Branding</div>),
      text_brand_detailscopy2_plainText: "Branding",
      textcopy2: (<div>App Background Color</div>),
      textcopy2_plainText: "App Background Color",
      textcopy3: (<div>App Logo Background Color</div>),
      textcopy3_plainText: "App Logo Background Color",
      textcopy4: (<div>Headline Text  Color</div>),
      textcopy4_plainText: "Headline Text  Color",
      textcopy5: (<div>Descriptions Text Color</div>),
      textcopy5_plainText: "Descriptions Text Color",
      textcopy14: (<div>Active Icon Color</div>),
      textcopy14_plainText: "Active Icon Color",
      textcopy15: (<div>InActive Icon Color</div>),
      textcopy15_plainText: "InActive Icon Color",
      textcopy16: (<div>Bottom Navigation Background Color</div>),
      textcopy16_plainText: "Bottom Navigation Background Color",
      textcopy17: (<div>Order Button Color</div>),
      textcopy17_plainText: "Order Button Color",
      textcopy18: (<div>Order Button Text Color</div>),
      textcopy18_plainText: "Order Button Text Color",
      textcopy19: (<div>Order Button Text</div>),
      textcopy19_plainText: "Order Button Text",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elField_Primary_color = (event) => {
    this.setState({field_Primary_color: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_brandPrimaryColor", event.target.value);
  }
  
  getValue_elField_Primary_color = () => {
    return this.state.field_Primary_color !== undefined ? this.state.field_Primary_color : (this.props.dataSheetRow ? this.props.dataSheetRow.primaryColor : '');
  }
  
  textInputChanged_elField_secondaryColor = (event) => {
    this.setState({field_secondaryColor: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_brandSecondaryColor", event.target.value);
  }
  
  getValue_elField_secondaryColor = () => {
    return this.state.field_secondaryColor !== undefined ? this.state.field_secondaryColor : (this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '');
  }
  
  textInputChanged_elField_title_color = (event) => {
    this.setState({field_title_color: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_brandTitleColor", event.target.value);
  }
  
  getValue_elField_title_color = () => {
    return this.state.field_title_color !== undefined ? this.state.field_title_color : (this.props.dataSheetRow ? this.props.dataSheetRow.titleColor : '');
  }
  
  textInputChanged_elField_baseTextColor2 = (event) => {
    this.setState({field_baseTextColor2: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_brandTextColor", event.target.value);
  }
  
  getValue_elField_baseTextColor2 = () => {
    return this.state.field_baseTextColor2 !== undefined ? this.state.field_baseTextColor2 : (this.props.dataSheetRow ? this.props.dataSheetRow.textColor : '');
  }
  
  textInputChanged_elFieldBottomNavActiveIconColor = (event) => {
    this.setState({fieldBottomNavActiveIconColor: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_brandActiveIconColor", event.target.value);
  }
  
  getValue_elFieldBottomNavActiveIconColor = () => {
    return this.state.fieldBottomNavActiveIconColor !== undefined ? this.state.fieldBottomNavActiveIconColor : (this.props.dataSheetRow ? this.props.dataSheetRow.bottomNavIconActiveColor : '');
  }
  
  textInputChanged_elFieldBottomNavInActiveIconColor = (event) => {
    this.setState({fieldBottomNavInActiveIconColor: event.target.value});
    
    {
      let newVal = event.target.value;
      
      let transformValue = (input) => {
        // This function modifies the value written to the target data slot.
        // There is a variable named 'input' that provides the input value.
        
        /*
        if(this.state.fieldBottomNavActiveIconColor != "")
        	{
            let iconURLString="https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:";
            iconURLString+=this.state.fieldBottomNavActiveIconColor; 
           	iconURLString+=",e_colorize:100,f_png,h_96,w_96/v1589862715/yesIcons/more-round.svg";
            this.props.appActions.updateDataSlot('ds_brandActiveIconColor', iconURLString);
            };
        
            */
        return input;
      }
      newVal = transformValue(newVal);
      
      this.props.appActions.updateDataSlot("ds_brandInactiveIconColor", newVal);
    }
  }
  
  getValue_elFieldBottomNavInActiveIconColor = () => {
    return this.state.fieldBottomNavInActiveIconColor !== undefined ? this.state.fieldBottomNavInActiveIconColor : (this.props.dataSheetRow ? this.props.dataSheetRow.bottomNavIconColor : '');
  }
  
  textInputChanged_elFieldBottomNavColor = (event) => {
    this.setState({fieldBottomNavColor: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_brandBottomNavColor", event.target.value);
  }
  
  getValue_elFieldBottomNavColor = () => {
    return this.state.fieldBottomNavColor !== undefined ? this.state.fieldBottomNavColor : (this.props.dataSheetRow ? this.props.dataSheetRow.bottonNavColor : '');
  }
  
  textInputChanged_elFieldOrderButtonColor = (event) => {
    this.setState({fieldOrderButtonColor: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_brandOrderButtonColor", event.target.value);
  }
  
  getValue_elFieldOrderButtonColor = () => {
    return this.state.fieldOrderButtonColor !== undefined ? this.state.fieldOrderButtonColor : (this.props.dataSheetRow ? this.props.dataSheetRow.orderButtonColor : '');
  }
  
  textInputChanged_elFieldOrderButtonTextColor = (event) => {
    this.setState({fieldOrderButtonTextColor: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_brandOrderButtonTextColor", event.target.value);
  }
  
  getValue_elFieldOrderButtonTextColor = () => {
    return this.state.fieldOrderButtonTextColor !== undefined ? this.state.fieldOrderButtonTextColor : (this.props.dataSheetRow ? this.props.dataSheetRow.orderButtonTextColor : '');
  }
  
  textInputChanged_elFieldOrderButtonText = (event) => {
    this.setState({fieldOrderButtonText: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_brandOrderButtonTextString", event.target.value);
  }
  
  getValue_elFieldOrderButtonText = () => {
    return (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandOrderButtonTextString'] : '');
  }
  
  onClick_elComp = async () => {
    await this.sendData_elComp_to_fb_myBrands();
  
  }
  
  
  sendData_elComp_to_fb_myBrands = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_myBrands');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
    };
    row = { ...row, 
      bottomNavIconActiveColor: this.getValue_elFieldBottomNavActiveIconColor(),
      bottonNavColor: this.getValue_elFieldBottomNavColor(),
      bottomNavIconColor: this.getValue_elFieldBottomNavInActiveIconColor(),
      orderButtonColor: this.getValue_elFieldOrderButtonColor(),
      primaryColor: this.getValue_elField_Primary_color(),
      titleColor: this.getValue_elField_title_color(),
      orderButtonTextColor: this.getValue_elFieldOrderButtonTextColor(),
      orderButtonCopy: this.getValue_elFieldOrderButtonText(),
      textColor: this.getValue_elField_baseTextColor2(),
      secondaryColor: this.getValue_elField_secondaryColor(),
    };
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_myBrands', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_myBrands', row);
    }
  }
  
  
  render() {
    const style_elText_brand_detailsCopy2 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elTextCopy2 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_field_Primary_color = this.props.dataSheetRow ? this.props.dataSheetRow.primaryColor : '';
    
    const style_elField_Primary_color = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy3 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_field_secondaryColor = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    
    const style_elField_secondaryColor = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy4 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_field_title_color = this.props.dataSheetRow ? this.props.dataSheetRow.titleColor : '';
    
    const style_elField_title_color = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy5 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_field_baseTextColor2 = this.props.dataSheetRow ? this.props.dataSheetRow.textColor : '';
    
    const style_elField_baseTextColor2 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy14 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_fieldBottomNavActiveIconColor = this.props.bottomNavIconActiveColor;
    
    const style_elFieldBottomNavActiveIconColor = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy15 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_fieldBottomNavInActiveIconColor = this.props.bottomNavIconColor;
    
    const style_elFieldBottomNavInActiveIconColor = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy16 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_fieldBottomNavColor = this.props.bottomNavIconColor;
    
    const style_elFieldBottomNavColor = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy17 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_fieldOrderButtonColor = this.props.dataSheetRow ? this.props.dataSheetRow.orderButtonColor : '';
    
    const style_elFieldOrderButtonColor = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy18 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_fieldOrderButtonTextColor = this.props.dataSheetRow ? this.props.dataSheetRow.orderButtonTextColor : '';
    
    const style_elFieldOrderButtonTextColor = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy19 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_fieldOrderButtonText = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandOrderButtonTextString'] : '');
    
    const style_elFieldOrderButtonText = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elComp = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Comp1">
        <div className="layoutFlow">
          <div className="elText_brand_detailsCopy2">
            <div className="font-futuraMedium" style={style_elText_brand_detailsCopy2}>
              <div>{this.state.text_brand_detailscopy2}</div>
            </div>
          </div>
          
          <div className="elTextCopy2">
            <div className="font-avenirBook" style={style_elTextCopy2}>
              <div>{this.state.textcopy2}</div>
            </div>
          </div>
          
          <div className="elField_Primary_color">
            <input className="font-avenirNextRegular" style={style_elField_Primary_color} type="text" placeholder={placeholderValue_field_Primary_color} onChange={this.textInputChanged_elField_Primary_color} value={this.getValue_elField_Primary_color()}  />
          </div>
          
          <div className="elTextCopy3">
            <div className="font-avenirBook" style={style_elTextCopy3}>
              <div>{this.state.textcopy3}</div>
            </div>
          </div>
          
          <div className="elField_secondaryColor">
            <input className="font-avenirNextRegular" style={style_elField_secondaryColor} type="text" placeholder={placeholderValue_field_secondaryColor} onChange={this.textInputChanged_elField_secondaryColor} value={this.getValue_elField_secondaryColor()}  />
          </div>
          
          <div className="elTextCopy4">
            <div className="font-avenirBook" style={style_elTextCopy4}>
              <div>{this.state.textcopy4}</div>
            </div>
          </div>
          
          <div className="elField_title_color">
            <input className="font-avenirNextRegular" style={style_elField_title_color} type="text" placeholder={placeholderValue_field_title_color} onChange={this.textInputChanged_elField_title_color} value={this.getValue_elField_title_color()}  />
          </div>
          
          <div className="elTextCopy5">
            <div className="font-avenirBook" style={style_elTextCopy5}>
              <div>{this.state.textcopy5}</div>
            </div>
          </div>
          
          <div className="elField_baseTextColor2">
            <input className="font-avenirNextRegular" style={style_elField_baseTextColor2} type="text" placeholder={placeholderValue_field_baseTextColor2} onChange={this.textInputChanged_elField_baseTextColor2} value={this.getValue_elField_baseTextColor2()}  />
          </div>
          
          <div className="elTextCopy14">
            <div className="font-avenirBook" style={style_elTextCopy14}>
              <div>{this.state.textcopy14}</div>
            </div>
          </div>
          
          <div className="elFieldBottomNavActiveIconColor">
            <input className="font-avenirNextRegular" style={style_elFieldBottomNavActiveIconColor} type="text" placeholder={placeholderValue_fieldBottomNavActiveIconColor} onChange={this.textInputChanged_elFieldBottomNavActiveIconColor} value={this.getValue_elFieldBottomNavActiveIconColor()}  />
          </div>
          
          <div className="elTextCopy15">
            <div className="font-avenirBook" style={style_elTextCopy15}>
              <div>{this.state.textcopy15}</div>
            </div>
          </div>
          
          <div className="elFieldBottomNavInActiveIconColor">
            <input className="font-avenirNextRegular" style={style_elFieldBottomNavInActiveIconColor} type="text" placeholder={placeholderValue_fieldBottomNavInActiveIconColor} onChange={this.textInputChanged_elFieldBottomNavInActiveIconColor} value={this.getValue_elFieldBottomNavInActiveIconColor()}  />
          </div>
          
          <div className="elTextCopy16">
            <div className="font-avenirBook" style={style_elTextCopy16}>
              <div>{this.state.textcopy16}</div>
            </div>
          </div>
          
          <div className="elFieldBottomNavColor">
            <input className="font-avenirNextRegular" style={style_elFieldBottomNavColor} type="text" placeholder={placeholderValue_fieldBottomNavColor} onChange={this.textInputChanged_elFieldBottomNavColor} value={this.getValue_elFieldBottomNavColor()}  />
          </div>
          
          <div className="elTextCopy17">
            <div className="font-avenirBook" style={style_elTextCopy17}>
              <div>{this.state.textcopy17}</div>
            </div>
          </div>
          
          <div className="elFieldOrderButtonColor">
            <input className="font-avenirNextRegular" style={style_elFieldOrderButtonColor} type="text" placeholder={placeholderValue_fieldOrderButtonColor} onChange={this.textInputChanged_elFieldOrderButtonColor} value={this.getValue_elFieldOrderButtonColor()}  />
          </div>
          
          <div className="elTextCopy18">
            <div className="font-avenirBook" style={style_elTextCopy18}>
              <div>{this.state.textcopy18}</div>
            </div>
          </div>
          
          <div className="elFieldOrderButtonTextColor">
            <input className="font-avenirNextRegular" style={style_elFieldOrderButtonTextColor} type="text" placeholder={placeholderValue_fieldOrderButtonTextColor} onChange={this.textInputChanged_elFieldOrderButtonTextColor} value={this.getValue_elFieldOrderButtonTextColor()}  />
          </div>
          
          <div className="elTextCopy19">
            <div className="font-avenirBook" style={style_elTextCopy19}>
              <div>{this.state.textcopy19}</div>
            </div>
          </div>
          
          <div className="elFieldOrderButtonText">
            <input className="font-avenirNextRegular" style={style_elFieldOrderButtonText} type="text" placeholder={placeholderValue_fieldOrderButtonText} onChange={this.textInputChanged_elFieldOrderButtonText} value={this.getValue_elFieldOrderButtonText()}  />
          </div>
          
          <div className="hasNestedComps elComp">
            <div style={style_elComp} onClick={this.onClick_elComp} >
              <Comp5 ref={(el)=> this._elComp = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
