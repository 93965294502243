import React, { Component } from 'react';
import './App.css';
import IPadLandscapeScreen from './IPadLandscapeScreen';
import NewsPopup from './NewsPopup';
import HelpOverlayScreen from './HelpOverlayScreen';

export default class MainScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, newsTitle, newsText, newsVideoURL, newsForumURL, showNews, dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elList_visible: true,
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['fb_brands'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_brands;
      if ( !this.props.appActions.dataSheetLoaded['fb_brands']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("brands", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_brands'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menuItems'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menuItems;
      if ( !this.props.appActions.dataSheetLoaded['fb_menuItems']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menuItems'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_myBrands'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_myBrands;
      if ( !this.props.appActions.dataSheetLoaded['fb_myBrands']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("brands", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_myBrands'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menuItem_options'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menuItem_options;
      if ( !this.props.appActions.dataSheetLoaded['fb_menuItem_options']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems/$slot(ds_selected_menuItem_key)/customizations/$slot(ds_selected_menuItemOption_key)/options", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menuItem_options'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fd_menuItemCustomizations'];
      let serviceOptions = this.props.appActions.serviceOptions_fd_menuItemCustomizations;
      if ( !this.props.appActions.dataSheetLoaded['fd_menuItemCustomizations']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems/$slot(ds_selected_menuItem_key)/customizations", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fd_menuItemCustomizations'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_fb_newsItems = this.props.dataSheets['fb_newsItems'];
    const style_state0_elBackground203627 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground203627_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    
    let contentElement_state0_elSwapper639884;  // This element's content can vary based on screen size
    contentElement_state0_elSwapper639884 = (<IPadLandscapeScreen dataSheetRow={this.props.dataSheetRow} agreementSigned={this.props.agreementSigned} email={this.props.email} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    if (this.props.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_state0_elSwapper639884 = (<IPadLandscapeScreen dataSheetRow={this.props.dataSheetRow} agreementSigned={this.props.agreementSigned} email={this.props.email} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_state0_elSwapper639884 = (<IPadLandscapeScreen dataSheetRow={this.props.dataSheetRow} agreementSigned={this.props.agreementSigned} email={this.props.email} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_state0_elSwapper639884 = (<IPadLandscapeScreen dataSheetRow={this.props.dataSheetRow} agreementSigned={this.props.agreementSigned} email={this.props.email} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_state0_elSwapper639884 = (<IPadLandscapeScreen dataSheetRow={this.props.dataSheetRow} agreementSigned={this.props.agreementSigned} email={this.props.email} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    
    let transformStateValue_state0_elList = (input) => {
      // This function modifies the value for property 'componentStateId'.
      // There is a variable named 'input' that provides the property value.
      //
      
      
      
      if (!this.props.dataSheetRow.showNews) {
       this.setState({state0_elList_visible: 'false'})
      }
      else {
       return input;
      }
      
    
    }
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('fb_newsItems').items);
    this._state0_elList_items = [];
    
    const state0_elList = this.state.state0_elList_visible ? (
      <div className="hasNestedComps state0_elList">
        <ul>
          {items_list.map((row, index) => {
            let itemComp = (row._componentId)
                ? listComps_list[row._componentId]
                : <NewsPopup visualStateIndex={transformStateValue_state0_elList(this.props.dataSheetRow ? this.props.dataSheetRow.showNews : '')} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_newsItems'} dataSheetRow={row} {...{ 'newsTitle': row['newsTitle'], 'newsText': row['newsText'], 'newsVideoURL': row['newsVideoURL'], 'newsForumURL': row['newsForumURL'], }} ref={(el) => {if (el) this._state0_elList_items.push(el)}} />;
            return (<li key={row.key}>
                {itemComp}
              </li>);
          })}
          <div className="marker" ref={(el)=> this._state0_elList_endMarker = el} />
        </ul>
      </div>
      
     ) : null;
    const state0_elHelpOverlayScreen1356134229 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_showHelpOverlay'] : '')) ? (
      <div className="hasNestedComps state0_elHelpOverlayScreen1356134229">
        <HelpOverlayScreen ref={(el)=> this._state0_elHelpOverlayScreen1356134229 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen MainScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBackground203627" style={style_state0_elBackground203627_outer}>
            <div className="appBg" style={style_state0_elBackground203627} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="flowRow flowRow_MainScreen_state0_elSwapper639884_639884">
          <div className="hasNestedComps state0_elSwapper639884">
            <div>
              {contentElement_state0_elSwapper639884}
            </div>
          </div>
          
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            { state0_elList }
            { state0_elHelpOverlayScreen1356134229 }
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_fb_newsItems = this.props.dataSheets['fb_newsItems'];
    const style_state1_elBackground203627 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground203627_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    
    let contentElement_state1_elSwapper639884;  // This element's content can vary based on screen size
    contentElement_state1_elSwapper639884 = (<IPadLandscapeScreen dataSheetRow={this.props.dataSheetRow} agreementSigned={this.props.agreementSigned} email={this.props.email} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    if (this.props.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_state1_elSwapper639884 = (<IPadLandscapeScreen dataSheetRow={this.props.dataSheetRow} agreementSigned={this.props.agreementSigned} email={this.props.email} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_state1_elSwapper639884 = (<IPadLandscapeScreen dataSheetRow={this.props.dataSheetRow} agreementSigned={this.props.agreementSigned} email={this.props.email} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_state1_elSwapper639884 = (<IPadLandscapeScreen dataSheetRow={this.props.dataSheetRow} agreementSigned={this.props.agreementSigned} email={this.props.email} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_state1_elSwapper639884 = (<IPadLandscapeScreen dataSheetRow={this.props.dataSheetRow} agreementSigned={this.props.agreementSigned} email={this.props.email} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    
    const state1_elHelpOverlayScreen1356134229 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_showHelpOverlay'] : '')) ? (
      <div className="hasNestedComps state1_elHelpOverlayScreen1356134229">
        <HelpOverlayScreen ref={(el)=> this._state1_elHelpOverlayScreen1356134229 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen MainScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state1_elBackground203627" style={style_state1_elBackground203627_outer}>
            <div className="appBg" style={style_state1_elBackground203627} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="flowRow flowRow_MainScreen_state1_elSwapper639884_639884">
          <div className="hasNestedComps state1_elSwapper639884">
            <div>
              {contentElement_state1_elSwapper639884}
            </div>
          </div>
          
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            { state1_elHelpOverlayScreen1356134229 }
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    let transformStateValue = (input) => {
      // This function modifies the state value of screen 'Main Screen'.
      // There is a variable named 'input' that provides the state value.
      // The function must return an integer.
      //
      
      if (this.props.appActions.getDataSheet('fb_newsItems').length > 0) {
      	return 0;
      }
      else {
        	return 1;
      };
    }
    switch (parseInt((transformStateValue(this.props.ds_hideNews) !== undefined) ? transformStateValue(this.props.ds_hideNews) : 0, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
