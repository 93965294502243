import React, { Component } from 'react';
import './App.css';
import btn_icon_103127 from './images/btn_icon_103127.png';
import btn_icon_765033 from './images/btn_icon_765033.png';
import btn_icon_643111 from './images/btn_icon_643111.png';
import btn_icon_30497 from './images/btn_icon_30497.png';
import btn_icon_193962 from './images/btn_icon_193962.png';
import btn_icon_382189 from './images/btn_icon_382189.png';
import btn_icon_350233 from './images/btn_icon_350233.png';
import btn_icon_490693 from './images/btn_icon_490693.png';
import btn_icon_545547 from './images/btn_icon_545547.png';
import btn_icon_828852 from './images/btn_icon_828852.png';

export default class MenuItemOptionsListItem extends Component {

  // Properties used by this component:
  // name, price, order, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    let dsArr = this.props.appActions.getDataSheet('fb_menuItem_options');
	var arrayLength = dsArr.items.length;
	for (var i = 0; i < arrayLength; i++) {
	console.log('Found item in comp: '+dsArr[i]);
	}
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  onClick_state0_elIconDelete = async () => {
    // Remove row from connected sheet
    await this.props.appActions.removeFromDataSheet(this.props.dataSheetId, this.props.dataSheetRow);
  
  }
  
  
  onClick_state0_elTextCopy = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  onClick_state0_elTextName = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  renderState0() {
    const style_state0_elCard1001746 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard1001746_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state0_elIconDelete = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_103127+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconHeart = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_765033+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    let transformPropValue_textCopy = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
          var n = this,
              decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
              decSeparator = decSeparator == undefined ? "." : decSeparator,
              thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
              sign = n < 0 ? "-" : "",
              i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
              j = (j = i.length) > 3 ? j % 3 : 0;
          return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
      };
      
      var myMoney = input / 100;
      
      return ( '$' + myMoney.formatMoney(2,',','.'))  // "$3,543.76"
      
      
      // return input;
    }
    
    const value_textCopy = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textCopy(this.props.price));
    
    const style_state0_elTextCopy = {
      color: '#eb771d',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_textName = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.name);
    
    const style_state0_elTextName = {
      color: '#eb771d',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconButtonCopy3 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_643111+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '86.294%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state0_elIconButtonCopy4 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_30497+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '74.547%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    return (
      <div className="MenuItemOptionsListItem">
        <div className="background">
          <div className="state0_elCard1001746" style={style_state0_elCard1001746_outer}>
            <div className="cardBg" style={style_state0_elCard1001746} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elIconDelete" style={style_state0_elIconDelete} onClick={this.onClick_state0_elIconDelete}  />
          <button className="actionFont state0_elIconHeart" style={style_state0_elIconHeart} />
          <div className="baseFont state0_elTextCopy" style={style_state0_elTextCopy} onClick={this.onClick_state0_elTextCopy} >
            <div>{value_textCopy}</div>
          </div>
          <div className="baseFont state0_elTextName" style={style_state0_elTextName} onClick={this.onClick_state0_elTextName} >
            <div>{value_textName}</div>
          </div>
          <button className="actionFont state0_elIconButtonCopy3" style={style_state0_elIconButtonCopy3} />
          <button className="actionFont state0_elIconButtonCopy4" style={style_state0_elIconButtonCopy4} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  onClick_state1_elIconSave382189 = async () => {
    await this.sendData_state1_elIconSave382189_to_fb_menuItem_options();
  
    this.setState({visualStateIndexOverride: 0})
  
  }
  
  
  textInputChanged_state1_elFieldPrice166322 = (event) => {
    this.setState({fieldPrice: event.target.value});
  }
  
  getValue_state1_elFieldPrice166322 = () => {
    let transformPropValue_fieldPrice = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
          var n = this,
              decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
              decSeparator = decSeparator == undefined ? "." : decSeparator,
              thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
              sign = n < 0 ? "-" : "",
              i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
              j = (j = i.length) > 3 ? j % 3 : 0;
          return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
      };
      
      var myMoney = this.props.price / 100;
      
      return ( '$' + myMoney.formatMoney(2,',','.'))  // "$3,543.76"
      
      
      //return input;
    }
    
    return this.state.fieldPrice !== undefined ? this.state.fieldPrice : (transformPropValue_fieldPrice(this.props.price));
  }
  
  textInputChanged_state1_elFieldName495378 = (event) => {
    this.setState({fieldName: event.target.value});
  }
  
  getValue_state1_elFieldName495378 = () => {
    return this.state.fieldName !== undefined ? this.state.fieldName : (this.props.name);
  }
  
  textInputChanged_state1_elFieldOrder499326 = (event) => {
    this.setState({fieldOrder: event.target.value});
  }
  
  getValue_state1_elFieldOrder499326 = () => {
    return this.state.fieldOrder !== undefined ? this.state.fieldOrder : (this.props.order);
  }
  
  sendData_state1_elIconSave382189_to_fb_menuItem_options = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItem_options');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
      "order": this.props.order,
    };
    row = { ...row, 
      order: this.getValue_state1_elFieldOrder499326(),
      price: this.getValue_state1_elFieldPrice166322(),
      name: this.getValue_state1_elFieldName495378(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      //input.price = Math.round(Number(this.state.fieldPrice)*100);
      
      var price = Math.round(100*Number((this.state.fieldPrice).replace("$", "")));
      input.price = price;
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItem_options', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItem_options', row);
    }
  }
  
  
  renderState1() {
    const style_state1_elCard1001746 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard1001746_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state1_elIconDelete = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_193962+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    const style_state1_elIconSave382189 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_382189+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconHeart350233 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_350233+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state1_elFieldPrice166322 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldName495378 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldOrder499326 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconButtonCopy3490693 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_490693+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state1_elIconButtonCopy4545547 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_545547+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    return (
      <div className="MenuItemOptionsListItem">
        <div className="background">
          <div className="state1_elCard1001746" style={style_state1_elCard1001746_outer}>
            <div className="cardBg" style={style_state1_elCard1001746} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state1_elIconDelete" style={style_state1_elIconDelete} />
          <button className="actionFont state1_elIconSave382189" style={style_state1_elIconSave382189} onClick={this.onClick_state1_elIconSave382189}  />
          <button className="actionFont state1_elIconHeart350233" style={style_state1_elIconHeart350233} />
          <input className="baseFont state1_elFieldPrice166322" style={style_state1_elFieldPrice166322} type="text" placeholder="{price delta}" onChange={this.textInputChanged_state1_elFieldPrice166322} value={this.getValue_state1_elFieldPrice166322()}  />
          <input className="baseFont state1_elFieldName495378" style={style_state1_elFieldName495378} type="text" placeholder="{name}" onChange={this.textInputChanged_state1_elFieldName495378} value={this.getValue_state1_elFieldName495378()}  />
          <input className="baseFont state1_elFieldOrder499326" style={style_state1_elFieldOrder499326} type="text" placeholder="{order}" onChange={this.textInputChanged_state1_elFieldOrder499326} value={this.getValue_state1_elFieldOrder499326()}  />
          <button className="actionFont state1_elIconButtonCopy3490693" style={style_state1_elIconButtonCopy3490693} />
          <button className="actionFont state1_elIconButtonCopy4545547" style={style_state1_elIconButtonCopy4545547} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  onClick_state2_elIconSave382189 = async () => {
    await this.sendData_state2_elIconSave382189_to_fb_menuItem_options();
  
    this.setState({visualStateIndexOverride: 0})
  
  }
  
  
  textInputChanged_state2_elFieldPrice166322 = (event) => {
    this.setState({fieldPrice: event.target.value});
  }
  
  getValue_state2_elFieldPrice166322 = () => {
    let transformPropValue_fieldPrice = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
          var n = this,
              decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
              decSeparator = decSeparator == undefined ? "." : decSeparator,
              thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
              sign = n < 0 ? "-" : "",
              i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
              j = (j = i.length) > 3 ? j % 3 : 0;
          return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
      };
      
      var myMoney = this.props.price / 100;
      
      return ( '$' + myMoney.formatMoney(2,',','.'))  // "$3,543.76"
      
      
      //return input;
    }
    
    return this.state.fieldPrice !== undefined ? this.state.fieldPrice : (transformPropValue_fieldPrice(this.props.price));
  }
  
  textInputChanged_state2_elFieldName495378 = (event) => {
    this.setState({fieldName: event.target.value});
  }
  
  getValue_state2_elFieldName495378 = () => {
    return this.state.fieldName !== undefined ? this.state.fieldName : (this.props.name);
  }
  
  textInputChanged_state2_elFieldOrder499326 = (event) => {
    this.setState({fieldOrder: event.target.value});
  }
  
  getValue_state2_elFieldOrder499326 = () => {
    return this.state.fieldOrder !== undefined ? this.state.fieldOrder : (this.props.order);
  }
  
  sendData_state2_elIconSave382189_to_fb_menuItem_options = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItem_options');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
      "order": this.props.order,
    };
    row = { ...row, 
      order: this.getValue_state2_elFieldOrder499326(),
      price: this.getValue_state2_elFieldPrice166322(),
      name: this.getValue_state2_elFieldName495378(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      //input.price = Math.round(Number(this.state.fieldPrice)*100);
      
      var price = Math.round(100*Number((this.state.fieldPrice).replace("$", "")));
      input.price = price;
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItem_options', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItem_options', row);
    }
  }
  
  
  renderState2() {
    const style_state2_elCard1001746 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elCard1001746_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state2_elIconDelete = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_828852+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    const style_state2_elIconSave382189 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_382189+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state2_elIconHeart350233 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_350233+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state2_elFieldPrice166322 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state2_elFieldName495378 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state2_elFieldOrder499326 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state2_elIconButtonCopy3490693 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_490693+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state2_elIconButtonCopy4545547 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_545547+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    return (
      <div className="MenuItemOptionsListItem">
        <div className="background">
          <div className="state2_elCard1001746" style={style_state2_elCard1001746_outer}>
            <div className="cardBg" style={style_state2_elCard1001746} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state2_elIconDelete" style={style_state2_elIconDelete} />
          <button className="actionFont state2_elIconSave382189" style={style_state2_elIconSave382189} onClick={this.onClick_state2_elIconSave382189}  />
          <button className="actionFont state2_elIconHeart350233" style={style_state2_elIconHeart350233} />
          <input className="baseFont state2_elFieldPrice166322" style={style_state2_elFieldPrice166322} type="text" placeholder="{price delta}" onChange={this.textInputChanged_state2_elFieldPrice166322} value={this.getValue_state2_elFieldPrice166322()}  />
          <input className="baseFont state2_elFieldName495378" style={style_state2_elFieldName495378} type="text" placeholder="{name}" onChange={this.textInputChanged_state2_elFieldName495378} value={this.getValue_state2_elFieldName495378()}  />
          <input className="baseFont state2_elFieldOrder499326" style={style_state2_elFieldOrder499326} type="text" placeholder="{order}" onChange={this.textInputChanged_state2_elFieldOrder499326} value={this.getValue_state2_elFieldOrder499326()}  />
          <button className="actionFont state2_elIconButtonCopy3490693" style={style_state2_elIconButtonCopy3490693} />
          <button className="actionFont state2_elIconButtonCopy4545547" style={style_state2_elIconButtonCopy4545547} />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}
