// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_breadcrumbs extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "BrandID";
    item['level'] = "0";
    item['type'] = "Brand";
    item['price'] = "0";
    item['name'] = "YesOMS";
    
    item = {};
    this.items.push(item);
    item['key'] = "Menukey";
    item['level'] = "1";
    item['type'] = "Menu";
    item['name'] = "- Version1\n";
    item['price'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "MenuItemkey";
    item['level'] = "2";
    item['type'] = "MenuItem";
    item['name'] = "- - Salad";
    item['price'] = "1100";
    
    item = {};
    this.items.push(item);
    item['key'] = "Customizationkey\n";
    item['level'] = "3";
    item['type'] = "MenuItemCustomization";
    item['name'] = "- - - Size";
    item['price'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "Detailkey";
    item['level'] = "4";
    item['type'] = "MenuItemCustomizationDetail";
    item['name'] = "- - - - Large";
    item['price'] = "200";
  }

}
