import React, { Component } from 'react';
import './App.css';
import img_state0_elImage2917248 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import btn_icon_175674 from './images/btn_icon_175674.png';
import img_state1_elImage2917248 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Docsipadlandscape extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elText592387: (<div>Terms and Conditions</div>),
      state0_elText592387_plainText: "Terms and Conditions",
      state1_elText592387: (<div>Terms and Conditions</div>),
      state1_elText592387_plainText: "Terms and Conditions",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elText592387 = async () => {
  
  
  }
  
  
  textInputChanged_state0_elFieldTitle758366 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state0_elFieldTitle758366 = () => {
    return this.state.fieldTitle || '';
  }
  
  onClick_state0_elIconButton175674 = async () => {
  
  
  }
  
  
  renderState0() {
    
    const style_state0_elRectangle2660527 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_state0_elRectangle538524 = {
      background: 'rgba(0, 0, 0, 1.000)',
     };
    const style_state0_elImage2917248 = {
      backgroundImage: 'url('+img_state0_elImage2917248+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state0_elText592387 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elFieldTitle758366 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_state0_elFieldTitle758366_outer = {
      display: 'none',
     };
    
    const style_state0_elIconButton175674 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_175674+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elIconButton175674_outer = {
      display: 'none',
     };
    
    const style_state0_elButton209167 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
     };
    
    return (
      <div className="Docsipadlandscape">
        <div className="background">
          <div className="state0_elRectangle2660527" style={style_state0_elRectangle2660527} />
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elRectangle538524">
            <div style={style_state0_elRectangle538524} />
          </div>
          
          <div className="state0_elImage2917248">
            <div style={style_state0_elImage2917248} />
          </div>
          
          <div className="state0_elText592387">
            <div className="font-futuraMedium" style={style_state0_elText592387} onClick={this.onClick_state0_elText592387} >
              <div>{this.state.state0_elText592387}</div>
            </div>
          </div>
          
          <div className="state0_elSpacer108014">
            <div />
          </div>
          
          <div className="state0_elFieldTitle758366" style={style_state0_elFieldTitle758366_outer}>
            <input className="font-futuraMedium" style={style_state0_elFieldTitle758366} type="text" placeholder="{Home}" onChange={this.textInputChanged_state0_elFieldTitle758366} value={this.getValue_state0_elFieldTitle758366()}  />
          </div>
          
          <div className="state0_elIconButton175674" style={style_state0_elIconButton175674_outer}>
            <button className="actionFont" style={style_state0_elIconButton175674} onClick={this.onClick_state0_elIconButton175674}  />
          </div>
          
          <div className="state0_elSpacer2732155">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <Button className="actionFont state0_elButton209167" style={style_state0_elButton209167}  color="accent" >
            Accept
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elText592387 = async () => {
  
  
  }
  
  
  textInputChanged_state1_elFieldTitle758366 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state1_elFieldTitle758366 = () => {
    return this.state.fieldTitle || '';
  }
  
  onClick_state1_elIconButton175674 = async () => {
  
  
  }
  
  
  renderState1() {
    
    const style_state1_elRectangle2660527 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_state1_elRectangle538524 = {
      background: 'rgba(0, 0, 0, 1.000)',
     };
    const style_state1_elImage2917248 = {
      backgroundImage: 'url('+img_state1_elImage2917248+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state1_elText592387 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldTitle758366 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_state1_elFieldTitle758366_outer = {
      display: 'none',
     };
    
    const style_state1_elIconButton175674 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_175674+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elIconButton175674_outer = {
      display: 'none',
     };
    
    const style_state1_elButton209167 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
     };
    
    return (
      <div className="Docsipadlandscape">
        <div className="background">
          <div className="state1_elRectangle2660527" style={style_state1_elRectangle2660527} />
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elRectangle538524">
            <div style={style_state1_elRectangle538524} />
          </div>
          
          <div className="state1_elImage2917248">
            <div style={style_state1_elImage2917248} />
          </div>
          
          <div className="state1_elText592387">
            <div className="font-futuraMedium" style={style_state1_elText592387} onClick={this.onClick_state1_elText592387} >
              <div>{this.state.state1_elText592387}</div>
            </div>
          </div>
          
          <div className="state1_elSpacer108014">
            <div />
          </div>
          
          <div className="state1_elFieldTitle758366" style={style_state1_elFieldTitle758366_outer}>
            <input className="font-futuraMedium" style={style_state1_elFieldTitle758366} type="text" placeholder="{Home}" onChange={this.textInputChanged_state1_elFieldTitle758366} value={this.getValue_state1_elFieldTitle758366()}  />
          </div>
          
          <div className="state1_elIconButton175674" style={style_state1_elIconButton175674_outer}>
            <button className="actionFont" style={style_state1_elIconButton175674} onClick={this.onClick_state1_elIconButton175674}  />
          </div>
          
          <div className="state1_elSpacer2732155">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <Button className="actionFont state1_elButton209167" style={style_state1_elButton209167}  color="accent" >
            Accept
          </Button>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
