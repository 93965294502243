import React, { Component } from 'react';
import './App.css';
import btn_icon_157837 from './images/btn_icon_157837.png';
import btn_icon_672241 from './images/btn_icon_672241.png';
import btn_icon_710594 from './images/btn_icon_710594.png';
import btn_icon_793629 from './images/btn_icon_793629.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class MenuItemOptionsAdd extends Component {

  // Properties used by this component:
  // name, price, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elIconButtonCopy2157837 = async () => {
    await this.sendData_state0_elIconButtonCopy2157837_to_fb_menuItem_options();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      fieldName: "",
      fieldPrice: "",
    });
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_menuItem_options'], this.props.appActions.serviceOptions_fb_menuItem_options, false);
  
  }
  
  
  onClick_state0_elIconButtonCopy = async () => {
  
  }
  
  
  textInputChanged_state0_elFieldPrice = (event) => {
    this.setState({fieldPrice: event.target.value});
  }
  
  getValue_state0_elFieldPrice = () => {
    return this.state.fieldPrice !== undefined ? this.state.fieldPrice : (this.props.price);
  }
  
  textInputChanged_state0_elFieldName = (event) => {
    this.setState({fieldName: event.target.value});
  }
  
  getValue_state0_elFieldName = () => {
    return this.state.fieldName !== undefined ? this.state.fieldName : (this.props.name);
  }
  
  onClick_state0_elButtonAddOption272196616 = async () => {
    await this.sendData_state0_elButtonAddOption272196616_to_fb_menuItem_options();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      fieldName: "",
      fieldPrice: "",
    });
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_menuItem_options'], this.props.appActions.serviceOptions_fb_menuItem_options, false);
  
  }
  
  
  sendData_state0_elIconButtonCopy2157837_to_fb_menuItem_options = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItem_options');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
    };
    row = { ...row, 
      order: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      name: this.getValue_state0_elFieldName(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      //input.price = Number(this.state.fieldPrice);
      
      var price = Math.round(100*Number((this.state.fieldPrice).replace("$", "")));
      input.price = price;
      
      
      
      
      return input;  }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItem_options', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItem_options', row);
    }
  }
  
  
  sendData_state0_elButtonAddOption272196616_to_fb_menuItem_options = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItem_options');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
    };
    row = { ...row, 
      name: this.getValue_state0_elFieldName(),
      price: this.getValue_state0_elFieldPrice(),
      order: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      //input.price = Number(this.state.fieldPrice);
      
      var price = Math.round(100*Number((this.state.fieldPrice).replace("$", "")));
      input.price = price;
      
      
      
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItem_options', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItem_options', row);
    }
  }
  
  
  renderState0() {
    const style_state0_elCard927629 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard927629_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state0_elIconButtonCopy2157837 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_157837+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_672241+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconButton710594 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_710594+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state0_elFieldPrice = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state0_elFieldName = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state0_elButtonAddOption272196616 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemOptionsAdd">
        <div className="background">
          <div className="state0_elCard927629" style={style_state0_elCard927629_outer}>
            <div className="cardBg" style={style_state0_elCard927629} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elIconButtonCopy2157837" style={style_state0_elIconButtonCopy2157837} onClick={this.onClick_state0_elIconButtonCopy2157837}  />
          <button className="actionFont state0_elIconButtonCopy" style={style_state0_elIconButtonCopy} onClick={this.onClick_state0_elIconButtonCopy}  />
          <button className="actionFont state0_elIconButton710594" style={style_state0_elIconButton710594} />
          <input className="baseFont state0_elFieldPrice" style={style_state0_elFieldPrice} type="text" placeholder={this.props.locStrings.menuitemlistitemadd3_fieldcopy2_233295} onChange={this.textInputChanged_state0_elFieldPrice} value={this.getValue_state0_elFieldPrice()}  />
          <input className="baseFont state0_elFieldName" style={style_state0_elFieldName} type="text" placeholder={this.props.locStrings.menuitemlistitemadd3_fieldcopy_707131} onChange={this.textInputChanged_state0_elFieldName} value={this.getValue_state0_elFieldName()}  />
          <Button className="actionFont state0_elButtonAddOption272196616" style={style_state0_elButtonAddOption272196616}  color="accent" onClick={this.onClick_state0_elButtonAddOption272196616} >
            {this.props.locStrings.menuitemoptionsadd_buttonaddmenuitem_272196616}
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elIconButtonCopy2157837 = async () => {
    await this.sendData_state1_elIconButtonCopy2157837_to_fb_menuItem_options();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      fieldName: "",
      fieldPrice: "",
    });
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_menuItem_options'], this.props.appActions.serviceOptions_fb_menuItem_options, false);
  
  }
  
  
  onClick_state1_elIconButtonCopy = async () => {
  
  }
  
  
  textInputChanged_state1_elFieldName = (event) => {
    this.setState({fieldName: event.target.value});
  }
  
  getValue_state1_elFieldName = () => {
    return this.state.fieldName !== undefined ? this.state.fieldName : (this.props.name);
  }
  
  onClick_state1_elButtonAddOption272196616 = async () => {
    await this.sendData_state1_elButtonAddOption272196616_to_fb_menuItem_options();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      fieldName: "",
      fieldPrice: "",
    });
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_menuItem_options'], this.props.appActions.serviceOptions_fb_menuItem_options, false);
  
  }
  
  
  sendData_state1_elIconButtonCopy2157837_to_fb_menuItem_options = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItem_options');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
    };
    row = { ...row, 
      order: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      name: null /* unable to collect data for 'field name' */,
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      //input.price = Number(this.state.fieldPrice);
      
      var price = Math.round(100*Number((this.state.fieldPrice).replace("$", "")));
      input.price = price;
      
      
      
      
      return input;  }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItem_options', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItem_options', row);
    }
  }
  
  
  sendData_state1_elButtonAddOption272196616_to_fb_menuItem_options = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItem_options');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
    };
    row = { ...row, 
      name: null /* unable to collect data for 'field name' */,
      price: null /* unable to collect data for 'fieldPrice' */,
      order: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      //input.price = Number(this.state.fieldPrice);
      
      var price = Math.round(100*Number((this.state.fieldPrice).replace("$", "")));
      input.price = price;
      
      
      
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItem_options', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItem_options', row);
    }
  }
  
  
  renderState1() {
    const style_state1_elCard927629 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard927629_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state1_elIconButtonCopy2157837 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_157837+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_793629+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconButton710594 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_710594+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state1_elFieldName = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elButtonAddOption272196616 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemOptionsAdd">
        <div className="background">
          <div className="state1_elCard927629" style={style_state1_elCard927629_outer}>
            <div className="cardBg" style={style_state1_elCard927629} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state1_elIconButtonCopy2157837" style={style_state1_elIconButtonCopy2157837} onClick={this.onClick_state1_elIconButtonCopy2157837}  />
          <button className="actionFont state1_elIconButtonCopy" style={style_state1_elIconButtonCopy} onClick={this.onClick_state1_elIconButtonCopy}  />
          <button className="actionFont state1_elIconButton710594" style={style_state1_elIconButton710594} />
          <input className="baseFont state1_elFieldName" style={style_state1_elFieldName} type="text" placeholder="{header title}" onChange={this.textInputChanged_state1_elFieldName} value={this.getValue_state1_elFieldName()}  />
          <Button className="actionFont state1_elButtonAddOption272196616" style={style_state1_elButtonAddOption272196616}  color="accent" onClick={this.onClick_state1_elButtonAddOption272196616} >
            {this.props.locStrings.menuitemoptionsadd_buttonaddmenuitem_272196616}
          </Button>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
