import React, { Component } from 'react';
import './App.css';
import Comp4 from './Comp4';

export default class DeviceInstructions2 extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elFieldCopy = (event) => {
    this.setState({fieldCopy: event.target.value});
  }
  
  getValue_elFieldCopy = () => {
    return this.state.fieldCopy !== undefined ? this.state.fieldCopy : ((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''));
  }
  
  render() {
    
    const style_elRectangle = {
      background: 'rgba(255, 255, 255, 1.000)',
      borderRadius: '10.0px',
      boxShadow: '0.0px 2.0px 3px rgba(0, 0, 0, 0.3000)',
     };
    const style_elText = {
      fontSize: 15.9,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elFieldCopy = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 14.0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elFieldCopy_outer = {
      display: 'none',
     };
    const style_elTextCopy2 = {
      fontSize: 14.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elTextCopy4 = {
      fontSize: 14.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elTextCopy3 = {
      fontSize: 14.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    const value_textCopy = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_activeBrandID'] : ''));
    
    const style_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextCopy_outer = {
      display: 'none',
     };
    
    return (
      <div className="DeviceInstructions2">
        <div className="background">
          <div className="elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="elText">
            <div className="font-futuraMedium" style={style_elText}>
              <div>{this.props.locStrings.deviceinstructions2_text_114724600}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elComp">
            <div>
              <Comp4 ref={(el)=> this._elComp = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elFieldCopy" style={style_elFieldCopy_outer}>
            <input className="font-futuraMedium" style={style_elFieldCopy} type="text" placeholder={this.props.locStrings.deviceinstructions2_fieldcopy_757918720} onChange={this.textInputChanged_elFieldCopy} value={this.getValue_elFieldCopy()}  />
          </div>
          
          <div className="elTextCopy2">
            <div className="font-avenirBook" style={style_elTextCopy2}>
              <div><div dangerouslySetInnerHTML={{__html: this.props.locStrings.deviceinstructions2_textcopy2_589751691.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="elTextCopy4">
            <div className="font-avenirBook" style={style_elTextCopy4}>
              <div>{this.props.locStrings.deviceinstructions2_textcopy4_778838260}</div>
            </div>
          </div>
          
          <div className="elTextCopy3">
            <div className="font-avenirBook" style={style_elTextCopy3}>
              <div>{this.props.locStrings.deviceinstructions2_textcopy3_1398069168}</div>
            </div>
          </div>
          
          <div className="elTextCopy" style={style_elTextCopy_outer}>
            <div className="baseFont" style={style_elTextCopy}>
              <div>{value_textCopy}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
