import React, { Component } from 'react';
import './App.css';
import btn_icon_2068655254 from './images/btn_icon_2068655254.png';
import btn_icon_1910482954 from './images/btn_icon_1910482954.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class LocationListItemAdd extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elIconButtonCopy2 = async () => {
    await this.sendData_elIconButtonCopy2_to_fb_locations();
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_my_locations'], this.props.appActions.serviceOptions_fb_my_locations, false);
  
    // Perform action 'Clear text' on element 'field copy'
    this.setState({
      fieldCopy: "",
    });
  
  }
  
  
  onClick_elIconButtonCopy = async () => {
  
  }
  
  
  textInputChanged_elFieldCopy = (event) => {
    this.setState({fieldCopy: event.target.value});
  }
  
  getValue_elFieldCopy = () => {
    return this.state.fieldCopy || '';
  }
  
  onClick_elButtonAddMenu = async () => {
    await this.sendData_elButtonAddMenu_to_fb_locations();
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_my_locations'], this.props.appActions.serviceOptions_fb_my_locations, false);
  
    // Perform action 'Clear text' on element 'field copy'
    this.setState({
      fieldCopy: "",
    });
  
  }
  
  
  sendData_elIconButtonCopy2_to_fb_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_locations');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
      name: this.getValue_elFieldCopy(),
      isActive: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_locations', row);
    }
  }
  
  
  sendData_elButtonAddMenu_to_fb_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_locations');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
      associatedBrand: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''),
      name: this.getValue_elFieldCopy(),
      isActive: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''),
    };
  
    const transformData = (input) => {
        var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      input.description = ''; 
      input.geoLocation = {"_long":0,"_lat":0};
      input.isActive = true;
      
      input.hours = 
      {
      "sunday_open":-1,
      "sunday_close":null,
      "monday_open":-1,
      "monday_close":null,
      "tuesday_open":-1,
      "tuesday_close":null,
      "wednesday_open":-1,
      "wednesday_close":null,
      "thursday_open":-1,
      "thursday_close":null,
      "friday_open":-1,
      "friday_close":null,
      "saturday_open":-1,
      "saturday_close":null,
      };
      
      input.geohash = '9q8yy';
      
      input.address = {"zipCode":"","country":"","streetNumber":"","city":"","streetName":"","state":""};
      
      
      return input;  }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_locations', row);
    }
  }
  
  
  render() {
    const style_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_elCard_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_elIconButtonCopy2 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_2068655254+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1910482954+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elFieldCopy = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elButtonAddMenu = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="LocationListItemAdd">
        <div className="background">
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" style={style_elCard} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont elIconButtonCopy2" style={style_elIconButtonCopy2} onClick={this.onClick_elIconButtonCopy2}  />
          <button className="actionFont elIconButtonCopy" style={style_elIconButtonCopy} onClick={this.onClick_elIconButtonCopy}  />
          <input className="baseFont elFieldCopy" style={style_elFieldCopy} type="text" placeholder={this.props.locStrings.menulistitemadd2_fieldcopy_234072440} onChange={this.textInputChanged_elFieldCopy} value={this.getValue_elFieldCopy()}  />
          <Button className="actionFont elButtonAddMenu" style={style_elButtonAddMenu}  color="accent" onClick={this.onClick_elButtonAddMenu} >
            {this.props.locStrings.menulistitemadd2_buttonaddmenu_1382508400}
          </Button>
        </div>
      </div>
    )
  }
  
}
