import React, { Component } from 'react';
import './App.css';
import btn_icon_729416 from './images/btn_icon_729416.png';
import img_state0_elImage2 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import btn_icon_592229 from './images/btn_icon_592229.png';
import Comp5 from './Comp5';
import MenuItemOptionsAdd from './MenuItemOptionsAdd';
import MenuItemOptionsListItem from './MenuItemOptionsListItem';
import BottomNavBar from './BottomNavBar';
import img_state0_elBrandLogoImage from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state0_elImage166520 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';
import IPhoneMockupComponent from './IPhoneMockupComponent';
import Footer from './Footer';
import Header from './Header';
import Component4 from './Component4';
import HelpOverlayScreen from './HelpOverlayScreen';
import btn_icon_back_menuitemoptions from './images/btn_icon_back_menuitemoptions.png';
import img_state1_elImage2 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elBrandLogoImage from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elImage166520 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';
import Appbar from 'muicss/lib/react/appbar';

export default class MenuItemOptionsScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, dataSheetRow, brandImageURL, bottomNavIconActiveColor, bottomNavIconColor, iconHomeURL, iconMenuURL, iconProfileURL, iconMoreURL

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elText757752: (<div>Brand Name</div>),
      state0_elText757752_plainText: "Brand Name",
      state0_elTextCopy657498: (<div>Customization Details</div>),
      state0_elTextCopy657498_plainText: "Customization Details",
      state0_elText_brand_name490714: (<div>Customization  Name</div>),
      state0_elText_brand_name490714_plainText: "Customization  Name",
      state0_elTextCopy2109982: (<div>Customization Description</div>),
      state0_elTextCopy2109982_plainText: "Customization Description",
      state0_elTextCopy3625345: (<div>Item Customization Options</div>),
      state0_elTextCopy3625345_plainText: "Item Customization Options",
      state1_elText757752: (<div>Brand Name</div>),
      state1_elText757752_plainText: "Brand Name",
      state1_elTextCopy657498: (<div>Customization Details</div>),
      state1_elTextCopy657498_plainText: "Customization Details",
      state1_elText_brand_name490714: (<div>Customization  Name</div>),
      state1_elText_brand_name490714_plainText: "Customization  Name",
      state1_elTextCopy2109982: (<div>Customization Description</div>),
      state1_elTextCopy2109982_plainText: "Customization Description",
      state1_elTextCopy3625345: (<div>Item Customization Options</div>),
      state1_elTextCopy3625345_plainText: "Item Customization Options",
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menuItem_options'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menuItem_options;
      if ( !this.props.appActions.dataSheetLoaded['fb_menuItem_options']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems/$slot(ds_selected_menuItem_key)/customizations/$slot(ds_selected_menuItemOption_key)/options", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menuItem_options'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fd_menuItemCustomizations'];
      let serviceOptions = this.props.appActions.serviceOptions_fd_menuItemCustomizations;
      if ( !this.props.appActions.dataSheetLoaded['fd_menuItemCustomizations']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems/$slot(ds_selected_menuItem_key)/customizations", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fd_menuItemCustomizations'] = true;
      }
    }
    let dsArr = this.props.appActions.getDataSheet('fb_menuItem_options');
	var arrayLength = dsArr.items.length;
	for (var i = 0; i < arrayLength; i++) {
	console.log('Found item: '+dsArr[i]);
	}
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    
    let dsArr = this.props.appActions.getDataSheet('fb_menuItem_options');
	var arrayLength = dsArr.items.length;
	for (var i = 0; i < arrayLength; i++) {
	console.log('Found item: '+dsArr[i]);
	}
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elIconButton2729416 = async () => {
    let newVal = "yyyy";
    this.props.appActions.updateDataSlot('ds_selected_menuItemOption_key', newVal);
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  textInputChanged_state0_elFieldTitle908003 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state0_elFieldTitle908003 = () => {
    return this.state.fieldTitle !== undefined ? this.state.fieldTitle : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  onClick_state0_elIconButton592229 = async () => {
  
  
  }
  
  
  textInputChanged_state0_elFieldCustomization_name = (event) => {
    this.setState({fieldCustomization_name: event.target.value});
  }
  
  getValue_state0_elFieldCustomization_name = () => {
    return this.state.fieldCustomization_name !== undefined ? this.state.fieldCustomization_name : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  textAreaChanged_state0_elTextarea926311 = (event) => {
    this.setState({textarea: event.target.value});
  }
  
  getValue_state0_elTextarea926311 = () => {
    return this.state.textarea !== undefined ? this.state.textarea : (this.props.dataSheetRow ? this.props.dataSheetRow.description : '');
  }
  
  
  getValue_state0_elField_isActiveCheckbox944726 = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : ((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''));
  }
  
  checkboxChanged_state0_elField_isActiveCheckbox944726 = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')}, async () => {
      let newVal = "field_isActiveCheckbox";
      this.props.appActions.updateDataSlot('(null)', newVal);
      
    });
  }
  
  onClick_state0_elComp1119688673 = async () => {
    await this.sendData_state0_elComp1119688673_to_fd_menuItemCustomizations();
  
  }
  
  
  
  sendData_state0_elComp1119688673_to_fd_menuItemCustomizations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fd_menuItemCustomizations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
    };
    row = { ...row, 
      name: this.getValue_state0_elFieldCustomization_name(),
      description: this.getValue_state0_elTextarea926311(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      return input;  }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fd_menuItemCustomizations', row);
    } else {
      return this.props.appActions.addToDataSheet('fd_menuItemCustomizations', row);
    }
  }
  
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_fb_currentBrand = this.props.dataSheets['fb_currentBrand'];
    // eslint-disable-next-line
    const dataSheet_fb_menuItem_options = this.props.dataSheets['fb_menuItem_options'];
    const style_state0_elBackground304257 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground304257_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    
    const style_state0_elRectangle2244616 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_state0_elIconButton2729416 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_729416+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elImage2 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedMenuItemImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
    
    const style_state0_elText757752 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const placeholderValue_fieldTitle = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_state0_elFieldTitle908003 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_state0_elFieldTitle908003_outer = {
      display: 'none',
     };
    
    const style_state0_elIconButton592229 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_592229+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elIconButton592229_outer = {
      display: 'none',
     };
    const style_state0_elTextCopy657498 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state0_elText_brand_name490714 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elFieldCustomization_name = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy2109982 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elTextarea926311 = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_state0_elField_isActiveCheckbox944726();
    
    const style_state0_elField_isActiveCheckbox944726 = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elComp1119688673 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy3625345 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    listComps_list['_tail'] = <MenuItemOptionsAdd appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    items_list = items_list.concat(this.props.appActions.getDataSheet('fb_menuItem_options').items);
    items_list.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._state0_elList679121_items = [];
    
    const style_state0_elList679121 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const fillColorValue_rectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_state0_elRectangle713918 = {
      background: (fillColorValue_rectangle && fillColorValue_rectangle.length > 0) ? fillColorValue_rectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const style_state0_elBottomNavBar643366 = {
      display: 'none',
     };
    const fillColorValue_card2 = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    const style_state0_elCard2694159 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard2694159_outer = {
      display: 'none',
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state0_elBrandLogoImage = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.brandImageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      display: 'none',
     };
    const style_state0_elImage166520 = {
      height: 'auto',
      display: 'none',
     };
    const state0_elComponent942473 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state0_elComponent942473">
        <Component4 ref={(el)=> this._state0_elComponent942473 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const state0_elHelpOverlayScreen1069123456 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_showHelpOverlay'] : '')) ? (
      <div className="hasNestedComps state0_elHelpOverlayScreen1069123456">
        <HelpOverlayScreen visualStateIndex={4} ref={(el)=> this._state0_elHelpOverlayScreen1069123456 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen MenuItemOptionsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBackground304257" style={style_state0_elBackground304257_outer}>
            <div className="appBg" style={style_state0_elBackground304257} />
          </div>
          
          <div className="state0_elRectangle2244616" style={style_state0_elRectangle2244616} />
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state0_elIconButton2729416">
            <button className="actionFont" style={style_state0_elIconButton2729416} onClick={this.onClick_state0_elIconButton2729416}  />
          </div>
          
          <div className="state0_elImage2">
            <div style={style_state0_elImage2} />
          </div>
          
          <div className="state0_elText757752">
            <div className="font-futuraMedium" style={style_state0_elText757752}>
              <div>{value_text}</div>
            </div>
          </div>
          
          <div className="state0_elSpacer811187">
            <div />
          </div>
          
          <div className="state0_elFieldTitle908003" style={style_state0_elFieldTitle908003_outer}>
            <input className="font-futuraMedium" style={style_state0_elFieldTitle908003} type="text" placeholder={placeholderValue_fieldTitle} onChange={this.textInputChanged_state0_elFieldTitle908003} value={this.getValue_state0_elFieldTitle908003()}  />
          </div>
          
          <div className="state0_elIconButton592229" style={style_state0_elIconButton592229_outer}>
            <button className="actionFont" style={style_state0_elIconButton592229} onClick={this.onClick_state0_elIconButton592229}  />
          </div>
          
          <div className="state0_elSpacer2231034">
            <div />
          </div>
          
          <div className="state0_elTextCopy657498">
            <div className="font-futuraMedium" style={style_state0_elTextCopy657498}>
              <div>{this.state.state0_elTextCopy657498}</div>
            </div>
          </div>
          
          <div className="state0_elText_brand_name490714">
            <div className="font-avenirBook" style={style_state0_elText_brand_name490714}>
              <div>{this.state.state0_elText_brand_name490714}</div>
            </div>
          </div>
          
          <div className="state0_elFieldCustomization_name">
            <input className="font-avenirNextRegular" style={style_state0_elFieldCustomization_name} type="text" placeholder={this.props.locStrings.menuitemoptions_field_brand_name_219269} onChange={this.textInputChanged_state0_elFieldCustomization_name} value={this.getValue_state0_elFieldCustomization_name()}  />
          </div>
          
          <div className="state0_elTextCopy2109982">
            <div className="font-avenirBook" style={style_state0_elTextCopy2109982}>
              <div>{this.state.state0_elTextCopy2109982}</div>
            </div>
          </div>
          
          <div className="state0_elTextarea926311">
            <textarea className="font-avenirNextRegular" style={style_state0_elTextarea926311}  placeholder="{optional - describe the customization if it needs further clarification}" onChange={this.textAreaChanged_state0_elTextarea926311} value={this.getValue_state0_elTextarea926311()}  />
          </div>
          
          <div className="state0_elField_isActiveCheckbox944726">
            <Checkbox className="font-openSans" style={style_state0_elField_isActiveCheckbox944726}  label="Customization is Active" checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_state0_elField_isActiveCheckbox944726} />
          </div>
          
          <div className="hasNestedComps state0_elComp1119688673">
            <div style={style_state0_elComp1119688673} onClick={this.onClick_state0_elComp1119688673} >
              <Comp5 ref={(el)=> this._state0_elComp1119688673 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state0_elTextCopy3625345">
            <div className="font-futuraMedium" style={style_state0_elTextCopy3625345}>
              <div>{this.state.state0_elTextCopy3625345}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elList679121">
            <ul style={style_state0_elList679121}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemOptionsListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_menuItem_options'} dataSheetRow={row} {...{ 'name': row['name'], 'price': row['price'], 'order': row['order'], }} ref={(el) => {if (el) this._state0_elList679121_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state0_elList679121_endMarker = el} />
            </ul>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">MenuItem Options</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_menuitemoptions} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state0_elRectangle713918" style={style_state0_elRectangle713918} />
            <div className="hasNestedComps state0_elBottomNavBar643366" style={style_state0_elBottomNavBar643366}>
              <BottomNavBar ref={(el)=> this._state0_elBottomNavBar643366 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="state0_elCard2694159" style={style_state0_elCard2694159_outer}>
              <div style={style_state0_elCard2694159} />
            </div>
            
            <div className="state0_elBrandLogoImage" style={style_state0_elBrandLogoImage} />
            <img className="state0_elImage166520" style={style_state0_elImage166520} src={img_state0_elImage166520} alt=""  />
            <div className="hasNestedComps state0_elIPhoneMockupComponent1704385770">
              <IPhoneMockupComponent {...dataSheet_fb_currentBrand.items[0]} visualStateIndex={2} ref={(el)=> this._state0_elIPhoneMockupComponent1704385770 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elFooter898468">
              <Footer ref={(el)=> this._state0_elFooter898468 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elHeader66715">
              <Header ref={(el)=> this._state0_elHeader66715 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state0_elComponent942473 }
            { state0_elHelpOverlayScreen1069123456 }
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elIconButton2729416 = async () => {
    let newVal = "yyyy";
    this.props.appActions.updateDataSlot('ds_selected_menuItemOption_key', newVal);
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  textInputChanged_state1_elFieldTitle908003 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state1_elFieldTitle908003 = () => {
    return this.state.fieldTitle !== undefined ? this.state.fieldTitle : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  onClick_state1_elIconButton592229 = async () => {
  
  
  }
  
  
  textInputChanged_state1_elFieldCustomization_name = (event) => {
    this.setState({fieldCustomization_name: event.target.value});
  }
  
  getValue_state1_elFieldCustomization_name = () => {
    return this.state.fieldCustomization_name !== undefined ? this.state.fieldCustomization_name : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  textAreaChanged_state1_elTextarea926311 = (event) => {
    this.setState({textarea: event.target.value});
  }
  
  getValue_state1_elTextarea926311 = () => {
    return this.state.textarea !== undefined ? this.state.textarea : (this.props.dataSheetRow ? this.props.dataSheetRow.description : '');
  }
  
  
  getValue_state1_elField_isActiveCheckbox944726 = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : ((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''));
  }
  
  checkboxChanged_state1_elField_isActiveCheckbox944726 = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')}, async () => {
      let newVal = "field_isActiveCheckbox";
      this.props.appActions.updateDataSlot('(null)', newVal);
      
    });
  }
  
  onClick_state1_elComp1119688673 = async () => {
    await this.sendData_state1_elComp1119688673_to_fd_menuItemCustomizations();
  
  }
  
  
  
  sendData_state1_elComp1119688673_to_fd_menuItemCustomizations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fd_menuItemCustomizations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
    };
    row = { ...row, 
      name: null /* unable to collect data for 'field customization_name' */,
      description: this.getValue_state1_elTextarea926311(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      return input;  }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fd_menuItemCustomizations', row);
    } else {
      return this.props.appActions.addToDataSheet('fd_menuItemCustomizations', row);
    }
  }
  
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_fb_currentBrand = this.props.dataSheets['fb_currentBrand'];
    // eslint-disable-next-line
    const dataSheet_fb_menuItem_options = this.props.dataSheets['fb_menuItem_options'];
    const style_state1_elBackground304257 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground304257_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    
    const style_state1_elRectangle2244616 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_state1_elIconButton2729416 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_729416+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elImage2 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
    
    const style_state1_elText757752 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const placeholderValue_fieldTitle = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_state1_elFieldTitle908003 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_state1_elFieldTitle908003_outer = {
      display: 'none',
     };
    
    const style_state1_elIconButton592229 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_592229+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elIconButton592229_outer = {
      display: 'none',
     };
    const style_state1_elTextCopy657498 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state1_elText_brand_name490714 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_fieldCustomization_name = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_state1_elFieldCustomization_name = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state1_elTextCopy2109982 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elTextarea926311 = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_state1_elField_isActiveCheckbox944726();
    
    const style_state1_elField_isActiveCheckbox944726 = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elComp1119688673 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elTextCopy3625345 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    listComps_list['_tail'] = <MenuItemOptionsAdd appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    items_list = items_list.concat(this.props.appActions.getDataSheet('fb_menuItem_options').items);
    items_list.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._state1_elList679121_items = [];
    
    const style_state1_elList679121 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const fillColorValue_rectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_state1_elRectangle713918 = {
      background: (fillColorValue_rectangle && fillColorValue_rectangle.length > 0) ? fillColorValue_rectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const style_state1_elBottomNavBar643366 = {
      display: 'none',
     };
    const fillColorValue_card2 = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    const style_state1_elCard2694159 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard2694159_outer = {
      display: 'none',
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state1_elBrandLogoImage = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow.brandImageURL : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      display: 'none',
     };
    const style_state1_elImage166520 = {
      height: 'auto',
      display: 'none',
     };
    const state1_elComponent942473 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state1_elComponent942473">
        <Component4 ref={(el)=> this._state1_elComponent942473 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const state1_elHelpOverlayScreen1069123456 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_showHelpOverlay'] : '')) ? (
      <div className="hasNestedComps state1_elHelpOverlayScreen1069123456">
        <HelpOverlayScreen visualStateIndex={4} ref={(el)=> this._state1_elHelpOverlayScreen1069123456 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen MenuItemOptionsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state1_elBackground304257" style={style_state1_elBackground304257_outer}>
            <div className="appBg" style={style_state1_elBackground304257} />
          </div>
          
          <div className="state1_elRectangle2244616" style={style_state1_elRectangle2244616} />
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state1_elIconButton2729416">
            <button className="actionFont" style={style_state1_elIconButton2729416} onClick={this.onClick_state1_elIconButton2729416}  />
          </div>
          
          <div className="state1_elImage2">
            <div style={style_state1_elImage2} />
          </div>
          
          <div className="state1_elText757752">
            <div className="font-futuraMedium" style={style_state1_elText757752}>
              <div>{value_text}</div>
            </div>
          </div>
          
          <div className="state1_elSpacer811187">
            <div />
          </div>
          
          <div className="state1_elFieldTitle908003" style={style_state1_elFieldTitle908003_outer}>
            <input className="font-futuraMedium" style={style_state1_elFieldTitle908003} type="text" placeholder={placeholderValue_fieldTitle} onChange={this.textInputChanged_state1_elFieldTitle908003} value={this.getValue_state1_elFieldTitle908003()}  />
          </div>
          
          <div className="state1_elIconButton592229" style={style_state1_elIconButton592229_outer}>
            <button className="actionFont" style={style_state1_elIconButton592229} onClick={this.onClick_state1_elIconButton592229}  />
          </div>
          
          <div className="state1_elSpacer2231034">
            <div />
          </div>
          
          <div className="state1_elTextCopy657498">
            <div className="font-futuraMedium" style={style_state1_elTextCopy657498}>
              <div>{this.state.state1_elTextCopy657498}</div>
            </div>
          </div>
          
          <div className="state1_elText_brand_name490714">
            <div className="font-avenirBook" style={style_state1_elText_brand_name490714}>
              <div>{this.state.state1_elText_brand_name490714}</div>
            </div>
          </div>
          
          <div className="state1_elFieldCustomization_name">
            <input className="font-avenirNextRegular" style={style_state1_elFieldCustomization_name} type="text" placeholder={placeholderValue_fieldCustomization_name} onChange={this.textInputChanged_state1_elFieldCustomization_name} value={this.getValue_state1_elFieldCustomization_name()}  />
          </div>
          
          <div className="state1_elTextCopy2109982">
            <div className="font-avenirBook" style={style_state1_elTextCopy2109982}>
              <div>{this.state.state1_elTextCopy2109982}</div>
            </div>
          </div>
          
          <div className="state1_elTextarea926311">
            <textarea className="font-avenirNextRegular" style={style_state1_elTextarea926311}  placeholder="{optional - describe the customization if it needs further clarification}" onChange={this.textAreaChanged_state1_elTextarea926311} value={this.getValue_state1_elTextarea926311()}  />
          </div>
          
          <div className="state1_elField_isActiveCheckbox944726">
            <Checkbox className="font-openSans" style={style_state1_elField_isActiveCheckbox944726}  label="Customization is Active" checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_state1_elField_isActiveCheckbox944726} />
          </div>
          
          <div className="hasNestedComps state1_elComp1119688673">
            <div style={style_state1_elComp1119688673} onClick={this.onClick_state1_elComp1119688673} >
              <Comp5 ref={(el)=> this._state1_elComp1119688673 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state1_elTextCopy3625345">
            <div className="font-futuraMedium" style={style_state1_elTextCopy3625345}>
              <div>{this.state.state1_elTextCopy3625345}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elList679121">
            <ul style={style_state1_elList679121}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemOptionsListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_menuItem_options'} dataSheetRow={row} {...{ 'name': row['name'], 'price': row['price'], 'order': row['order'], }} ref={(el) => {if (el) this._state1_elList679121_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state1_elList679121_endMarker = el} />
            </ul>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">MenuItem Options</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_menuitemoptions} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state1_elRectangle713918" style={style_state1_elRectangle713918} />
            <div className="hasNestedComps state1_elBottomNavBar643366" style={style_state1_elBottomNavBar643366}>
              <BottomNavBar ref={(el)=> this._state1_elBottomNavBar643366 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="state1_elCard2694159" style={style_state1_elCard2694159_outer}>
              <div style={style_state1_elCard2694159} />
            </div>
            
            <div className="state1_elBrandLogoImage" style={style_state1_elBrandLogoImage} />
            <img className="state1_elImage166520" style={style_state1_elImage166520} src={img_state1_elImage166520} alt=""  />
            <div className="hasNestedComps state1_elIPhoneMockupComponent1704385770">
              <IPhoneMockupComponent {...dataSheet_fb_currentBrand.items[0]} visualStateIndex={2} ref={(el)=> this._state1_elIPhoneMockupComponent1704385770 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state1_elFooter898468">
              <Footer ref={(el)=> this._state1_elFooter898468 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state1_elHeader66715">
              <Header ref={(el)=> this._state1_elHeader66715 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state1_elComponent942473 }
            { state1_elHelpOverlayScreen1069123456 }
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (0) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
