import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/DetailsComponent_state1_elRoundimage_743990.png';
import btn_icon_161872 from './images/btn_icon_161872.png';

export default class BrandListForDropdown extends Component {

  // Properties used by this component:
  // imageURL, name, price, dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
      textblock: (<div>$0.00</div>),
      textblock_plainText: "$0.00",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elIconButton = async () => {
    let newVal = this.props.dataSheetRow.name;
    
    let transformValue = (input) => {
      // This function modifies the value written to the target data slot.
      // There is a variable named 'input' that provides the input value.
      
      //let newVal = "1";
      /*
      this.props.appActions.updateDataSlot('ds_activeMenuItemName', this.props.dataSheetRow.name);
      this.props.appActions.updateDataSlot('ds_activeMenuItemPrice', this.props.dataSheetRow.price);
      this.props.appActions.updateDataSlot('ds_selected_menuItem_key', this.props.document_key);
      this.props.appActions.updateDataSlot('ds_uploadedMenuItemImageLocation', this.props.imageURL);
      this.props.appActions.updateDataSlot('ds_brandpromoitemflag', this.props.dataSheetRow.isBrandPromo);
      
       
          this.props.appActions.updateDataSlot('ds_brandCreateState', newVal);
              
          newVal = this.props.document_key;
          this.props.appActions.updateDataSlot('ds_selectedbrandkey', newVal);
              
          newVal = this.props.dataSheetRow.brandImageURL;
          this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', newVal);
          
          newVal = this.props.dataSheetRow.brandBackgroundImageURL;
          this.props.appActions.updateDataSlot('ds_backgroundImagefileStorageLocation', newVal);
              
          newVal = this.props.name;
          this.props.appActions.updateDataSlot('ds_activeBrandName', newVal);
              
          newVal = "0";
          this.props.appActions.updateDataSlot('ds_iphone_mock_state', newVal);
          
          
          this.props.appActions.updateDataSlot('ds_brandSecondaryColor', this.props.dataSheetRow.secondaryColor);
          this.props.appActions.updateDataSlot('ds_brandTitleColor', this.props.dataSheetRow.titleColor);
          this.props.appActions.updateDataSlot('ds_brandTextColor', this.props.dataSheetRow.textColor);
          //this.props.appActions.updateDataSlot('ds_brandInactiveIconColor', this.props.dataSheetRow.titleColor);
          //this.props.appActions.updateDataSlot('ds_brandActiveIconColor', this.props.dataSheetRow.titleColor);
          this.props.appActions.updateDataSlot('ds_brandBottomNavColor', this.props.dataSheetRow.bottonNavColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonColor', this.props.dataSheetRow.orderButtonColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonTextColor', this.props.dataSheetRow.orderButtonTextColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonTextString', this.props.dataSheetRow.orderButtonCopy);
          
      
      return input;
      
      */
      return input;
    }
    newVal = transformValue(newVal);
    
    this.props.appActions.updateDataSlot('ds_activeMenuItemName', newVal);
  
    newVal = this.props.dataSheetRow.price;
    
    transformValue = (input) => {
      // This function modifies the value written to the target data slot.
      // There is a variable named 'input' that provides the input value.
      
      Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
          var n = this,
              decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
              decSeparator = decSeparator == undefined ? "." : decSeparator,
              thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
              sign = n < 0 ? "-" : "",
              i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
              j = (j = i.length) > 3 ? j % 3 : 0;
          return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
      };
      
      var myMoney = input / 100;
      
      return ( '$' + myMoney.formatMoney(2,',','.'))  // "$3,543.76"
      
      
      //return input;
    }
    newVal = transformValue(newVal);
    
    this.props.appActions.updateDataSlot('ds_activeMenuItemPrice', newVal);
  
    newVal = this.props.dataSheetRow.imageURL;
    this.props.appActions.updateDataSlot('ds_uploadedMenuItemImageLocation', newVal);
  
    // Go to screen 'MenuItem Details'
    this.props.appActions.goToScreen('menuItemDetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    
    const style_elRectangle = {
      background: 'rgba(252, 254, 255, 1.000)',
      borderRadius: '5.0px',
     };
    const style_elImage = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.imageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_161872+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '19.192%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_textBlock = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
          var n = this,
              decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
              decSeparator = decSeparator == undefined ? "." : decSeparator,
              thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
              sign = n < 0 ? "-" : "",
              i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
              j = (j = i.length) > 3 ? j % 3 : 0;
          return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
      };
      
      var myMoney = input / 100;
      
      return ( '$' + myMoney.formatMoney(2,',','.'))  // "$3,543.76"
      
      
      //return input;
    }
    
    const value_textBlock = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textBlock(this.props.price));
    
    const style_elTextBlock = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textItemName = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.name);
    
    const style_elTextItemName = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    return (
      <div className="BrandListForDropdown">
        <div className="background">
          <div className="elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="elImage">
            <div style={style_elImage} />
          </div>
          
          <div className="elIconButton">
            <button className="actionFont" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
          </div>
        </div>
        
        <div className="foreground">
          <div className="baseFont elTextBlock" style={style_elTextBlock}>
            <div>{value_textBlock}</div>
          </div>
          <div className="baseFont elTextItemName" style={style_elTextItemName}>
            <div>{value_textItemName}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
