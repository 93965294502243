// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';
import datasheet_sheet1_row0_image from './images/datasheet_sheet1_row0_image.png';
import datasheet_sheet1_row1_image from './images/datasheet_sheet1_row1_image.png';

export default class DataSheet_sheet1 extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['order'] = "0";
    item['name'] = "Yes Brand";
    item['image'] = datasheet_sheet1_row0_image;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['order'] = "1\t";
    item['name'] = "Sample Brand";
    item['image'] = datasheet_sheet1_row1_image;
    item.key = key++;
  }

}
