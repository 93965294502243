import React, { Component } from 'react';
import './App.css';
import btn_icon_892467 from './images/btn_icon_892467.png';
import btn_icon_631268 from './images/btn_icon_631268.png';
import btn_icon_1005126 from './images/btn_icon_1005126.png';
import btn_icon_896632 from './images/btn_icon_896632.png';
import btn_icon_651049 from './images/btn_icon_651049.png';
import btn_icon_947878 from './images/btn_icon_947878.png';
import btn_icon_28840 from './images/btn_icon_28840.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class MenuListItem extends Component {

  // Properties used by this component:
  // name, order, document_key, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  onClick_state0_elIconButtonCopy2892467 = async () => {
    // Remove row from connected sheet
    await this.props.appActions.removeFromDataSheet(this.props.dataSheetId, this.props.dataSheetRow);
  
  }
  
  
  onClick_state0_elButton392368401 = async () => {
    let newVal = this.props.document_key;
    
    let transformValue = (input) => {
      // This function modifies the value written to the target data slot.
      // There is a variable named 'input' that provides the input value.   let newVal = "1";
          //this.props.appActions.updateDataSlot('ds_brandCreateState', newVal);
              
          /*newVal = this.props.document_key;
          this.props.appActions.updateDataSlot('ds_selectedbrandkey', newVal);
              
          newVal = this.props.dataSheetRow.brandImageURL;
          this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', newVal);
          
          newVal = this.props.dataSheetRow.brandBackgroundImageURL;
          this.props.appActions.updateDataSlot('ds_backgroundImagefileStorageLocation', newVal);
          */
      
          newVal = this.props.textName;
          this.props.appActions.updateDataSlot('ds_activeMenuName', newVal);
              
          //newVal = "0";
          //this.props.appActions.updateDataSlot('ds_iphone_mock_state', newVal);
          
      /*
          this.props.appActions.updateDataSlot('ds_brandPrimaryColor', this.props.dataSheetRow.primaryColor);
          this.props.appActions.updateDataSlot('ds_brandSecondaryColor', this.props.dataSheetRow.secondaryColor);
          this.props.appActions.updateDataSlot('ds_brandTitleColor', this.props.dataSheetRow.titleColor);
          this.props.appActions.updateDataSlot('ds_brandTextColor', this.props.dataSheetRow.textColor);
          //this.props.appActions.updateDataSlot('ds_brandInactiveIconColor', this.props.dataSheetRow.titleColor);
          //this.props.appActions.updateDataSlot('ds_brandActiveIconColor', this.props.dataSheetRow.titleColor);
          this.props.appActions.updateDataSlot('ds_brandBottomNavColor', this.props.dataSheetRow.bottonNavColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonColor', this.props.dataSheetRow.orderButtonColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonTextColor', this.props.dataSheetRow.orderButtonTextColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonTextString', this.props.dataSheetRow.orderButtonCopy);
      */
      
      return input;
    }
    newVal = transformValue(newVal);
    
    this.props.appActions.updateDataSlot('ds_selected_menu_key', newVal);
  
    newVal = null /* unable to collect data for 'field copy' */;
    this.props.appActions.updateDataSlot('ds_activeMenuName', newVal);
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_currentBrand'], this.props.appActions.serviceOptions_fb_currentBrand, false);
  
    // Go to screen 'Menu Details'
    this.props.appActions.goToScreen('menuDetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elTextName = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  renderState0() {
    const style_state0_elCard192225 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard192225_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state0_elIconButtonCopy2892467 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_892467+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elButton392368401 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_textName = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.name);
    
    const style_state0_elTextName = {
      color: '#eb771d',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconButtonCopy3 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_631268+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '86.294%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state0_elIconButtonCopy4 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1005126+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '74.547%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    return (
      <div className="MenuListItem">
        <div className="background">
          <div className="state0_elCard192225" style={style_state0_elCard192225_outer}>
            <div className="cardBg" style={style_state0_elCard192225} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elIconButtonCopy2892467" style={style_state0_elIconButtonCopy2892467} onClick={this.onClick_state0_elIconButtonCopy2892467}  />
          <Button className="actionFont state0_elButton392368401" style={style_state0_elButton392368401}  color="accent" onClick={this.onClick_state0_elButton392368401} >
            {this.props.locStrings.menulistitem_button_392368401}
          </Button>
          <div className="baseFont state0_elTextName" style={style_state0_elTextName} onClick={this.onClick_state0_elTextName} >
            <div>{value_textName}</div>
          </div>
          <button className="actionFont state0_elIconButtonCopy3" style={style_state0_elIconButtonCopy3} />
          <button className="actionFont state0_elIconButtonCopy4" style={style_state0_elIconButtonCopy4} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  onClick_state1_elIconButtonCopy2892467 = async () => {
    // Remove row from connected sheet
    await this.props.appActions.removeFromDataSheet(this.props.dataSheetId, this.props.dataSheetRow);
  
  }
  
  
  onClick_state1_elIconButtonCopy = async () => {
    this.setState({visualStateIndexOverride: 0})
  
    await this.sendData_state1_elIconButtonCopy_to_fb_menus();
  
  }
  
  
  onClick_state1_elButton392368401 = async () => {
    let newVal = this.props.document_key;
    
    let transformValue = (input) => {
      // This function modifies the value written to the target data slot.
      // There is a variable named 'input' that provides the input value.   let newVal = "1";
          //this.props.appActions.updateDataSlot('ds_brandCreateState', newVal);
              
          /*newVal = this.props.document_key;
          this.props.appActions.updateDataSlot('ds_selectedbrandkey', newVal);
              
          newVal = this.props.dataSheetRow.brandImageURL;
          this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', newVal);
          
          newVal = this.props.dataSheetRow.brandBackgroundImageURL;
          this.props.appActions.updateDataSlot('ds_backgroundImagefileStorageLocation', newVal);
          */
      
          newVal = this.props.textName;
          this.props.appActions.updateDataSlot('ds_activeMenuName', newVal);
              
          //newVal = "0";
          //this.props.appActions.updateDataSlot('ds_iphone_mock_state', newVal);
          
      /*
          this.props.appActions.updateDataSlot('ds_brandPrimaryColor', this.props.dataSheetRow.primaryColor);
          this.props.appActions.updateDataSlot('ds_brandSecondaryColor', this.props.dataSheetRow.secondaryColor);
          this.props.appActions.updateDataSlot('ds_brandTitleColor', this.props.dataSheetRow.titleColor);
          this.props.appActions.updateDataSlot('ds_brandTextColor', this.props.dataSheetRow.textColor);
          //this.props.appActions.updateDataSlot('ds_brandInactiveIconColor', this.props.dataSheetRow.titleColor);
          //this.props.appActions.updateDataSlot('ds_brandActiveIconColor', this.props.dataSheetRow.titleColor);
          this.props.appActions.updateDataSlot('ds_brandBottomNavColor', this.props.dataSheetRow.bottonNavColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonColor', this.props.dataSheetRow.orderButtonColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonTextColor', this.props.dataSheetRow.orderButtonTextColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonTextString', this.props.dataSheetRow.orderButtonCopy);
      */
      
      return input;
    }
    newVal = transformValue(newVal);
    
    this.props.appActions.updateDataSlot('ds_selected_menu_key', newVal);
  
    newVal = this.getValue_state1_elFieldCopy();
    this.props.appActions.updateDataSlot('ds_activeMenuName', newVal);
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_currentBrand'], this.props.appActions.serviceOptions_fb_currentBrand, false);
  
    // Go to screen 'Menu Details'
    this.props.appActions.goToScreen('menuDetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  textInputChanged_state1_elFieldCopy = (event) => {
    this.setState({fieldCopy: event.target.value});
  }
  
  getValue_state1_elFieldCopy = () => {
    return this.state.fieldCopy !== undefined ? this.state.fieldCopy : (this.props.name);
  }
  
  textInputChanged_state1_elFieldOrder = (event) => {
    this.setState({fieldOrder: event.target.value});
  }
  
  getValue_state1_elFieldOrder = () => {
    return this.state.fieldOrder !== undefined ? this.state.fieldOrder : (this.props.order);
  }
  
  sendData_state1_elIconButtonCopy_to_fb_menus = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menus');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "order": this.props.order,
      "document_key": this.props.document_key,
    };
    row = { ...row, 
      name: this.getValue_state1_elFieldCopy(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      
      var order = Number((this.state.fieldOrder));
      
      input.order = order;
      
      //this.setState({fieldPrice: this.state.textPrice});
      
      return input;
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menus', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menus', row);
    }
  }
  
  
  renderState1() {
    const style_state1_elCard192225 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard192225_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state1_elIconButtonCopy2892467 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_892467+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_896632+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elButton392368401 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldCopy = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldOrder = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconButtonCopy3 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_651049+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state1_elIconButtonCopy4 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_947878+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    return (
      <div className="MenuListItem">
        <div className="background">
          <div className="state1_elCard192225" style={style_state1_elCard192225_outer}>
            <div className="cardBg" style={style_state1_elCard192225} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state1_elIconButtonCopy2892467" style={style_state1_elIconButtonCopy2892467} onClick={this.onClick_state1_elIconButtonCopy2892467}  />
          <button className="actionFont state1_elIconButtonCopy" style={style_state1_elIconButtonCopy} onClick={this.onClick_state1_elIconButtonCopy}  />
          <Button className="actionFont state1_elButton392368401" style={style_state1_elButton392368401}  color="accent" onClick={this.onClick_state1_elButton392368401} >
            {this.props.locStrings.menulistitem_button_392368401}
          </Button>
          <input className="baseFont state1_elFieldCopy" style={style_state1_elFieldCopy} type="text" placeholder="{name}" onChange={this.textInputChanged_state1_elFieldCopy} value={this.getValue_state1_elFieldCopy()}  />
          <input className="baseFont state1_elFieldOrder" style={style_state1_elFieldOrder} type="text" placeholder="{order}" onChange={this.textInputChanged_state1_elFieldOrder} value={this.getValue_state1_elFieldOrder()}  />
          <button className="actionFont state1_elIconButtonCopy3" style={style_state1_elIconButtonCopy3} />
          <button className="actionFont state1_elIconButtonCopy4" style={style_state1_elIconButtonCopy4} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  onClick_state2_elIconButtonCopy = async () => {
    let newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selected_menu_key', newVal);
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_currentBrand'], this.props.appActions.serviceOptions_fb_currentBrand, false);
  
    newVal = null /* unable to collect data for 'field copy' */;
    this.props.appActions.updateDataSlot('ds_activeMenuName', newVal);
  
    // Go to screen 'Menu Details'
    this.props.appActions.goToScreen('menuDetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  renderState2() {
    
    const style_state2_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_28840+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuListItem">
        <div className="foreground">
          <button className="actionFont state2_elIconButtonCopy" style={style_state2_elIconButtonCopy} onClick={this.onClick_state2_elIconButtonCopy}  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}
