import React, { Component } from 'react';
import './App.css';
import ColorPicker from './ColorPicker';
import img_state0_elBackgroundImage726172219 from './images/IPhoneMockupComponent_state0_elBackgroundImage726172219_726172219.png';
import BottomNavBar from './BottomNavBar';
import img_state0_elImage3 from './images/DetailsComponent_state1_elRoundimage_743990.png';
import img_state0_elBrandLogoImage308356580 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state0_elImage523364028 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';
import img_state1_elBackgroundImage726172219 from './images/IPhoneMockupComponent_state0_elBackgroundImage726172219_726172219.png';
import BrandListForDropdown from './BrandListForDropdown';
import img_state1_elBrandLogoImage308356580 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elImage523364028 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';
import img_state2_elBackgroundImage726172219 from './images/IPhoneMockupComponent_state0_elBackgroundImage726172219_726172219.png';
import img_state2_elImage3 from './images/DetailsComponent_state1_elRoundimage_743990.png';
import img_state2_elBrandLogoImage308356580 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state2_elImage523364028 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';

export default class IPhoneMockupComponent extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elPriceText: (<div>$5.00</div>),
      state0_elPriceText_plainText: "$5.00",
      state2_elText2: (<div> </div>),
      state2_elText2_plainText: " ",
      state2_elTitleText: (<div>menuItem</div>),
      state2_elTitleText_plainText: "menuItem",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  renderState0() {
    // eslint-disable-next-line
    const dataSheet_fb_myBrands = this.props.appActions.getDataSheet('fb_myBrands');
    const fillColorValue_backgroundRectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_state0_elBackgroundRectangle121457101 = {
      background: (fillColorValue_backgroundRectangle && fillColorValue_backgroundRectangle.length > 0) ? fillColorValue_backgroundRectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const fillColorValue_backgroundCard = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    const style_state0_elBackgroundCard899971861 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackgroundCard899971861_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_backgroundCard && fillColorValue_backgroundCard.length > 0) ? fillColorValue_backgroundCard : 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    const style_state0_elBackgroundImage726172219 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_backgroundImagefileStorageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const fillColorValue_card3 = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandOrderButtonColor'] : '');
    const style_state0_elCard3 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard3_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card3 && fillColorValue_card3.length > 0) ? fillColorValue_card3 : 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    const value_text2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandOrderButtonTextString'] : ''));
    
    const style_state0_elText2 = {
      fontSize: 15.9,
      color: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandOrderButtonTextColor'] : ''),
      textAlign: 'center',
     };
    const style_state0_elPriceText = {
      color: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandTextColor'] : ''),
      textAlign: 'center',
     };
    let transformPropValue_image3 = (input) => {
      // This function modifies the value for property 'image'.
      // There is a variable named 'input' that provides the property value.
      //
      
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      
      if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "beerPub") {
        return "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2FmenuItems%2F15b472fe-bda7-4184-83e2-85807866ee74.png?alt=media";
      }
      
      else if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "coffeeShop") {
        return "https://i.pinimg.com/originals/f1/34/8e/f1348ef41147a2d398f9a152d079e4c6.png";
      }
      
      else if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "foodTruck") {
        return "https://taco.sfo2.digitaloceanspaces.com/2018/10/Classic-Burrito-VEGGIE.png";
      }
      
      else if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "restaurantFastCasual") {
        return "https://i2.wp.com/freepngimages.com/wp-content/uploads/2016/11/bacon-burger.png?fit=895%2C895";
      }
      
      else if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "restaurantFineDining") {
        return "https://lh3.googleusercontent.com/proxy/I7M6Hb2mD_lBlFi2rBF2l08v0jwAhHOFZq92ZrDMbJ9tJ5mk1qyUQyEBe8_vUffVCIt7AlMOAowlwK5AKdVWwgoSpHt7y3-oAmgtrTi18BKKYJcie4tRxNYQ0VsDjI88ZPNzQzMZvv-2pelQaKexdK07IQ";
      }
      
      else if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "eventSpace") {
        return "https://pngimg.com/uploads/pizza/pizza_PNG44016.png";
      }
      
      else if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "Apparel") {
        return "https://www.seekpng.com/png/full/861-8614896_white-hoodie-transparent-background.png";
      }
      
      else return "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fb70136b6-cbc5-4f56-8b3d-5739ad722e7b.png?alt=media";
      //return input;
    }
    const style_state0_elImage3 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(transformPropValue_image3((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_sampleImageURL'] : '')))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    let transformPropValue_titleText = (input) => {
      // This function modifies the value for property 'image'.
      // There is a variable named 'input' that provides the property value.
      //
      
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      
      if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "beerPub") {
        return "Sample Beer";
      }
      
      else if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "coffeeShop") {
        return "Sample Coffee";
      }
      
      else if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "foodTruck") {
        return "Sample Burrito";
      }
      
      else if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "restaurantFastCasual") {
        return "Sample Burger";
      }
      
      else if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "restaurantFineDining") {
        return "Sample Entree";
      }
      
      else if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "eventSpace") {
        return "Sample Pizza";
      }
      
      else if (this.props.appActions.dataSlots['ds_sampleImageURL'] === "Apparel") {
        return "Sample Hoodie";
      }
      
      else return "Sample Item";
      //return input;
    }
    let transformColorPropValue_titleText = (input) => {
      // This function modifies the value for property 'color'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      return input;
    }
    
    const value_titleText = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_titleText((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_activeMenuItemName'] : '')));
    
    const style_state0_elTitleText = {
      fontSize: 31.0,
      color: transformColorPropValue_titleText((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandTitleColor'] : '')),
      textAlign: 'center',
     };
    const fillColorValue_card2 = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandSecondaryColor'] : '');
    const style_state0_elCard2858008141 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard2858008141_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state0_elBrandLogoImage308356580 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state0_elImage523364028 = {
      height: 'auto',
     };
    
    return (
      <div className="IPhoneMockupComponent">
        <div className="foreground">
          <div className="hasNestedComps state0_elColorPicker1428156670">
            <ColorPicker ref={(el)=> this._state0_elColorPicker1428156670 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="state0_elBackgroundRectangle121457101" style={style_state0_elBackgroundRectangle121457101} />
          <div className="state0_elBackgroundCard899971861" style={style_state0_elBackgroundCard899971861_outer}>
            <div style={style_state0_elBackgroundCard899971861} />
          </div>
          
          <div className="state0_elBackgroundImage726172219" style={style_state0_elBackgroundImage726172219} />
          <div className="hasNestedComps state0_elBottomNavBar1504949432">
            <BottomNavBar {...dataSheet_fb_myBrands.items[0]} ref={(el)=> this._state0_elBottomNavBar1504949432 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="state0_elCard3" style={style_state0_elCard3_outer}>
            <div style={style_state0_elCard3} />
          </div>
          
          <div className="systemFontBold  state0_elText2" style={style_state0_elText2}>
            <div>{value_text2}</div>
          </div>
          <div className="baseFont state0_elPriceText" style={style_state0_elPriceText}>
            <div>{this.state.state0_elPriceText}</div>
          </div>
          <div className="state0_elImage3" style={style_state0_elImage3} />
          <div className="systemFontBold  state0_elTitleText" style={style_state0_elTitleText}>
            <div>{value_titleText}</div>
          </div>
          <div className="state0_elCard2858008141" style={style_state0_elCard2858008141_outer}>
            <div style={style_state0_elCard2858008141} />
          </div>
          
          <div className="state0_elBrandLogoImage308356580" style={style_state0_elBrandLogoImage308356580} />
          <img className="state0_elImage523364028" style={style_state0_elImage523364028} src={img_state0_elImage523364028} alt=""  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  
  renderState1() {
    // eslint-disable-next-line
    const dataSheet_fb_my_menu_items = this.props.appActions.getDataSheet('fb_my_menu_items');
    // eslint-disable-next-line
    const dataSheet_fb_myBrands = this.props.appActions.getDataSheet('fb_myBrands');
    const fillColorValue_backgroundRectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_state1_elBackgroundRectangle121457101 = {
      background: (fillColorValue_backgroundRectangle && fillColorValue_backgroundRectangle.length > 0) ? fillColorValue_backgroundRectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const fillColorValue_backgroundCard = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    const style_state1_elBackgroundCard899971861 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackgroundCard899971861_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_backgroundCard && fillColorValue_backgroundCard.length > 0) ? fillColorValue_backgroundCard : 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    const style_state1_elBackgroundImage726172219 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_backgroundImagefileStorageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    // Source items and any special components used for list/grid element 'menu items list'.
    let items_menuItemsList = [];
    let listComps_menuItemsList = {};
    items_menuItemsList = items_menuItemsList.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    this._state1_elMenuItemsList_items = [];
    
    let transformColorPropValue_titleText = (input) => {
      // This function modifies the value for property 'color'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      return input;
    }
    
    const value_titleText = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_activeMenuName'] : ''));
    
    const style_state1_elTitleText = {
      fontSize: 31.0,
      color: transformColorPropValue_titleText((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandTitleColor'] : '')),
      textAlign: 'center',
     };
    const fillColorValue_card2 = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandSecondaryColor'] : '');
    const style_state1_elCard2858008141 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard2858008141_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state1_elBrandLogoImage308356580 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state1_elImage523364028 = {
      height: 'auto',
     };
    
    return (
      <div className="IPhoneMockupComponent">
        <div className="foreground">
          <div className="hasNestedComps state1_elColorPicker1428156670">
            <ColorPicker ref={(el)=> this._state1_elColorPicker1428156670 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="state1_elBackgroundRectangle121457101" style={style_state1_elBackgroundRectangle121457101} />
          <div className="state1_elBackgroundCard899971861" style={style_state1_elBackgroundCard899971861_outer}>
            <div style={style_state1_elBackgroundCard899971861} />
          </div>
          
          <div className="state1_elBackgroundImage726172219" style={style_state1_elBackgroundImage726172219} />
          <div className="hasNestedComps state1_elBottomNavBar1504949432">
            <BottomNavBar {...dataSheet_fb_myBrands.items[0]} ref={(el)=> this._state1_elBottomNavBar1504949432 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="hasNestedComps state1_elMenuItemsList">
            <ul>
              {items_menuItemsList.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_menuItemsList[row._componentId]
                    : <BrandListForDropdown appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'imageURL': row['imageURL'], 'name': row['name'], 'price': row['price'], }} ref={(el) => {if (el) this._state1_elMenuItemsList_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state1_elMenuItemsList_endMarker = el} />
            </ul>
          </div>
          
          <div className="systemFontBold  state1_elTitleText" style={style_state1_elTitleText}>
            <div>{value_titleText}</div>
          </div>
          <div className="state1_elCard2858008141" style={style_state1_elCard2858008141_outer}>
            <div style={style_state1_elCard2858008141} />
          </div>
          
          <div className="state1_elBrandLogoImage308356580" style={style_state1_elBrandLogoImage308356580} />
          <img className="state1_elImage523364028" style={style_state1_elImage523364028} src={img_state1_elImage523364028} alt=""  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  renderState2() {
    // eslint-disable-next-line
    const dataSheet_fb_my_menu_items = this.props.appActions.getDataSheet('fb_my_menu_items');
    // eslint-disable-next-line
    const dataSheet_fb_myBrands = this.props.appActions.getDataSheet('fb_myBrands');
    const fillColorValue_backgroundRectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_state2_elBackgroundRectangle121457101 = {
      background: (fillColorValue_backgroundRectangle && fillColorValue_backgroundRectangle.length > 0) ? fillColorValue_backgroundRectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const fillColorValue_backgroundCard = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    const style_state2_elBackgroundCard899971861 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elBackgroundCard899971861_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_backgroundCard && fillColorValue_backgroundCard.length > 0) ? fillColorValue_backgroundCard : 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    const style_state2_elBackgroundImage726172219 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_backgroundImagefileStorageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const fillColorValue_card3 = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandOrderButtonColor'] : '');
    const style_state2_elCard3 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elCard3_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card3 && fillColorValue_card3.length > 0) ? fillColorValue_card3 : 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    const value_text2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandOrderButtonTextString'] : ''));
    
    const style_state2_elText2 = {
      fontSize: 15.9,
      color: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandOrderButtonTextColor'] : ''),
      textAlign: 'center',
     };
    let transformPropValue_priceText = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      return input;
    }
    
    const value_priceText = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_priceText((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_activeMenuItemPrice'] : '')));
    
    const style_state2_elPriceText = {
      color: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandTextColor'] : ''),
      textAlign: 'center',
     };
    const style_state2_elImage3 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedMenuItemImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    let transformColorPropValue_titleText = (input) => {
      // This function modifies the value for property 'color'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      return input;
    }
    
    const value_titleText = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_activeMenuItemName'] : ''));
    
    const style_state2_elTitleText = {
      fontSize: 31.0,
      color: transformColorPropValue_titleText((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandTitleColor'] : '')),
      textAlign: 'center',
     };
    const fillColorValue_card2 = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandSecondaryColor'] : '');
    const style_state2_elCard2858008141 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elCard2858008141_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state2_elBrandLogoImage308356580 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state2_elImage523364028 = {
      height: 'auto',
     };
    
    return (
      <div className="IPhoneMockupComponent">
        <div className="foreground">
          <div className="hasNestedComps state2_elColorPicker1428156670">
            <ColorPicker ref={(el)=> this._state2_elColorPicker1428156670 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="state2_elBackgroundRectangle121457101" style={style_state2_elBackgroundRectangle121457101} />
          <div className="state2_elBackgroundCard899971861" style={style_state2_elBackgroundCard899971861_outer}>
            <div style={style_state2_elBackgroundCard899971861} />
          </div>
          
          <div className="state2_elBackgroundImage726172219" style={style_state2_elBackgroundImage726172219} />
          <div className="hasNestedComps state2_elBottomNavBar1504949432">
            <BottomNavBar {...dataSheet_fb_myBrands.items[0]} ref={(el)=> this._state2_elBottomNavBar1504949432 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="state2_elCard3" style={style_state2_elCard3_outer}>
            <div style={style_state2_elCard3} />
          </div>
          
          <div className="systemFontBold  state2_elText2" style={style_state2_elText2}>
            <div>{value_text2}</div>
          </div>
          <div className="baseFont state2_elPriceText" style={style_state2_elPriceText}>
            <div>{value_priceText}</div>
          </div>
          <div className="state2_elImage3" style={style_state2_elImage3} />
          <div className="systemFontBold  state2_elTitleText" style={style_state2_elTitleText}>
            <div>{value_titleText}</div>
          </div>
          <div className="state2_elCard2858008141" style={style_state2_elCard2858008141_outer}>
            <div style={style_state2_elCard2858008141} />
          </div>
          
          <div className="state2_elBrandLogoImage308356580" style={style_state2_elBrandLogoImage308356580} />
          <img className="state2_elImage523364028" style={style_state2_elImage523364028} src={img_state2_elImage523364028} alt=""  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}
