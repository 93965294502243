import React, { Component } from 'react';
import './App.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import btn_icon_1628349802 from './images/btn_icon_1628349802.png';

export default class HolidayListItem extends Component {

  // Properties used by this component:
  // date, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  
  getValue_state0_elPicker = () => {
    return this.state.picker !== undefined ? this.state.picker : (this.props.date);
  }
  
  pickerValueChanged_state0_elPicker = (date) => {
    this.setState({picker: date.toString()});
  }
  
  onClick_state0_elIconButtonCopy2 = async () => {
  
  }
  
  
  onClick_state0_elTextNameCopy = async () => {
  
  }
  
  
  renderState0() {
    const style_state0_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    
    const style_state0_elIconButtonCopy2 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1628349802+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_textName = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.date);
    
    const style_state0_elTextName = {
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const value_textNameCopy = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.date);
    
    const style_state0_elTextNameCopy = {
      color: '#eb771d',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="HolidayListItem">
        <div className="background">
          <div className="state0_elCard" style={style_state0_elCard_outer}>
            <div className="cardBg" style={style_state0_elCard} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elPicker">
            <DatePicker className="baseFont"  onChange={this.pickerValueChanged_state0_elPicker} selected={Date.parse(this.getValue_state0_elPicker())}  />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elIconButtonCopy2" style={style_state0_elIconButtonCopy2} onClick={this.onClick_state0_elIconButtonCopy2}  />
          <div className="baseFont state0_elTextName" style={style_state0_elTextName}>
            <div>{value_textName}</div>
          </div>
          <div className="baseFont state0_elTextNameCopy" style={style_state0_elTextNameCopy} onClick={this.onClick_state0_elTextNameCopy} >
            <div>{value_textNameCopy}</div>
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  renderState1() {
    
    return (
      <div className="HolidayListItem">
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
