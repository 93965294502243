import React, { Component } from 'react';
import './App.css';
import btn_icon_1350471191 from './images/btn_icon_1350471191.png';
import img_state0_elImage2 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import btn_icon_1141083902 from './images/btn_icon_1141083902.png';
import Comp5 from './Comp5';
import MenuItemListItem from './MenuItemListItem';
import btn_icon_1666040709 from './images/btn_icon_1666040709.png';
import btn_icon_269532913 from './images/btn_icon_269532913.png';
import btn_icon_777441329 from './images/btn_icon_777441329.png';
import btn_icon_1228449746 from './images/btn_icon_1228449746.png';
import btn_icon_792710781 from './images/btn_icon_792710781.png';
import btn_icon_1066919085 from './images/btn_icon_1066919085.png';
import btn_icon_1247610652 from './images/btn_icon_1247610652.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import btn_icon_1264729165 from './images/btn_icon_1264729165.png';
import HolidayListItem from './HolidayListItem';
import BottomNavBar from './BottomNavBar';
import BrandListForDropdown from './BrandListForDropdown';
import img_state0_elBrandLogoImage1230304929 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state0_elImage1093429335 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';
import IPhoneMockupComponent from './IPhoneMockupComponent';
import Footer from './Footer';
import Header from './Header';
import Component4 from './Component4';
import HelpOverlayScreen from './HelpOverlayScreen';
import img_state1_elImage2 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elBrandLogoImage1230304929 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elImage1093429335 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';

export default class LocationDetailsScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, dataSheetRow, brandImageURL, bottomNavIconActiveColor, bottomNavIconColor, iconHomeURL, iconMenuURL, iconProfileURL, iconMoreURL, hours

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menus'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menus;
      if ( !this.props.appActions.dataSheetLoaded['fb_menus']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/menus/", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menus'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_my_locations'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_my_locations;
      if ( !this.props.appActions.dataSheetLoaded['fb_my_locations']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/locations/", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_my_locations'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_holidays'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_holidays;
      if ( !this.props.appActions.dataSheetLoaded['fb_holidays']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/locations/$slot('ds_selected_location_key')/holidays", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_holidays'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_my_menu_items'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_my_menu_items;
      if ( !this.props.appActions.dataSheetLoaded['fb_my_menu_items']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_my_menu_items'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menuItems'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menuItems;
      if ( !this.props.appActions.dataSheetLoaded['fb_menuItems']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menuItems'] = true;
      }
    }
    let newVal = "https://discuss.yesoms.com/t/walkthrough-the-menu-detail-screen/";
    this.props.appActions.updateDataSlot('ds_forumHelpURL', newVal);
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elIconButton21350471191 = async () => {
    let newVal = "yyyy";
    this.props.appActions.updateDataSlot('ds_activeLocationName', newVal);
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  textInputChanged_state0_elFieldTitle730799850 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state0_elFieldTitle730799850 = () => {
    return this.state.fieldTitle !== undefined ? this.state.fieldTitle : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  onClick_state0_elIconButton1141083902 = async () => {
  
  
  }
  
  
  textInputChanged_state0_elField_brand_name1852171699 = (event) => {
    this.setState({field_brand_name: event.target.value});
  }
  
  getValue_state0_elField_brand_name1852171699 = () => {
    return this.state.field_brand_name !== undefined ? this.state.field_brand_name : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  textAreaChanged_state0_elFieldDescription1596337839 = (event) => {
    this.setState({fieldDescription: event.target.value});
  }
  
  getValue_state0_elFieldDescription1596337839 = () => {
    return this.state.fieldDescription !== undefined ? this.state.fieldDescription : (this.props.dataSheetRow ? this.props.dataSheetRow.description : '');
  }
  
  
  getValue_state0_elField_isActiveCheckbox298406343 = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : (this.props.dataSheetRow ? this.props.dataSheetRow.isActive : '');
  }
  
  checkboxChanged_state0_elField_isActiveCheckbox298406343 = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')});
  }
  
  onClick_state0_elComp464653734 = async () => {
    await this.sendData_state0_elComp464653734_to_fb_menus();
  
  }
  
  
  
  textInputChanged_state0_elField1042362342 = (event) => {
    this.setState({field: event.target.value});
  }
  
  getValue_state0_elField1042362342 = () => {
    let transformPropValue_field = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input._lat;
    }
    
    return this.state.field !== undefined ? this.state.field : (transformPropValue_field(this.props.dataSheetRow ? this.props.dataSheetRow.geoLocation : ''));
  }
  
  textInputChanged_state0_elFieldCopy420526630 = (event) => {
    this.setState({fieldCopy: event.target.value});
  }
  
  getValue_state0_elFieldCopy420526630 = () => {
    let transformPropValue_fieldCopy = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input._long;
    }
    
    return this.state.fieldCopy !== undefined ? this.state.fieldCopy : (transformPropValue_fieldCopy(this.props.dataSheetRow ? this.props.dataSheetRow.geoLocation : ''));
  }
  
  textInputChanged_state0_elFieldCopy21704827828 = (event) => {
    this.setState({fieldCopy2: event.target.value});
  }
  
  getValue_state0_elFieldCopy21704827828 = () => {
    let transformPropValue_fieldCopy2 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    }
    
    return this.state.fieldCopy2 !== undefined ? this.state.fieldCopy2 : (transformPropValue_fieldCopy2(this.props.dataSheetRow ? this.props.dataSheetRow.geohash : ''));
  }
  
  
  getValue_state0_elMonday_picker_open398940501 = () => {
    let transformPropValue_monday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.monday_open;
    }
    
    return this.state.monday_picker_open !== undefined ? this.state.monday_picker_open : (transformPropValue_monday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elMonday_picker_open398940501 = (event) => {
    this.setState({monday_picker_open: event.target.value});
  }
  
  
  getValue_state0_elMonday_picker_closed659010974 = () => {
    let transformPropValue_monday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.monday_close;
    }
    
    return this.state.monday_picker_closed !== undefined ? this.state.monday_picker_closed : (transformPropValue_monday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elMonday_picker_closed659010974 = (event) => {
    this.setState({monday_picker_closed: event.target.value});
  }
  
  onClick_state0_elMonday_icon_save_hours1666040709 = async () => {
    await this.sendData_state0_elMonday_icon_save_hours1666040709_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elTuesday_picker_open251877840 = () => {
    let transformPropValue_tuesday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.tuesday_open;
    }
    
    return this.state.tuesday_picker_open !== undefined ? this.state.tuesday_picker_open : (transformPropValue_tuesday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elTuesday_picker_open251877840 = (event) => {
    this.setState({tuesday_picker_open: event.target.value});
  }
  
  
  getValue_state0_elTuesday_picker_closed1321277388 = () => {
    let transformPropValue_tuesday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.tuesday_close;
    }
    
    return this.state.tuesday_picker_closed !== undefined ? this.state.tuesday_picker_closed : (transformPropValue_tuesday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elTuesday_picker_closed1321277388 = (event) => {
    this.setState({tuesday_picker_closed: event.target.value});
  }
  
  onClick_state0_elTuesday_icon_save_hours269532913 = async () => {
    await this.sendData_state0_elTuesday_icon_save_hours269532913_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elWednesday_picker_open2067468121 = () => {
    let transformPropValue_wednesday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.wednesday_open;
    }
    
    return this.state.wednesday_picker_open !== undefined ? this.state.wednesday_picker_open : (transformPropValue_wednesday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elWednesday_picker_open2067468121 = (event) => {
    this.setState({wednesday_picker_open: event.target.value});
  }
  
  
  getValue_state0_elWednesday_picker_closed705079950 = () => {
    let transformPropValue_wednesday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.wednesday_close;
    }
    
    return this.state.wednesday_picker_closed !== undefined ? this.state.wednesday_picker_closed : (transformPropValue_wednesday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elWednesday_picker_closed705079950 = (event) => {
    this.setState({wednesday_picker_closed: event.target.value});
  }
  
  onClick_state0_elWednesday_icon_save_hours777441329 = async () => {
    await this.sendData_state0_elWednesday_icon_save_hours777441329_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elThursday_picker_open1841647548 = () => {
    let transformPropValue_thursday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.thursday_open;
    }
    
    return this.state.thursday_picker_open !== undefined ? this.state.thursday_picker_open : (transformPropValue_thursday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elThursday_picker_open1841647548 = (event) => {
    this.setState({thursday_picker_open: event.target.value});
  }
  
  
  getValue_state0_elThursday_picker_closed301801150 = () => {
    let transformPropValue_thursday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.thursday_close;
    }
    
    return this.state.thursday_picker_closed !== undefined ? this.state.thursday_picker_closed : (transformPropValue_thursday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elThursday_picker_closed301801150 = (event) => {
    this.setState({thursday_picker_closed: event.target.value});
  }
  
  onClick_state0_elThursday_icon_save_hours1228449746 = async () => {
    await this.sendData_state0_elThursday_icon_save_hours1228449746_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elFriday_picker_open1037987712 = () => {
    let transformPropValue_friday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.friday_open;
    }
    
    return this.state.friday_picker_open !== undefined ? this.state.friday_picker_open : (transformPropValue_friday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elFriday_picker_open1037987712 = (event) => {
    this.setState({friday_picker_open: event.target.value});
  }
  
  
  getValue_state0_elFriday_picker_closed2058466485 = () => {
    let transformPropValue_friday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.friday_close;
    }
    
    return this.state.friday_picker_closed !== undefined ? this.state.friday_picker_closed : (transformPropValue_friday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elFriday_picker_closed2058466485 = (event) => {
    this.setState({friday_picker_closed: event.target.value});
  }
  
  onClick_state0_elFriday_icon_save_hours792710781 = async () => {
    await this.sendData_state0_elFriday_icon_save_hours792710781_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elSaturday_picker_open1914129443 = () => {
    let transformPropValue_saturday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.saturday_open;
    }
    
    return this.state.saturday_picker_open !== undefined ? this.state.saturday_picker_open : (transformPropValue_saturday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSaturday_picker_open1914129443 = (event) => {
    this.setState({saturday_picker_open: event.target.value});
  }
  
  
  getValue_state0_elSaturday_picker_closed1288282373 = () => {
    let transformPropValue_saturday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.saturday_close;
    }
    
    return this.state.saturday_picker_closed !== undefined ? this.state.saturday_picker_closed : (transformPropValue_saturday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSaturday_picker_closed1288282373 = (event) => {
    this.setState({saturday_picker_closed: event.target.value});
  }
  
  onClick_state0_elSaturday_icon_save_hours1066919085 = async () => {
    await this.sendData_state0_elSaturday_icon_save_hours1066919085_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elSunday_picker_open1262554967 = () => {
    let transformPropValue_sunday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.sunday_open;
    }
    
    return this.state.sunday_picker_open !== undefined ? this.state.sunday_picker_open : (transformPropValue_sunday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSunday_picker_open1262554967 = (event) => {
    this.setState({sunday_picker_open: event.target.value});
  }
  
  
  getValue_state0_elSunday_picker_closed1421457572 = () => {
    let transformPropValue_sunday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.sunday_close;
    }
    
    return this.state.sunday_picker_closed !== undefined ? this.state.sunday_picker_closed : (transformPropValue_sunday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSunday_picker_closed1421457572 = (event) => {
    this.setState({sunday_picker_closed: event.target.value});
  }
  
  onClick_state0_elSunday_icon_save_hours1247610652 = async () => {
    await this.sendData_state0_elSunday_icon_save_hours1247610652_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elPicker_holiday311812817 = () => {
    return this.state.picker_holiday !== undefined ? this.state.picker_holiday : '';
  }
  
  pickerValueChanged_state0_elPicker_holiday311812817 = (date) => {
    this.setState({picker_holiday: date.toString()});
  }
  
  onClick_state0_elSunday_icon_save_hoursCopy1264729165 = async () => {
    await this.sendData_state0_elSunday_icon_save_hoursCopy1264729165_to_fb_holidays();
  
  }
  
  
  
  
  sendData_state0_elWednesday_icon_save_hours777441329_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.wednesday_open = parseInt(this.state.wednesday_picker_open);
      input.hours.wednesday_close = parseInt(this.state.wednesday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  sendData_state0_elSunday_icon_save_hours1247610652_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.sunday_open = parseInt(this.state.sunday_picker_open);
      input.hours.sunday_close = parseInt(this.state.sunday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  sendData_state0_elComp464653734_to_fb_menus = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menus');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        
      // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      
      if(this.state.field_isActiveCheckbox === "true" || 
         this.state.field_isActiveCheckbox === true ||
         this.state.field_isActiveCheckbox === "1")
      	{
            input.isActive=true
          };
      
      if(this.state.field_isActiveCheckbox === "false" || 
         this.state.field_isActiveCheckbox === false ||
         this.state.field_isActiveCheckbox === "0")
      	{
            input.isActive=false
          };
      
      
      return input;
      
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menus', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menus', row);
    }
  }
  
  
  sendData_state0_elFriday_icon_save_hours792710781_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.friday_open = parseInt(this.state.friday_picker_open);
      input.hours.friday_close = parseInt(this.state.friday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  sendData_state0_elThursday_icon_save_hours1228449746_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.thursday_open = parseInt(this.state.thursday_picker_open);
      input.hours.thursday_close = parseInt(this.state.thursday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  sendData_state0_elSaturday_icon_save_hours1066919085_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.saturday_open = parseInt(this.state.saturday_picker_open);
      input.hours.saturday_close = parseInt(this.state.saturday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  sendData_state0_elMonday_icon_save_hours1666040709_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.monday_open = parseInt(this.state.monday_picker_open);
      input.hours.monday_close = parseInt(this.state.monday_picker_closed);
      
      //don't work:
      //input.hours.monday_close.open = parseInt(this.state.monday_picker_open);
      //input.hoursalt[0].close = parseInt(this.state.monday_picker_closed);
      //input.hoursalt[0].name = 'monday';
      //
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  sendData_state0_elSunday_icon_save_hoursCopy1264729165_to_fb_holidays = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_holidays');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
      altdate: this.getValue_state0_elPicker_holiday311812817(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_holidays', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_holidays', row);
    }
  }
  
  
  sendData_state0_elTuesday_icon_save_hours269532913_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.tuesday_open = parseInt(this.state.tuesday_picker_open);
      input.hours.tuesday_close = parseInt(this.state.tuesday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_fb_currentBrand = this.props.dataSheets['fb_currentBrand'];
    // eslint-disable-next-line
    const dataSheet_fb_my_menu_items = this.props.dataSheets['fb_my_menu_items'];
    const style_state0_elBackground675961797 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground675961797_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    
    const style_state0_elRectangle21641069097 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_state0_elIconButton21350471191 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1350471191+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elImage2 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_textCopy2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_activeMenuName'] : ''));
    
    const style_state0_elTextCopy287944581 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const placeholderValue_fieldTitle = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_state0_elFieldTitle730799850 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_state0_elFieldTitle730799850_outer = {
      display: 'none',
     };
    
    const style_state0_elIconButton1141083902 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1141083902+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elIconButton1141083902_outer = {
      display: 'none',
     };
    const style_state0_elTextCopy61640246345 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state0_elText_brand_name1135612262 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elField_brand_name1852171699 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy183155673 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elFieldDescription1596337839 = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_state0_elField_isActiveCheckbox298406343();
    
    const style_state0_elField_isActiveCheckbox298406343 = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elComp464653734 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy3591760950 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    this._state0_elList1545617919_items = [];
    
    const style_state0_elList1545617919 = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_state0_elTextCopy41438074854 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state0_elText_brand_nameCopy1131753664 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elField1042362342 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy2458308127 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elFieldCopy420526630 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy31917917604 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elFieldCopy21704827828 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy5109082731 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state0_elText_brand_nameCopy13523964514 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_monday_picker_open = this.getValue_state0_elMonday_picker_open398940501();
    // Source datasheet and selected data column for picker element 'monday_picker_open'
    const dataSource_monday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_monday_picker_open = 'value';
    const labelColumnName_monday_picker_open = 'display';
    
    const style_state0_elMonday_picker_open398940501 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy718164037 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_monday_picker_closed = this.getValue_state0_elMonday_picker_closed659010974();
    // Source datasheet and selected data column for picker element 'monday_picker_closed'
    const dataSource_monday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_monday_picker_closed = 'value';
    const labelColumnName_monday_picker_closed = 'display';
    
    const style_state0_elMonday_picker_closed659010974 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elMonday_icon_save_hours1666040709 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1666040709+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy4419489363 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_tuesday_picker_open = this.getValue_state0_elTuesday_picker_open251877840();
    // Source datasheet and selected data column for picker element 'tuesday_picker_open'
    const dataSource_tuesday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_tuesday_picker_open = 'value';
    const labelColumnName_tuesday_picker_open = 'display';
    
    const style_state0_elTuesday_picker_open251877840 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy1365476359 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_tuesday_picker_closed = this.getValue_state0_elTuesday_picker_closed1321277388();
    // Source datasheet and selected data column for picker element 'tuesday_picker_closed'
    const dataSource_tuesday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_tuesday_picker_closed = 'value';
    const labelColumnName_tuesday_picker_closed = 'display';
    
    const style_state0_elTuesday_picker_closed1321277388 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elTuesday_icon_save_hours269532913 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_269532913+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy5269711768 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_wednesday_picker_open = this.getValue_state0_elWednesday_picker_open2067468121();
    // Source datasheet and selected data column for picker element 'wednesday_picker_open'
    const dataSource_wednesday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_wednesday_picker_open = 'value';
    const labelColumnName_wednesday_picker_open = 'display';
    
    const style_state0_elWednesday_picker_open2067468121 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy22510494 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_wednesday_picker_closed = this.getValue_state0_elWednesday_picker_closed705079950();
    // Source datasheet and selected data column for picker element 'wednesday_picker_closed'
    const dataSource_wednesday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_wednesday_picker_closed = 'value';
    const labelColumnName_wednesday_picker_closed = 'display';
    
    const style_state0_elWednesday_picker_closed705079950 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elWednesday_icon_save_hours777441329 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_777441329+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy61934904717 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_thursday_picker_open = this.getValue_state0_elThursday_picker_open1841647548();
    // Source datasheet and selected data column for picker element 'thursday_picker_open'
    const dataSource_thursday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_thursday_picker_open = 'value';
    const labelColumnName_thursday_picker_open = 'display';
    
    const style_state0_elThursday_picker_open1841647548 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy3464131207 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_thursday_picker_closed = this.getValue_state0_elThursday_picker_closed301801150();
    // Source datasheet and selected data column for picker element 'thursday_picker_closed'
    const dataSource_thursday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_thursday_picker_closed = 'value';
    const labelColumnName_thursday_picker_closed = 'display';
    
    const style_state0_elThursday_picker_closed301801150 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elThursday_icon_save_hours1228449746 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1228449746+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy71700526529 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_friday_picker_open = this.getValue_state0_elFriday_picker_open1037987712();
    // Source datasheet and selected data column for picker element 'friday_picker_open'
    const dataSource_friday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_friday_picker_open = 'value';
    const labelColumnName_friday_picker_open = 'display';
    
    const style_state0_elFriday_picker_open1037987712 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy41348846220 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_friday_picker_closed = this.getValue_state0_elFriday_picker_closed2058466485();
    // Source datasheet and selected data column for picker element 'friday_picker_closed'
    const dataSource_friday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_friday_picker_closed = 'value';
    const labelColumnName_friday_picker_closed = 'display';
    
    const style_state0_elFriday_picker_closed2058466485 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elFriday_icon_save_hours792710781 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_792710781+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy8163227735 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_saturday_picker_open = this.getValue_state0_elSaturday_picker_open1914129443();
    // Source datasheet and selected data column for picker element 'saturday_picker_open'
    const dataSource_saturday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_saturday_picker_open = 'value';
    const labelColumnName_saturday_picker_open = 'display';
    
    const style_state0_elSaturday_picker_open1914129443 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy51985618532 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_saturday_picker_closed = this.getValue_state0_elSaturday_picker_closed1288282373();
    // Source datasheet and selected data column for picker element 'saturday_picker_closed'
    const dataSource_saturday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_saturday_picker_closed = 'value';
    const labelColumnName_saturday_picker_closed = 'display';
    
    const style_state0_elSaturday_picker_closed1288282373 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elSaturday_icon_save_hours1066919085 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1066919085+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy91432910011 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_sunday_picker_open = this.getValue_state0_elSunday_picker_open1262554967();
    // Source datasheet and selected data column for picker element 'sunday_picker_open'
    const dataSource_sunday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_sunday_picker_open = 'value';
    const labelColumnName_sunday_picker_open = 'display';
    
    const style_state0_elSunday_picker_open1262554967 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy62066558617 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_sunday_picker_closed = this.getValue_state0_elSunday_picker_closed1421457572();
    // Source datasheet and selected data column for picker element 'sunday_picker_closed'
    const dataSource_sunday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_sunday_picker_closed = 'value';
    const labelColumnName_sunday_picker_closed = 'display';
    
    const style_state0_elSunday_picker_closed1421457572 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elSunday_icon_save_hours1247610652 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1247610652+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_holidays2039980172 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    
    const style_state0_elSunday_icon_save_hoursCopy1264729165 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1264729165+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list 2'.
    let items_list2 = [];
    let listComps_list2 = {};
    this._state0_elList21673429372_items = [];
    
    const style_state0_elList21673429372 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const fillColorValue_rectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_state0_elRectangle1554018587 = {
      background: (fillColorValue_rectangle && fillColorValue_rectangle.length > 0) ? fillColorValue_rectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const style_state0_elBottomNavBar713744221 = {
      display: 'none',
     };
    
    // Source items and any special components used for list/grid element 'menu items list'.
    let items_menuItemsList = [];
    let listComps_menuItemsList = {};
    items_menuItemsList = items_menuItemsList.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    this._state0_elMenuItemsList74605394_items = [];
    
    const style_state0_elMenuItemsList74605394_outer = {
      display: 'none',
     };
    const style_state0_elBrandLogoImage1230304929 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.brandImageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      display: 'none',
     };
    const style_state0_elImage1093429335 = {
      height: 'auto',
      display: 'none',
     };
    const state0_elComponent1638506712 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state0_elComponent1638506712">
        <Component4 ref={(el)=> this._state0_elComponent1638506712 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const state0_elHelpOverlayScreen567710614 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_showHelpOverlay'] : '')) ? (
      <div className="hasNestedComps state0_elHelpOverlayScreen567710614">
        <HelpOverlayScreen visualStateIndex={2} ref={(el)=> this._state0_elHelpOverlayScreen567710614 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen LocationDetailsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBackground675961797" style={style_state0_elBackground675961797_outer}>
            <div className="appBg" style={style_state0_elBackground675961797} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state0_elRectangle21641069097">
            <div style={style_state0_elRectangle21641069097} />
          </div>
          
          <div className="state0_elIconButton21350471191">
            <button className="actionFont" style={style_state0_elIconButton21350471191} onClick={this.onClick_state0_elIconButton21350471191}  />
          </div>
          
          <div className="state0_elImage2">
            <div style={style_state0_elImage2} />
          </div>
          
          <div className="state0_elSpacer1582594028">
            <div />
          </div>
          
          <div className="state0_elTextCopy287944581">
            <div className="font-futuraMedium" style={style_state0_elTextCopy287944581}>
              <div>{value_textCopy2}</div>
            </div>
          </div>
          
          <div className="state0_elFieldTitle730799850" style={style_state0_elFieldTitle730799850_outer}>
            <input className="font-futuraMedium" style={style_state0_elFieldTitle730799850} type="text" placeholder={placeholderValue_fieldTitle} onChange={this.textInputChanged_state0_elFieldTitle730799850} value={this.getValue_state0_elFieldTitle730799850()}  />
          </div>
          
          <div className="state0_elIconButton1141083902" style={style_state0_elIconButton1141083902_outer}>
            <button className="actionFont" style={style_state0_elIconButton1141083902} onClick={this.onClick_state0_elIconButton1141083902}  />
          </div>
          
          <div className="state0_elSpacer21660507363">
            <div />
          </div>
          
          <div className="state0_elTextCopy61640246345">
            <div className="font-futuraMedium" style={style_state0_elTextCopy61640246345}>
              <div>{this.props.locStrings.menudetails2_textcopy6_1640246345}</div>
            </div>
          </div>
          
          <div className="state0_elText_brand_name1135612262">
            <div className="font-avenirBook" style={style_state0_elText_brand_name1135612262}>
              <div>{this.props.locStrings.menudetails2_text_brand_name_1135612262}</div>
            </div>
          </div>
          
          <div className="state0_elField_brand_name1852171699">
            <input className="font-avenirNextRegular" style={style_state0_elField_brand_name1852171699} type="text" placeholder={this.props.locStrings.menudetails2_field_brand_name_1852171699} onChange={this.textInputChanged_state0_elField_brand_name1852171699} value={this.getValue_state0_elField_brand_name1852171699()}  />
          </div>
          
          <div className="state0_elTextCopy183155673">
            <div className="font-avenirBook" style={style_state0_elTextCopy183155673}>
              <div>{this.props.locStrings.menudetails2_textcopy_183155673}</div>
            </div>
          </div>
          
          <div className="state0_elFieldDescription1596337839">
            <textarea className="font-avenirNextRegular" style={style_state0_elFieldDescription1596337839}  placeholder={this.props.locStrings.menudetails2_fielddescription_1596337839} onChange={this.textAreaChanged_state0_elFieldDescription1596337839} value={this.getValue_state0_elFieldDescription1596337839()}  />
          </div>
          
          <div className="state0_elField_isActiveCheckbox298406343">
            <Checkbox className="font-openSans" style={style_state0_elField_isActiveCheckbox298406343}  label={this.props.locStrings.menudetails2_field_isactivecheckbox_298406343} checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_state0_elField_isActiveCheckbox298406343} />
          </div>
          
          <div className="hasNestedComps state0_elComp464653734">
            <div style={style_state0_elComp464653734} onClick={this.onClick_state0_elComp464653734} >
              <Comp5 ref={(el)=> this._state0_elComp464653734 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state0_elTextCopy3591760950">
            <div className="font-futuraMedium" style={style_state0_elTextCopy3591760950}>
              <div>{this.props.locStrings.menudetails2_textcopy3_591760950}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elList1545617919">
            <ul style={style_state0_elList1545617919}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'name': row['name'], 'price': row['price'], 'order': row['order'], 'document_key': row['document_key'], 'imageURL': row['imageURL'], }} ref={(el) => {if (el) this._state0_elList1545617919_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state0_elList1545617919_endMarker = el} />
            </ul>
          </div>
          
          <div className="state0_elSpacer31341192102">
            <div />
          </div>
          
          <div className="state0_elTextCopy41438074854">
            <div className="font-futuraMedium" style={style_state0_elTextCopy41438074854}>
              <div>{this.props.locStrings.locationdetails_textcopy4_1438074854}</div>
            </div>
          </div>
          
          <div className="state0_elText_brand_nameCopy1131753664">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy1131753664}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy_1131753664}</div>
            </div>
          </div>
          
          <div className="state0_elField1042362342">
            <input className="baseFont" style={style_state0_elField1042362342} type="text" placeholder={this.props.locStrings.locationdetails_field_1042362342} onChange={this.textInputChanged_state0_elField1042362342} value={this.getValue_state0_elField1042362342()}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy2458308127">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy2458308127}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy2_458308127}</div>
            </div>
          </div>
          
          <div className="state0_elFieldCopy420526630">
            <input className="baseFont" style={style_state0_elFieldCopy420526630} type="text" placeholder={this.props.locStrings.locationdetails_fieldcopy_420526630} onChange={this.textInputChanged_state0_elFieldCopy420526630} value={this.getValue_state0_elFieldCopy420526630()}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy31917917604">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy31917917604}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy3_1917917604}</div>
            </div>
          </div>
          
          <div className="state0_elFieldCopy21704827828">
            <input className="baseFont" style={style_state0_elFieldCopy21704827828} type="text" placeholder={this.props.locStrings.locationdetails_fieldcopy2_1704827828} onChange={this.textInputChanged_state0_elFieldCopy21704827828} value={this.getValue_state0_elFieldCopy21704827828()}  />
          </div>
          
          <div className="state0_elTextCopy5109082731">
            <div className="font-futuraMedium" style={style_state0_elTextCopy5109082731}>
              <div>{this.props.locStrings.locationdetails_textcopy5_109082731}</div>
            </div>
          </div>
          
          <div className="state0_elText_brand_nameCopy13523964514">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy13523964514}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy13_523964514}</div>
            </div>
          </div>
          
          <div className="state0_elMonday_picker_open398940501">
            <Select className="baseFont" style={style_state0_elMonday_picker_open398940501}  onChange={this.pickerValueChanged_state0_elMonday_picker_open398940501} value={selection_monday_picker_open} >
              {dataSource_monday_picker_open.items.every(item => {
                return item[valueColumnName_monday_picker_open] !== selection_monday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_monday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_monday_picker_open];
                const labelColValue = item[labelColumnName_monday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy718164037">
            <div className="baseFont" style={style_state0_elText_toCopy718164037}>
              <div>{this.props.locStrings.locationdetails_text_tocopy7_18164037}</div>
            </div>
          </div>
          
          <div className="state0_elMonday_picker_closed659010974">
            <Select className="baseFont" style={style_state0_elMonday_picker_closed659010974}  onChange={this.pickerValueChanged_state0_elMonday_picker_closed659010974} value={selection_monday_picker_closed} >
              {dataSource_monday_picker_closed.items.every(item => {
                return item[valueColumnName_monday_picker_closed] !== selection_monday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_monday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_monday_picker_closed];
                const labelColValue = item[labelColumnName_monday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elMonday_icon_save_hours1666040709">
            <button className="actionFont" style={style_state0_elMonday_icon_save_hours1666040709} onClick={this.onClick_state0_elMonday_icon_save_hours1666040709}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy4419489363">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy4419489363}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy4_419489363}</div>
            </div>
          </div>
          
          <div className="state0_elTuesday_picker_open251877840">
            <Select className="baseFont" style={style_state0_elTuesday_picker_open251877840}  onChange={this.pickerValueChanged_state0_elTuesday_picker_open251877840} value={selection_tuesday_picker_open} >
              {dataSource_tuesday_picker_open.items.every(item => {
                return item[valueColumnName_tuesday_picker_open] !== selection_tuesday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_tuesday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_tuesday_picker_open];
                const labelColValue = item[labelColumnName_tuesday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy1365476359">
            <div className="baseFont" style={style_state0_elText_toCopy1365476359}>
              <div>{this.props.locStrings.locationdetails_text_tocopy_1365476359}</div>
            </div>
          </div>
          
          <div className="state0_elTuesday_picker_closed1321277388">
            <Select className="baseFont" style={style_state0_elTuesday_picker_closed1321277388}  onChange={this.pickerValueChanged_state0_elTuesday_picker_closed1321277388} value={selection_tuesday_picker_closed} >
              {dataSource_tuesday_picker_closed.items.every(item => {
                return item[valueColumnName_tuesday_picker_closed] !== selection_tuesday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_tuesday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_tuesday_picker_closed];
                const labelColValue = item[labelColumnName_tuesday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elTuesday_icon_save_hours269532913">
            <button className="actionFont" style={style_state0_elTuesday_icon_save_hours269532913} onClick={this.onClick_state0_elTuesday_icon_save_hours269532913}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy5269711768">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy5269711768}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy5_269711768}</div>
            </div>
          </div>
          
          <div className="state0_elWednesday_picker_open2067468121">
            <Select className="baseFont" style={style_state0_elWednesday_picker_open2067468121}  onChange={this.pickerValueChanged_state0_elWednesday_picker_open2067468121} value={selection_wednesday_picker_open} >
              {dataSource_wednesday_picker_open.items.every(item => {
                return item[valueColumnName_wednesday_picker_open] !== selection_wednesday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_wednesday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_wednesday_picker_open];
                const labelColValue = item[labelColumnName_wednesday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy22510494">
            <div className="baseFont" style={style_state0_elText_toCopy22510494}>
              <div>{this.props.locStrings.locationdetails_text_tocopy2_2510494}</div>
            </div>
          </div>
          
          <div className="state0_elWednesday_picker_closed705079950">
            <Select className="baseFont" style={style_state0_elWednesday_picker_closed705079950}  onChange={this.pickerValueChanged_state0_elWednesday_picker_closed705079950} value={selection_wednesday_picker_closed} >
              {dataSource_wednesday_picker_closed.items.every(item => {
                return item[valueColumnName_wednesday_picker_closed] !== selection_wednesday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_wednesday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_wednesday_picker_closed];
                const labelColValue = item[labelColumnName_wednesday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elWednesday_icon_save_hours777441329">
            <button className="actionFont" style={style_state0_elWednesday_icon_save_hours777441329} onClick={this.onClick_state0_elWednesday_icon_save_hours777441329}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy61934904717">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy61934904717}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy6_1934904717}</div>
            </div>
          </div>
          
          <div className="state0_elThursday_picker_open1841647548">
            <Select className="baseFont" style={style_state0_elThursday_picker_open1841647548}  onChange={this.pickerValueChanged_state0_elThursday_picker_open1841647548} value={selection_thursday_picker_open} >
              {dataSource_thursday_picker_open.items.every(item => {
                return item[valueColumnName_thursday_picker_open] !== selection_thursday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_thursday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_thursday_picker_open];
                const labelColValue = item[labelColumnName_thursday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy3464131207">
            <div className="baseFont" style={style_state0_elText_toCopy3464131207}>
              <div>{this.props.locStrings.locationdetails_text_tocopy3_464131207}</div>
            </div>
          </div>
          
          <div className="state0_elThursday_picker_closed301801150">
            <Select className="baseFont" style={style_state0_elThursday_picker_closed301801150}  onChange={this.pickerValueChanged_state0_elThursday_picker_closed301801150} value={selection_thursday_picker_closed} >
              {dataSource_thursday_picker_closed.items.every(item => {
                return item[valueColumnName_thursday_picker_closed] !== selection_thursday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_thursday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_thursday_picker_closed];
                const labelColValue = item[labelColumnName_thursday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elThursday_icon_save_hours1228449746">
            <button className="actionFont" style={style_state0_elThursday_icon_save_hours1228449746} onClick={this.onClick_state0_elThursday_icon_save_hours1228449746}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy71700526529">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy71700526529}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy7_1700526529}</div>
            </div>
          </div>
          
          <div className="state0_elFriday_picker_open1037987712">
            <Select className="baseFont" style={style_state0_elFriday_picker_open1037987712}  onChange={this.pickerValueChanged_state0_elFriday_picker_open1037987712} value={selection_friday_picker_open} >
              {dataSource_friday_picker_open.items.every(item => {
                return item[valueColumnName_friday_picker_open] !== selection_friday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_friday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_friday_picker_open];
                const labelColValue = item[labelColumnName_friday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy41348846220">
            <div className="baseFont" style={style_state0_elText_toCopy41348846220}>
              <div>{this.props.locStrings.locationdetails_text_tocopy4_1348846220}</div>
            </div>
          </div>
          
          <div className="state0_elFriday_picker_closed2058466485">
            <Select className="baseFont" style={style_state0_elFriday_picker_closed2058466485}  onChange={this.pickerValueChanged_state0_elFriday_picker_closed2058466485} value={selection_friday_picker_closed} >
              {dataSource_friday_picker_closed.items.every(item => {
                return item[valueColumnName_friday_picker_closed] !== selection_friday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_friday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_friday_picker_closed];
                const labelColValue = item[labelColumnName_friday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elFriday_icon_save_hours792710781">
            <button className="actionFont" style={style_state0_elFriday_icon_save_hours792710781} onClick={this.onClick_state0_elFriday_icon_save_hours792710781}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy8163227735">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy8163227735}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy8_163227735}</div>
            </div>
          </div>
          
          <div className="state0_elSaturday_picker_open1914129443">
            <Select className="baseFont" style={style_state0_elSaturday_picker_open1914129443}  onChange={this.pickerValueChanged_state0_elSaturday_picker_open1914129443} value={selection_saturday_picker_open} >
              {dataSource_saturday_picker_open.items.every(item => {
                return item[valueColumnName_saturday_picker_open] !== selection_saturday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_saturday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_saturday_picker_open];
                const labelColValue = item[labelColumnName_saturday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy51985618532">
            <div className="baseFont" style={style_state0_elText_toCopy51985618532}>
              <div>{this.props.locStrings.locationdetails_text_tocopy5_1985618532}</div>
            </div>
          </div>
          
          <div className="state0_elSaturday_picker_closed1288282373">
            <Select className="baseFont" style={style_state0_elSaturday_picker_closed1288282373}  onChange={this.pickerValueChanged_state0_elSaturday_picker_closed1288282373} value={selection_saturday_picker_closed} >
              {dataSource_saturday_picker_closed.items.every(item => {
                return item[valueColumnName_saturday_picker_closed] !== selection_saturday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_saturday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_saturday_picker_closed];
                const labelColValue = item[labelColumnName_saturday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elSaturday_icon_save_hours1066919085">
            <button className="actionFont" style={style_state0_elSaturday_icon_save_hours1066919085} onClick={this.onClick_state0_elSaturday_icon_save_hours1066919085}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy91432910011">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy91432910011}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy9_1432910011}</div>
            </div>
          </div>
          
          <div className="state0_elSunday_picker_open1262554967">
            <Select className="baseFont" style={style_state0_elSunday_picker_open1262554967}  onChange={this.pickerValueChanged_state0_elSunday_picker_open1262554967} value={selection_sunday_picker_open} >
              {dataSource_sunday_picker_open.items.every(item => {
                return item[valueColumnName_sunday_picker_open] !== selection_sunday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_open];
                const labelColValue = item[labelColumnName_sunday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy62066558617">
            <div className="baseFont" style={style_state0_elText_toCopy62066558617}>
              <div>{this.props.locStrings.locationdetails_text_tocopy6_2066558617}</div>
            </div>
          </div>
          
          <div className="state0_elSunday_picker_closed1421457572">
            <Select className="baseFont" style={style_state0_elSunday_picker_closed1421457572}  onChange={this.pickerValueChanged_state0_elSunday_picker_closed1421457572} value={selection_sunday_picker_closed} >
              {dataSource_sunday_picker_closed.items.every(item => {
                return item[valueColumnName_sunday_picker_closed] !== selection_sunday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_closed];
                const labelColValue = item[labelColumnName_sunday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elSunday_icon_save_hours1247610652">
            <button className="actionFont" style={style_state0_elSunday_icon_save_hours1247610652} onClick={this.onClick_state0_elSunday_icon_save_hours1247610652}  />
          </div>
          
          <div className="state0_elText_holidays2039980172">
            <div className="font-avenirBook" style={style_state0_elText_holidays2039980172}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy10_2039980172}</div>
            </div>
          </div>
          
          <div className="state0_elPicker_holiday311812817">
            <DatePicker className="baseFont"  onChange={this.pickerValueChanged_state0_elPicker_holiday311812817} selected={Date.parse(this.getValue_state0_elPicker_holiday311812817())}  />
          </div>
          
          <div className="state0_elSunday_icon_save_hoursCopy1264729165">
            <button className="actionFont" style={style_state0_elSunday_icon_save_hoursCopy1264729165} onClick={this.onClick_state0_elSunday_icon_save_hoursCopy1264729165}  />
          </div>
          
          <div className="hasNestedComps state0_elList21673429372">
            <ul style={style_state0_elList21673429372}>
              {items_list2.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list2[row._componentId]
                    : <HolidayListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} ref={(el) => {if (el) this._state0_elList21673429372_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state0_elList21673429372_endMarker = el} />
            </ul>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state0_elRectangle1554018587" style={style_state0_elRectangle1554018587} />
            <div className="hasNestedComps state0_elBottomNavBar713744221" style={style_state0_elBottomNavBar713744221}>
              <BottomNavBar ref={(el)=> this._state0_elBottomNavBar713744221 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elMenuItemsList74605394" style={style_state0_elMenuItemsList74605394_outer}>
              <ul>
                {items_menuItemsList.map((row, index) => {
                  let itemComp = (row._componentId)
                      ? listComps_menuItemsList[row._componentId]
                      : <BrandListForDropdown appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'imageURL': row['imageURL'], 'name': row['name'], 'price': row['price'], }} ref={(el) => {if (el) this._state0_elMenuItemsList74605394_items.push(el)}} />;
                  return (<li key={row.key}>
                      {itemComp}
                    </li>);
                })}
                <div className="marker" ref={(el)=> this._state0_elMenuItemsList74605394_endMarker = el} />
              </ul>
            </div>
            
            <div className="state0_elBrandLogoImage1230304929" style={style_state0_elBrandLogoImage1230304929} />
            <img className="state0_elImage1093429335" style={style_state0_elImage1093429335} src={img_state0_elImage1093429335} alt=""  />
            <div className="hasNestedComps state0_elIPhoneMockupComponent2128991200">
              <IPhoneMockupComponent {...dataSheet_fb_currentBrand.items[0]} visualStateIndex={1} ref={(el)=> this._state0_elIPhoneMockupComponent2128991200 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elFooter1887594677">
              <Footer ref={(el)=> this._state0_elFooter1887594677 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elHeader397462128">
              <Header ref={(el)=> this._state0_elHeader397462128 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state0_elComponent1638506712 }
            { state0_elHelpOverlayScreen567710614 }
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elIconButton21350471191 = async () => {
    let newVal = "yyyy";
    this.props.appActions.updateDataSlot('ds_activeLocationName', newVal);
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  textInputChanged_state1_elFieldTitle730799850 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state1_elFieldTitle730799850 = () => {
    return this.state.fieldTitle !== undefined ? this.state.fieldTitle : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  onClick_state1_elIconButton1141083902 = async () => {
  
  
  }
  
  
  textInputChanged_state1_elField_brand_name1852171699 = (event) => {
    this.setState({field_brand_name: event.target.value});
  }
  
  getValue_state1_elField_brand_name1852171699 = () => {
    return this.state.field_brand_name !== undefined ? this.state.field_brand_name : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  textAreaChanged_state1_elFieldDescription1596337839 = (event) => {
    this.setState({fieldDescription: event.target.value});
  }
  
  getValue_state1_elFieldDescription1596337839 = () => {
    return this.state.fieldDescription !== undefined ? this.state.fieldDescription : (this.props.dataSheetRow ? this.props.dataSheetRow.description : '');
  }
  
  
  getValue_state1_elField_isActiveCheckbox298406343 = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : (this.props.dataSheetRow ? this.props.dataSheetRow.isActive : '');
  }
  
  checkboxChanged_state1_elField_isActiveCheckbox298406343 = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')});
  }
  
  onClick_state1_elComp464653734 = async () => {
    await this.sendData_state1_elComp464653734_to_fb_menus();
  
  }
  
  
  
  textInputChanged_state1_elField1042362342 = (event) => {
    this.setState({field: event.target.value});
  }
  
  getValue_state1_elField1042362342 = () => {
    let transformPropValue_field = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input._lat;
    }
    
    return this.state.field !== undefined ? this.state.field : (transformPropValue_field(this.props.dataSheetRow ? this.props.dataSheetRow.geoLocation : ''));
  }
  
  textInputChanged_state1_elFieldCopy420526630 = (event) => {
    this.setState({fieldCopy: event.target.value});
  }
  
  getValue_state1_elFieldCopy420526630 = () => {
    let transformPropValue_fieldCopy = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input._long;
    }
    
    return this.state.fieldCopy !== undefined ? this.state.fieldCopy : (transformPropValue_fieldCopy(this.props.dataSheetRow ? this.props.dataSheetRow.geoLocation : ''));
  }
  
  textInputChanged_state1_elFieldCopy21704827828 = (event) => {
    this.setState({fieldCopy2: event.target.value});
  }
  
  getValue_state1_elFieldCopy21704827828 = () => {
    let transformPropValue_fieldCopy2 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    }
    
    return this.state.fieldCopy2 !== undefined ? this.state.fieldCopy2 : (transformPropValue_fieldCopy2(this.props.dataSheetRow ? this.props.dataSheetRow.geohash : ''));
  }
  
  
  getValue_state1_elMonday_picker_open398940501 = () => {
    let transformPropValue_monday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.monday_open;
    }
    
    return this.state.monday_picker_open !== undefined ? this.state.monday_picker_open : (transformPropValue_monday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elMonday_picker_open398940501 = (event) => {
    this.setState({monday_picker_open: event.target.value});
  }
  
  
  getValue_state1_elMonday_picker_closed659010974 = () => {
    let transformPropValue_monday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.monday_close;
    }
    
    return this.state.monday_picker_closed !== undefined ? this.state.monday_picker_closed : (transformPropValue_monday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elMonday_picker_closed659010974 = (event) => {
    this.setState({monday_picker_closed: event.target.value});
  }
  
  onClick_state1_elMonday_icon_save_hours1666040709 = async () => {
    await this.sendData_state1_elMonday_icon_save_hours1666040709_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elTuesday_picker_open251877840 = () => {
    let transformPropValue_tuesday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.tuesday_open;
    }
    
    return this.state.tuesday_picker_open !== undefined ? this.state.tuesday_picker_open : (transformPropValue_tuesday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elTuesday_picker_open251877840 = (event) => {
    this.setState({tuesday_picker_open: event.target.value});
  }
  
  
  getValue_state1_elTuesday_picker_closed1321277388 = () => {
    let transformPropValue_tuesday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.tuesday_close;
    }
    
    return this.state.tuesday_picker_closed !== undefined ? this.state.tuesday_picker_closed : (transformPropValue_tuesday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elTuesday_picker_closed1321277388 = (event) => {
    this.setState({tuesday_picker_closed: event.target.value});
  }
  
  onClick_state1_elTuesday_icon_save_hours269532913 = async () => {
    await this.sendData_state1_elTuesday_icon_save_hours269532913_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elWednesday_picker_open2067468121 = () => {
    let transformPropValue_wednesday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.wednesday_open;
    }
    
    return this.state.wednesday_picker_open !== undefined ? this.state.wednesday_picker_open : (transformPropValue_wednesday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elWednesday_picker_open2067468121 = (event) => {
    this.setState({wednesday_picker_open: event.target.value});
  }
  
  
  getValue_state1_elWednesday_picker_closed705079950 = () => {
    let transformPropValue_wednesday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.wednesday_close;
    }
    
    return this.state.wednesday_picker_closed !== undefined ? this.state.wednesday_picker_closed : (transformPropValue_wednesday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elWednesday_picker_closed705079950 = (event) => {
    this.setState({wednesday_picker_closed: event.target.value});
  }
  
  onClick_state1_elWednesday_icon_save_hours777441329 = async () => {
    await this.sendData_state1_elWednesday_icon_save_hours777441329_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elThursday_picker_open1841647548 = () => {
    let transformPropValue_thursday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.thursday_open;
    }
    
    return this.state.thursday_picker_open !== undefined ? this.state.thursday_picker_open : (transformPropValue_thursday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elThursday_picker_open1841647548 = (event) => {
    this.setState({thursday_picker_open: event.target.value});
  }
  
  
  getValue_state1_elThursday_picker_closed301801150 = () => {
    let transformPropValue_thursday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.thursday_close;
    }
    
    return this.state.thursday_picker_closed !== undefined ? this.state.thursday_picker_closed : (transformPropValue_thursday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elThursday_picker_closed301801150 = (event) => {
    this.setState({thursday_picker_closed: event.target.value});
  }
  
  onClick_state1_elThursday_icon_save_hours1228449746 = async () => {
    await this.sendData_state1_elThursday_icon_save_hours1228449746_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elFriday_picker_open1037987712 = () => {
    let transformPropValue_friday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.friday_open;
    }
    
    return this.state.friday_picker_open !== undefined ? this.state.friday_picker_open : (transformPropValue_friday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elFriday_picker_open1037987712 = (event) => {
    this.setState({friday_picker_open: event.target.value});
  }
  
  
  getValue_state1_elFriday_picker_closed2058466485 = () => {
    let transformPropValue_friday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.friday_close;
    }
    
    return this.state.friday_picker_closed !== undefined ? this.state.friday_picker_closed : (transformPropValue_friday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elFriday_picker_closed2058466485 = (event) => {
    this.setState({friday_picker_closed: event.target.value});
  }
  
  onClick_state1_elFriday_icon_save_hours792710781 = async () => {
    await this.sendData_state1_elFriday_icon_save_hours792710781_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elSaturday_picker_open1914129443 = () => {
    let transformPropValue_saturday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.saturday_open;
    }
    
    return this.state.saturday_picker_open !== undefined ? this.state.saturday_picker_open : (transformPropValue_saturday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSaturday_picker_open1914129443 = (event) => {
    this.setState({saturday_picker_open: event.target.value});
  }
  
  
  getValue_state1_elSaturday_picker_closed1288282373 = () => {
    let transformPropValue_saturday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.saturday_close;
    }
    
    return this.state.saturday_picker_closed !== undefined ? this.state.saturday_picker_closed : (transformPropValue_saturday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSaturday_picker_closed1288282373 = (event) => {
    this.setState({saturday_picker_closed: event.target.value});
  }
  
  onClick_state1_elSaturday_icon_save_hours1066919085 = async () => {
    await this.sendData_state1_elSaturday_icon_save_hours1066919085_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elSunday_picker_open1262554967 = () => {
    let transformPropValue_sunday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.sunday_open;
    }
    
    return this.state.sunday_picker_open !== undefined ? this.state.sunday_picker_open : (transformPropValue_sunday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSunday_picker_open1262554967 = (event) => {
    this.setState({sunday_picker_open: event.target.value});
  }
  
  
  getValue_state1_elSunday_picker_closed1421457572 = () => {
    let transformPropValue_sunday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.sunday_close;
    }
    
    return this.state.sunday_picker_closed !== undefined ? this.state.sunday_picker_closed : (transformPropValue_sunday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSunday_picker_closed1421457572 = (event) => {
    this.setState({sunday_picker_closed: event.target.value});
  }
  
  onClick_state1_elSunday_icon_save_hours1247610652 = async () => {
    await this.sendData_state1_elSunday_icon_save_hours1247610652_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elPicker_holiday311812817 = () => {
    return this.state.picker_holiday !== undefined ? this.state.picker_holiday : '';
  }
  
  pickerValueChanged_state1_elPicker_holiday311812817 = (date) => {
    this.setState({picker_holiday: date.toString()});
  }
  
  onClick_state1_elSunday_icon_save_hoursCopy1264729165 = async () => {
    await this.sendData_state1_elSunday_icon_save_hoursCopy1264729165_to_fb_holidays();
  
  }
  
  
  
  
  sendData_state1_elWednesday_icon_save_hours777441329_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.wednesday_open = parseInt(this.state.wednesday_picker_open);
      input.hours.wednesday_close = parseInt(this.state.wednesday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  sendData_state1_elFriday_icon_save_hours792710781_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.friday_open = parseInt(this.state.friday_picker_open);
      input.hours.friday_close = parseInt(this.state.friday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  sendData_state1_elSunday_icon_save_hours1247610652_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.sunday_open = parseInt(this.state.sunday_picker_open);
      input.hours.sunday_close = parseInt(this.state.sunday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  sendData_state1_elSunday_icon_save_hoursCopy1264729165_to_fb_holidays = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_holidays');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
      altdate: this.getValue_state1_elPicker_holiday311812817(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_holidays', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_holidays', row);
    }
  }
  
  
  sendData_state1_elThursday_icon_save_hours1228449746_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.thursday_open = parseInt(this.state.thursday_picker_open);
      input.hours.thursday_close = parseInt(this.state.thursday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  sendData_state1_elComp464653734_to_fb_menus = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menus');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        
      // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      
      if(this.state.field_isActiveCheckbox === "true" || 
         this.state.field_isActiveCheckbox === true ||
         this.state.field_isActiveCheckbox === "1")
      	{
            input.isActive=true
          };
      
      if(this.state.field_isActiveCheckbox === "false" || 
         this.state.field_isActiveCheckbox === false ||
         this.state.field_isActiveCheckbox === "0")
      	{
            input.isActive=false
          };
      
      
      return input;
      
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menus', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menus', row);
    }
  }
  
  
  sendData_state1_elSaturday_icon_save_hours1066919085_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.saturday_open = parseInt(this.state.saturday_picker_open);
      input.hours.saturday_close = parseInt(this.state.saturday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  sendData_state1_elMonday_icon_save_hours1666040709_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.monday_open = parseInt(this.state.monday_picker_open);
      input.hours.monday_close = parseInt(this.state.monday_picker_closed);
      
      //don't work:
      //input.hours.monday_close.open = parseInt(this.state.monday_picker_open);
      //input.hoursalt[0].close = parseInt(this.state.monday_picker_closed);
      //input.hoursalt[0].name = 'monday';
      //
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  sendData_state1_elTuesday_icon_save_hours269532913_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.tuesday_open = parseInt(this.state.tuesday_picker_open);
      input.hours.tuesday_close = parseInt(this.state.tuesday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_my_locations', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_my_locations', row);
    }
  }
  
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_fb_currentBrand = this.props.dataSheets['fb_currentBrand'];
    // eslint-disable-next-line
    const dataSheet_fb_my_menu_items = this.props.dataSheets['fb_my_menu_items'];
    const style_state1_elBackground675961797 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground675961797_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    
    const style_state1_elRectangle21641069097 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_state1_elIconButton21350471191 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1350471191+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elImage2 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_textCopy2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_activeMenuName'] : ''));
    
    const style_state1_elTextCopy287944581 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const placeholderValue_fieldTitle = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_state1_elFieldTitle730799850 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_state1_elFieldTitle730799850_outer = {
      display: 'none',
     };
    
    const style_state1_elIconButton1141083902 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1141083902+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elIconButton1141083902_outer = {
      display: 'none',
     };
    const style_state1_elTextCopy61640246345 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state1_elText_brand_name1135612262 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elField_brand_name1852171699 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state1_elTextCopy183155673 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elFieldDescription1596337839 = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_state1_elField_isActiveCheckbox298406343();
    
    const style_state1_elField_isActiveCheckbox298406343 = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elComp464653734 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elTextCopy3591760950 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    this._state1_elList1545617919_items = [];
    
    const style_state1_elList1545617919 = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_state1_elTextCopy41438074854 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state1_elText_brand_nameCopy1131753664 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elField1042362342 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy2458308127 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elFieldCopy420526630 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy31917917604 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elFieldCopy21704827828 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state1_elTextCopy5109082731 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state1_elText_brand_nameCopy13523964514 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_monday_picker_open = this.getValue_state1_elMonday_picker_open398940501();
    // Source datasheet and selected data column for picker element 'monday_picker_open'
    const dataSource_monday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_monday_picker_open = 'value';
    const labelColumnName_monday_picker_open = 'display';
    
    const style_state1_elMonday_picker_open398940501 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy718164037 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_monday_picker_closed = this.getValue_state1_elMonday_picker_closed659010974();
    // Source datasheet and selected data column for picker element 'monday_picker_closed'
    const dataSource_monday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_monday_picker_closed = 'value';
    const labelColumnName_monday_picker_closed = 'display';
    
    const style_state1_elMonday_picker_closed659010974 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elMonday_icon_save_hours1666040709 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1666040709+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy4419489363 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_tuesday_picker_open = this.getValue_state1_elTuesday_picker_open251877840();
    // Source datasheet and selected data column for picker element 'tuesday_picker_open'
    const dataSource_tuesday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_tuesday_picker_open = 'value';
    const labelColumnName_tuesday_picker_open = 'display';
    
    const style_state1_elTuesday_picker_open251877840 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy1365476359 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_tuesday_picker_closed = this.getValue_state1_elTuesday_picker_closed1321277388();
    // Source datasheet and selected data column for picker element 'tuesday_picker_closed'
    const dataSource_tuesday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_tuesday_picker_closed = 'value';
    const labelColumnName_tuesday_picker_closed = 'display';
    
    const style_state1_elTuesday_picker_closed1321277388 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elTuesday_icon_save_hours269532913 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_269532913+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy5269711768 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_wednesday_picker_open = this.getValue_state1_elWednesday_picker_open2067468121();
    // Source datasheet and selected data column for picker element 'wednesday_picker_open'
    const dataSource_wednesday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_wednesday_picker_open = 'value';
    const labelColumnName_wednesday_picker_open = 'display';
    
    const style_state1_elWednesday_picker_open2067468121 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy22510494 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_wednesday_picker_closed = this.getValue_state1_elWednesday_picker_closed705079950();
    // Source datasheet and selected data column for picker element 'wednesday_picker_closed'
    const dataSource_wednesday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_wednesday_picker_closed = 'value';
    const labelColumnName_wednesday_picker_closed = 'display';
    
    const style_state1_elWednesday_picker_closed705079950 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elWednesday_icon_save_hours777441329 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_777441329+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy61934904717 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_thursday_picker_open = this.getValue_state1_elThursday_picker_open1841647548();
    // Source datasheet and selected data column for picker element 'thursday_picker_open'
    const dataSource_thursday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_thursday_picker_open = 'value';
    const labelColumnName_thursday_picker_open = 'display';
    
    const style_state1_elThursday_picker_open1841647548 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy3464131207 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_thursday_picker_closed = this.getValue_state1_elThursday_picker_closed301801150();
    // Source datasheet and selected data column for picker element 'thursday_picker_closed'
    const dataSource_thursday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_thursday_picker_closed = 'value';
    const labelColumnName_thursday_picker_closed = 'display';
    
    const style_state1_elThursday_picker_closed301801150 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elThursday_icon_save_hours1228449746 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1228449746+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy71700526529 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_friday_picker_open = this.getValue_state1_elFriday_picker_open1037987712();
    // Source datasheet and selected data column for picker element 'friday_picker_open'
    const dataSource_friday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_friday_picker_open = 'value';
    const labelColumnName_friday_picker_open = 'display';
    
    const style_state1_elFriday_picker_open1037987712 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy41348846220 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_friday_picker_closed = this.getValue_state1_elFriday_picker_closed2058466485();
    // Source datasheet and selected data column for picker element 'friday_picker_closed'
    const dataSource_friday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_friday_picker_closed = 'value';
    const labelColumnName_friday_picker_closed = 'display';
    
    const style_state1_elFriday_picker_closed2058466485 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elFriday_icon_save_hours792710781 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_792710781+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy8163227735 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_saturday_picker_open = this.getValue_state1_elSaturday_picker_open1914129443();
    // Source datasheet and selected data column for picker element 'saturday_picker_open'
    const dataSource_saturday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_saturday_picker_open = 'value';
    const labelColumnName_saturday_picker_open = 'display';
    
    const style_state1_elSaturday_picker_open1914129443 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy51985618532 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_saturday_picker_closed = this.getValue_state1_elSaturday_picker_closed1288282373();
    // Source datasheet and selected data column for picker element 'saturday_picker_closed'
    const dataSource_saturday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_saturday_picker_closed = 'value';
    const labelColumnName_saturday_picker_closed = 'display';
    
    const style_state1_elSaturday_picker_closed1288282373 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elSaturday_icon_save_hours1066919085 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1066919085+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy91432910011 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_sunday_picker_open = this.getValue_state1_elSunday_picker_open1262554967();
    // Source datasheet and selected data column for picker element 'sunday_picker_open'
    const dataSource_sunday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_sunday_picker_open = 'value';
    const labelColumnName_sunday_picker_open = 'display';
    
    const style_state1_elSunday_picker_open1262554967 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy62066558617 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_sunday_picker_closed = this.getValue_state1_elSunday_picker_closed1421457572();
    // Source datasheet and selected data column for picker element 'sunday_picker_closed'
    const dataSource_sunday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_sunday_picker_closed = 'value';
    const labelColumnName_sunday_picker_closed = 'display';
    
    const style_state1_elSunday_picker_closed1421457572 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elSunday_icon_save_hours1247610652 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1247610652+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_holidays2039980172 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    
    const style_state1_elSunday_icon_save_hoursCopy1264729165 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1264729165+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list 2'.
    let items_list2 = [];
    let listComps_list2 = {};
    this._state1_elList21673429372_items = [];
    
    const style_state1_elList21673429372 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const fillColorValue_rectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_state1_elRectangle1554018587 = {
      background: (fillColorValue_rectangle && fillColorValue_rectangle.length > 0) ? fillColorValue_rectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const style_state1_elBottomNavBar713744221 = {
      display: 'none',
     };
    
    // Source items and any special components used for list/grid element 'menu items list'.
    let items_menuItemsList = [];
    let listComps_menuItemsList = {};
    items_menuItemsList = items_menuItemsList.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    this._state1_elMenuItemsList74605394_items = [];
    
    const style_state1_elMenuItemsList74605394_outer = {
      display: 'none',
     };
    const style_state1_elBrandLogoImage1230304929 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.brandImageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      display: 'none',
     };
    const style_state1_elImage1093429335 = {
      height: 'auto',
      display: 'none',
     };
    const state1_elComponent1638506712 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state1_elComponent1638506712">
        <Component4 ref={(el)=> this._state1_elComponent1638506712 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const state1_elHelpOverlayScreen567710614 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_showHelpOverlay'] : '')) ? (
      <div className="hasNestedComps state1_elHelpOverlayScreen567710614">
        <HelpOverlayScreen visualStateIndex={2} ref={(el)=> this._state1_elHelpOverlayScreen567710614 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen LocationDetailsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state1_elBackground675961797" style={style_state1_elBackground675961797_outer}>
            <div className="appBg" style={style_state1_elBackground675961797} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state1_elRectangle21641069097">
            <div style={style_state1_elRectangle21641069097} />
          </div>
          
          <div className="state1_elIconButton21350471191">
            <button className="actionFont" style={style_state1_elIconButton21350471191} onClick={this.onClick_state1_elIconButton21350471191}  />
          </div>
          
          <div className="state1_elImage2">
            <div style={style_state1_elImage2} />
          </div>
          
          <div className="state1_elSpacer1582594028">
            <div />
          </div>
          
          <div className="state1_elTextCopy287944581">
            <div className="font-futuraMedium" style={style_state1_elTextCopy287944581}>
              <div>{value_textCopy2}</div>
            </div>
          </div>
          
          <div className="state1_elFieldTitle730799850" style={style_state1_elFieldTitle730799850_outer}>
            <input className="font-futuraMedium" style={style_state1_elFieldTitle730799850} type="text" placeholder={placeholderValue_fieldTitle} onChange={this.textInputChanged_state1_elFieldTitle730799850} value={this.getValue_state1_elFieldTitle730799850()}  />
          </div>
          
          <div className="state1_elIconButton1141083902" style={style_state1_elIconButton1141083902_outer}>
            <button className="actionFont" style={style_state1_elIconButton1141083902} onClick={this.onClick_state1_elIconButton1141083902}  />
          </div>
          
          <div className="state1_elSpacer21660507363">
            <div />
          </div>
          
          <div className="state1_elTextCopy61640246345">
            <div className="font-futuraMedium" style={style_state1_elTextCopy61640246345}>
              <div>{this.props.locStrings.menudetails2_textcopy6_1640246345}</div>
            </div>
          </div>
          
          <div className="state1_elText_brand_name1135612262">
            <div className="font-avenirBook" style={style_state1_elText_brand_name1135612262}>
              <div>{this.props.locStrings.menudetails2_text_brand_name_1135612262}</div>
            </div>
          </div>
          
          <div className="state1_elField_brand_name1852171699">
            <input className="font-avenirNextRegular" style={style_state1_elField_brand_name1852171699} type="text" placeholder={this.props.locStrings.menudetails2_field_brand_name_1852171699} onChange={this.textInputChanged_state1_elField_brand_name1852171699} value={this.getValue_state1_elField_brand_name1852171699()}  />
          </div>
          
          <div className="state1_elTextCopy183155673">
            <div className="font-avenirBook" style={style_state1_elTextCopy183155673}>
              <div>{this.props.locStrings.menudetails2_textcopy_183155673}</div>
            </div>
          </div>
          
          <div className="state1_elFieldDescription1596337839">
            <textarea className="font-avenirNextRegular" style={style_state1_elFieldDescription1596337839}  placeholder={this.props.locStrings.menudetails2_fielddescription_1596337839} onChange={this.textAreaChanged_state1_elFieldDescription1596337839} value={this.getValue_state1_elFieldDescription1596337839()}  />
          </div>
          
          <div className="state1_elField_isActiveCheckbox298406343">
            <Checkbox className="font-openSans" style={style_state1_elField_isActiveCheckbox298406343}  label={this.props.locStrings.menudetails2_field_isactivecheckbox_298406343} checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_state1_elField_isActiveCheckbox298406343} />
          </div>
          
          <div className="hasNestedComps state1_elComp464653734">
            <div style={style_state1_elComp464653734} onClick={this.onClick_state1_elComp464653734} >
              <Comp5 ref={(el)=> this._state1_elComp464653734 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state1_elTextCopy3591760950">
            <div className="font-futuraMedium" style={style_state1_elTextCopy3591760950}>
              <div>{this.props.locStrings.menudetails2_textcopy3_591760950}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elList1545617919">
            <ul style={style_state1_elList1545617919}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'name': row['name'], 'price': row['price'], 'order': row['order'], 'document_key': row['document_key'], 'imageURL': row['imageURL'], }} ref={(el) => {if (el) this._state1_elList1545617919_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state1_elList1545617919_endMarker = el} />
            </ul>
          </div>
          
          <div className="state1_elSpacer31341192102">
            <div />
          </div>
          
          <div className="state1_elTextCopy41438074854">
            <div className="font-futuraMedium" style={style_state1_elTextCopy41438074854}>
              <div>{this.props.locStrings.locationdetails_textcopy4_1438074854}</div>
            </div>
          </div>
          
          <div className="state1_elText_brand_nameCopy1131753664">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy1131753664}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy_1131753664}</div>
            </div>
          </div>
          
          <div className="state1_elField1042362342">
            <input className="baseFont" style={style_state1_elField1042362342} type="text" placeholder={this.props.locStrings.locationdetails_field_1042362342} onChange={this.textInputChanged_state1_elField1042362342} value={this.getValue_state1_elField1042362342()}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy2458308127">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy2458308127}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy2_458308127}</div>
            </div>
          </div>
          
          <div className="state1_elFieldCopy420526630">
            <input className="baseFont" style={style_state1_elFieldCopy420526630} type="text" placeholder={this.props.locStrings.locationdetails_fieldcopy_420526630} onChange={this.textInputChanged_state1_elFieldCopy420526630} value={this.getValue_state1_elFieldCopy420526630()}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy31917917604">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy31917917604}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy3_1917917604}</div>
            </div>
          </div>
          
          <div className="state1_elFieldCopy21704827828">
            <input className="baseFont" style={style_state1_elFieldCopy21704827828} type="text" placeholder={this.props.locStrings.locationdetails_fieldcopy2_1704827828} onChange={this.textInputChanged_state1_elFieldCopy21704827828} value={this.getValue_state1_elFieldCopy21704827828()}  />
          </div>
          
          <div className="state1_elTextCopy5109082731">
            <div className="font-futuraMedium" style={style_state1_elTextCopy5109082731}>
              <div>{this.props.locStrings.locationdetails_textcopy5_109082731}</div>
            </div>
          </div>
          
          <div className="state1_elText_brand_nameCopy13523964514">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy13523964514}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy13_523964514}</div>
            </div>
          </div>
          
          <div className="state1_elMonday_picker_open398940501">
            <Select className="baseFont" style={style_state1_elMonday_picker_open398940501}  onChange={this.pickerValueChanged_state1_elMonday_picker_open398940501} value={selection_monday_picker_open} >
              {dataSource_monday_picker_open.items.every(item => {
                return item[valueColumnName_monday_picker_open] !== selection_monday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_monday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_monday_picker_open];
                const labelColValue = item[labelColumnName_monday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy718164037">
            <div className="baseFont" style={style_state1_elText_toCopy718164037}>
              <div>{this.props.locStrings.locationdetails_text_tocopy7_18164037}</div>
            </div>
          </div>
          
          <div className="state1_elMonday_picker_closed659010974">
            <Select className="baseFont" style={style_state1_elMonday_picker_closed659010974}  onChange={this.pickerValueChanged_state1_elMonday_picker_closed659010974} value={selection_monday_picker_closed} >
              {dataSource_monday_picker_closed.items.every(item => {
                return item[valueColumnName_monday_picker_closed] !== selection_monday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_monday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_monday_picker_closed];
                const labelColValue = item[labelColumnName_monday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elMonday_icon_save_hours1666040709">
            <button className="actionFont" style={style_state1_elMonday_icon_save_hours1666040709} onClick={this.onClick_state1_elMonday_icon_save_hours1666040709}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy4419489363">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy4419489363}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy4_419489363}</div>
            </div>
          </div>
          
          <div className="state1_elTuesday_picker_open251877840">
            <Select className="baseFont" style={style_state1_elTuesday_picker_open251877840}  onChange={this.pickerValueChanged_state1_elTuesday_picker_open251877840} value={selection_tuesday_picker_open} >
              {dataSource_tuesday_picker_open.items.every(item => {
                return item[valueColumnName_tuesday_picker_open] !== selection_tuesday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_tuesday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_tuesday_picker_open];
                const labelColValue = item[labelColumnName_tuesday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy1365476359">
            <div className="baseFont" style={style_state1_elText_toCopy1365476359}>
              <div>{this.props.locStrings.locationdetails_text_tocopy_1365476359}</div>
            </div>
          </div>
          
          <div className="state1_elTuesday_picker_closed1321277388">
            <Select className="baseFont" style={style_state1_elTuesday_picker_closed1321277388}  onChange={this.pickerValueChanged_state1_elTuesday_picker_closed1321277388} value={selection_tuesday_picker_closed} >
              {dataSource_tuesday_picker_closed.items.every(item => {
                return item[valueColumnName_tuesday_picker_closed] !== selection_tuesday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_tuesday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_tuesday_picker_closed];
                const labelColValue = item[labelColumnName_tuesday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elTuesday_icon_save_hours269532913">
            <button className="actionFont" style={style_state1_elTuesday_icon_save_hours269532913} onClick={this.onClick_state1_elTuesday_icon_save_hours269532913}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy5269711768">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy5269711768}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy5_269711768}</div>
            </div>
          </div>
          
          <div className="state1_elWednesday_picker_open2067468121">
            <Select className="baseFont" style={style_state1_elWednesday_picker_open2067468121}  onChange={this.pickerValueChanged_state1_elWednesday_picker_open2067468121} value={selection_wednesday_picker_open} >
              {dataSource_wednesday_picker_open.items.every(item => {
                return item[valueColumnName_wednesday_picker_open] !== selection_wednesday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_wednesday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_wednesday_picker_open];
                const labelColValue = item[labelColumnName_wednesday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy22510494">
            <div className="baseFont" style={style_state1_elText_toCopy22510494}>
              <div>{this.props.locStrings.locationdetails_text_tocopy2_2510494}</div>
            </div>
          </div>
          
          <div className="state1_elWednesday_picker_closed705079950">
            <Select className="baseFont" style={style_state1_elWednesday_picker_closed705079950}  onChange={this.pickerValueChanged_state1_elWednesday_picker_closed705079950} value={selection_wednesday_picker_closed} >
              {dataSource_wednesday_picker_closed.items.every(item => {
                return item[valueColumnName_wednesday_picker_closed] !== selection_wednesday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_wednesday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_wednesday_picker_closed];
                const labelColValue = item[labelColumnName_wednesday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elWednesday_icon_save_hours777441329">
            <button className="actionFont" style={style_state1_elWednesday_icon_save_hours777441329} onClick={this.onClick_state1_elWednesday_icon_save_hours777441329}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy61934904717">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy61934904717}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy6_1934904717}</div>
            </div>
          </div>
          
          <div className="state1_elThursday_picker_open1841647548">
            <Select className="baseFont" style={style_state1_elThursday_picker_open1841647548}  onChange={this.pickerValueChanged_state1_elThursday_picker_open1841647548} value={selection_thursday_picker_open} >
              {dataSource_thursday_picker_open.items.every(item => {
                return item[valueColumnName_thursday_picker_open] !== selection_thursday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_thursday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_thursday_picker_open];
                const labelColValue = item[labelColumnName_thursday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy3464131207">
            <div className="baseFont" style={style_state1_elText_toCopy3464131207}>
              <div>{this.props.locStrings.locationdetails_text_tocopy3_464131207}</div>
            </div>
          </div>
          
          <div className="state1_elThursday_picker_closed301801150">
            <Select className="baseFont" style={style_state1_elThursday_picker_closed301801150}  onChange={this.pickerValueChanged_state1_elThursday_picker_closed301801150} value={selection_thursday_picker_closed} >
              {dataSource_thursday_picker_closed.items.every(item => {
                return item[valueColumnName_thursday_picker_closed] !== selection_thursday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_thursday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_thursday_picker_closed];
                const labelColValue = item[labelColumnName_thursday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elThursday_icon_save_hours1228449746">
            <button className="actionFont" style={style_state1_elThursday_icon_save_hours1228449746} onClick={this.onClick_state1_elThursday_icon_save_hours1228449746}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy71700526529">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy71700526529}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy7_1700526529}</div>
            </div>
          </div>
          
          <div className="state1_elFriday_picker_open1037987712">
            <Select className="baseFont" style={style_state1_elFriday_picker_open1037987712}  onChange={this.pickerValueChanged_state1_elFriday_picker_open1037987712} value={selection_friday_picker_open} >
              {dataSource_friday_picker_open.items.every(item => {
                return item[valueColumnName_friday_picker_open] !== selection_friday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_friday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_friday_picker_open];
                const labelColValue = item[labelColumnName_friday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy41348846220">
            <div className="baseFont" style={style_state1_elText_toCopy41348846220}>
              <div>{this.props.locStrings.locationdetails_text_tocopy4_1348846220}</div>
            </div>
          </div>
          
          <div className="state1_elFriday_picker_closed2058466485">
            <Select className="baseFont" style={style_state1_elFriday_picker_closed2058466485}  onChange={this.pickerValueChanged_state1_elFriday_picker_closed2058466485} value={selection_friday_picker_closed} >
              {dataSource_friday_picker_closed.items.every(item => {
                return item[valueColumnName_friday_picker_closed] !== selection_friday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_friday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_friday_picker_closed];
                const labelColValue = item[labelColumnName_friday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elFriday_icon_save_hours792710781">
            <button className="actionFont" style={style_state1_elFriday_icon_save_hours792710781} onClick={this.onClick_state1_elFriday_icon_save_hours792710781}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy8163227735">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy8163227735}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy8_163227735}</div>
            </div>
          </div>
          
          <div className="state1_elSaturday_picker_open1914129443">
            <Select className="baseFont" style={style_state1_elSaturday_picker_open1914129443}  onChange={this.pickerValueChanged_state1_elSaturday_picker_open1914129443} value={selection_saturday_picker_open} >
              {dataSource_saturday_picker_open.items.every(item => {
                return item[valueColumnName_saturday_picker_open] !== selection_saturday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_saturday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_saturday_picker_open];
                const labelColValue = item[labelColumnName_saturday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy51985618532">
            <div className="baseFont" style={style_state1_elText_toCopy51985618532}>
              <div>{this.props.locStrings.locationdetails_text_tocopy5_1985618532}</div>
            </div>
          </div>
          
          <div className="state1_elSaturday_picker_closed1288282373">
            <Select className="baseFont" style={style_state1_elSaturday_picker_closed1288282373}  onChange={this.pickerValueChanged_state1_elSaturday_picker_closed1288282373} value={selection_saturday_picker_closed} >
              {dataSource_saturday_picker_closed.items.every(item => {
                return item[valueColumnName_saturday_picker_closed] !== selection_saturday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_saturday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_saturday_picker_closed];
                const labelColValue = item[labelColumnName_saturday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elSaturday_icon_save_hours1066919085">
            <button className="actionFont" style={style_state1_elSaturday_icon_save_hours1066919085} onClick={this.onClick_state1_elSaturday_icon_save_hours1066919085}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy91432910011">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy91432910011}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy9_1432910011}</div>
            </div>
          </div>
          
          <div className="state1_elSunday_picker_open1262554967">
            <Select className="baseFont" style={style_state1_elSunday_picker_open1262554967}  onChange={this.pickerValueChanged_state1_elSunday_picker_open1262554967} value={selection_sunday_picker_open} >
              {dataSource_sunday_picker_open.items.every(item => {
                return item[valueColumnName_sunday_picker_open] !== selection_sunday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_open];
                const labelColValue = item[labelColumnName_sunday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy62066558617">
            <div className="baseFont" style={style_state1_elText_toCopy62066558617}>
              <div>{this.props.locStrings.locationdetails_text_tocopy6_2066558617}</div>
            </div>
          </div>
          
          <div className="state1_elSunday_picker_closed1421457572">
            <Select className="baseFont" style={style_state1_elSunday_picker_closed1421457572}  onChange={this.pickerValueChanged_state1_elSunday_picker_closed1421457572} value={selection_sunday_picker_closed} >
              {dataSource_sunday_picker_closed.items.every(item => {
                return item[valueColumnName_sunday_picker_closed] !== selection_sunday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_closed];
                const labelColValue = item[labelColumnName_sunday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elSunday_icon_save_hours1247610652">
            <button className="actionFont" style={style_state1_elSunday_icon_save_hours1247610652} onClick={this.onClick_state1_elSunday_icon_save_hours1247610652}  />
          </div>
          
          <div className="state1_elText_holidays2039980172">
            <div className="font-avenirBook" style={style_state1_elText_holidays2039980172}>
              <div>{this.props.locStrings.locationdetails_text_brand_namecopy10_2039980172}</div>
            </div>
          </div>
          
          <div className="state1_elPicker_holiday311812817">
            <DatePicker className="baseFont"  onChange={this.pickerValueChanged_state1_elPicker_holiday311812817} selected={Date.parse(this.getValue_state1_elPicker_holiday311812817())}  />
          </div>
          
          <div className="state1_elSunday_icon_save_hoursCopy1264729165">
            <button className="actionFont" style={style_state1_elSunday_icon_save_hoursCopy1264729165} onClick={this.onClick_state1_elSunday_icon_save_hoursCopy1264729165}  />
          </div>
          
          <div className="hasNestedComps state1_elList21673429372">
            <ul style={style_state1_elList21673429372}>
              {items_list2.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list2[row._componentId]
                    : <HolidayListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} ref={(el) => {if (el) this._state1_elList21673429372_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state1_elList21673429372_endMarker = el} />
            </ul>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state1_elRectangle1554018587" style={style_state1_elRectangle1554018587} />
            <div className="hasNestedComps state1_elBottomNavBar713744221" style={style_state1_elBottomNavBar713744221}>
              <BottomNavBar ref={(el)=> this._state1_elBottomNavBar713744221 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state1_elMenuItemsList74605394" style={style_state1_elMenuItemsList74605394_outer}>
              <ul>
                {items_menuItemsList.map((row, index) => {
                  let itemComp = (row._componentId)
                      ? listComps_menuItemsList[row._componentId]
                      : <BrandListForDropdown appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'imageURL': row['imageURL'], 'name': row['name'], 'price': row['price'], }} ref={(el) => {if (el) this._state1_elMenuItemsList74605394_items.push(el)}} />;
                  return (<li key={row.key}>
                      {itemComp}
                    </li>);
                })}
                <div className="marker" ref={(el)=> this._state1_elMenuItemsList74605394_endMarker = el} />
              </ul>
            </div>
            
            <div className="state1_elBrandLogoImage1230304929" style={style_state1_elBrandLogoImage1230304929} />
            <img className="state1_elImage1093429335" style={style_state1_elImage1093429335} src={img_state1_elImage1093429335} alt=""  />
            <div className="hasNestedComps state1_elIPhoneMockupComponent2128991200">
              <IPhoneMockupComponent {...dataSheet_fb_currentBrand.items[0]} visualStateIndex={1} ref={(el)=> this._state1_elIPhoneMockupComponent2128991200 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state1_elFooter1887594677">
              <Footer ref={(el)=> this._state1_elFooter1887594677 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state1_elHeader397462128">
              <Header ref={(el)=> this._state1_elHeader397462128 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state1_elComponent1638506712 }
            { state1_elHelpOverlayScreen567710614 }
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (0) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
