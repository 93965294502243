import React, { Component } from 'react';
import './App.css';

export default class MainScreenIntructionText extends Component {

  // Properties used by this component:
  // number, title, text

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    
    const style_elRectangle2 = {
      background: 'rgba(203, 203, 203, 1.000)',
      borderRadius: '15.0px',
      boxShadow: '0.0px 2.0px 3px rgba(0, 0, 0, 0.3000)',
     };
    
    const style_elRectangle = {
      background: 'rgba(238, 119, 0, 1.000)',
      borderRadius: '25.0px',
     };
    
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.Number);
    
    const style_elText = {
      fontSize: 31.0,
      color: '#fffef8',
      textAlign: 'center',
     };
    
    const value_text2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.Title);
    
    const style_elText2 = {
      fontSize: 25.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_text3 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.Text);
    
    const style_elText3 = {
      fontSize: 19.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    return (
      <div className="MainScreenIntructionText">
        <div className="background">
          <div className="elRectangle2" style={style_elRectangle2} />
        </div>
        
        <div className="layoutFlow">
          <div className="elRectangle">
            <div style={style_elRectangle} />
          </div>
          
          <div className="elText">
            <div className="font-avenirBook" style={style_elText}>
              <div>{value_text}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="font-avenirHeavy" style={style_elText2}>
              <div>{value_text2}</div>
            </div>
          </div>
          
          <div className="elText3">
            <div className="systemFontRegular" style={style_elText3}>
              <div>{value_text3}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
