import React, { Component } from 'react';
import './App.css';
import DetailsComponent from './DetailsComponent';
import btn_icon_951795 from './images/btn_icon_951795.png';

export default class UserDetailDrawer extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elIconButton = async () => {
    this.props.closeDrawer();
  
  }
  
  
  render() {
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_951795+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="UserDetailDrawer">
        <div className="layoutFlow">
          <div className="hasNestedComps elDetailsComponent">
            <div>
              <DetailsComponent visualStateIndex={1} ref={(el)=> this._elDetailsComponent = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
        </div>
      </div>
    )
  }
  
}
