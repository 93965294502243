import React, { Component } from 'react';
import './App.css';
import SetupVsSimulator from './SetupVsSimulator';
import IPhoneSim2 from './IPhoneSim2';
import DeviceInstructions2 from './DeviceInstructions2';
import Button1 from './Button1';
import btn_icon_863115 from './images/btn_icon_863115.png';

export default class RightDrawer extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      elSetupVsSimulator_stateIndex: 0,
      selectedIndex_selector: 0,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  selectorSelectionChanged = (idx, ev) => {
    this.setState({selectedIndex_selector: idx});
  }
  
  onClick_elIconButton = async () => {
    this.props.closeDrawer();
  
  }
  
  
  render() {
    const selectedIndex_selector = this.state.selectedIndex_selector;
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#626262',
     };
    
    let contentElement_elSetupVsSimulator;
    switch (selectedIndex_selector) {
      default:
      case 0:
        contentElement_elSetupVsSimulator = (<IPhoneSim2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
      break;
      case 1:
        contentElement_elSetupVsSimulator = (<DeviceInstructions2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
      break;
    }
    
    const style_elSelector = {
      overflow: 'hidden',  // This element is not in scroll flow
     };
    // eslint-disable-next-line no-unused-vars
    const style_elSelector_part = {
      display: 'inline-block',
      position: 'relative',
      width: '76px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_863115+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '62.963%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="RightDrawer">
        <div className="background">
          <div className="elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
          
          <div className="hasNestedComps elSetupVsSimulator">
            {contentElement_elSetupVsSimulator}
          </div>
          <div className="hasNestedComps elSelector" style={style_elSelector}>
            <div style={style_elSelector_part}  onClick={this.selectorSelectionChanged.bind(this, 0)}><Button1 visualStateIndex={this.state.selectedIndex_selector === 0 ? 1 : 0} title='Simulator' unselectedStateIndex='0' selectedStateIndex='0' appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} /></div>
            <div style={style_elSelector_part}  onClick={this.selectorSelectionChanged.bind(this, 1)}><Button1 visualStateIndex={this.state.selectedIndex_selector === 1 ? 1 : 0} title='Device' unselectedStateIndex='0' selectedStateIndex='0' appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} /></div>
          </div>
          <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
        </div>
        
      </div>
    )
  }
  
}
