import React, { Component } from 'react';
import './App.css';
import btn_icon_663710 from './images/btn_icon_663710.png';
import btn_icon_744747 from './images/btn_icon_744747.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class MenuListItemAdd extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elIconButtonCopy2 = async () => {
    await this.sendData_elIconButtonCopy2_to_fb_menus();
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_my_menus'], this.props.appActions.serviceOptions_fb_my_menus, false);
  
    // Perform action 'Clear text' on element 'field copy'
    this.setState({
      fieldCopy: "",
    });
  
  }
  
  
  onClick_elIconButtonCopy = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  textInputChanged_elFieldCopy = (event) => {
    this.setState({fieldCopy: event.target.value});
  }
  
  getValue_elFieldCopy = () => {
    return this.state.fieldCopy || '';
  }
  
  onClick_elButtonAddMenu = async () => {
    await this.sendData_elButtonAddMenu_to_fb_menus();
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_my_menus'], this.props.appActions.serviceOptions_fb_my_menus, false);
  
    // Perform action 'Clear text' on element 'field copy'
    this.setState({
      fieldCopy: "",
    });
  
  }
  
  
  sendData_elButtonAddMenu_to_fb_menus = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menus');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
      associatedBrand: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''),
      name: this.getValue_elFieldCopy(),
      isActive: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''),
    };
  
    const transformData = (input) => {
        var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      input.description = ''; 
      
      return input;  }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menus', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menus', row);
    }
  }
  
  
  sendData_elIconButtonCopy2_to_fb_menus = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menus');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
      isActive: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''),
      name: this.getValue_elFieldCopy(),
      description: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      associatedBrand: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menus', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menus', row);
    }
  }
  
  
  render() {
    const style_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_elCard_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_elIconButtonCopy2 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_663710+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_744747+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elFieldCopy = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elButtonAddMenu = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuListItemAdd">
        <div className="background">
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" style={style_elCard} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont elIconButtonCopy2" style={style_elIconButtonCopy2} onClick={this.onClick_elIconButtonCopy2}  />
          <button className="actionFont elIconButtonCopy" style={style_elIconButtonCopy} onClick={this.onClick_elIconButtonCopy}  />
          <input className="baseFont elFieldCopy" style={style_elFieldCopy} type="text" placeholder="{enter new menu name here}" onChange={this.textInputChanged_elFieldCopy} value={this.getValue_elFieldCopy()}  />
          <Button className="actionFont elButtonAddMenu" style={style_elButtonAddMenu}  color="accent" onClick={this.onClick_elButtonAddMenu} >
            {this.props.locStrings.menulistitemadd_button_1836019596}
          </Button>
        </div>
      </div>
    )
  }
  
}
