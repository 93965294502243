import React, { Component } from 'react';
import './App.css';
import btn_icon_328827 from './images/btn_icon_328827.png';
import img_state0_elImage2 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import btn_icon_1016476 from './images/btn_icon_1016476.png';
import Comp5 from './Comp5';
import MenuItemListItemAdd from './MenuItemListItemAdd';
import MenuItemListItem from './MenuItemListItem';
import BottomNavBar from './BottomNavBar';
import BrandListForDropdown from './BrandListForDropdown';
import img_state0_elBrandLogoImage771872 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state0_elImage63845 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';
import IPhoneMockupComponent from './IPhoneMockupComponent';
import Footer from './Footer';
import Header from './Header';
import Component4 from './Component4';
import HelpOverlayScreen from './HelpOverlayScreen';
import img_state1_elImage2 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elBrandLogoImage771872 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elImage63845 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';

export default class MenuDetailsScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, dataSheetRow, brandImageURL, bottomNavIconActiveColor, bottomNavIconColor, iconHomeURL, iconMenuURL, iconProfileURL, iconMoreURL

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elTextCopy6282605: (<div>Menu Details</div>),
      state0_elTextCopy6282605_plainText: "Menu Details",
      state0_elText_brand_name491709: (<div>Menu Name</div>),
      state0_elText_brand_name491709_plainText: "Menu Name",
      state0_elTextCopy680350: (<div>Description</div>),
      state0_elTextCopy680350_plainText: "Description",
      state0_elTextCopy3215405: (<div>Menu Items on this Menu</div>),
      state0_elTextCopy3215405_plainText: "Menu Items on this Menu",
      state1_elTextCopy6282605: (<div>Menu Details</div>),
      state1_elTextCopy6282605_plainText: "Menu Details",
      state1_elText_brand_name491709: (<div>Menu Name</div>),
      state1_elText_brand_name491709_plainText: "Menu Name",
      state1_elTextCopy680350: (<div>Description</div>),
      state1_elTextCopy680350_plainText: "Description",
      state1_elTextCopy3215405: (<div>Menu Items on this Menu</div>),
      state1_elTextCopy3215405_plainText: "Menu Items on this Menu",
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menuItems'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menuItems;
      if ( !this.props.appActions.dataSheetLoaded['fb_menuItems']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menuItems'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_my_menu_items'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_my_menu_items;
      if ( !this.props.appActions.dataSheetLoaded['fb_my_menu_items']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_my_menu_items'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menus'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menus;
      if ( !this.props.appActions.dataSheetLoaded['fb_menus']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/menus/", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menus'] = true;
      }
    }
    let newVal = "https://discuss.yesoms.com/t/walkthrough-the-menu-detail-screen/";
    this.props.appActions.updateDataSlot('ds_forumHelpURL', newVal);
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elIconButton2328827 = async () => {
    let newVal = "yyyy";
    this.props.appActions.updateDataSlot('ds_selected_menu_key', newVal);
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  textInputChanged_state0_elFieldTitle418159 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state0_elFieldTitle418159 = () => {
    return this.state.fieldTitle !== undefined ? this.state.fieldTitle : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  onClick_state0_elIconButton1016476 = async () => {
  
  
  }
  
  
  textInputChanged_state0_elField_brand_name287463 = (event) => {
    this.setState({field_brand_name: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_activeMenuName", event.target.value);
  }
  
  getValue_state0_elField_brand_name287463 = () => {
    return this.state.field_brand_name !== undefined ? this.state.field_brand_name : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  textAreaChanged_state0_elFieldDescription72230 = (event) => {
    this.setState({fieldDescription: event.target.value});
  }
  
  getValue_state0_elFieldDescription72230 = () => {
    return this.state.fieldDescription !== undefined ? this.state.fieldDescription : (this.props.dataSheetRow ? this.props.dataSheetRow.description : '');
  }
  
  
  getValue_state0_elField_isActiveCheckbox562502 = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : (this.props.dataSheetRow ? this.props.dataSheetRow.isActive : '');
  }
  
  checkboxChanged_state0_elField_isActiveCheckbox562502 = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')});
  }
  
  onClick_state0_elComp2000969552 = async () => {
    await this.sendData_state0_elComp2000969552_to_fb_menus();
  
  }
  
  
  
  
  sendData_state0_elComp2000969552_to_fb_menus = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menus');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
    };
    row = { ...row, 
      name: this.getValue_state0_elField_brand_name287463(),
      description: this.getValue_state0_elFieldDescription72230(),
    };
  
    const transformData = (input) => {
        
      // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      
      if(this.state.field_isActiveCheckbox === "true" || 
         this.state.field_isActiveCheckbox === true ||
         this.state.field_isActiveCheckbox === "1")
      	{
            input.isActive=true
          };
      
      if(this.state.field_isActiveCheckbox === "false" || 
         this.state.field_isActiveCheckbox === false ||
         this.state.field_isActiveCheckbox === "0")
      	{
            input.isActive=false
          };
      
      
      return input;
      
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menus', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menus', row);
    }
  }
  
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_fb_currentBrand = this.props.dataSheets['fb_currentBrand'];
    // eslint-disable-next-line
    const dataSheet_fb_my_menu_items = this.props.dataSheets['fb_my_menu_items'];
    const style_state0_elBackground512746 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground512746_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    
    const style_state0_elRectangle2660403 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_state0_elIconButton2328827 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_328827+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elImage2 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_textCopy2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
    
    const style_state0_elTextCopy2284119382 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const placeholderValue_fieldTitle = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_state0_elFieldTitle418159 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_state0_elFieldTitle418159_outer = {
      display: 'none',
     };
    
    const style_state0_elIconButton1016476 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1016476+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elIconButton1016476_outer = {
      display: 'none',
     };
    const style_state0_elTextCopy6282605 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state0_elText_brand_name491709 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elField_brand_name287463 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy680350 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elFieldDescription72230 = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_state0_elField_isActiveCheckbox562502();
    
    const style_state0_elField_isActiveCheckbox562502 = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elComp2000969552 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy3215405 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'menuItemList'.
    let items_menuItemList = [];
    let listComps_menuItemList = {};
    listComps_menuItemList['_tail'] = <MenuItemListItemAdd appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    items_menuItemList = items_menuItemList.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    items_menuItemList.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._state0_elMenuItemList99233_items = [];
    
    const style_state0_elMenuItemList99233 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const fillColorValue_rectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_state0_elRectangle162512 = {
      background: (fillColorValue_rectangle && fillColorValue_rectangle.length > 0) ? fillColorValue_rectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const style_state0_elBottomNavBar787259 = {
      display: 'none',
     };
    
    // Source items and any special components used for list/grid element 'menu items list'.
    let items_menuItemsList = [];
    let listComps_menuItemsList = {};
    items_menuItemsList = items_menuItemsList.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    this._state0_elMenuItemsList11131_items = [];
    
    const style_state0_elMenuItemsList11131_outer = {
      display: 'none',
     };
    const fillColorValue_card2 = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    const style_state0_elCard2636010 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard2636010_outer = {
      display: 'none',
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state0_elBrandLogoImage771872 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.brandImageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      display: 'none',
     };
    const style_state0_elImage63845 = {
      height: 'auto',
      display: 'none',
     };
    const state0_elComponent17075 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state0_elComponent17075">
        <Component4 ref={(el)=> this._state0_elComponent17075 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const state0_elHelpOverlayScreen434393854 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_showHelpOverlay'] : '')) ? (
      <div className="hasNestedComps state0_elHelpOverlayScreen434393854">
        <HelpOverlayScreen visualStateIndex={2} ref={(el)=> this._state0_elHelpOverlayScreen434393854 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen MenuDetailsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBackground512746" style={style_state0_elBackground512746_outer}>
            <div className="appBg" style={style_state0_elBackground512746} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state0_elRectangle2660403">
            <div style={style_state0_elRectangle2660403} />
          </div>
          
          <div className="state0_elIconButton2328827">
            <button className="actionFont" style={style_state0_elIconButton2328827} onClick={this.onClick_state0_elIconButton2328827}  />
          </div>
          
          <div className="state0_elImage2">
            <div style={style_state0_elImage2} />
          </div>
          
          <div className="state0_elSpacer185626">
            <div />
          </div>
          
          <div className="state0_elTextCopy2284119382">
            <div className="font-futuraMedium" style={style_state0_elTextCopy2284119382}>
              <div>{value_textCopy2}</div>
            </div>
          </div>
          
          <div className="state0_elFieldTitle418159" style={style_state0_elFieldTitle418159_outer}>
            <input className="font-futuraMedium" style={style_state0_elFieldTitle418159} type="text" placeholder={placeholderValue_fieldTitle} onChange={this.textInputChanged_state0_elFieldTitle418159} value={this.getValue_state0_elFieldTitle418159()}  />
          </div>
          
          <div className="state0_elIconButton1016476" style={style_state0_elIconButton1016476_outer}>
            <button className="actionFont" style={style_state0_elIconButton1016476} onClick={this.onClick_state0_elIconButton1016476}  />
          </div>
          
          <div className="state0_elSpacer2578293">
            <div />
          </div>
          
          <div className="state0_elTextCopy6282605">
            <div className="font-futuraMedium" style={style_state0_elTextCopy6282605}>
              <div>{this.state.state0_elTextCopy6282605}</div>
            </div>
          </div>
          
          <div className="state0_elText_brand_name491709">
            <div className="font-avenirBook" style={style_state0_elText_brand_name491709}>
              <div>{this.state.state0_elText_brand_name491709}</div>
            </div>
          </div>
          
          <div className="state0_elField_brand_name287463">
            <input className="font-avenirNextRegular" style={style_state0_elField_brand_name287463} type="text" placeholder={this.props.locStrings.menudetails_field_brand_name_287463} onChange={this.textInputChanged_state0_elField_brand_name287463} value={this.getValue_state0_elField_brand_name287463()}  />
          </div>
          
          <div className="state0_elTextCopy680350">
            <div className="font-avenirBook" style={style_state0_elTextCopy680350}>
              <div>{this.state.state0_elTextCopy680350}</div>
            </div>
          </div>
          
          <div className="state0_elFieldDescription72230">
            <textarea className="font-avenirNextRegular" style={style_state0_elFieldDescription72230}  placeholder="{optional - this will show up on the menu screen below the menu title}" onChange={this.textAreaChanged_state0_elFieldDescription72230} value={this.getValue_state0_elFieldDescription72230()}  />
          </div>
          
          <div className="state0_elField_isActiveCheckbox562502">
            <Checkbox className="font-openSans" style={style_state0_elField_isActiveCheckbox562502}  label="Menu Is Active" checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_state0_elField_isActiveCheckbox562502} />
          </div>
          
          <div className="hasNestedComps state0_elComp2000969552">
            <div style={style_state0_elComp2000969552} onClick={this.onClick_state0_elComp2000969552} >
              <Comp5 ref={(el)=> this._state0_elComp2000969552 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state0_elTextCopy3215405">
            <div className="font-futuraMedium" style={style_state0_elTextCopy3215405}>
              <div>{this.state.state0_elTextCopy3215405}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elMenuItemList99233">
            <ul style={style_state0_elMenuItemList99233}>
              {items_menuItemList.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_menuItemList[row._componentId]
                    : <MenuItemListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'name': row['name'], 'price': row['price'], 'order': row['order'], 'document_key': row['document_key'], 'imageURL': row['imageURL'], }} ref={(el) => {if (el) this._state0_elMenuItemList99233_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state0_elMenuItemList99233_endMarker = el} />
            </ul>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state0_elRectangle162512" style={style_state0_elRectangle162512} />
            <div className="hasNestedComps state0_elBottomNavBar787259" style={style_state0_elBottomNavBar787259}>
              <BottomNavBar ref={(el)=> this._state0_elBottomNavBar787259 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elMenuItemsList11131" style={style_state0_elMenuItemsList11131_outer}>
              <ul>
                {items_menuItemsList.map((row, index) => {
                  let itemComp = (row._componentId)
                      ? listComps_menuItemsList[row._componentId]
                      : <BrandListForDropdown appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'imageURL': row['imageURL'], 'name': row['name'], 'price': row['price'], }} ref={(el) => {if (el) this._state0_elMenuItemsList11131_items.push(el)}} />;
                  return (<li key={row.key}>
                      {itemComp}
                    </li>);
                })}
                <div className="marker" ref={(el)=> this._state0_elMenuItemsList11131_endMarker = el} />
              </ul>
            </div>
            
            <div className="state0_elCard2636010" style={style_state0_elCard2636010_outer}>
              <div style={style_state0_elCard2636010} />
            </div>
            
            <div className="state0_elBrandLogoImage771872" style={style_state0_elBrandLogoImage771872} />
            <img className="state0_elImage63845" style={style_state0_elImage63845} src={img_state0_elImage63845} alt=""  />
            <div className="hasNestedComps state0_elIPhoneMockupComponent266108727">
              <IPhoneMockupComponent {...dataSheet_fb_currentBrand.items[0]} visualStateIndex={1} ref={(el)=> this._state0_elIPhoneMockupComponent266108727 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elFooter976508">
              <Footer ref={(el)=> this._state0_elFooter976508 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elHeader138243">
              <Header ref={(el)=> this._state0_elHeader138243 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state0_elComponent17075 }
            { state0_elHelpOverlayScreen434393854 }
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elIconButton2328827 = async () => {
    let newVal = "yyyy";
    this.props.appActions.updateDataSlot('ds_selected_menu_key', newVal);
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  textInputChanged_state1_elFieldTitle418159 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state1_elFieldTitle418159 = () => {
    return this.state.fieldTitle !== undefined ? this.state.fieldTitle : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  onClick_state1_elIconButton1016476 = async () => {
  
  
  }
  
  
  textInputChanged_state1_elField_brand_name287463 = (event) => {
    this.setState({field_brand_name: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_activeMenuName", event.target.value);
  }
  
  getValue_state1_elField_brand_name287463 = () => {
    return this.state.field_brand_name !== undefined ? this.state.field_brand_name : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  textAreaChanged_state1_elFieldDescription72230 = (event) => {
    this.setState({fieldDescription: event.target.value});
  }
  
  getValue_state1_elFieldDescription72230 = () => {
    return this.state.fieldDescription !== undefined ? this.state.fieldDescription : (this.props.dataSheetRow ? this.props.dataSheetRow.description : '');
  }
  
  
  getValue_state1_elField_isActiveCheckbox562502 = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : (this.props.dataSheetRow ? this.props.dataSheetRow.isActive : '');
  }
  
  checkboxChanged_state1_elField_isActiveCheckbox562502 = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')});
  }
  
  onClick_state1_elComp2000969552 = async () => {
    await this.sendData_state1_elComp2000969552_to_fb_menus();
  
  }
  
  
  
  
  sendData_state1_elComp2000969552_to_fb_menus = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menus');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
    };
    row = { ...row, 
      name: this.getValue_state1_elField_brand_name287463(),
      description: this.getValue_state1_elFieldDescription72230(),
    };
  
    const transformData = (input) => {
        
      // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      
      if(this.state.field_isActiveCheckbox === "true" || 
         this.state.field_isActiveCheckbox === true ||
         this.state.field_isActiveCheckbox === "1")
      	{
            input.isActive=true
          };
      
      if(this.state.field_isActiveCheckbox === "false" || 
         this.state.field_isActiveCheckbox === false ||
         this.state.field_isActiveCheckbox === "0")
      	{
            input.isActive=false
          };
      
      
      return input;
      
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menus', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menus', row);
    }
  }
  
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_fb_currentBrand = this.props.dataSheets['fb_currentBrand'];
    // eslint-disable-next-line
    const dataSheet_fb_my_menu_items = this.props.dataSheets['fb_my_menu_items'];
    const style_state1_elBackground512746 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground512746_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    
    const style_state1_elRectangle2660403 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_state1_elIconButton2328827 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_328827+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elImage2 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_textCopy2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
    
    const style_state1_elTextCopy2284119382 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const placeholderValue_fieldTitle = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_state1_elFieldTitle418159 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_state1_elFieldTitle418159_outer = {
      display: 'none',
     };
    
    const style_state1_elIconButton1016476 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1016476+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elIconButton1016476_outer = {
      display: 'none',
     };
    const style_state1_elTextCopy6282605 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state1_elText_brand_name491709 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elField_brand_name287463 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state1_elTextCopy680350 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elFieldDescription72230 = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_state1_elField_isActiveCheckbox562502();
    
    const style_state1_elField_isActiveCheckbox562502 = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elComp2000969552 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elTextCopy3215405 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'menuItemList'.
    let items_menuItemList = [];
    let listComps_menuItemList = {};
    listComps_menuItemList['_tail'] = <MenuItemListItemAdd appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    items_menuItemList = items_menuItemList.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    items_menuItemList.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._state1_elMenuItemList99233_items = [];
    
    const style_state1_elMenuItemList99233 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const fillColorValue_rectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_state1_elRectangle162512 = {
      background: (fillColorValue_rectangle && fillColorValue_rectangle.length > 0) ? fillColorValue_rectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const style_state1_elBottomNavBar787259 = {
      display: 'none',
     };
    
    // Source items and any special components used for list/grid element 'menu items list'.
    let items_menuItemsList = [];
    let listComps_menuItemsList = {};
    items_menuItemsList = items_menuItemsList.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    this._state1_elMenuItemsList11131_items = [];
    
    const style_state1_elMenuItemsList11131_outer = {
      display: 'none',
     };
    const fillColorValue_card2 = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    const style_state1_elCard2636010 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard2636010_outer = {
      display: 'none',
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state1_elBrandLogoImage771872 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.brandImageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      display: 'none',
     };
    const style_state1_elImage63845 = {
      height: 'auto',
      display: 'none',
     };
    const state1_elComponent17075 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state1_elComponent17075">
        <Component4 ref={(el)=> this._state1_elComponent17075 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const state1_elHelpOverlayScreen434393854 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_showHelpOverlay'] : '')) ? (
      <div className="hasNestedComps state1_elHelpOverlayScreen434393854">
        <HelpOverlayScreen visualStateIndex={2} ref={(el)=> this._state1_elHelpOverlayScreen434393854 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen MenuDetailsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state1_elBackground512746" style={style_state1_elBackground512746_outer}>
            <div className="appBg" style={style_state1_elBackground512746} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state1_elRectangle2660403">
            <div style={style_state1_elRectangle2660403} />
          </div>
          
          <div className="state1_elIconButton2328827">
            <button className="actionFont" style={style_state1_elIconButton2328827} onClick={this.onClick_state1_elIconButton2328827}  />
          </div>
          
          <div className="state1_elImage2">
            <div style={style_state1_elImage2} />
          </div>
          
          <div className="state1_elSpacer185626">
            <div />
          </div>
          
          <div className="state1_elTextCopy2284119382">
            <div className="font-futuraMedium" style={style_state1_elTextCopy2284119382}>
              <div>{value_textCopy2}</div>
            </div>
          </div>
          
          <div className="state1_elFieldTitle418159" style={style_state1_elFieldTitle418159_outer}>
            <input className="font-futuraMedium" style={style_state1_elFieldTitle418159} type="text" placeholder={placeholderValue_fieldTitle} onChange={this.textInputChanged_state1_elFieldTitle418159} value={this.getValue_state1_elFieldTitle418159()}  />
          </div>
          
          <div className="state1_elIconButton1016476" style={style_state1_elIconButton1016476_outer}>
            <button className="actionFont" style={style_state1_elIconButton1016476} onClick={this.onClick_state1_elIconButton1016476}  />
          </div>
          
          <div className="state1_elSpacer2578293">
            <div />
          </div>
          
          <div className="state1_elTextCopy6282605">
            <div className="font-futuraMedium" style={style_state1_elTextCopy6282605}>
              <div>{this.state.state1_elTextCopy6282605}</div>
            </div>
          </div>
          
          <div className="state1_elText_brand_name491709">
            <div className="font-avenirBook" style={style_state1_elText_brand_name491709}>
              <div>{this.state.state1_elText_brand_name491709}</div>
            </div>
          </div>
          
          <div className="state1_elField_brand_name287463">
            <input className="font-avenirNextRegular" style={style_state1_elField_brand_name287463} type="text" placeholder={this.props.locStrings.menudetails_field_brand_name_287463} onChange={this.textInputChanged_state1_elField_brand_name287463} value={this.getValue_state1_elField_brand_name287463()}  />
          </div>
          
          <div className="state1_elTextCopy680350">
            <div className="font-avenirBook" style={style_state1_elTextCopy680350}>
              <div>{this.state.state1_elTextCopy680350}</div>
            </div>
          </div>
          
          <div className="state1_elFieldDescription72230">
            <textarea className="font-avenirNextRegular" style={style_state1_elFieldDescription72230}  placeholder="{optional - this will show up on the menu screen below the menu title}" onChange={this.textAreaChanged_state1_elFieldDescription72230} value={this.getValue_state1_elFieldDescription72230()}  />
          </div>
          
          <div className="state1_elField_isActiveCheckbox562502">
            <Checkbox className="font-openSans" style={style_state1_elField_isActiveCheckbox562502}  label="Menu Is Active" checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_state1_elField_isActiveCheckbox562502} />
          </div>
          
          <div className="hasNestedComps state1_elComp2000969552">
            <div style={style_state1_elComp2000969552} onClick={this.onClick_state1_elComp2000969552} >
              <Comp5 ref={(el)=> this._state1_elComp2000969552 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state1_elTextCopy3215405">
            <div className="font-futuraMedium" style={style_state1_elTextCopy3215405}>
              <div>{this.state.state1_elTextCopy3215405}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elMenuItemList99233">
            <ul style={style_state1_elMenuItemList99233}>
              {items_menuItemList.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_menuItemList[row._componentId]
                    : <MenuItemListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'name': row['name'], 'price': row['price'], 'order': row['order'], 'document_key': row['document_key'], 'imageURL': row['imageURL'], }} ref={(el) => {if (el) this._state1_elMenuItemList99233_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state1_elMenuItemList99233_endMarker = el} />
            </ul>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state1_elRectangle162512" style={style_state1_elRectangle162512} />
            <div className="hasNestedComps state1_elBottomNavBar787259" style={style_state1_elBottomNavBar787259}>
              <BottomNavBar ref={(el)=> this._state1_elBottomNavBar787259 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state1_elMenuItemsList11131" style={style_state1_elMenuItemsList11131_outer}>
              <ul>
                {items_menuItemsList.map((row, index) => {
                  let itemComp = (row._componentId)
                      ? listComps_menuItemsList[row._componentId]
                      : <BrandListForDropdown appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'imageURL': row['imageURL'], 'name': row['name'], 'price': row['price'], }} ref={(el) => {if (el) this._state1_elMenuItemsList11131_items.push(el)}} />;
                  return (<li key={row.key}>
                      {itemComp}
                    </li>);
                })}
                <div className="marker" ref={(el)=> this._state1_elMenuItemsList11131_endMarker = el} />
              </ul>
            </div>
            
            <div className="state1_elCard2636010" style={style_state1_elCard2636010_outer}>
              <div style={style_state1_elCard2636010} />
            </div>
            
            <div className="state1_elBrandLogoImage771872" style={style_state1_elBrandLogoImage771872} />
            <img className="state1_elImage63845" style={style_state1_elImage63845} src={img_state1_elImage63845} alt=""  />
            <div className="hasNestedComps state1_elIPhoneMockupComponent266108727">
              <IPhoneMockupComponent {...dataSheet_fb_currentBrand.items[0]} visualStateIndex={1} ref={(el)=> this._state1_elIPhoneMockupComponent266108727 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state1_elFooter976508">
              <Footer ref={(el)=> this._state1_elFooter976508 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state1_elHeader138243">
              <Header ref={(el)=> this._state1_elHeader138243 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state1_elComponent17075 }
            { state1_elHelpOverlayScreen434393854 }
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (0) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
