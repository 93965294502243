import React, { Component } from 'react';
import './App.css';
import img_state0_elImage362063 from './images/DetailsComponent_state1_elRoundimage_743990.png';
import btn_icon_26198 from './images/btn_icon_26198.png';
import Icon_BrandPromo from './Icon_BrandPromo';
import img_state1_elImage362063 from './images/DetailsComponent_state1_elRoundimage_743990.png';
import btn_icon_736415 from './images/btn_icon_736415.png';
import img_state2_elImage362063 from './images/DetailsComponent_state1_elRoundimage_743990.png';
import btn_icon_436055 from './images/btn_icon_436055.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class MenuItemListItem extends Component {

  // Properties used by this component:
  // name, price, order, document_key, imageURL, dataSheetRow, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  onClick_state0_elIconDelete26198 = async () => {
    // Remove row from connected sheet
    await this.props.appActions.removeFromDataSheet(this.props.dataSheetId, this.props.dataSheetRow);
  
  }
  
  
  onClick_state0_elButtonManage506765285 = async () => {
    let newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selected_menuItem_key', newVal);
  
    newVal = this.props.imageURL;
    this.props.appActions.updateDataSlot('ds_uploadedMenuItemImageLocation', newVal);
  
    newVal = null /* unable to collect data for 'field name' */;
    this.props.appActions.updateDataSlot('ds_activeMenuItemName', newVal);
  
    newVal = this.props.dataSheetRow.isBrandPromo;
    this.props.appActions.updateDataSlot('ds_brandpromoitemflag', newVal);
  
    newVal = null /* unable to collect data for 'fieldPrice' */;
    this.props.appActions.updateDataSlot('ds_activeMenuItemPrice', newVal);
  
    // Go to screen 'MenuItem Details'
    this.props.appActions.goToScreen('menuItemDetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elButtonPromote368368155 = async () => {
    await this.sendData_state0_elButtonPromote368368155_to_fb_menuItems();
  
  }
  
  
  onClick_state0_elTextPrice = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  onClick_state0_elTextName = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  sendData_state0_elButtonPromote368368155_to_fb_menuItems = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItems');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
      "order": this.props.order,
      "document_key": this.props.document_key,
      "imageURL": this.props.imageURL,
      "dataSheetRow": this.props.dataSheetRow,
    };
    row = { ...row, 
      isBrandPromo: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''),
      promoBrand: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      //input.isBrandPromo=true;
      
      return input;  }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItems', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItems', row);
    }
  }
  
  
  renderState0() {
    const style_state0_elCard119406 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard119406_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    const style_state0_elImage362063 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.imageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const style_state0_elIconDelete26198 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_26198+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elButtonManage506765285 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elButtonPromote368368155 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_textPrice = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      // collect price
      
      
      // divide by 100
      
      // format output to include two digits only.
      
      Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
          var n = this,
              decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
              decSeparator = decSeparator == undefined ? "." : decSeparator,
              thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
              sign = n < 0 ? "-" : "",
              i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
              j = (j = i.length) > 3 ? j % 3 : 0;
          return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
      };
      
      var myMoney = input / 100;
      
      return ( '$' + myMoney.formatMoney(2,',','.'))  // "$3,543.76"
      
      
      //return input;
    }
    
    const value_textPrice = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_textPrice(this.props.price));
    
    const style_state0_elTextPrice = {
      color: '#eb771d',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_textName = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.name);
    
    const style_state0_elTextName = {
      color: '#eb771d',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemListItem">
        <div className="background">
          <div className="state0_elCard119406" style={style_state0_elCard119406_outer}>
            <div className="cardBg" style={style_state0_elCard119406} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elImage362063">
            <div style={style_state0_elImage362063} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elIconDelete26198" style={style_state0_elIconDelete26198} onClick={this.onClick_state0_elIconDelete26198}  />
          <Button className="actionFont state0_elButtonManage506765285" style={style_state0_elButtonManage506765285}  color="accent" onClick={this.onClick_state0_elButtonManage506765285} >
            {this.props.locStrings.menuitemlistitem_button_506765285}
          </Button>
          <Button className="actionFont state0_elButtonPromote368368155" style={style_state0_elButtonPromote368368155}  color="accent" onClick={this.onClick_state0_elButtonPromote368368155} >
            {this.props.locStrings.menuitemlistitem_buttoncopy_368368155}
          </Button>
          <div className="baseFont state0_elTextPrice" style={style_state0_elTextPrice} onClick={this.onClick_state0_elTextPrice} >
            <div>{value_textPrice}</div>
          </div>
          <div className="baseFont state0_elTextName" style={style_state0_elTextName} onClick={this.onClick_state0_elTextName} >
            <div>{value_textName}</div>
          </div>
          <div className="hasNestedComps state0_elIcon_brandPromo1212390374">
            <Icon_BrandPromo {...this.props} visualStateIndex={this.props.dataSheetRow ? this.props.dataSheetRow.isBrandPromo : ''} ref={(el)=> this._state0_elIcon_brandPromo1212390374 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  onClick_state1_elIconDelete26198 = async () => {
    // Remove row from connected sheet
    await this.props.appActions.removeFromDataSheet(this.props.dataSheetId, this.props.dataSheetRow);
  
  }
  
  
  onClick_state1_elButtonManage506765285 = async () => {
    let newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selected_menuItem_key', newVal);
  
    newVal = this.props.imageURL;
    this.props.appActions.updateDataSlot('ds_uploadedMenuItemImageLocation', newVal);
  
    newVal = this.getValue_state1_elFieldName1043797();
    this.props.appActions.updateDataSlot('ds_activeMenuItemName', newVal);
  
    newVal = this.props.dataSheetRow.isBrandPromo;
    this.props.appActions.updateDataSlot('ds_brandpromoitemflag', newVal);
  
    newVal = this.getValue_state1_elFieldPrice125409();
    this.props.appActions.updateDataSlot('ds_activeMenuItemPrice', newVal);
  
    // Go to screen 'MenuItem Details'
    this.props.appActions.goToScreen('menuItemDetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state1_elIconSave = async () => {
    await this.sendData_state1_elIconSave_to_fb_menuItems();
  
    this.setState({visualStateIndexOverride: 0})
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_my_menu_items'], this.props.appActions.serviceOptions_fb_my_menu_items, false);
  
  }
  
  
  onClick_state1_elButtonPromote368368155 = async () => {
    await this.sendData_state1_elButtonPromote368368155_to_fb_menuItems();
  
  }
  
  
  textInputChanged_state1_elFieldPrice125409 = (event) => {
    this.setState({fieldPrice: event.target.value});
  }
  
  getValue_state1_elFieldPrice125409 = () => {
    let transformPropValue_fieldPrice = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      
      Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
          var n = this,
              decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
              decSeparator = decSeparator == undefined ? "." : decSeparator,
              thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
              sign = n < 0 ? "-" : "",
              i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
              j = (j = i.length) > 3 ? j % 3 : 0;
          return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
      };
      
      var myMoney = this.props.price / 100;
      
      return ( '$' + myMoney.formatMoney(2,',','.'))  // "$3,543.76"
      
      
      //return this.state.textPrice;
      //return this.props.price;
      
      //return input;
    }
    
    return this.state.fieldPrice !== undefined ? this.state.fieldPrice : (transformPropValue_fieldPrice(this.props.price));
  }
  
  textInputChanged_state1_elFieldName1043797 = (event) => {
    this.setState({fieldName: event.target.value});
  }
  
  getValue_state1_elFieldName1043797 = () => {
    return this.state.fieldName !== undefined ? this.state.fieldName : (this.props.name);
  }
  
  textInputChanged_state1_elFieldOrder608779 = (event) => {
    this.setState({fieldOrder: event.target.value});
  }
  
  getValue_state1_elFieldOrder608779 = () => {
    return this.state.fieldOrder !== undefined ? this.state.fieldOrder : (this.props.order);
  }
  
  sendData_state1_elIconSave_to_fb_menuItems = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItems');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
      "order": this.props.order,
      "document_key": this.props.document_key,
      "imageURL": this.props.imageURL,
      "dataSheetRow": this.props.dataSheetRow,
    };
    row = { ...row, 
      name: this.getValue_state1_elFieldName1043797(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      
      var price = Math.round(100*Number((this.state.fieldPrice).replace("$", "")));
      
      input.price = price;
      
      var order = Number((this.state.fieldOrder));
      
      input.order = order;
      
      //this.setState({fieldPrice: this.state.textPrice});
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItems', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItems', row);
    }
  }
  
  
  sendData_state1_elButtonPromote368368155_to_fb_menuItems = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItems');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "price": this.props.price,
      "order": this.props.order,
      "document_key": this.props.document_key,
      "imageURL": this.props.imageURL,
      "dataSheetRow": this.props.dataSheetRow,
    };
    row = { ...row, 
      isBrandPromo: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''),
      promoBrand: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      //input.isBrandPromo=true;
      
      return input;  }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItems', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItems', row);
    }
  }
  
  
  renderState1() {
    const style_state1_elCard119406 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard119406_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    const style_state1_elImage362063 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.imageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const style_state1_elIconDelete26198 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_26198+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elButtonManage506765285 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconSave = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_736415+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elButtonPromote368368155 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldPrice125409 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldName1043797 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldOrder608779 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemListItem">
        <div className="background">
          <div className="state1_elCard119406" style={style_state1_elCard119406_outer}>
            <div className="cardBg" style={style_state1_elCard119406} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elImage362063">
            <div style={style_state1_elImage362063} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state1_elIconDelete26198" style={style_state1_elIconDelete26198} onClick={this.onClick_state1_elIconDelete26198}  />
          <Button className="actionFont state1_elButtonManage506765285" style={style_state1_elButtonManage506765285}  color="accent" onClick={this.onClick_state1_elButtonManage506765285} >
            {this.props.locStrings.menuitemlistitem_button_506765285}
          </Button>
          <button className="actionFont state1_elIconSave" style={style_state1_elIconSave} onClick={this.onClick_state1_elIconSave}  />
          <Button className="actionFont state1_elButtonPromote368368155" style={style_state1_elButtonPromote368368155}  color="accent" onClick={this.onClick_state1_elButtonPromote368368155} >
            {this.props.locStrings.menuitemlistitem_buttoncopy_368368155}
          </Button>
          <input className="baseFont state1_elFieldPrice125409" style={style_state1_elFieldPrice125409} type="text" placeholder="{base price}" onChange={this.textInputChanged_state1_elFieldPrice125409} value={this.getValue_state1_elFieldPrice125409()}  />
          <input className="baseFont state1_elFieldName1043797" style={style_state1_elFieldName1043797} type="text" placeholder="{name}" onChange={this.textInputChanged_state1_elFieldName1043797} value={this.getValue_state1_elFieldName1043797()}  />
          <input className="baseFont state1_elFieldOrder608779" style={style_state1_elFieldOrder608779} type="text" placeholder="{order}" onChange={this.textInputChanged_state1_elFieldOrder608779} value={this.getValue_state1_elFieldOrder608779()}  />
          <div className="hasNestedComps state1_elIcon_brandPromo1212390374">
            <Icon_BrandPromo {...this.props} visualStateIndex={this.props.dataSheetRow ? this.props.dataSheetRow.isBrandPromo : ''} ref={(el)=> this._state1_elIcon_brandPromo1212390374 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  onClick_state2_elIconSave = async () => {
    let newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selected_menuItem_key', newVal);
  
    newVal = this.props.imageURL;
    this.props.appActions.updateDataSlot('ds_uploadedMenuItemImageLocation', newVal);
  
    // Go to screen 'MenuItem Details'
    this.props.appActions.goToScreen('menuItemDetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  textInputChanged_state2_elFieldPrice125409 = (event) => {
    this.setState({fieldPrice: event.target.value});
  }
  
  getValue_state2_elFieldPrice125409 = () => {
    let transformPropValue_fieldPrice = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      
      Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
          var n = this,
              decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
              decSeparator = decSeparator == undefined ? "." : decSeparator,
              thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
              sign = n < 0 ? "-" : "",
              i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
              j = (j = i.length) > 3 ? j % 3 : 0;
          return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
      };
      
      var myMoney = this.props.price / 100;
      
      return ( '$' + myMoney.formatMoney(2,',','.'))  // "$3,543.76"
      
      
      //return this.state.textPrice;
      //return this.props.price;
      
      //return input;
    }
    
    return this.state.fieldPrice !== undefined ? this.state.fieldPrice : (transformPropValue_fieldPrice(this.props.price));
  }
  
  textInputChanged_state2_elFieldName1043797 = (event) => {
    this.setState({fieldName: event.target.value});
  }
  
  getValue_state2_elFieldName1043797 = () => {
    return this.state.fieldName !== undefined ? this.state.fieldName : (this.props.name);
  }
  
  textInputChanged_state2_elFieldOrder608779 = (event) => {
    this.setState({fieldOrder: event.target.value});
  }
  
  getValue_state2_elFieldOrder608779 = () => {
    return this.state.fieldOrder !== undefined ? this.state.fieldOrder : (this.props.order);
  }
  
  renderState2() {
    const style_state2_elCard119406 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elCard119406_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    const style_state2_elImage362063 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.imageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const style_state2_elIconSave = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_436055+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state2_elFieldPrice125409 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state2_elFieldName1043797 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state2_elFieldOrder608779 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemListItem">
        <div className="background">
          <div className="state2_elCard119406" style={style_state2_elCard119406_outer}>
            <div className="cardBg" style={style_state2_elCard119406} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state2_elImage362063">
            <div style={style_state2_elImage362063} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state2_elIconSave" style={style_state2_elIconSave} onClick={this.onClick_state2_elIconSave}  />
          <input className="baseFont state2_elFieldPrice125409" style={style_state2_elFieldPrice125409} type="text" placeholder="{base price}" onChange={this.textInputChanged_state2_elFieldPrice125409} value={this.getValue_state2_elFieldPrice125409()}  />
          <input className="baseFont state2_elFieldName1043797" style={style_state2_elFieldName1043797} type="text" placeholder="{name}" onChange={this.textInputChanged_state2_elFieldName1043797} value={this.getValue_state2_elFieldName1043797()}  />
          <input className="baseFont state2_elFieldOrder608779" style={style_state2_elFieldOrder608779} type="text" placeholder="{order}" onChange={this.textInputChanged_state2_elFieldOrder608779} value={this.getValue_state2_elFieldOrder608779()}  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}
