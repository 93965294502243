import React, { Component } from 'react';
import './App.css';
import img_elImageCopy from './images/DetailsComponent_state1_elRoundimage_743990.png';
import img_elImage from './images/Comp4_elImage_322894704.png';

export default class Comp4 extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    let transformPropValue_imageCopy = (input) => {
      // This function modifies the value for property 'image'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      //input.image = "https://qrcode.tec-it.com/API/QRCode?data=hello";
      
      
      //var dsget = "https//" + this.props.appActions.dataSlots['ds_selected_brand_key'];
      var dsget = this.props.appActions.dataSlots['ds_selectedbrandkey'];
      var dsdata = "https://qrcode.tec-it.com/API/QRCode?errorcorrection=H&color=%23f47e25&backcolor=%23ffffff&data=http://yesoms.com/b/?brand=" + dsget;
      //var dsupdate = this.props.appActions.updateDataSlot("ds_QRCodeURL", dsdata);
      //var input = dsupdate;
      
      return dsdata;
      
      //return input;
    }
    const style_elImageCopy = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(transformPropValue_imageCopy((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : '')))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="Comp4">
        <div className="layoutFlow">
          <div className="elImageCopy">
            <div style={style_elImageCopy} />
          </div>
        </div>
        
        <div className="foreground">
          <div className="elImage" style={style_elImage} />
        </div>
      </div>
    )
  }
  
}
