import React, { Component } from 'react';
import './App.css';

export default class PrivacyPolicy extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      webcontent: 'https://docs.google.com/document/d/e/2PACX-1vSUVXrmOGOuBVIGEcrfjQ-e0VsW-C4y3cNyQySrZ9ffVbjVSYy1XMn8QtnAMmfH3zwiwq_h8zkajHqW/pub?embedded=true',
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    
    const style_elRectangle = {
      background: 'rgba(252, 254, 255, 1.000)',
      boxShadow: '0.0px 3.0px 10px rgba(0, 0, 0, 0.4500)',
     };
    const style_elWebcontent = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="PrivacyPolicy">
        <div className="background">
          <div className="elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="foreground">
          <iframe className="elWebcontent" style={style_elWebcontent} src={this.state.webcontent}  />
        </div>
      </div>
    )
  }
  
}
