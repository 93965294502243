import React, { Component } from 'react';
import './App.css';
import img_elImage6 from './images/DetailsComponent_state1_elRoundimage_743990.png';
import btn_icon_1891901926 from './images/btn_icon_1891901926.png';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';

export default class Comp_image_upload extends Component {

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elIconButtonCopy = async () => {
    this.props.appActions.updateDataSlot('ds_backgroundImagefileStorageLocation', "");
    this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', "");
  
    await this.sendData_elIconButtonCopy_to_fb_currentBrand();
  
  }
  
  
  sendData_elIconButtonCopy_to_fb_currentBrand = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_currentBrand');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
    };
    row = { ...row, 
    };
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_currentBrand', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_currentBrand', row);
    }
  }
  
  
  render() {
    const style_elTextCopy7 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    const style_elImage6 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen1_imageURL : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const style_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1891901926+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elFirebaseUpload = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Comp_image_upload">
        <div className="layoutFlow">
          <div className="elTextCopy7">
            <div className="font-avenirBook" style={style_elTextCopy7}>
              <div>{this.props.locStrings.comp_image_upload_textcopy7_358353037}</div>
            </div>
          </div>
          
          <div className="elImage6">
            <div style={style_elImage6} />
          </div>
          
          <div className="elIconButtonCopy">
            <button className="actionFont" style={style_elIconButtonCopy} onClick={this.onClick_elIconButtonCopy}  />
          </div>
          
          <div className="elFirebaseUpload">
            <div style={style_elFirebaseUpload}>
              <FileUploader
                accept="image/*"
                randomizeFilename
                storageRef={firebase.storage().ref((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_fileStorageLocation'] : ''))}
                onUploadStart={() => {
                  this.props.appActions.setState({loading: true});
                }}
                onUploadError={error => {
                  this.props.appActions.setState({loading: false});
                  console.error('** Firebase upload failed: ', error);
                }}
                onUploadSuccess={filename => {
                  this.props.appActions.setState({loading: false});
                  firebase.storage().ref((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_fileStorageLocation'] : '')).child(filename).getDownloadURL().then(url => {
                    let setDataSlotValue = (v) => { this.props.appActions.updateDataSlot("ds_uploaded_Screen0_ImageURL", v) };
                    setDataSlotValue(url);
                    let setfilenameDataSlotValue = (v) => { this.props.appActions.updateDataSlot("ds_uploadedBrandImageName", v) };
                    setfilenameDataSlotValue(filename);
                  }).catch(function(error) {
                    console.error('** Firebase upload failed: ', error);
                  });
                }}
                />
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
