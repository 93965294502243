import React, { Component } from 'react';
import './App.css';
import btn_icon_1036460 from './images/btn_icon_1036460.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class BrandButton2 extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div>Add a brand</div>),
      text_plainText: "Add a brand",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elHotspot = async () => {
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_brandCreateState', newVal);
  
    await this.sendData_elHotspot_to_fb_brands();
  
  }
  
  
  sendData_elHotspot_to_fb_brands = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_brands');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
      contactEmail: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_userEmail'] : ''),
      document_ref: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_nullvalue'] : ''),
      brandOfferingOther: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_nullvalue'] : ''),
      brandOfferingType: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_nullvalue'] : ''),
      description: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_nullvalue'] : ''),
      creator: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_userID'] : ''),
      name: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_newBrandDefaultName'] : ''),
      isActive: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
            input.updated = currentDate.getTime();
            input.created = currentDate.getTime();
      
      
      // new as of 6/5/2020
      
      input.name = 'My new Brand';
      input.description = '';
      input.primaryColor = '#777777';
      input.secondaryColor = '#777777';
      input.titleColor = '#000000';
      input.textColor = '#000000';
      input.bottomNavIconActiveColor = '#444444';
      input.bottomNavIconColor = '#777777';
      input.bottonNavColor = '#EEEEEE';
      input.orderButtonColor = '#EEEEEE';
      input.orderButtonTextColor = '#000000';
      input.orderButtonCopy = 'Start New Order';
      
      //
      input.brandBackgroundImageURL = '';
      input.logoImageURL = 'https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media';
      input.brandImageURL = 'https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media';
      
      // round logo
      // https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fb70136b6-cbc5-4f56-8b3d-5739ad722e7b.png?alt=media
      
      //
      
      input.onboarding_screen0_color = '#ffffff';
      
      input.onboarding_screen1_color = '#777777';
      input.onboarding_screen1_title = 'Welcome!';
      input.onboarding_screen1_line1 = 'This is default text';
      input.onboarding_screen1_line2 = 'which will show on the onboarding';
      input.onboarding_screen1_line3 = 'screen of your new app';
      input.onboarding_screen1_imageURL = 'https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media';
      
      input.onboarding_screen2_color = '#aaaaaa';
      input.onboarding_screen2_title = 'Screen Two';
      input.onboarding_screen2_line1 = 'Desrcribe your app here';
      input.onboarding_screen2_line2 = 'so that customers know';
      input.onboarding_screen2_line3 = 'what to expect';
      
      input.onboarding_screen3_color = '#dddddd';
      input.onboarding_screen3_title = 'Screen Three';
      input.onboarding_screen3_line1 = 'Without further ado';
      input.onboarding_screen3_line2 = 'Lets get started';
      input.onboarding_screen3_line3 = 'Cheers!';
      
      
      input.serviceChargeBasisPoints = 0;
      input.brandDefaultTip = 15;
      input.preference_printer = false;
      input.preference_sound_orderup = false;
      input.preference_delivery = true;
      input.preference_pickup = true;
      input.maintenanceInProgress = '';
      input.currentPickupETA = 30;
      input.stripeProcessor = 'https://us-central1-fir-stripe-sandbox.cloudfunctions.net/payWithStripeConnect';
      input.stripePublicKey = 'pk_test_cX8MCHMWe7tB2RzMYq2S3Wse00LF8UlcW6';
      input.stripeAcctID = 'acct_1FvYf5IfJ0cqiU5E';
      input.stripeTestAcctID = 'acct_1FvYf5IfJ0cqiU5E';
      input.development_mode = true;
      
      
      return input;
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_brands', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_brands', row);
    }
  }
  
  
  render() {
    
    const style_elRectangle = {
      background: 'rgba(255, 255, 255, 1.000)',
      borderRadius: '10.0px',
      boxShadow: '0.0px 2.0px 3px rgba(0, 0, 0, 0.3000)',
     };
    
    const style_elFab = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elHotspot = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="BrandButton2">
        <div className="background">
          <div className="elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="elFab">
            <Button className="actionFont" style={style_elFab}  variant="fab" color="accent" >
              <img src={btn_icon_1036460} alt="" style={{width: '50.000%', marginTop: '25.000%'}} />
            </Button>
          </div>
        </div>
        
        <div className="foreground">
          <div className="baseFont elText" style={style_elText}>
            <div>{this.state.text}</div>
          </div>
          <div className="actionFont elHotspot" style={style_elHotspot} onClick={this.onClick_elHotspot}  />
        </div>
      </div>
    )
  }
  
}
