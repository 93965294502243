import React, { Component } from 'react';
import './App.css';
import ReactPlayer from 'react-player';
import btn_icon_870106 from './images/btn_icon_870106.png';

export default class NewsPopup extends Component {

  // Properties used by this component:
  // newsTitle, newsText, newsVideoURL, newsForumURL, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  onClick_state0_elText3 = async () => {
    window.open(this.props.newsVideoURL, '_blank');
  
  }
  
  
  onClick_state0_elTextCopy = async () => {
    window.open(this.props.newsForumURL, '_blank');
  
  }
  
  
  onClick_state0_elIconButton = async () => {
    this.setState({visualStateIndexOverride: 1})
  
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_hideNews', newVal);
  
  }
  
  
  renderState0() {
    
    const style_state0_elRectangle = {
      background: 'rgba(252, 254, 255, 1.000)',
      borderRadius: '15.0px',
      boxShadow: '0.0px 2.0px 3px rgba(0, 0, 0, 0.3000)',
     };
    
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.newsTitle);
    
    const style_state0_elText = {
      fontSize: 22.0,
      color: '#eb771d',
      textAlign: 'center',
     };
    
    const value_text2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.newsText);
    
    const style_state0_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_state0_elReactPlayer = {
      pointerEvents: 'auto',
     };
    const style_state0_elText3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy = {
      color: '#eb771d',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_870106+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="NewsPopup">
        <div className="background">
          <div className="state0_elRectangle" style={style_state0_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elText">
            <div className="font-futuraMedium" style={style_state0_elText}>
              <div>{value_text}</div>
            </div>
          </div>
          
          <div className="state0_elText2">
            <div className="baseFont" style={style_state0_elText2}>
              <div>{value_text2}</div>
            </div>
          </div>
          
          <div className="state0_elReactPlayer">
            <div style={style_state0_elReactPlayer}>
              <ReactPlayer url={this.props.newsVideoURL} loop={false} playing={false} controls={false} volume={0.8} width='100%' height='100%' hidden={false} />
            </div>
          </div>
          
          <div className="state0_elText3">
            <div className="baseFont" style={style_state0_elText3} onClick={this.onClick_state0_elText3} >
              <div>{this.props.locStrings.newspopup_text3_588086}</div>
            </div>
          </div>
          
          <div className="state0_elTextCopy">
            <div className="baseFont" style={style_state0_elTextCopy} onClick={this.onClick_state0_elTextCopy} >
              <div>{this.props.locStrings.newspopup_textcopy_559851}</div>
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elIconButton" style={style_state0_elIconButton} onClick={this.onClick_state0_elIconButton}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  renderState1() {
    
    return (
      <div className="NewsPopup">
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  renderState2() {
    
    return (
      <div className="NewsPopup">
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}
