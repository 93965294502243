// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_fb_my_menuItems_sorted extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['description'] = "Public House fire roasted tomato-grilled veggie salsa with house-fried corn tortilla chips";
    item['updated'] = "1609287446305";
    item['document_path'] = "menuItems/2uPq3L2OtXahMP0dtsQc";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "0";
    item['price'] = "700";
    item['order'] = "6";
    item['imageURL'] = "";
    item['document_key'] = "2uPq3L2OtXahMP0dtsQc";
    item['created'] = "1609287227855";
    item['document_ref'] = "";
    item['associatedMenu'] = "eAEcfuan5eVEL1EDm3am";
    item['name'] = "Fire Roasted Tomato Salsa";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Grilled sockeye salmon, mixed greens tossed in bleu cheese dressing, crumbled bleu cheese, bacon, hard-boiled egg, cherry tomato, avocado";
    item['updated'] = "1609288424698";
    item['document_path'] = "menuItems/69f51k31ci6Nlc7P2kJ1";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "0";
    item['price'] = "1250";
    item['order'] = "5";
    item['imageURL'] = "";
    item['document_key'] = "69f51k31ci6Nlc7P2kJ1";
    item['created'] = "1609287975473";
    item['customizations'] = "[{\"updated\":1609288348627,\"order\":1609288.348627,\"document_key\":\"\",\"created\":1609288348627,\"description\":\"\",\"options\":[{\"updated\":1609288356246,\"order\":\"    \",\"price\":0,\"created\":1609288356246,\"name\":\"Regular\"},{\"updated\":1609288360192,\"order\":\"    \",\"price\":400,\"created\":1609288360192,\"name\":\"Large\"}],\"name\":\"Size\",\"isActive\":true}]";
    item['document_ref'] = "";
    item['associatedMenu'] = "4NkVg2kQ44Y5Fqmg0qs9";
    item['name'] = "Salmon Cobb";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Roasted garlic hummus, assorted veggies, grilled naan flatbread, creamy feta dressing";
    item['updated'] = "1609287417646";
    item['document_path'] = "menuItems/6jSNjnZYBPxgG7Noa8pg";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "1";
    item['price'] = "1050";
    item['order'] = "2";
    item['imageURL'] = "";
    item['document_key'] = "6jSNjnZYBPxgG7Noa8pg";
    item['created'] = "1609287184098";
    item['document_ref'] = "";
    item['associatedMenu'] = "eAEcfuan5eVEL1EDm3am";
    item['name'] = "Hummus Platter";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "";
    item['updated'] = "1609287427373";
    item['document_path'] = "menuItems/8DGUIddhf7pNIkbPuZS6";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "2";
    item['price'] = "900";
    item['order'] = "3";
    item['imageURL'] = "";
    item['document_key'] = "8DGUIddhf7pNIkbPuZS6";
    item['created'] = "1609287194414";
    item['document_ref'] = "";
    item['associatedMenu'] = "eAEcfuan5eVEL1EDm3am";
    item['name'] = "Habañero Cheese Dip";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Flour tortillas with cheddar and Monterey jack, topped with tomato, green onion, sour cream";
    item['updated'] = "1609287466434";
    item['document_path'] = "menuItems/Bcjasre63iZthRFQ9zBu";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "3";
    item['price'] = "1000";
    item['order'] = "9";
    item['imageURL'] = "";
    item['document_key'] = "Bcjasre63iZthRFQ9zBu";
    item['created'] = "1609287243518";
    item['document_ref'] = "";
    item['associatedMenu'] = "eAEcfuan5eVEL1EDm3am";
    item['name'] = "Quesadilla";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Brussel sprouts, onions, and diced bacon, Frank’s Original hot sauce topped with melted bleu cheese crumbles. Served with bleu cheese dressing";
    item['updated'] = "1609287460962";
    item['document_path'] = "menuItems/NLmGuM8SGw4EqKWL1q0h";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "4";
    item['price'] = "1200";
    item['order'] = "8";
    item['imageURL'] = "";
    item['document_key'] = "NLmGuM8SGw4EqKWL1q0h";
    item['created'] = "1609287253158";
    item['document_ref'] = "";
    item['associatedMenu'] = "eAEcfuan5eVEL1EDm3am";
    item['name'] = "Brussel Sprouts";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Chopped hearts of romaine tossed in Caesar dressing, house crouton, parmesan";
    item['updated'] = "1609288437582";
    item['document_path'] = "menuItems/ScTgEhXsSSYRKRHkAZs7";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "1";
    item['price'] = "750";
    item['order'] = "6";
    item['imageURL'] = "";
    item['document_key'] = "ScTgEhXsSSYRKRHkAZs7";
    item['created'] = "1609287923965";
    item['customizations'] = "[{\"updated\":1609287995090,\"order\":1609287.99509,\"document_key\":\"\",\"created\":1609287995090,\"description\":\"\",\"name\":\"Size\",\"isActive\":true},{\"updated\":1609288013113,\"document_path\":\"menuItems/ScTgEhXsSSYRKRHkAZs7/customizations/1VwfH26MjDJdPcxvaj5n\",\"order\":1609287.99509,\"document_key\":\"\",\"created\":1609287995090,\"description\":\"\",\"options\":[{\"updated\":1609288005878,\"order\":\"    \",\"price\":0,\"created\":1609288005878,\"name\":\"Regular\"},{\"updated\":1609288011663,\"order\":\"    \",\"price\":300,\"created\":1609288011663,\"name\":\"Large\"}],\"name\":\"Size\",\"isActive\":true},{\"updated\":1609287995090,\"order\":1609287.99509,\"document_key\":\"\",\"created\":1609287995090,\"description\":\"\",\"options\":[{\"updated\":1609288005878,\"order\":\"    \",\"price\":0,\"created\":1609288005878,\"name\":\"Regular\"},{\"updated\":1609288011663,\"order\":\"    \",\"price\":300,\"created\":1609288011663,\"name\":\"Large\"}],\"name\":\"Size\",\"isActive\":true}]";
    item['document_ref'] = "";
    item['associatedMenu'] = "4NkVg2kQ44Y5Fqmg0qs9";
    item['name'] = "Caesar";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Grilled Angus flat iron steak, tossed mixed greens, avocado, pickled red onion, cucumber, cherry tomato, sunflower seeds, croutons, choice of dressing";
    item['updated'] = "1609288405217";
    item['document_path'] = "menuItems/b9Ev1LrFbftlgK4wElB7";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "2";
    item['price'] = "1400";
    item['order'] = "2";
    item['imageURL'] = "";
    item['document_key'] = "b9Ev1LrFbftlgK4wElB7";
    item['created'] = "1609287958986";
    item['customizations'] = "[{\"updated\":1609288279298,\"order\":1609288.279298,\"document_key\":\"\",\"created\":1609288279298,\"description\":\"\",\"options\":[{\"updated\":1609288289400,\"order\":\"    \",\"price\":0,\"created\":1609288289400,\"name\":\"Regular\"},{\"updated\":1609288293592,\"order\":\"    \",\"price\":300,\"created\":1609288293592,\"name\":\"Large\"}],\"name\":\"Size\",\"isActive\":true}]";
    item['document_ref'] = "";
    item['associatedMenu'] = "4NkVg2kQ44Y5Fqmg0qs9";
    item['name'] = "Steak";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Locally baked pretzel, habañero cheese sauce, stoneground mustard.";
    item['updated'] = "1609287411002";
    item['document_path'] = "menuItems/cud6EQYeVhu3hxBBXatc";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "5";
    item['price'] = "1050";
    item['order'] = "1";
    item['imageURL'] = "";
    item['document_key'] = "cud6EQYeVhu3hxBBXatc";
    item['created'] = "1609287174797";
    item['document_ref'] = "";
    item['associatedMenu'] = "eAEcfuan5eVEL1EDm3am";
    item['name'] = "Pretzel and Cheese";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Quarter rack baby back pork ribs in sweet chili sauce, coleslaw, sesame seeds, green onion";
    item['updated'] = "1609287435117";
    item['document_path'] = "menuItems/ezPzSYlu9ptUKObKEkQf";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "6";
    item['price'] = "1250";
    item['order'] = "4";
    item['imageURL'] = "";
    item['document_key'] = "ezPzSYlu9ptUKObKEkQf";
    item['created'] = "1609287219297";
    item['document_ref'] = "";
    item['associatedMenu'] = "eAEcfuan5eVEL1EDm3am";
    item['name'] = "Sweet Chili Baby Back Ribs";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Tossed Mixed greens in bleu cheese dressing, House smoked chicken, bacon, hard-boiled egg, crumbled bleu cheese, cherry tomato, avocado";
    item['updated'] = "1609288407790";
    item['document_path'] = "menuItems/hUIgNpFXhBfumhirR9Tb";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "3";
    item['price'] = "1000";
    item['order'] = "3";
    item['imageURL'] = "";
    item['document_key'] = "hUIgNpFXhBfumhirR9Tb";
    item['created'] = "1609287937349";
    item['customizations'] = "[{\"updated\":1609288132846,\"order\":1609288.132846,\"document_key\":\"\",\"created\":1609288132846,\"description\":\"\",\"options\":[{\"updated\":1609288155838,\"order\":\"    \",\"price\":0,\"created\":1609288155838,\"name\":\"Regular\"},{\"updated\":1609288160692,\"order\":\"    \",\"price\":450,\"created\":1609288160692,\"name\":\"Large\"}],\"name\":\"Size\",\"isActive\":true}]";
    item['document_ref'] = "";
    item['associatedMenu'] = "4NkVg2kQ44Y5Fqmg0qs9";
    item['name'] = "Cobb";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Grilled chicken tossed in Frank’s Original hot sauce, chopped hearts of romaine in bleu cheese dressing, celery, carrot, cucumber, crumbled bleu cheese";
    item['updated'] = "1609288445770";
    item['document_path'] = "menuItems/mWgkUgTtvOFYTlbnlFl0";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "4";
    item['price'] = "1100";
    item['order'] = "7";
    item['imageURL'] = "";
    item['document_key'] = "mWgkUgTtvOFYTlbnlFl0";
    item['created'] = "1609287930991";
    item['customizations'] = "[{\"updated\":1609288054231,\"order\":1609288.054231,\"document_key\":\"\",\"created\":1609288054231,\"description\":\"\",\"options\":[{\"updated\":1609288065338,\"order\":\"    \",\"price\":0,\"created\":1609288065338,\"name\":\"Regular\"},{\"updated\":1609288069405,\"order\":\"    \",\"price\":300,\"created\":1609288069405,\"name\":\"Large\"}],\"name\":\"Size\",\"isActive\":true}]";
    item['document_ref'] = "";
    item['associatedMenu'] = "4NkVg2kQ44Y5Fqmg0qs9";
    item['name'] = "Buffalo Chicken";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "";
    item['updated'] = "1609288421901";
    item['document_path'] = "menuItems/oVJOl66KBTh76eFGWRvW";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "5";
    item['price'] = "900";
    item['order'] = "4";
    item['imageURL'] = "";
    item['document_key'] = "oVJOl66KBTh76eFGWRvW";
    item['created'] = "1609287950695";
    item['customizations'] = "[{\"updated\":1609288245889,\"order\":1609288.245889,\"document_key\":\"\",\"created\":1609288245889,\"description\":\"\",\"options\":[{\"updated\":1609288251889,\"order\":\"    \",\"price\":0,\"created\":1609288251889,\"name\":\"Regular\"},{\"updated\":1609288261280,\"order\":\"    \",\"price\":400,\"created\":1609288261280,\"name\":\"Large\"}],\"name\":\"Size\",\"isActive\":true}]";
    item['document_ref'] = "";
    item['associatedMenu'] = "4NkVg2kQ44Y5Fqmg0qs9";
    item['name'] = "Spinach";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Creamy spinach artichoke dip with roasted jalapeño, baked with parmesan, topped with tomato and green onion. Served with house-fried corn tortilla chips";
    item['updated'] = "1609287440060";
    item['document_path'] = "menuItems/pcloP6yRowkFRK4lqb5k";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "7";
    item['price'] = "1000";
    item['order'] = "5";
    item['imageURL'] = "";
    item['document_key'] = "pcloP6yRowkFRK4lqb5k";
    item['created'] = "1609287235214";
    item['document_ref'] = "";
    item['associatedMenu'] = "eAEcfuan5eVEL1EDm3am";
    item['name'] = "Spicy Spinach and Artichoke Dip";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Jumbo deep-fried dummies tossed in Frank's Original hot sauce w/ carrot & celery sticks, cucumbers & blue cheese";
    item['updated'] = "1609287454626";
    item['document_path'] = "menuItems/qZsLDXL95Bsat6R5IevD";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "8";
    item['price'] = "1000";
    item['order'] = "7";
    item['imageURL'] = "";
    item['document_key'] = "qZsLDXL95Bsat6R5IevD";
    item['created'] = "1609287205507";
    item['document_ref'] = "";
    item['associatedMenu'] = "eAEcfuan5eVEL1EDm3am";
    item['name'] = "Buffalo Wings";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['dummy'] = "dummy";
    item['document_key'] = "vG1osgoAR2uPlil9FqgZ";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['description'] = "Tossed Mixed greens, cherry tomatoes, cucumbers, carrots, red onions & croutons with choice of dressing";
    item['updated'] = "1609288398385";
    item['document_path'] = "menuItems/yqF1yDqfo8qvEpBzVXMd";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "6";
    item['price'] = "600";
    item['order'] = "1";
    item['imageURL'] = "";
    item['document_key'] = "yqF1yDqfo8qvEpBzVXMd";
    item['created'] = "1609287944472";
    item['customizations'] = "[{\"updated\":1609288186573,\"order\":1609288.186573,\"document_key\":\"\",\"created\":1609288186573,\"description\":\"\",\"options\":[{\"updated\":1609288206804,\"order\":\"    \",\"price\":0,\"created\":1609288206804,\"name\":\"Regular\"},{\"updated\":1609288210900,\"order\":\"    \",\"price\":300,\"created\":1609288210900,\"name\":\"Large\"}],\"name\":\"Size\",\"isActive\":true}]";
    item['document_ref'] = "";
    item['associatedMenu'] = "4NkVg2kQ44Y5Fqmg0qs9";
    item['name'] = "House";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "Chopped hearts of romaine tossed in creamy feta dressing, crumbled feta cheese, marinated Greek olive, artichoke heart, tomato, pepperoncini, cucumber, red onion";
    item['updated'] = "1609288449705";
    item['document_path'] = "menuItems/zbyFz8CG5sEZp44EhKyy";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "7";
    item['price'] = "900";
    item['order'] = "8";
    item['imageURL'] = "";
    item['document_key'] = "zbyFz8CG5sEZp44EhKyy";
    item['created'] = "1609287966516";
    item['customizations'] = "[{\"updated\":1609288317071,\"order\":1609288.317071,\"document_key\":\"\",\"created\":1609288317071,\"description\":\"\",\"options\":[{\"updated\":1609288324930,\"order\":\"    \",\"price\":0,\"created\":1609288324930,\"name\":\"Regular\"},{\"updated\":1609288328688,\"order\":\"    \",\"price\":400,\"created\":1609288328688,\"name\":\"Large\"}],\"name\":\"Size\",\"isActive\":true}]";
    item['document_ref'] = "";
    item['associatedMenu'] = "4NkVg2kQ44Y5Fqmg0qs9";
    item['name'] = "Greek";
    item['isActive'] = "1";
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    if (item.document_key && item.document_key.length > 0) {
      let parsedItem = (({ document_key, ...item }) => (item))( item );
      addItemPromise = collection.doc(item.document_key).set(parsedItem);
    } else {
      addItemPromise = collection.add(item);
    }
    addItemPromise
      .then((docRef) => {
        var addedKey="";
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          addedKey=docRef.id;
          item.document_key = docRef.id;
          item.document_path = docRef.path;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
          addedKey=item.document_key;
        }   
        if (this.onLastAddedDocumentKeyChanged != null) {
          this.onLastAddedDocumentKeyChanged(addedKey);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
  
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
      
    docRef.set((({ key, document_ref, document_key, ...item }) => (item))( item ), {merge: true})
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  

}
