import React, { Component } from 'react';
import './App.css';
import Icon_BrandPromo from './Icon_BrandPromo';
import img_elImage6 from './images/DetailsComponent_state1_elRoundimage_743990.png';
import btn_icon_1321095672 from './images/btn_icon_1321095672.png';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';
import Comp5 from './Comp5';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';

export default class Comp_MenuItemDetalils extends Component {

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elField_itemName = (event) => {
    this.setState({field_itemName: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_activeMenuItemName", event.target.value);
  }
  
  getValue_elField_itemName = () => {
    return this.state.field_itemName !== undefined ? this.state.field_itemName : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  textInputChanged_elField_itemPrice = (event) => {
    this.setState({field_itemPrice: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_activeMenuItemPrice", event.target.value);
  }
  
  getValue_elField_itemPrice = () => {
    let transformPropValue_field_itemPrice = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
          var n = this,
              decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
              decSeparator = decSeparator == undefined ? "." : decSeparator,
              thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
              sign = n < 0 ? "-" : "",
              i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
              j = (j = i.length) > 3 ? j % 3 : 0;
          return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
      };
      
      var myMoney = input / 100;
      
      return ( '$' + myMoney.formatMoney(2,',','.'))  // "$3,543.76"
      
      
      //return input;
    }
    
    return this.state.field_itemPrice !== undefined ? this.state.field_itemPrice : (transformPropValue_field_itemPrice(this.props.dataSheetRow ? this.props.dataSheetRow.price : ''));
  }
  
  textAreaChanged_elTextarea = (event) => {
    this.setState({textarea: event.target.value});
  }
  
  getValue_elTextarea = () => {
    return this.state.textarea !== undefined ? this.state.textarea : (this.props.dataSheetRow ? this.props.dataSheetRow.description : '');
  }
  
  onClick_elIcon_BrandPromo = async () => {
    // Write your script here
    // setState(this.state.brandpromoText: "true");
    this.setState({brandpromoText: "1"});
  
  }
  
  
  
  getValue_elField_isActiveCheckbox = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : (this.props.dataSheetRow ? this.props.dataSheetRow.isActive : '');
  }
  
  checkboxChanged_elField_isActiveCheckbox = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')});
  }
  
  onClick_elIconButtonCopy = async () => {
    this.props.appActions.updateDataSlot('ds_backgroundImagefileStorageLocation', "");
    this.props.appActions.updateDataSlot('ds_uploadedMenuItemImageLocation', "");
    this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', "");
  
    await this.sendData_elIconButtonCopy_to_fb_currentBrand();
  
  }
  
  
  onClick_elComp = async () => {
    await this.sendData_elComp_to_fb_menuItems();
  
  }
  
  
  sendData_elIconButtonCopy_to_fb_currentBrand = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_currentBrand');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
    };
    row = { ...row, 
    };
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_currentBrand', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_currentBrand', row);
    }
  }
  
  
  sendData_elComp_to_fb_menuItems = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItems');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
    };
    row = { ...row, 
      imageURL: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedMenuItemImageLocation'] : ''),
      name: this.getValue_elField_itemName(),
      price: this.getValue_elField_itemPrice(),
      description: this.getValue_elTextarea(),
    };
  
    const transformData = (input) => {
        
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      
      if(this.state.field_isActiveCheckbox === "true" || 
         this.state.field_isActiveCheckbox === true ||
         this.state.field_isActiveCheckbox === "1")
      	{
            input.isActive=true
          };
      
      if(this.state.field_isActiveCheckbox === "false" || 
         this.state.field_isActiveCheckbox === false ||
         this.state.field_isActiveCheckbox === "0")
      	{
            input.isActive=false
          };
      
      
      
      var price = Math.round(100*Number((this.state.field_itemPrice).replace("$", "")));
      
      input.price = price;
      
      return input;
      
      
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItems', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItems', row);
    }
  }
  
  
  render() {
    const style_elText_brand_detailsCopy2 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elTextCopy2 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_field_itemName = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_elField_itemName = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy3 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_field_itemPrice = this.props.dataSheetRow ? this.props.dataSheetRow.price : '';
    
    const style_elField_itemPrice = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy4 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elTextarea = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      pointerEvents: 'auto',
     };
    const style_elIcon_BrandPromo = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elText4 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_elField_isActiveCheckbox();
    
    const style_elField_isActiveCheckbox = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextCopy = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elImage6 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedMenuItemImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const style_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1321095672+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elFirebaseUpload = {
      pointerEvents: 'auto',
     };
    const style_elComp = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Comp_MenuItemDetalils">
        <div className="layoutFlow">
          <div className="elText_brand_detailsCopy2">
            <div className="font-futuraMedium" style={style_elText_brand_detailsCopy2}>
              <div>{this.props.locStrings.comp6_text_brand_detailscopy2_110724717}</div>
            </div>
          </div>
          
          <div className="elTextCopy2">
            <div className="font-avenirBook" style={style_elTextCopy2}>
              <div>{this.props.locStrings.comp6_textcopy2_1138174374}</div>
            </div>
          </div>
          
          <div className="elField_itemName">
            <input className="font-avenirNextRegular" style={style_elField_itemName} type="text" placeholder={placeholderValue_field_itemName} onChange={this.textInputChanged_elField_itemName} value={this.getValue_elField_itemName()}  />
          </div>
          
          <div className="elTextCopy3">
            <div className="font-avenirBook" style={style_elTextCopy3}>
              <div>{this.props.locStrings.comp6_textcopy3_975212044}</div>
            </div>
          </div>
          
          <div className="elField_itemPrice">
            <input className="font-avenirNextRegular" style={style_elField_itemPrice} type="text" placeholder={placeholderValue_field_itemPrice} onChange={this.textInputChanged_elField_itemPrice} value={this.getValue_elField_itemPrice()}  />
          </div>
          
          <div className="elTextCopy4">
            <div className="font-avenirBook" style={style_elTextCopy4}>
              <div>{this.props.locStrings.comp6_textcopy4_1834857722}</div>
            </div>
          </div>
          
          <div className="elTextarea">
            <textarea className="baseFont" style={style_elTextarea}  placeholder={this.props.locStrings.comp6_textarea_526554840} onChange={this.textAreaChanged_elTextarea} value={this.getValue_elTextarea()}  />
          </div>
          
          <div className="hasNestedComps elIcon_BrandPromo">
            <div style={style_elIcon_BrandPromo} onClick={this.onClick_elIcon_BrandPromo} >
              <Icon_BrandPromo {...this.props} visualStateIndex={this.props.dataSheetRow ? this.props.dataSheetRow.isBrandPromo : ''} ref={(el)=> this._elIcon_BrandPromo = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elText4">
            <div className="baseFont" style={style_elText4}>
              <div>{this.props.locStrings.comp_menuitemdetalils_text4_811279111}</div>
            </div>
          </div>
          
          <div className="elField_isActiveCheckbox">
            <Checkbox className="font-openSans" style={style_elField_isActiveCheckbox}  label={this.props.locStrings.comp_menuitemdetalils_field_isactivecheckbox_1558566830} checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_elField_isActiveCheckbox} />
          </div>
          
          <div className="elTextCopy">
            <div className="font-futuraMedium" style={style_elTextCopy}>
              <div>{this.props.locStrings.comp_menuitemdetalils_textcopy_2137752609}</div>
            </div>
          </div>
          
          <div className="elImage6">
            <div style={style_elImage6} />
          </div>
          
          <div className="elIconButtonCopy">
            <button className="actionFont" style={style_elIconButtonCopy} onClick={this.onClick_elIconButtonCopy}  />
          </div>
          
          <div className="elFirebaseUpload">
            <div style={style_elFirebaseUpload}>
              <FileUploader
                accept="image/*"
                randomizeFilename
                storageRef={firebase.storage().ref((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_MenuItemfileStorageLocation'] : ''))}
                onUploadStart={() => {
                  this.props.appActions.setState({loading: true});
                }}
                onUploadError={error => {
                  this.props.appActions.setState({loading: false});
                  console.error('** Firebase upload failed: ', error);
                }}
                onUploadSuccess={filename => {
                  this.props.appActions.setState({loading: false});
                  firebase.storage().ref((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_MenuItemfileStorageLocation'] : '')).child(filename).getDownloadURL().then(url => {
                    let setDataSlotValue = (v) => { this.props.appActions.updateDataSlot("ds_uploadedMenuItemImageLocation", v) };
                    setDataSlotValue(url);
                    let setfilenameDataSlotValue = (v) => { this.props.appActions.updateDataSlot("ds_uploadedMenuItemmageName", v) };
                    setfilenameDataSlotValue(filename);
                  }).catch(function(error) {
                    console.error('** Firebase upload failed: ', error);
                  });
                }}
                />
            </div>
          </div>
          
          <div className="hasNestedComps elComp">
            <div style={style_elComp} onClick={this.onClick_elComp} >
              <Comp5 ref={(el)=> this._elComp = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
