// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_fb_myBrands extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['orderButtonCopy'] = "Start New Order";
    item['associatedParent'] = "l4xZGcqCW8hzTeRVGNz3";
    item['creator'] = "tqQKQFzMu4Yhf5z9WdRoY1GfhQa2";
    item['document_ref'] = "";
    item['onboarding_screen3_line2'] = "Lets get started";
    item['onboarding_screen1_title'] = "Welcome!";
    item['onboarding_screen1_line1'] = "This is default text";
    item['onboarding_screen2_title'] = "Screen Two";
    item['onboarding_screen3_imageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['onboarding_screen2_line1'] = "Desrcribe your app here";
    item['onboarding_screen3_title'] = "Screen Three";
    item['brandImageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['created'] = "1602523993583";
    item['updated'] = "1602523993583";
    item['onboarding_screen3_line1'] = "Without further ado";
    item['brandDefaultTip'] = "15";
    item['description'] = "";
    item['onboarding_screen2_imageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['contactEmail'] = "brojokevin@gmail.com";
    item['primaryColor'] = "#777777";
    item['brandBackgroundImageURL'] = "";
    item['onboarding_screen1_imageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['bottonNavColor'] = "#EEEEEE";
    item['titleColor'] = "#000000";
    item['name'] = "My new Brand";
    item['isActive'] = "1";
    item['bottomNavIconActiveColor'] = "#444444";
    item['secondaryColor'] = "#777777";
    item['textColor'] = "#000000";
    item['document_key'] = "l4xZGcqCW8hzTeRVGNz3";
    item['onboarding_screen0_color'] = "#ffffff";
    item['brandOfferingOther'] = "";
    item['onboarding_screen1_line3'] = "screen of your new app";
    item['orderButtonColor'] = "#EEEEEE";
    item['onboarding_screen1_color'] = "#777777";
    item['onboarding_screen2_line3'] = "what to expect";
    item['document_path'] = "brands/l4xZGcqCW8hzTeRVGNz3";
    item['orderButtonTextColor'] = "#000000";
    item['onboarding_screen2_color'] = "#aaaaaa";
    item['onboarding_screen3_line3'] = "Cheers!";
    item['brandOfferingType'] = "";
    item['bottomNavIconColor'] = "#777777";
    item['onboarding_screen1_line2'] = "which will show on the onboarding";
    item['logoImageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['onboarding_screen3_color'] = "#dddddd";
    item['onboarding_screen2_line2'] = "so that customers know";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['onboarding_screen2_color'] = "#658463";
    item['key'] = "0";
    item['id'] = "LlZcqwBHBj4fiO8qmoHH";
    item['titleColor'] = "#000000";
    item['onboarding_screen3_line3'] = "We will have it waiting for you.";
    item['associatedMenuItems'] = [{"price":600,"key":"5CriT6L2sO2r08CmgPkv","imageURL":"https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2FmenuItems%2Fdf0f4d48-f180-42b5-99b8-65b07954007d.png?alt=media&token=6684e23b-8dba-4dfa-a7fe-6140060a3f1c","type":"BrandPromo","name":"Caldera - Cocoa Porter"},{"price":600,"key":"R1jhY2zawbo4seq2I4Ov","imageURL":"https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2FmenuItems%2Faa552ee6-847b-480c-a429-749884292b55.png?alt=media&token=1104da71-fa7f-4bc2-8e8f-b29d64b7959b","type":"BrandPromo","name":"Everybody's Hazy IPA"},{"price":575,"key":"aRMIVrP9jOuE9Ca3rcbR","imageURL":"https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2FmenuItems%2Fdbc39e7f-d3fe-4570-a5e1-5518bd33eade.png?alt=media&token=4b3719fa-7a3b-40b7-95bd-ed006e50d1ff","type":"BrandPromo","name":"Boneyard - RPM"},{"price":600,"key":"anjIUlpMTFtGdCj6zEdS","imageURL":"https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2FmenuItems%2Ff3d18633-4966-42eb-a83e-2e4be3efb60a.png?alt=media&token=82b19cc1-8d7b-4041-8040-84c343e08214","type":"BrandPromo","name":"Monkless - Saison"},{"price":600,"key":"jF6nN4MHoja9Zr8UQCCC","imageURL":"https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2FmenuItems%2F4623dfea-7e86-43f9-b2c7-46f804639801.png?alt=media&token=6678d37b-08d5-42ce-923f-e1f8e7e83718","type":"BrandPromo","name":"Von Ebert - Pilsner"}];
    item['FirstSceneColor'] = "#ede7d4";
    item['activeBrandID'] = "LlZcqwBHBj4fiO8qmoHH";
    item['onboarding_screen1_line1'] = "Welcome to Brother Jon's.";
    item['created'] = "1602013098914";
    item['document_path'] = "brands/LlZcqwBHBj4fiO8qmoHH";
    item['orderButtonCopy'] = "I'll take it!";
    item['secondScreenLine1'] = "Brother Jon's";
    item['onboarding_screen1_line2'] = "Thanks for stopping by.";
    item['document_ref'] = "";
    item['onboarding_screen0_color'] = "#ffffff";
    item['onboarding_screen1_line3'] = "We hope you enjoy your meal";
    item['onboarding_screen3_imageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['iconHomeURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:ffffff,e_colorize:100,f_png,h_96,w_96/v1589822630/yesIcons/heartIcon.svg";
    item['description'] = "Established in 2009, Brother Jon’s is a restaurant and pub located on the west side of Bend, Oregon.";
    item['associatedParent'] = "LlZcqwBHBj4fiO8qmoHH";
    item['primaryColor'] = "#658463";
    item['orderButtonTextColor'] = "#EDDDC4";
    item['bottonNavColor'] = "#EDDDC4";
    item['logoImageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['brandOfferingType'] = "";
    item['onboarding_screen2_title'] = "Reach out!";
    item['brandImageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2Fcd60d2bb-1edb-4d17-a947-0d7aaae6fc8a.png?alt=media&token=116edbc1-0114-4a1e-b4f0-2fb37cd1d1e5";
    item['onboarding_screen2_imageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['onboarding_screen3_color'] = "#EDDDC4";
    item['iconMoreURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:9C9F84,e_colorize:100,f_png,h_96,w_96/v1589862715/yesIcons/more-round.svg";
    item['brandBackgroundImageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2Ff8b0670d-379a-4809-a100-57e467c1f38f.jpg?alt=media&token=5c70155a-7dd0-41d0-aa92-53112f9f1281";
    item['onboarding_screen1_imageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['name'] = "Brother Jon's Public House ";
    item['onboarding_screen2_line1'] = "Please let us know if there ";
    item['ThirdSceneColor'] = "#ede7d4";
    item['iconMenuURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:9C9F84,e_colorize:100,f_png,h_96,w_96/v1589825284/yesIcons/menu-boxedIcon.svg";
    item['onboarding_screen2_line2'] = "is anything you may need";
    item['bottomNavIconActiveColor'] = "#ffffff";
    item['onboarding_screen1_color'] = "#EDDDC4";
    item['iconProfileURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:9C9F84,e_colorize:100,f_png,h_96,w_96/v1589825354/yesIcons/profileIcon.svg";
    item['SecondSceneColor'] = "#fef8e5";
    item['bottomNavIconColor'] = "#9C9F84";
    item['onboarding_screen2_line3'] = "We are happy to help.";
    item['brandOfferingOther'] = "";
    item['onboarding_screen3_title'] = "Let's get started.";
    item['document_key'] = "LlZcqwBHBj4fiO8qmoHH";
    item['textColor'] = "#EDDDC4";
    item['creator'] = "tqQKQFzMu4Yhf5z9WdRoY1GfhQa2";
    item['iconOrderURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:9C9F84,e_colorize:100,f_png,h_96,w_96/v1589825246/yesIcons/menu-roundIcon.svg";
    item['updated'] = "1602013098914";
    item['orderButtonColor'] = "#9C9F84";
    item['contactEmail'] = "brojokevin@gmail.com";
    item['onboarding_screen3_line1'] = "Order from your table..";
    item['secondaryColor'] = "#658463";
    item['thirdScreenLine1'] = "Last Scene";
    item['onboarding_screen1_title'] = "Welcome!";
    item['onboarding_screen3_line2'] = "..or order ahead.";
    item['isActive'] = "1";
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    if (item.document_key && item.document_key.length > 0) {
      let parsedItem = (({ document_key, ...item }) => (item))( item );
      addItemPromise = collection.doc(item.document_key).set(parsedItem);
    } else {
      addItemPromise = collection.add(item);
    }
    addItemPromise
      .then((docRef) => {
        var addedKey="";
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          addedKey=docRef.id;
          item.document_key = docRef.id;
          item.document_path = docRef.path;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
          addedKey=item.document_key;
        }   
        if (this.onLastAddedDocumentKeyChanged != null) {
          this.onLastAddedDocumentKeyChanged(addedKey);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
  
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
      
    docRef.set((({ key, document_ref, document_key, ...item }) => (item))( item ), {merge: true})
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  

}
