import React, { Component } from 'react';
import './App.css';
import Ipadlandscape from './Ipadlandscape';
import Footer from './Footer';
import Header from './Header';
import DetailsComponent from './DetailsComponent';
import Component4 from './Component4';
import MenuItemsipadlandscape from './MenuItemsipadlandscape';
import MenuItemDetailsipadlandscape from './MenuItemDetailsipadlandscape';
import MenuItemOptionsipadlandscape from './MenuItemOptionsipadlandscape';
import Docsipadlandscape from './Docsipadlandscape';

export default class IPadLandscapeScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, dataSheetRow, agreementSigned, email

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['fd_menuItemCustomizations'];
      let serviceOptions = this.props.appActions.serviceOptions_fd_menuItemCustomizations;
      if ( !this.props.appActions.dataSheetLoaded['fd_menuItemCustomizations']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems/$slot(ds_selected_menuItem_key)/customizations", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fd_menuItemCustomizations'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menuItem_options'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menuItem_options;
      if ( !this.props.appActions.dataSheetLoaded['fb_menuItem_options']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems/$slot(ds_selected_menuItem_key)/customizations/$slot(ds_selected_menuItemOption_key)/options", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menuItem_options'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_myBrands'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_myBrands;
      if ( !this.props.appActions.dataSheetLoaded['fb_myBrands']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("brands", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_myBrands'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_brands'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_brands;
      if ( !this.props.appActions.dataSheetLoaded['fb_brands']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("brands", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_brands'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menuItems'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menuItems;
      if ( !this.props.appActions.dataSheetLoaded['fb_menuItems']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menuItems'] = true;
      }
    }
    
    let newVal = "https://discuss.yesoms.com/t/site-walkthrough-the-brands-page/";
    this.props.appActions.updateDataSlot('ds_forumHelpURL', newVal);
    
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 6) --- 
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state0_elComponent203814 = {
      display: 'none',
     };
    const state0_elComponentCopy2014693634 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state0_elComponentCopy2014693634">
        <Component4 ref={(el)=> this._state0_elComponentCopy2014693634 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen IPadLandscapeScreen" style={baseStyle}>
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps state0_elIpadlandscape">
            <div>
              <Ipadlandscape ref={(el)=> this._state0_elIpadlandscape = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="hasNestedComps state0_elComp3">
            <div>
              <Footer ref={(el)=> this._state0_elComp3 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps state0_elHeader">
              <Header ref={(el)=> this._state0_elHeader = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elComponent203814" style={style_state0_elComponent203814}>
              <DetailsComponent ref={(el)=> this._state0_elComponent203814 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state0_elComponentCopy2014693634 }
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 6) --- 
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state1_elComponent203814 = {
      display: 'none',
     };
    const state1_elComponentCopy2014693634 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state1_elComponentCopy2014693634">
        <Component4 ref={(el)=> this._state1_elComponentCopy2014693634 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen IPadLandscapeScreen" style={baseStyle}>
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps state1_elComponent203814" style={style_state1_elComponent203814}>
              <DetailsComponent ref={(el)=> this._state1_elComponent203814 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state1_elComponentCopy2014693634 }
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 6) --- 
  
  renderState2() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state2_elComponent203814 = {
      display: 'none',
     };
    const state2_elComponentCopy2014693634 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state2_elComponentCopy2014693634">
        <Component4 ref={(el)=> this._state2_elComponentCopy2014693634 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen IPadLandscapeScreen" style={baseStyle}>
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps state2_elMenuItemsipadlandscape">
              <MenuItemsipadlandscape ref={(el)=> this._state2_elMenuItemsipadlandscape = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state2_elComponent203814" style={style_state2_elComponent203814}>
              <DetailsComponent ref={(el)=> this._state2_elComponent203814 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state2_elComponentCopy2014693634 }
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 6) --- 
  
  renderState3() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state3_elComponent203814 = {
      display: 'none',
     };
    const state3_elComponentCopy2014693634 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state3_elComponentCopy2014693634">
        <Component4 ref={(el)=> this._state3_elComponentCopy2014693634 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen IPadLandscapeScreen" style={baseStyle}>
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps state3_elMenuItemDetailsipadlandscape">
              <MenuItemDetailsipadlandscape ref={(el)=> this._state3_elMenuItemDetailsipadlandscape = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state3_elComponent203814" style={style_state3_elComponent203814}>
              <DetailsComponent ref={(el)=> this._state3_elComponent203814 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state3_elComponentCopy2014693634 }
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 4 (5 of 6) --- 
  
  renderState4() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state4_elComponent203814 = {
      display: 'none',
     };
    const state4_elComponentCopy2014693634 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state4_elComponentCopy2014693634">
        <Component4 ref={(el)=> this._state4_elComponentCopy2014693634 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen IPadLandscapeScreen" style={baseStyle}>
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps state4_elMenuItemOptionsipadlandscape">
              <MenuItemOptionsipadlandscape ref={(el)=> this._state4_elMenuItemOptionsipadlandscape = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state4_elComponent203814" style={style_state4_elComponent203814}>
              <DetailsComponent ref={(el)=> this._state4_elComponent203814 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state4_elComponentCopy2014693634 }
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 5 (6 of 6) --- 
  
  renderState5() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state5_elComponent203814 = {
      display: 'none',
     };
    const state5_elComponentCopy2014693634 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state5_elComponentCopy2014693634">
        <Component4 ref={(el)=> this._state5_elComponentCopy2014693634 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen IPadLandscapeScreen" style={baseStyle}>
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps state5_elDocsipadlandscape">
              <Docsipadlandscape ref={(el)=> this._state5_elDocsipadlandscape = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state5_elComponent203814" style={style_state5_elComponent203814}>
              <DetailsComponent ref={(el)=> this._state5_elComponent203814 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state5_elComponentCopy2014693634 }
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.props.ds_mainScreenState !== undefined) ? this.props.ds_mainScreenState : 0, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
      case 4:
        return this.renderState4();
      case 5:
        return this.renderState5();
    }
  }
  
}
