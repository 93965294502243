// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_ds_list_of_times extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['value'] = "-1";
    item['display'] = "Closed";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0";
    item['display'] = "12:00am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "30";
    item['display'] = "12:30am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "100";
    item['display'] = "1:00am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "130";
    item['display'] = "1:30am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "200";
    item['display'] = "2:00am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "230";
    item['display'] = "2:30am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "300";
    item['display'] = "3:00am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "330";
    item['display'] = "3:30am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "400";
    item['display'] = "4:00am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "430";
    item['display'] = "4:30am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "500";
    item['display'] = "5:00am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "530";
    item['display'] = "5:30am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "600";
    item['display'] = "6:00am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "630";
    item['display'] = "6:30am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "700";
    item['display'] = "7:00am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "730";
    item['display'] = "7:30am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "800";
    item['display'] = "8:00am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "830";
    item['display'] = "8:30am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "900";
    item['display'] = "9:00am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "930";
    item['display'] = "9:30am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1000";
    item['display'] = "10:00am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1030";
    item['display'] = "10:30am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1100";
    item['display'] = "11:00am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1130";
    item['display'] = "11:30am";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1200";
    item['display'] = "12:00pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1230";
    item['display'] = "12:30pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1300";
    item['display'] = "1:00pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1330";
    item['display'] = "1:30pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1400";
    item['display'] = "2:00pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1430";
    item['display'] = "2:30pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1500";
    item['display'] = "3:00pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1530";
    item['display'] = "3:30pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1600";
    item['display'] = "4:00pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1630";
    item['display'] = "4:30pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1700";
    item['display'] = "5:00pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1730";
    item['display'] = "5:30pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1800";
    item['display'] = "6:00pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1830";
    item['display'] = "6:30pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1900";
    item['display'] = "7:00pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1930";
    item['display'] = "7:30pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "2000";
    item['display'] = "8:00pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "2030";
    item['display'] = "8:30pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "2100";
    item['display'] = "9:00pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "2130";
    item['display'] = "9:30pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "2200";
    item['display'] = "10:00pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "2230";
    item['display'] = "10:30pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "2300";
    item['display'] = "11:00pm";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "2330";
    item['display'] = "11:30pm";
    item.key = key++;
  }

}
