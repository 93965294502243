import React, { Component } from 'react';
import './App.css';
import btn_icon_848113 from './images/btn_icon_848113.png';
import btn_icon_697776 from './images/btn_icon_697776.png';
import RightDrawer from './RightDrawer';
import LeftDrawer3 from './LeftDrawer3';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Footer extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      rightDrawer_isOpen: false,
      leftDrawer3_isOpen: false,
      rightDrawer_isOpen: false,
      leftDrawer3_isOpen: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elIconButtonCopy2848113 = async () => {
    this.toggle_rightDrawer();
  
  }
  
  
  onClick_state0_elButton310683115 = async () => {
    this.toggle_rightDrawer();
  
  }
  
  
  onClick_state0_elIconButtonCopy697776 = async () => {
    this.toggle_leftDrawer3();
  
  }
  
  
  toggle_rightDrawer = () => {
    this.setState({rightDrawer_isOpen: !this.state.rightDrawer_isOpen});
  }
  
  toggle_leftDrawer3 = () => {
    this.setState({leftDrawer3_isOpen: !this.state.leftDrawer3_isOpen});
  }
  
  renderState0() {
    const style_state0_elFooter = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elFooter_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    const style_state0_elIconButtonCopy2848113 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_848113+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '62.963%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elButton310683115 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconButtonCopy697776 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_697776+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '62.963%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_rightDrawer = {
      pointerEvents: (this.state.rightDrawer_isOpen) ? 'auto' : 'none'
    }
    const style_leftDrawer3 = {
      pointerEvents: (this.state.leftDrawer3_isOpen) ? 'auto' : 'none'
    }
    
    return (
      <div className="Footer">
        <div className="foreground">
          <div className="state0_elFooter" style={style_state0_elFooter_outer}>
            <div className="cardBg" style={style_state0_elFooter} />
          </div>
          
          <button className="actionFont state0_elIconButtonCopy2848113" style={style_state0_elIconButtonCopy2848113} onClick={this.onClick_state0_elIconButtonCopy2848113}  />
          <Button className="actionFont state0_elButton310683115" style={style_state0_elButton310683115}  color="accent" onClick={this.onClick_state0_elButton310683115} >
            {this.props.locStrings.footer_button_310683115}
          </Button>
          <button className="actionFont state0_elIconButtonCopy697776" style={style_state0_elIconButtonCopy697776} onClick={this.onClick_state0_elIconButtonCopy697776}  />
        </div>
        <div className="drawer rightDrawer" style={style_rightDrawer} onClick={this.toggle_rightDrawer}>
          <div className={"drawerContent" + (this.state.rightDrawer_isOpen ? " drawerOpen": " drawerClosedRight")} onClick={(e) => {e.stopPropagation();}}>
            <RightDrawer appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} closeDrawer={this.toggle_rightDrawer} />
          </div>
        </div>
        <div className="drawer leftDrawer3" style={style_leftDrawer3} onClick={this.toggle_leftDrawer3}>
          <div className={"drawerContent" + (this.state.leftDrawer3_isOpen ? " drawerOpen": " drawerClosedLeft")} onClick={(e) => {e.stopPropagation();}}>
            <LeftDrawer3 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} closeDrawer={this.toggle_leftDrawer3} />
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elIconButtonCopy2848113 = async () => {
    this.toggle_rightDrawer();
  
  }
  
  
  onClick_state1_elButton310683115 = async () => {
    this.toggle_rightDrawer();
  
  }
  
  
  onClick_state1_elIconButtonCopy697776 = async () => {
    this.toggle_leftDrawer3();
  
  }
  
  
  toggle_rightDrawer = () => {
    this.setState({rightDrawer_isOpen: !this.state.rightDrawer_isOpen});
  }
  
  toggle_leftDrawer3 = () => {
    this.setState({leftDrawer3_isOpen: !this.state.leftDrawer3_isOpen});
  }
  
  renderState1() {
    
    const style_state1_elIconButtonCopy2848113 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_848113+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '62.963%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elButton310683115 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconButtonCopy697776 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_697776+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '62.963%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_rightDrawer = {
      pointerEvents: (this.state.rightDrawer_isOpen) ? 'auto' : 'none'
    }
    const style_leftDrawer3 = {
      pointerEvents: (this.state.leftDrawer3_isOpen) ? 'auto' : 'none'
    }
    
    return (
      <div className="Footer">
        <div className="foreground">
          <button className="actionFont state1_elIconButtonCopy2848113" style={style_state1_elIconButtonCopy2848113} onClick={this.onClick_state1_elIconButtonCopy2848113}  />
          <Button className="actionFont state1_elButton310683115" style={style_state1_elButton310683115}  color="accent" onClick={this.onClick_state1_elButton310683115} >
            {this.props.locStrings.footer_button_310683115}
          </Button>
          <button className="actionFont state1_elIconButtonCopy697776" style={style_state1_elIconButtonCopy697776} onClick={this.onClick_state1_elIconButtonCopy697776}  />
        </div>
        <div className="drawer rightDrawer" style={style_rightDrawer} onClick={this.toggle_rightDrawer}>
          <div className={"drawerContent" + (this.state.rightDrawer_isOpen ? " drawerOpen": " drawerClosedRight")} onClick={(e) => {e.stopPropagation();}}>
            <RightDrawer appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} closeDrawer={this.toggle_rightDrawer} />
          </div>
        </div>
        <div className="drawer leftDrawer3" style={style_leftDrawer3} onClick={this.toggle_leftDrawer3}>
          <div className={"drawerContent" + (this.state.leftDrawer3_isOpen ? " drawerOpen": " drawerClosedLeft")} onClick={(e) => {e.stopPropagation();}}>
            <LeftDrawer3 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} closeDrawer={this.toggle_leftDrawer3} />
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
