import React, { Component } from 'react';
import './App.css';
import IPhoneMockupComponent from './IPhoneMockupComponent';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class PublishOverlayScreen extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 5) --- 
  
  onClick_state0_elButton736328524 = async () => {
    let newVal = "false";
    this.props.appActions.updateDataSlot('(null)', newVal);
  
  }
  
  
  renderState0() {
    const style_state0_elBackground926451329 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground926451329_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'rgba(0, 0, 0, 0.9000)',
     };
    const style_state0_elText1412605911 = {
      color: '#fffef8',
      textAlign: 'center',
     };
    
    const style_state0_elButton736328524 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="PublishOverlayScreen">
        <div className="background">
          <div className="containerMinHeight state0_elBackground926451329" style={style_state0_elBackground926451329_outer}>
            <div style={style_state0_elBackground926451329} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elText1412605911">
            <div className="headlineFont" style={style_state0_elText1412605911}>
              <div>{this.props.locStrings.helpoverlayscreen2_text_1412605911}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elIPhoneMockupComponent1440207934">
            <div>
              <IPhoneMockupComponent ref={(el)=> this._state0_elIPhoneMockupComponent1440207934 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state0_elButton736328524">
            <Button className="actionFont" style={style_state0_elButton736328524}  color="accent" onClick={this.onClick_state0_elButton736328524} >
              {this.props.locStrings.helpoverlayscreen2_button_736328524}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 5) --- 
  
  onClick_state1_elButton736328524 = async () => {
    let newVal = "false";
    this.props.appActions.updateDataSlot('(null)', newVal);
  
  }
  
  
  renderState1() {
    const style_state1_elBackground926451329 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground926451329_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'rgba(0, 0, 0, 0.9000)',
     };
    const style_state1_elText1412605911 = {
      color: '#fffef8',
      textAlign: 'center',
     };
    
    const style_state1_elButton736328524 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="PublishOverlayScreen">
        <div className="background">
          <div className="containerMinHeight state1_elBackground926451329" style={style_state1_elBackground926451329_outer}>
            <div style={style_state1_elBackground926451329} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elText1412605911">
            <div className="headlineFont" style={style_state1_elText1412605911}>
              <div>{this.props.locStrings.helpoverlayscreen2_text_1412605911}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elIPhoneMockupComponent1440207934">
            <div>
              <IPhoneMockupComponent ref={(el)=> this._state1_elIPhoneMockupComponent1440207934 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state1_elButton736328524">
            <Button className="actionFont" style={style_state1_elButton736328524}  color="accent" onClick={this.onClick_state1_elButton736328524} >
              {this.props.locStrings.helpoverlayscreen2_button_736328524}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 5) --- 
  
  onClick_state2_elButton736328524 = async () => {
    let newVal = "false";
    this.props.appActions.updateDataSlot('(null)', newVal);
  
  }
  
  
  renderState2() {
    const style_state2_elBackground926451329 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elBackground926451329_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'rgba(0, 0, 0, 0.9000)',
     };
    const style_state2_elText1412605911 = {
      color: '#fffef8',
      textAlign: 'center',
     };
    
    const style_state2_elButton736328524 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="PublishOverlayScreen">
        <div className="background">
          <div className="containerMinHeight state2_elBackground926451329" style={style_state2_elBackground926451329_outer}>
            <div style={style_state2_elBackground926451329} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state2_elText1412605911">
            <div className="headlineFont" style={style_state2_elText1412605911}>
              <div>{this.props.locStrings.helpoverlayscreen2_text_1412605911}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state2_elIPhoneMockupComponent1440207934">
            <div>
              <IPhoneMockupComponent ref={(el)=> this._state2_elIPhoneMockupComponent1440207934 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state2_elButton736328524">
            <Button className="actionFont" style={style_state2_elButton736328524}  color="accent" onClick={this.onClick_state2_elButton736328524} >
              {this.props.locStrings.helpoverlayscreen2_button_736328524}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 5) --- 
  
  onClick_state3_elButton736328524 = async () => {
    let newVal = "false";
    this.props.appActions.updateDataSlot('(null)', newVal);
  
  }
  
  
  renderState3() {
    const style_state3_elBackground926451329 = {
      width: '100%',
      height: '100%',
     };
    const style_state3_elBackground926451329_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'rgba(0, 0, 0, 0.9000)',
     };
    const style_state3_elText1412605911 = {
      color: '#fffef8',
      textAlign: 'center',
     };
    
    const style_state3_elButton736328524 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="PublishOverlayScreen">
        <div className="background">
          <div className="containerMinHeight state3_elBackground926451329" style={style_state3_elBackground926451329_outer}>
            <div style={style_state3_elBackground926451329} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state3_elText1412605911">
            <div className="headlineFont" style={style_state3_elText1412605911}>
              <div>{this.props.locStrings.helpoverlayscreen2_text_1412605911}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state3_elIPhoneMockupComponent1440207934">
            <div>
              <IPhoneMockupComponent ref={(el)=> this._state3_elIPhoneMockupComponent1440207934 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state3_elButton736328524">
            <Button className="actionFont" style={style_state3_elButton736328524}  color="accent" onClick={this.onClick_state3_elButton736328524} >
              {this.props.locStrings.helpoverlayscreen2_button_736328524}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 4 (5 of 5) --- 
  
  onClick_state4_elButton736328524 = async () => {
    let newVal = "false";
    this.props.appActions.updateDataSlot('(null)', newVal);
  
  }
  
  
  renderState4() {
    const style_state4_elBackground926451329 = {
      width: '100%',
      height: '100%',
     };
    const style_state4_elBackground926451329_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'rgba(0, 0, 0, 0.9000)',
     };
    const style_state4_elText1412605911 = {
      color: '#fffef8',
      textAlign: 'center',
     };
    
    const style_state4_elButton736328524 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="PublishOverlayScreen">
        <div className="background">
          <div className="containerMinHeight state4_elBackground926451329" style={style_state4_elBackground926451329_outer}>
            <div style={style_state4_elBackground926451329} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state4_elText1412605911">
            <div className="headlineFont" style={style_state4_elText1412605911}>
              <div>{this.props.locStrings.helpoverlayscreen2_text_1412605911}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state4_elIPhoneMockupComponent1440207934">
            <div>
              <IPhoneMockupComponent ref={(el)=> this._state4_elIPhoneMockupComponent1440207934 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state4_elButton736328524">
            <Button className="actionFont" style={style_state4_elButton736328524}  color="accent" onClick={this.onClick_state4_elButton736328524} >
              {this.props.locStrings.helpoverlayscreen2_button_736328524}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
      case 4:
        return this.renderState4();
    }
  }
  
}
