import React, { Component } from 'react';
import './App.css';
import img_state1_elRoundimage from './images/DetailsComponent_state1_elRoundimage_743990.png';
import btn_icon_227933 from './images/btn_icon_227933.png';
import firebase from 'firebase';
import firestore from 'firebase/firestore';

export default class DetailsComponent extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state1_elTextCopy4: (<div> </div>),
      state1_elTextCopy4_plainText: " ",
      state1_elTextCopy5: (<div> </div>),
      state1_elTextCopy5_plainText: " ",
      state1_elText: (<div>Logout</div>),
      state1_elText_plainText: "Logout",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  renderState0() {
    
    return (
      <div className="DetailsComponent">
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elIconButton = async () => {
    // 'Sign out (lock gate)' action.
    this.clearSavedLogin();
    sessionStorage.setItem('loginStatus_login1', 'inactive');
    this.props.appActions.goToScreen('login1');
  
  }
  
  
  clearSavedLogin() {
    // Logout
    firebase.auth().signOut();
  }
  
  
  renderState1() {
    
    const style_state1_elRectangle3 = {
      background: 'rgba(255, 255, 255, 1.000)',
      border: '0.5px solid rgba(203, 203, 203, 0.4500)',
      borderRadius: '17.0px',
      boxShadow: '0.0px 2.0px 3px rgba(0, 0, 0, 0.3000)',
     };
    const style_state1_elRoundimage = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_userPhoto'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const value_textCopy4 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_userName'] : ''));
    
    const style_state1_elTextCopy4 = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
     };
    
    const value_textCopy5 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_userEmail'] : ''));
    
    const style_state1_elTextCopy5 = {
      fontSize: 11.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
     };
    
    const style_state1_elLine = {
      borderTop: '1px solid rgba(98, 98, 98, 0.7000)',
     };
    const style_state1_elText = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    const style_state1_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_227933+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '48.545%',
      backgroundPosition: '50% 0%',
      fontSize: 6.0,
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="DetailsComponent">
        <div className="background">
          <div className="state1_elRectangle3" style={style_state1_elRectangle3} />
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elRoundimage">
            <div style={style_state1_elRoundimage} />
          </div>
          
          <div className="state1_elTextCopy4">
            <div className="baseFont" style={style_state1_elTextCopy4}>
              <div>{value_textCopy4}</div>
            </div>
          </div>
          
          <div className="state1_elTextCopy5">
            <div className="systemFontRegular" style={style_state1_elTextCopy5}>
              <div>{value_textCopy5}</div>
            </div>
          </div>
          
          <div className="state1_elLine">
            <div style={style_state1_elLine} />
          </div>
          
          <div className="state1_elText">
            <div className="baseFont" style={style_state1_elText}>
              <div>{this.state.state1_elText}</div>
            </div>
          </div>
          
          <div className="state1_elIconButton">
            <button className="systemFontRegular" style={style_state1_elIconButton} onClick={this.onClick_state1_elIconButton}  />
          </div>
          
          <div className="state1_elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
