import React, { Component } from 'react';
import './App.css';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class HelpOverlayScreen extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 5) --- 
  
  onClick_state0_elButton1029114731 = async () => {
    let newVal = "false";
    this.props.appActions.updateDataSlot('ds_showHelpOverlay', newVal);
  
  }
  
  
  renderState0() {
    const style_state0_elBackground1032353078 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground1032353078_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'rgba(0, 0, 0, 0.6411)',
     };
    const style_state0_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state0_elButton1029114731 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="HelpOverlayScreen">
        <div className="background">
          <div className="containerMinHeight state0_elBackground1032353078" style={style_state0_elBackground1032353078_outer}>
            <div style={style_state0_elBackground1032353078} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elText">
            <div className="baseFont" style={style_state0_elText}>
              <div>{this.props.locStrings.helpoverlayscreen_text_1726275935}</div>
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <Button className="actionFont state0_elButton1029114731" style={style_state0_elButton1029114731}  color="accent" onClick={this.onClick_state0_elButton1029114731} >
            {this.props.locStrings.helpoverlayscreen_button_1029114731}
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 5) --- 
  
  onClick_state1_elButton1029114731 = async () => {
    let newVal = "false";
    this.props.appActions.updateDataSlot('ds_showHelpOverlay', newVal);
  
  }
  
  
  renderState1() {
    const style_state1_elBackground1032353078 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground1032353078_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'rgba(0, 0, 0, 0.6411)',
     };
    const style_state1_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state1_elButton1029114731 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="HelpOverlayScreen">
        <div className="background">
          <div className="containerMinHeight state1_elBackground1032353078" style={style_state1_elBackground1032353078_outer}>
            <div style={style_state1_elBackground1032353078} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elText">
            <div className="baseFont" style={style_state1_elText}>
              <div>{this.props.locStrings.helpoverlayscreen_text_45911030}</div>
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <Button className="actionFont state1_elButton1029114731" style={style_state1_elButton1029114731}  color="accent" onClick={this.onClick_state1_elButton1029114731} >
            {this.props.locStrings.helpoverlayscreen_button_1029114731}
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 5) --- 
  
  onClick_state2_elButton1029114731 = async () => {
    let newVal = "false";
    this.props.appActions.updateDataSlot('ds_showHelpOverlay', newVal);
  
  }
  
  
  renderState2() {
    const style_state2_elBackground1032353078 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elBackground1032353078_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'rgba(0, 0, 0, 0.6411)',
     };
    const style_state2_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state2_elButton1029114731 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="HelpOverlayScreen">
        <div className="background">
          <div className="containerMinHeight state2_elBackground1032353078" style={style_state2_elBackground1032353078_outer}>
            <div style={style_state2_elBackground1032353078} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state2_elText">
            <div className="baseFont" style={style_state2_elText}>
              <div>{this.props.locStrings.helpoverlayscreen_text_41747492}</div>
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <Button className="actionFont state2_elButton1029114731" style={style_state2_elButton1029114731}  color="accent" onClick={this.onClick_state2_elButton1029114731} >
            {this.props.locStrings.helpoverlayscreen_button_1029114731}
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 5) --- 
  
  onClick_state3_elButton1029114731 = async () => {
    let newVal = "false";
    this.props.appActions.updateDataSlot('ds_showHelpOverlay', newVal);
  
  }
  
  
  renderState3() {
    const style_state3_elBackground1032353078 = {
      width: '100%',
      height: '100%',
     };
    const style_state3_elBackground1032353078_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'rgba(0, 0, 0, 0.6411)',
     };
    const style_state3_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state3_elButton1029114731 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="HelpOverlayScreen">
        <div className="background">
          <div className="containerMinHeight state3_elBackground1032353078" style={style_state3_elBackground1032353078_outer}>
            <div style={style_state3_elBackground1032353078} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state3_elText">
            <div className="baseFont" style={style_state3_elText}>
              <div>{this.props.locStrings.helpoverlayscreen_text_156086196}</div>
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <Button className="actionFont state3_elButton1029114731" style={style_state3_elButton1029114731}  color="accent" onClick={this.onClick_state3_elButton1029114731} >
            {this.props.locStrings.helpoverlayscreen_button_1029114731}
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 4 (5 of 5) --- 
  
  onClick_state4_elButton1029114731 = async () => {
    let newVal = "false";
    this.props.appActions.updateDataSlot('ds_showHelpOverlay', newVal);
  
  }
  
  
  renderState4() {
    const style_state4_elBackground1032353078 = {
      width: '100%',
      height: '100%',
     };
    const style_state4_elBackground1032353078_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'rgba(0, 0, 0, 0.6411)',
     };
    const style_state4_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state4_elButton1029114731 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="HelpOverlayScreen">
        <div className="background">
          <div className="containerMinHeight state4_elBackground1032353078" style={style_state4_elBackground1032353078_outer}>
            <div style={style_state4_elBackground1032353078} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state4_elText">
            <div className="baseFont" style={style_state4_elText}>
              <div>{this.props.locStrings.helpoverlayscreen_text_1030004518}</div>
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <Button className="actionFont state4_elButton1029114731" style={style_state4_elButton1029114731}  color="accent" onClick={this.onClick_state4_elButton1029114731} >
            {this.props.locStrings.helpoverlayscreen_button_1029114731}
          </Button>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
      case 4:
        return this.renderState4();
    }
  }
  
}
