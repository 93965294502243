import React, { Component } from 'react';
import './App.css';
import img_state0_elImage2371284 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import btn_icon_746338 from './images/btn_icon_746338.png';
import MenuItemCustomizationListItem from './MenuItemCustomizationListItem';
import BottomNavBar from './BottomNavBar';
import img_state0_elImage3265422 from './images/MenuItemsipadlandscape_state0_elImage3444232_444232.png';
import img_state0_elBrandLogoImage161627 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state0_elImage430191 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';
import btn_icon_1043597 from './images/btn_icon_1043597.png';
import img_state1_elImage2371284 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elImage3265422 from './images/MenuItemsipadlandscape_state0_elImage3444232_444232.png';
import img_state1_elBrandLogoImage161627 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elImage430191 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';
import Button from 'muicss/lib/react/button';

export default class MenuItemDetailsipadlandscape extends Component {

  // Properties used by this component:
  // dataSheetRow, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elText850392: (<div>Menu Item</div>),
      state0_elText850392_plainText: "Menu Item",
      state0_elFieldTitle357204_visible: false,
      state0_elIconButton746338_visible: false,
      state0_elTextCopy6500348: (<div>Item Details</div>),
      state0_elTextCopy6500348_plainText: "Item Details",
      state0_elTextCopy481584: (<div>Description</div>),
      state0_elTextCopy481584_plainText: "Description",
      state0_elTextCopy2395158: (<div>Customizations</div>),
      state0_elTextCopy2395158_plainText: "Customizations",
      state0_elText2799442: (<div>Start New Order</div>),
      state0_elText2799442_plainText: "Start New Order",
      state0_elTitleText411525: (<div>Sample Item</div>),
      state0_elTitleText411525_plainText: "Sample Item",
      state0_elPriceText976229: (<div>$5.00</div>),
      state0_elPriceText976229_plainText: "$5.00",
      state1_elText850392: (<div>Menu Item</div>),
      state1_elText850392_plainText: "Menu Item",
      state1_elFieldTitle357204_visible: false,
      state1_elIconButton746338_visible: false,
      state1_elTextCopy6500348: (<div>Item Details</div>),
      state1_elTextCopy6500348_plainText: "Item Details",
      state1_elTextCopy481584: (<div>Description</div>),
      state1_elTextCopy481584_plainText: "Description",
      state1_elTextCopy2395158: (<div>Customizations</div>),
      state1_elTextCopy2395158_plainText: "Customizations",
      state1_elText2799442: (<div>Start New Order</div>),
      state1_elText2799442_plainText: "Start New Order",
      state1_elTitleText411525: (<div>Sample Item</div>),
      state1_elTitleText411525_plainText: "Sample Item",
      state1_elPriceText976229: (<div>$5.00</div>),
      state1_elPriceText976229_plainText: "$5.00",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elText850392 = async () => {
    this.setState({state0_elFieldTitle357204_visible: true});
  
    this.setState({state0_elIconButton746338_visible: true});
  
  }
  
  
  textInputChanged_state0_elFieldTitle357204 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state0_elFieldTitle357204 = () => {
    return this.state.fieldTitle || '';
  }
  
  onClick_state0_elIconButton746338 = async () => {
    this.setState({state0_elFieldTitle357204_visible: false});
  
    this.setState({state0_elIconButton746338_visible: false});
  
  }
  
  
  textAreaChanged_state0_elTextarea827045 = (event) => {
    this.setState({textarea: event.target.value});
  }
  
  getValue_state0_elTextarea827045 = () => {
    return this.state.textarea || '';
  }
  
  
  getValue_state0_elField_isActiveCheckbox914441 = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : (this.props.dataSheetRow ? this.props.dataSheetRow.isActive : '');
  }
  
  checkboxChanged_state0_elField_isActiveCheckbox914441 = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')}, async () => {
      let newVal = "field_isActiveCheckbox";
      this.props.appActions.updateDataSlot('ds_showCustomizations', newVal);
      
    });
  }
  
  
  onClick_state0_elIconButton21043597 = async () => {
    let newVal = "2";
    this.props.appActions.updateDataSlot('ds_mainScreenState', newVal);
  
  }
  
  
  renderState0() {
    // eslint-disable-next-line
    const dataSheet_menuItemCustomizations = this.props.appActions.getDataSheet('menuItemCustomizations');
    
    const style_state0_elRectangle2259512 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    const style_state0_elImage2371284 = {
      backgroundImage: 'url('+img_state0_elImage2371284+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state0_elText850392 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elFieldTitle357204 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const state0_elFieldTitle357204 = this.state.state0_elFieldTitle357204_visible ? (
      <div className="state0_elFieldTitle357204">
        <input className="font-futuraMedium" style={style_state0_elFieldTitle357204} type="text" placeholder="{Customizations}" onChange={this.textInputChanged_state0_elFieldTitle357204} value={this.getValue_state0_elFieldTitle357204()}  />
      </div>
      
     ) : null;
    
    const style_state0_elIconButton746338 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_746338+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const state0_elIconButton746338 = this.state.state0_elIconButton746338_visible ? (
      <div className="state0_elIconButton746338">
        <button className="actionFont" style={style_state0_elIconButton746338} onClick={this.onClick_state0_elIconButton746338}  />
      </div>
      
     ) : null;
    const style_state0_elTextCopy6500348 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state0_elTextCopy481584 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elTextarea827045 = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_state0_elField_isActiveCheckbox914441();
    
    const style_state0_elField_isActiveCheckbox914441 = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy2395158 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(null  /* Warning: React Studio codegen didn't find requested data sheet */.items);
    this._state0_elList726249_items = [];
    
    const style_state0_elList726249 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state0_elButton951361 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
     };
    
    const style_state0_elRectangle3907003 = {
      background: 'rgba(0, 0, 0, 1.000)',
      border: '0.5px solid rgba(255, 41, 0, 0.4500)',
      borderRadius: '40.0px',
     };
    
    const style_state0_elRectangleCopy417370 = {
      background: 'rgba(255, 255, 255, 1.000)',
      border: '0.5px solid rgba(43, 79, 255, 0.4500)',
      borderRadius: '6.0px',
     };
    const style_state0_elText2799442 = {
      fontSize: 15.9,
      color: '#0c81fe',
      textAlign: 'center',
     };
    let transformColorPropValue_titleText = (input) => {
      // This function modifies the value for property 'color'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      return input;
    }
    const style_state0_elTitleText411525 = {
      fontSize: 31.0,
      color: transformColorPropValue_titleText(this.props.dataSheetRow ? this.props.dataSheetRow.titleColor : ''),
      textAlign: 'center',
     };
    const style_state0_elPriceText976229 = {
      color: this.props.dataSheetRow ? this.props.dataSheetRow.textColor : '',
      textAlign: 'center',
     };
    const style_state0_elImage3265422 = {
      backgroundImage: 'url('+img_state0_elImage3265422+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const fillColorValue_card2 = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    const style_state0_elCard2237290 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard2237290_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state0_elBrandLogoImage161627 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow.brandImageURL : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state0_elImage430191 = {
      height: 'auto',
     };
    
    const style_state0_elIconButton21043597 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1043597+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemDetailsipadlandscape">
        <div className="background">
          <div className="state0_elRectangle2259512" style={style_state0_elRectangle2259512} />
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elImage2371284">
            <div style={style_state0_elImage2371284} />
          </div>
          
          <div className="state0_elText850392">
            <div className="font-futuraMedium" style={style_state0_elText850392} onClick={this.onClick_state0_elText850392} >
              <div>{this.state.state0_elText850392}</div>
            </div>
          </div>
          
          { state0_elFieldTitle357204 }
          { state0_elIconButton746338 }
          <div className="state0_elSpacer555740">
            <div />
          </div>
          
          <div className="state0_elTextCopy6500348">
            <div className="font-futuraMedium" style={style_state0_elTextCopy6500348}>
              <div>{this.state.state0_elTextCopy6500348}</div>
            </div>
          </div>
          
          <div className="state0_elTextCopy481584">
            <div className="font-avenirBook" style={style_state0_elTextCopy481584}>
              <div>{this.state.state0_elTextCopy481584}</div>
            </div>
          </div>
          
          <div className="state0_elTextarea827045">
            <textarea className="font-avenirNextRegular" style={style_state0_elTextarea827045}  placeholder="{description}" onChange={this.textAreaChanged_state0_elTextarea827045} value={this.getValue_state0_elTextarea827045()}  />
          </div>
          
          <div className="state0_elField_isActiveCheckbox914441">
            <Checkbox className="font-openSans" style={style_state0_elField_isActiveCheckbox914441}  label="has customizations" checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_state0_elField_isActiveCheckbox914441} />
          </div>
          
          <div className="state0_elTextCopy2395158">
            <div className="font-futuraMedium" style={style_state0_elTextCopy2395158}>
              <div>{this.state.state0_elTextCopy2395158}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elList726249">
            <ul style={style_state0_elList726249}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemCustomizationListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'menuItemCustomizations'} dataSheetRow={row} {...{ 'name': row['name'], 'sort': row['sort'], 'document_key': row['document_key'], 'priceadd': row['priceadd'], }} ref={(el) => {if (el) this._state0_elList726249_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state0_elList726249_endMarker = el} />
            </ul>
          </div>
          
          <div className="state0_elButton951361">
            <Button className="actionFont" style={style_state0_elButton951361}  color="accent" >
              New button
            </Button>
          </div>
          
          <div className="state0_elSpacer2678908">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="state0_elRectangle3907003" style={style_state0_elRectangle3907003} />
          <div className="hasNestedComps state0_elBottomNavBar288502">
            <BottomNavBar ref={(el)=> this._state0_elBottomNavBar288502 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="state0_elRectangleCopy417370" style={style_state0_elRectangleCopy417370} />
          <div className="systemFontBold  state0_elText2799442" style={style_state0_elText2799442}>
            <div>{this.state.state0_elText2799442}</div>
          </div>
          <div className="systemFontBold  state0_elTitleText411525" style={style_state0_elTitleText411525}>
            <div>{this.state.state0_elTitleText411525}</div>
          </div>
          <div className="baseFont state0_elPriceText976229" style={style_state0_elPriceText976229}>
            <div>{this.state.state0_elPriceText976229}</div>
          </div>
          <div className="state0_elImage3265422" style={style_state0_elImage3265422} />
          <div className="state0_elCard2237290" style={style_state0_elCard2237290_outer}>
            <div style={style_state0_elCard2237290} />
          </div>
          
          <div className="state0_elBrandLogoImage161627" style={style_state0_elBrandLogoImage161627} />
          <img className="state0_elImage430191" style={style_state0_elImage430191} src={img_state0_elImage430191} alt=""  />
          <button className="actionFont state0_elIconButton21043597" style={style_state0_elIconButton21043597} onClick={this.onClick_state0_elIconButton21043597}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elText850392 = async () => {
    this.setState({state1_elFieldTitle357204_visible: true});
  
    this.setState({state1_elIconButton746338_visible: true});
  
  }
  
  
  textInputChanged_state1_elFieldTitle357204 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state1_elFieldTitle357204 = () => {
    return this.state.fieldTitle || '';
  }
  
  onClick_state1_elIconButton746338 = async () => {
    this.setState({state1_elFieldTitle357204_visible: false});
  
    this.setState({state1_elIconButton746338_visible: false});
  
  }
  
  
  textAreaChanged_state1_elTextarea827045 = (event) => {
    this.setState({textarea: event.target.value});
  }
  
  getValue_state1_elTextarea827045 = () => {
    return this.state.textarea || '';
  }
  
  
  getValue_state1_elField_isActiveCheckbox914441 = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : (this.props.dataSheetRow ? this.props.dataSheetRow.isActive : '');
  }
  
  checkboxChanged_state1_elField_isActiveCheckbox914441 = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')}, async () => {
      let newVal = "field_isActiveCheckbox";
      this.props.appActions.updateDataSlot('ds_showCustomizations', newVal);
      
    });
  }
  
  
  onClick_state1_elIconButton21043597 = async () => {
    let newVal = "2";
    this.props.appActions.updateDataSlot('ds_mainScreenState', newVal);
  
  }
  
  
  renderState1() {
    // eslint-disable-next-line
    const dataSheet_menuItemCustomizations = this.props.appActions.getDataSheet('menuItemCustomizations');
    
    const style_state1_elRectangle2259512 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    const style_state1_elImage2371284 = {
      backgroundImage: 'url('+img_state1_elImage2371284+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state1_elText850392 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldTitle357204 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const state1_elFieldTitle357204 = this.state.state1_elFieldTitle357204_visible ? (
      <div className="state1_elFieldTitle357204">
        <input className="font-futuraMedium" style={style_state1_elFieldTitle357204} type="text" placeholder="{Customizations}" onChange={this.textInputChanged_state1_elFieldTitle357204} value={this.getValue_state1_elFieldTitle357204()}  />
      </div>
      
     ) : null;
    
    const style_state1_elIconButton746338 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_746338+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const state1_elIconButton746338 = this.state.state1_elIconButton746338_visible ? (
      <div className="state1_elIconButton746338">
        <button className="actionFont" style={style_state1_elIconButton746338} onClick={this.onClick_state1_elIconButton746338}  />
      </div>
      
     ) : null;
    const style_state1_elTextCopy6500348 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state1_elTextCopy481584 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elTextarea827045 = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_state1_elField_isActiveCheckbox914441();
    
    const style_state1_elField_isActiveCheckbox914441 = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elTextCopy2395158 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(null  /* Warning: React Studio codegen didn't find requested data sheet */.items);
    this._state1_elList726249_items = [];
    
    const style_state1_elList726249 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state1_elButton951361 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
     };
    
    const style_state1_elRectangle3907003 = {
      background: 'rgba(0, 0, 0, 1.000)',
      border: '0.5px solid rgba(255, 41, 0, 0.4500)',
      borderRadius: '40.0px',
     };
    
    const style_state1_elRectangleCopy417370 = {
      background: 'rgba(255, 255, 255, 1.000)',
      border: '0.5px solid rgba(43, 79, 255, 0.4500)',
      borderRadius: '6.0px',
     };
    const style_state1_elText2799442 = {
      fontSize: 15.9,
      color: '#0c81fe',
      textAlign: 'center',
     };
    let transformColorPropValue_titleText = (input) => {
      // This function modifies the value for property 'color'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      return input;
    }
    const style_state1_elTitleText411525 = {
      fontSize: 31.0,
      color: transformColorPropValue_titleText(this.props.dataSheetRow ? this.props.dataSheetRow.titleColor : ''),
      textAlign: 'center',
     };
    const style_state1_elPriceText976229 = {
      color: this.props.dataSheetRow ? this.props.dataSheetRow.textColor : '',
      textAlign: 'center',
     };
    const style_state1_elImage3265422 = {
      backgroundImage: 'url('+img_state1_elImage3265422+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const fillColorValue_card2 = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    const style_state1_elCard2237290 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard2237290_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state1_elBrandLogoImage161627 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow.brandImageURL : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state1_elImage430191 = {
      height: 'auto',
     };
    
    const style_state1_elIconButton21043597 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1043597+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemDetailsipadlandscape">
        <div className="background">
          <div className="state1_elRectangle2259512" style={style_state1_elRectangle2259512} />
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elImage2371284">
            <div style={style_state1_elImage2371284} />
          </div>
          
          <div className="state1_elText850392">
            <div className="font-futuraMedium" style={style_state1_elText850392} onClick={this.onClick_state1_elText850392} >
              <div>{this.state.state1_elText850392}</div>
            </div>
          </div>
          
          { state1_elFieldTitle357204 }
          { state1_elIconButton746338 }
          <div className="state1_elSpacer555740">
            <div />
          </div>
          
          <div className="state1_elTextCopy6500348">
            <div className="font-futuraMedium" style={style_state1_elTextCopy6500348}>
              <div>{this.state.state1_elTextCopy6500348}</div>
            </div>
          </div>
          
          <div className="state1_elTextCopy481584">
            <div className="font-avenirBook" style={style_state1_elTextCopy481584}>
              <div>{this.state.state1_elTextCopy481584}</div>
            </div>
          </div>
          
          <div className="state1_elTextarea827045">
            <textarea className="font-avenirNextRegular" style={style_state1_elTextarea827045}  placeholder="{description}" onChange={this.textAreaChanged_state1_elTextarea827045} value={this.getValue_state1_elTextarea827045()}  />
          </div>
          
          <div className="state1_elField_isActiveCheckbox914441">
            <Checkbox className="font-openSans" style={style_state1_elField_isActiveCheckbox914441}  label="has customizations" checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_state1_elField_isActiveCheckbox914441} />
          </div>
          
          <div className="state1_elTextCopy2395158">
            <div className="font-futuraMedium" style={style_state1_elTextCopy2395158}>
              <div>{this.state.state1_elTextCopy2395158}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elList726249">
            <ul style={style_state1_elList726249}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemCustomizationListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'menuItemCustomizations'} dataSheetRow={row} {...{ 'name': row['name'], 'sort': row['sort'], 'document_key': row['document_key'], 'priceadd': row['priceadd'], }} ref={(el) => {if (el) this._state1_elList726249_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state1_elList726249_endMarker = el} />
            </ul>
          </div>
          
          <div className="state1_elButton951361">
            <Button className="actionFont" style={style_state1_elButton951361}  color="accent" >
              New button
            </Button>
          </div>
          
          <div className="state1_elSpacer2678908">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="state1_elRectangle3907003" style={style_state1_elRectangle3907003} />
          <div className="hasNestedComps state1_elBottomNavBar288502">
            <BottomNavBar ref={(el)=> this._state1_elBottomNavBar288502 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="state1_elRectangleCopy417370" style={style_state1_elRectangleCopy417370} />
          <div className="systemFontBold  state1_elText2799442" style={style_state1_elText2799442}>
            <div>{this.state.state1_elText2799442}</div>
          </div>
          <div className="systemFontBold  state1_elTitleText411525" style={style_state1_elTitleText411525}>
            <div>{this.state.state1_elTitleText411525}</div>
          </div>
          <div className="baseFont state1_elPriceText976229" style={style_state1_elPriceText976229}>
            <div>{this.state.state1_elPriceText976229}</div>
          </div>
          <div className="state1_elImage3265422" style={style_state1_elImage3265422} />
          <div className="state1_elCard2237290" style={style_state1_elCard2237290_outer}>
            <div style={style_state1_elCard2237290} />
          </div>
          
          <div className="state1_elBrandLogoImage161627" style={style_state1_elBrandLogoImage161627} />
          <img className="state1_elImage430191" style={style_state1_elImage430191} src={img_state1_elImage430191} alt=""  />
          <button className="actionFont state1_elIconButton21043597" style={style_state1_elIconButton21043597} onClick={this.onClick_state1_elIconButton21043597}  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
