import React, { Component } from 'react';
import './App.css';
import img_state0_elImage2554142 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import btn_icon_285179 from './images/btn_icon_285179.png';
import MenuItemListItem from './MenuItemListItem';
import btn_icon_410226 from './images/btn_icon_410226.png';
import BottomNavBar from './BottomNavBar';
import img_state0_elImage3444232 from './images/MenuItemsipadlandscape_state0_elImage3444232_444232.png';
import img_state0_elBrandLogoImage16461 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state0_elImage160803 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';
import img_state1_elImage2554142 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elImage3444232 from './images/MenuItemsipadlandscape_state0_elImage3444232_444232.png';
import img_state1_elBrandLogoImage16461 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elImage160803 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';
import Button from 'muicss/lib/react/button';

export default class MenuItemsipadlandscape extends Component {

  // Properties used by this component:
  // dataSheetRow, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elText2051734770: (<div>Menu</div>),
      state0_elText2051734770_plainText: "Menu",
      state0_elFieldTitle404102_visible: false,
      state0_elIconButton285179_visible: false,
      state0_elTextCopy6925749: (<div>Menu Details</div>),
      state0_elTextCopy6925749_plainText: "Menu Details",
      state0_elTextCopy1007190: (<div>Description</div>),
      state0_elTextCopy1007190_plainText: "Description",
      state0_elTextCopy3759114: (<div>Menu Items</div>),
      state0_elTextCopy3759114_plainText: "Menu Items",
      state0_elText2604941: (<div>Start New Order</div>),
      state0_elText2604941_plainText: "Start New Order",
      state0_elTitleText175783: (<div>Sample Item</div>),
      state0_elTitleText175783_plainText: "Sample Item",
      state0_elPriceText279027: (<div>$5.00</div>),
      state0_elPriceText279027_plainText: "$5.00",
      state1_elText2051734770: (<div>Menu</div>),
      state1_elText2051734770_plainText: "Menu",
      state1_elFieldTitle404102_visible: false,
      state1_elIconButton285179_visible: false,
      state1_elTextCopy6925749: (<div>Menu Details</div>),
      state1_elTextCopy6925749_plainText: "Menu Details",
      state1_elTextCopy1007190: (<div>Description</div>),
      state1_elTextCopy1007190_plainText: "Description",
      state1_elTextCopy3759114: (<div>Menu Items</div>),
      state1_elTextCopy3759114_plainText: "Menu Items",
      state1_elText2604941: (<div>Start New Order</div>),
      state1_elText2604941_plainText: "Start New Order",
      state1_elTitleText175783: (<div>Sample Item</div>),
      state1_elTitleText175783_plainText: "Sample Item",
      state1_elPriceText279027: (<div>$5.00</div>),
      state1_elPriceText279027_plainText: "$5.00",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elText2051734770 = async () => {
    this.setState({state0_elFieldTitle404102_visible: true});
  
    this.setState({state0_elIconButton285179_visible: true});
  
  }
  
  
  textInputChanged_state0_elFieldTitle404102 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state0_elFieldTitle404102 = () => {
    return this.state.fieldTitle || '';
  }
  
  onClick_state0_elIconButton285179 = async () => {
    this.setState({state0_elIconButton285179_visible: false});
  
    this.setState({state0_elFieldTitle404102_visible: false});
  
  }
  
  
  textAreaChanged_state0_elTextarea475419 = (event) => {
    this.setState({textarea: event.target.value});
  }
  
  getValue_state0_elTextarea475419 = () => {
    return this.state.textarea || '';
  }
  
  
  getValue_state0_elField_isActiveCheckbox212721 = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : (this.props.dataSheetRow ? this.props.dataSheetRow.isActive : '');
  }
  
  checkboxChanged_state0_elField_isActiveCheckbox212721 = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')});
  }
  
  
  onClick_state0_elIconButton2410226 = async () => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_mainScreenState', newVal);
  
  }
  
  
  renderState0() {
    // eslint-disable-next-line
    const dataSheet_menuItems = this.props.appActions.getDataSheet('menuItems');
    
    const style_state0_elRectangle2411669 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    const style_state0_elImage2554142 = {
      backgroundImage: 'url('+img_state0_elImage2554142+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state0_elText2051734770 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elFieldTitle404102 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const state0_elFieldTitle404102 = this.state.state0_elFieldTitle404102_visible ? (
      <div className="state0_elFieldTitle404102">
        <input className="font-futuraMedium" style={style_state0_elFieldTitle404102} type="text" placeholder="{Menu}" onChange={this.textInputChanged_state0_elFieldTitle404102} value={this.getValue_state0_elFieldTitle404102()}  />
      </div>
      
     ) : null;
    
    const style_state0_elIconButton285179 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_285179+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const state0_elIconButton285179 = this.state.state0_elIconButton285179_visible ? (
      <div className="state0_elIconButton285179">
        <button className="actionFont" style={style_state0_elIconButton285179} onClick={this.onClick_state0_elIconButton285179}  />
      </div>
      
     ) : null;
    const style_state0_elTextCopy6925749 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state0_elTextCopy1007190 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elTextarea475419 = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_state0_elField_isActiveCheckbox212721();
    
    const style_state0_elField_isActiveCheckbox212721 = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy3759114 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('menuItems').items);
    this._state0_elList972692_items = [];
    
    const style_state0_elList972692 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state0_elButton351117 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
     };
    
    const style_state0_elIconButton2410226 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_410226+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elRectangle360223 = {
      background: 'rgba(0, 0, 0, 1.000)',
      border: '0.5px solid rgba(255, 41, 0, 0.4500)',
      borderRadius: '40.0px',
     };
    
    const style_state0_elRectangleCopy139241 = {
      background: 'rgba(255, 255, 255, 1.000)',
      border: '0.5px solid rgba(43, 79, 255, 0.4500)',
      borderRadius: '6.0px',
     };
    const style_state0_elText2604941 = {
      fontSize: 15.9,
      color: '#0c81fe',
      textAlign: 'center',
     };
    let transformColorPropValue_titleText = (input) => {
      // This function modifies the value for property 'color'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      return input;
    }
    const style_state0_elTitleText175783 = {
      fontSize: 31.0,
      color: transformColorPropValue_titleText(this.props.dataSheetRow ? this.props.dataSheetRow.titleColor : ''),
      textAlign: 'center',
     };
    const style_state0_elPriceText279027 = {
      color: this.props.dataSheetRow ? this.props.dataSheetRow.textColor : '',
      textAlign: 'center',
     };
    const style_state0_elImage3444232 = {
      backgroundImage: 'url('+img_state0_elImage3444232+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const fillColorValue_card2 = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    const style_state0_elCard2144027 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard2144027_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state0_elBrandLogoImage16461 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow.brandImageURL : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state0_elImage160803 = {
      height: 'auto',
     };
    
    return (
      <div className="MenuItemsipadlandscape">
        <div className="background">
          <div className="state0_elRectangle2411669" style={style_state0_elRectangle2411669} />
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elImage2554142">
            <div style={style_state0_elImage2554142} />
          </div>
          
          <div className="state0_elText2051734770">
            <div className="font-futuraMedium" style={style_state0_elText2051734770} onClick={this.onClick_state0_elText2051734770} >
              <div>{this.state.state0_elText2051734770}</div>
            </div>
          </div>
          
          <div className="state0_elSpacer227482">
            <div />
          </div>
          
          { state0_elFieldTitle404102 }
          { state0_elIconButton285179 }
          <div className="state0_elTextCopy6925749">
            <div className="font-futuraMedium" style={style_state0_elTextCopy6925749}>
              <div>{this.state.state0_elTextCopy6925749}</div>
            </div>
          </div>
          
          <div className="state0_elTextCopy1007190">
            <div className="font-avenirBook" style={style_state0_elTextCopy1007190}>
              <div>{this.state.state0_elTextCopy1007190}</div>
            </div>
          </div>
          
          <div className="state0_elTextarea475419">
            <textarea className="font-avenirNextRegular" style={style_state0_elTextarea475419}  placeholder="{description}" onChange={this.textAreaChanged_state0_elTextarea475419} value={this.getValue_state0_elTextarea475419()}  />
          </div>
          
          <div className="state0_elField_isActiveCheckbox212721">
            <Checkbox className="font-openSans" style={style_state0_elField_isActiveCheckbox212721}  label="Menu is Active" checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_state0_elField_isActiveCheckbox212721} />
          </div>
          
          <div className="state0_elTextCopy3759114">
            <div className="font-futuraMedium" style={style_state0_elTextCopy3759114}>
              <div>{this.state.state0_elTextCopy3759114}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elList972692">
            <ul style={style_state0_elList972692}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'menuItems'} dataSheetRow={row} {...{ 'name': row['name'], 'price': row['price'], 'order': row['order'], 'document_key': row['document_key'], 'imageURL': row['imageURL'], }} ref={(el) => {if (el) this._state0_elList972692_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state0_elList972692_endMarker = el} />
            </ul>
          </div>
          
          <div className="state0_elButton351117">
            <Button className="actionFont" style={style_state0_elButton351117}  color="accent" >
              New button
            </Button>
          </div>
          
          <div className="state0_elSpacer21003063">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elIconButton2410226" style={style_state0_elIconButton2410226} onClick={this.onClick_state0_elIconButton2410226}  />
          <div className="state0_elRectangle360223" style={style_state0_elRectangle360223} />
          <div className="hasNestedComps state0_elBottomNavBar985384">
            <BottomNavBar ref={(el)=> this._state0_elBottomNavBar985384 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="state0_elRectangleCopy139241" style={style_state0_elRectangleCopy139241} />
          <div className="systemFontBold  state0_elText2604941" style={style_state0_elText2604941}>
            <div>{this.state.state0_elText2604941}</div>
          </div>
          <div className="systemFontBold  state0_elTitleText175783" style={style_state0_elTitleText175783}>
            <div>{this.state.state0_elTitleText175783}</div>
          </div>
          <div className="baseFont state0_elPriceText279027" style={style_state0_elPriceText279027}>
            <div>{this.state.state0_elPriceText279027}</div>
          </div>
          <div className="state0_elImage3444232" style={style_state0_elImage3444232} />
          <div className="state0_elCard2144027" style={style_state0_elCard2144027_outer}>
            <div style={style_state0_elCard2144027} />
          </div>
          
          <div className="state0_elBrandLogoImage16461" style={style_state0_elBrandLogoImage16461} />
          <img className="state0_elImage160803" style={style_state0_elImage160803} src={img_state0_elImage160803} alt=""  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elText2051734770 = async () => {
    this.setState({state1_elFieldTitle404102_visible: true});
  
    this.setState({state1_elIconButton285179_visible: true});
  
  }
  
  
  textInputChanged_state1_elFieldTitle404102 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state1_elFieldTitle404102 = () => {
    return this.state.fieldTitle || '';
  }
  
  onClick_state1_elIconButton285179 = async () => {
    this.setState({state1_elIconButton285179_visible: false});
  
    this.setState({state1_elFieldTitle404102_visible: false});
  
  }
  
  
  textAreaChanged_state1_elTextarea475419 = (event) => {
    this.setState({textarea: event.target.value});
  }
  
  getValue_state1_elTextarea475419 = () => {
    return this.state.textarea || '';
  }
  
  
  getValue_state1_elField_isActiveCheckbox212721 = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : (this.props.dataSheetRow ? this.props.dataSheetRow.isActive : '');
  }
  
  checkboxChanged_state1_elField_isActiveCheckbox212721 = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')});
  }
  
  
  onClick_state1_elIconButton2410226 = async () => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_mainScreenState', newVal);
  
  }
  
  
  renderState1() {
    // eslint-disable-next-line
    const dataSheet_menuItems = this.props.appActions.getDataSheet('menuItems');
    
    const style_state1_elRectangle2411669 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    const style_state1_elImage2554142 = {
      backgroundImage: 'url('+img_state1_elImage2554142+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state1_elText2051734770 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldTitle404102 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const state1_elFieldTitle404102 = this.state.state1_elFieldTitle404102_visible ? (
      <div className="state1_elFieldTitle404102">
        <input className="font-futuraMedium" style={style_state1_elFieldTitle404102} type="text" placeholder="{Menu}" onChange={this.textInputChanged_state1_elFieldTitle404102} value={this.getValue_state1_elFieldTitle404102()}  />
      </div>
      
     ) : null;
    
    const style_state1_elIconButton285179 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_285179+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const state1_elIconButton285179 = this.state.state1_elIconButton285179_visible ? (
      <div className="state1_elIconButton285179">
        <button className="actionFont" style={style_state1_elIconButton285179} onClick={this.onClick_state1_elIconButton285179}  />
      </div>
      
     ) : null;
    const style_state1_elTextCopy6925749 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state1_elTextCopy1007190 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elTextarea475419 = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_state1_elField_isActiveCheckbox212721();
    
    const style_state1_elField_isActiveCheckbox212721 = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elTextCopy3759114 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('menuItems').items);
    this._state1_elList972692_items = [];
    
    const style_state1_elList972692 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state1_elButton351117 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
     };
    
    const style_state1_elIconButton2410226 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_410226+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elRectangle360223 = {
      background: 'rgba(0, 0, 0, 1.000)',
      border: '0.5px solid rgba(255, 41, 0, 0.4500)',
      borderRadius: '40.0px',
     };
    
    const style_state1_elRectangleCopy139241 = {
      background: 'rgba(255, 255, 255, 1.000)',
      border: '0.5px solid rgba(43, 79, 255, 0.4500)',
      borderRadius: '6.0px',
     };
    const style_state1_elText2604941 = {
      fontSize: 15.9,
      color: '#0c81fe',
      textAlign: 'center',
     };
    let transformColorPropValue_titleText = (input) => {
      // This function modifies the value for property 'color'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      return input;
    }
    const style_state1_elTitleText175783 = {
      fontSize: 31.0,
      color: transformColorPropValue_titleText(this.props.dataSheetRow ? this.props.dataSheetRow.titleColor : ''),
      textAlign: 'center',
     };
    const style_state1_elPriceText279027 = {
      color: this.props.dataSheetRow ? this.props.dataSheetRow.textColor : '',
      textAlign: 'center',
     };
    const style_state1_elImage3444232 = {
      backgroundImage: 'url('+img_state1_elImage3444232+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const fillColorValue_card2 = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    const style_state1_elCard2144027 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard2144027_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state1_elBrandLogoImage16461 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow.brandImageURL : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state1_elImage160803 = {
      height: 'auto',
     };
    
    return (
      <div className="MenuItemsipadlandscape">
        <div className="background">
          <div className="state1_elRectangle2411669" style={style_state1_elRectangle2411669} />
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elImage2554142">
            <div style={style_state1_elImage2554142} />
          </div>
          
          <div className="state1_elText2051734770">
            <div className="font-futuraMedium" style={style_state1_elText2051734770} onClick={this.onClick_state1_elText2051734770} >
              <div>{this.state.state1_elText2051734770}</div>
            </div>
          </div>
          
          <div className="state1_elSpacer227482">
            <div />
          </div>
          
          { state1_elFieldTitle404102 }
          { state1_elIconButton285179 }
          <div className="state1_elTextCopy6925749">
            <div className="font-futuraMedium" style={style_state1_elTextCopy6925749}>
              <div>{this.state.state1_elTextCopy6925749}</div>
            </div>
          </div>
          
          <div className="state1_elTextCopy1007190">
            <div className="font-avenirBook" style={style_state1_elTextCopy1007190}>
              <div>{this.state.state1_elTextCopy1007190}</div>
            </div>
          </div>
          
          <div className="state1_elTextarea475419">
            <textarea className="font-avenirNextRegular" style={style_state1_elTextarea475419}  placeholder="{description}" onChange={this.textAreaChanged_state1_elTextarea475419} value={this.getValue_state1_elTextarea475419()}  />
          </div>
          
          <div className="state1_elField_isActiveCheckbox212721">
            <Checkbox className="font-openSans" style={style_state1_elField_isActiveCheckbox212721}  label="Menu is Active" checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_state1_elField_isActiveCheckbox212721} />
          </div>
          
          <div className="state1_elTextCopy3759114">
            <div className="font-futuraMedium" style={style_state1_elTextCopy3759114}>
              <div>{this.state.state1_elTextCopy3759114}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elList972692">
            <ul style={style_state1_elList972692}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'menuItems'} dataSheetRow={row} {...{ 'name': row['name'], 'price': row['price'], 'order': row['order'], 'document_key': row['document_key'], 'imageURL': row['imageURL'], }} ref={(el) => {if (el) this._state1_elList972692_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state1_elList972692_endMarker = el} />
            </ul>
          </div>
          
          <div className="state1_elButton351117">
            <Button className="actionFont" style={style_state1_elButton351117}  color="accent" >
              New button
            </Button>
          </div>
          
          <div className="state1_elSpacer21003063">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state1_elIconButton2410226" style={style_state1_elIconButton2410226} onClick={this.onClick_state1_elIconButton2410226}  />
          <div className="state1_elRectangle360223" style={style_state1_elRectangle360223} />
          <div className="hasNestedComps state1_elBottomNavBar985384">
            <BottomNavBar ref={(el)=> this._state1_elBottomNavBar985384 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="state1_elRectangleCopy139241" style={style_state1_elRectangleCopy139241} />
          <div className="systemFontBold  state1_elText2604941" style={style_state1_elText2604941}>
            <div>{this.state.state1_elText2604941}</div>
          </div>
          <div className="systemFontBold  state1_elTitleText175783" style={style_state1_elTitleText175783}>
            <div>{this.state.state1_elTitleText175783}</div>
          </div>
          <div className="baseFont state1_elPriceText279027" style={style_state1_elPriceText279027}>
            <div>{this.state.state1_elPriceText279027}</div>
          </div>
          <div className="state1_elImage3444232" style={style_state1_elImage3444232} />
          <div className="state1_elCard2144027" style={style_state1_elCard2144027_outer}>
            <div style={style_state1_elCard2144027} />
          </div>
          
          <div className="state1_elBrandLogoImage16461" style={style_state1_elBrandLogoImage16461} />
          <img className="state1_elImage160803" style={style_state1_elImage160803} src={img_state1_elImage160803} alt=""  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
