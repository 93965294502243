import React, { Component } from 'react';
import './App.css';
import btn_icon_514825 from './images/btn_icon_514825.png';
import btn_icon_816930 from './images/btn_icon_816930.png';
import btn_icon_222258 from './images/btn_icon_222258.png';
import btn_icon_359848 from './images/btn_icon_359848.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class MenuItemCustomizationAdd extends Component {

  // Properties used by this component:
  // name, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elIconPlus514825 = async () => {
    await this.sendData_state0_elIconPlus514825_to_fd_menuItemCustomizations();
  
    await this.sendData_state0_elIconPlus514825_to_fb_menuItem_options();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      fieldName: "",
    });
  
  }
  
  
  onClick_state0_elIconButtonCopy = async () => {
  
  }
  
  
  textInputChanged_state0_elFieldName = (event) => {
    this.setState({fieldName: event.target.value});
  }
  
  getValue_state0_elFieldName = () => {
    return this.state.fieldName !== undefined ? this.state.fieldName : (this.props.name);
  }
  
  onClick_state0_elButtonAddMenuItem800960172 = async () => {
    await this.sendData_state0_elButtonAddMenuItem800960172_to_fd_menuItemCustomizations();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      fieldName: "",
    });
  
  }
  
  
  sendData_state0_elIconPlus514825_to_fd_menuItemCustomizations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fd_menuItemCustomizations');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
    };
    row = { ...row, 
      order: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      name: this.getValue_state0_elFieldName(),
      description: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      //input.options = [{"name":"select option"}];
      //input.options = [{}];
      //input.options = ['-'];
      
      return input;
      
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fd_menuItemCustomizations', row);
    } else {
      return this.props.appActions.addToDataSheet('fd_menuItemCustomizations', row);
    }
  }
  
  
  sendData_state0_elButtonAddMenuItem800960172_to_fd_menuItemCustomizations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fd_menuItemCustomizations');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
    };
    row = { ...row, 
      name: this.getValue_state0_elFieldName(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      input.isActive = true;
      
      //input.price = Math.round(100*Number((this.state.fieldBasePrice).replace("$", "")));
      input.description = "";
      input.document_key = "";
      //input.document_ref = "";
      
      input.order = currentDate.getTime() / 1000000;
      
      //input.order = this.props.appActions.getDataSheet('fb_menuItemCustomizations').items.length;  //works?
      
      return input;
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fd_menuItemCustomizations', row);
    } else {
      return this.props.appActions.addToDataSheet('fd_menuItemCustomizations', row);
    }
  }
  
  
  sendData_state0_elIconPlus514825_to_fb_menuItem_options = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItem_options');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
    };
    row = { ...row, 
      order: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      name: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      price: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      input.isActive = false;
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItem_options', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItem_options', row);
    }
  }
  
  
  renderState0() {
    const style_state0_elCard650823 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard650823_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state0_elIconPlus514825 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_514825+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_816930+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconButton222258 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_222258+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state0_elFieldName = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state0_elButtonAddMenuItem800960172 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemCustomizationAdd">
        <div className="background">
          <div className="state0_elCard650823" style={style_state0_elCard650823_outer}>
            <div className="cardBg" style={style_state0_elCard650823} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elIconPlus514825" style={style_state0_elIconPlus514825} onClick={this.onClick_state0_elIconPlus514825}  />
          <button className="actionFont state0_elIconButtonCopy" style={style_state0_elIconButtonCopy} onClick={this.onClick_state0_elIconButtonCopy}  />
          <button className="actionFont state0_elIconButton222258" style={style_state0_elIconButton222258} />
          <input className="baseFont state0_elFieldName" style={style_state0_elFieldName} type="text" placeholder={this.props.locStrings.menuitemlistitemadd2_fieldcopy_18299} onChange={this.textInputChanged_state0_elFieldName} value={this.getValue_state0_elFieldName()}  />
          <Button className="actionFont state0_elButtonAddMenuItem800960172" style={style_state0_elButtonAddMenuItem800960172}  color="accent" onClick={this.onClick_state0_elButtonAddMenuItem800960172} >
            {this.props.locStrings.menuitemcustomizationadd_buttonaddmenuitem_800960172}
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elIconPlus514825 = async () => {
    await this.sendData_state1_elIconPlus514825_to_fd_menuItemCustomizations();
  
    await this.sendData_state1_elIconPlus514825_to_fb_menuItem_options();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      fieldName: "",
    });
  
  }
  
  
  onClick_state1_elIconButtonCopy = async () => {
  
  }
  
  
  textInputChanged_state1_elFieldName = (event) => {
    this.setState({fieldName: event.target.value});
  }
  
  getValue_state1_elFieldName = () => {
    return this.state.fieldName !== undefined ? this.state.fieldName : (this.props.name);
  }
  
  onClick_state1_elButtonAddMenuItem800960172 = async () => {
    await this.sendData_state1_elButtonAddMenuItem800960172_to_fd_menuItemCustomizations();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      fieldName: "",
    });
  
  }
  
  
  sendData_state1_elIconPlus514825_to_fd_menuItemCustomizations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fd_menuItemCustomizations');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
    };
    row = { ...row, 
      order: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      name: null /* unable to collect data for 'field name' */,
      description: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      //input.options = [{"name":"select option"}];
      //input.options = [{}];
      //input.options = ['-'];
      
      return input;
      
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fd_menuItemCustomizations', row);
    } else {
      return this.props.appActions.addToDataSheet('fd_menuItemCustomizations', row);
    }
  }
  
  
  sendData_state1_elIconPlus514825_to_fb_menuItem_options = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItem_options');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
    };
    row = { ...row, 
      order: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      name: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
      price: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      input.isActive = false;
      
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItem_options', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItem_options', row);
    }
  }
  
  
  sendData_state1_elButtonAddMenuItem800960172_to_fd_menuItemCustomizations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fd_menuItemCustomizations');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
    };
    row = { ...row, 
      name: null /* unable to collect data for 'field name' */,
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      input.created = currentDate.getTime();
      
      input.isActive = true;
      
      //input.price = Math.round(100*Number((this.state.fieldBasePrice).replace("$", "")));
      input.description = "";
      input.document_key = "";
      //input.document_ref = "";
      
      input.order = currentDate.getTime() / 1000000;
      
      //input.order = this.props.appActions.getDataSheet('fb_menuItemCustomizations').items.length;  //works?
      
      return input;
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fd_menuItemCustomizations', row);
    } else {
      return this.props.appActions.addToDataSheet('fd_menuItemCustomizations', row);
    }
  }
  
  
  renderState1() {
    const style_state1_elCard650823 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard650823_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state1_elIconPlus514825 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_514825+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_359848+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconButton222258 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_222258+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state1_elFieldName = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elButtonAddMenuItem800960172 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemCustomizationAdd">
        <div className="background">
          <div className="state1_elCard650823" style={style_state1_elCard650823_outer}>
            <div className="cardBg" style={style_state1_elCard650823} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state1_elIconPlus514825" style={style_state1_elIconPlus514825} onClick={this.onClick_state1_elIconPlus514825}  />
          <button className="actionFont state1_elIconButtonCopy" style={style_state1_elIconButtonCopy} onClick={this.onClick_state1_elIconButtonCopy}  />
          <button className="actionFont state1_elIconButton222258" style={style_state1_elIconButton222258} />
          <input className="baseFont state1_elFieldName" style={style_state1_elFieldName} type="text" placeholder="{header title}" onChange={this.textInputChanged_state1_elFieldName} value={this.getValue_state1_elFieldName()}  />
          <Button className="actionFont state1_elButtonAddMenuItem800960172" style={style_state1_elButtonAddMenuItem800960172}  color="accent" onClick={this.onClick_state1_elButtonAddMenuItem800960172} >
            {this.props.locStrings.menuitemcustomizationadd_buttonaddmenuitem_800960172}
          </Button>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
