import React, { Component } from 'react';
import './App.css';
import btn_icon_1593025761 from './images/btn_icon_1593025761.png';
import btn_icon_2132145781 from './images/btn_icon_2132145781.png';
import btn_icon_902772916 from './images/btn_icon_902772916.png';
import btn_icon_851035281 from './images/btn_icon_851035281.png';
import btn_icon_1800672884 from './images/btn_icon_1800672884.png';
import btn_icon_485467112 from './images/btn_icon_485467112.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class LocationListItem extends Component {

  // Properties used by this component:
  // name, order, document_key, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elIconButtonCopy21593025761 = async () => {
    // Remove row from connected sheet
    await this.props.appActions.removeFromDataSheet(this.props.dataSheetId, this.props.dataSheetRow);
  
  }
  
  
  onClick_state0_elButton1402916613 = async () => {
    let newVal = this.props.document_key;
    
    let transformValue = (input) => {
      // This function modifies the value written to the target data slot.
      // There is a variable named 'input' that provides the input value.   let newVal = "1";
          //this.props.appActions.updateDataSlot('ds_brandCreateState', newVal);
              
          /*newVal = this.props.document_key;
          this.props.appActions.updateDataSlot('ds_selectedbrandkey', newVal);
              
          newVal = this.props.dataSheetRow.brandImageURL;
          this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', newVal);
          
          newVal = this.props.dataSheetRow.brandBackgroundImageURL;
          this.props.appActions.updateDataSlot('ds_backgroundImagefileStorageLocation', newVal);
          */
      
          newVal = this.props.textName;
          this.props.appActions.updateDataSlot('ds_activeMenuName', newVal);
              
          //newVal = "0";
          //this.props.appActions.updateDataSlot('ds_iphone_mock_state', newVal);
          
      /*
          this.props.appActions.updateDataSlot('ds_brandPrimaryColor', this.props.dataSheetRow.primaryColor);
          this.props.appActions.updateDataSlot('ds_brandSecondaryColor', this.props.dataSheetRow.secondaryColor);
          this.props.appActions.updateDataSlot('ds_brandTitleColor', this.props.dataSheetRow.titleColor);
          this.props.appActions.updateDataSlot('ds_brandTextColor', this.props.dataSheetRow.textColor);
          //this.props.appActions.updateDataSlot('ds_brandInactiveIconColor', this.props.dataSheetRow.titleColor);
          //this.props.appActions.updateDataSlot('ds_brandActiveIconColor', this.props.dataSheetRow.titleColor);
          this.props.appActions.updateDataSlot('ds_brandBottomNavColor', this.props.dataSheetRow.bottonNavColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonColor', this.props.dataSheetRow.orderButtonColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonTextColor', this.props.dataSheetRow.orderButtonTextColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonTextString', this.props.dataSheetRow.orderButtonCopy);
      */
      
      return input;
    }
    newVal = transformValue(newVal);
    
    this.props.appActions.updateDataSlot('ds_selected_location_key', newVal);
  
    newVal = null /* unable to collect data for 'field copy' */;
    this.props.appActions.updateDataSlot('ds_activeLocationName', newVal);
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_currentBrand'], this.props.appActions.serviceOptions_fb_currentBrand, false);
  
    // Go to screen 'Location Details'
    this.props.appActions.goToScreen('locationDetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elTextName = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  renderState0() {
    const style_state0_elCard1107104883 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard1107104883_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state0_elIconButtonCopy21593025761 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1593025761+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elButton1402916613 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_textName = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.name);
    
    const style_state0_elTextName = {
      color: '#eb771d',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconButtonCopy3 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_2132145781+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '86.294%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state0_elIconButtonCopy4 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_902772916+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '74.547%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    return (
      <div className="LocationListItem">
        <div className="background">
          <div className="state0_elCard1107104883" style={style_state0_elCard1107104883_outer}>
            <div className="cardBg" style={style_state0_elCard1107104883} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elIconButtonCopy21593025761" style={style_state0_elIconButtonCopy21593025761} onClick={this.onClick_state0_elIconButtonCopy21593025761}  />
          <Button className="actionFont state0_elButton1402916613" style={style_state0_elButton1402916613}  color="accent" onClick={this.onClick_state0_elButton1402916613} >
            {this.props.locStrings.menulistitem2_button_1402916613}
          </Button>
          <div className="baseFont state0_elTextName" style={style_state0_elTextName} onClick={this.onClick_state0_elTextName} >
            <div>{value_textName}</div>
          </div>
          <button className="actionFont state0_elIconButtonCopy3" style={style_state0_elIconButtonCopy3} />
          <button className="actionFont state0_elIconButtonCopy4" style={style_state0_elIconButtonCopy4} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elIconButtonCopy21593025761 = async () => {
    // Remove row from connected sheet
    await this.props.appActions.removeFromDataSheet(this.props.dataSheetId, this.props.dataSheetRow);
  
  }
  
  
  onClick_state1_elIconButtonCopy = async () => {
    this.setState({visualStateIndexOverride: 0})
  
  }
  
  
  onClick_state1_elButton1402916613 = async () => {
    let newVal = this.props.document_key;
    
    let transformValue = (input) => {
      // This function modifies the value written to the target data slot.
      // There is a variable named 'input' that provides the input value.   let newVal = "1";
          //this.props.appActions.updateDataSlot('ds_brandCreateState', newVal);
              
          /*newVal = this.props.document_key;
          this.props.appActions.updateDataSlot('ds_selectedbrandkey', newVal);
              
          newVal = this.props.dataSheetRow.brandImageURL;
          this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', newVal);
          
          newVal = this.props.dataSheetRow.brandBackgroundImageURL;
          this.props.appActions.updateDataSlot('ds_backgroundImagefileStorageLocation', newVal);
          */
      
          newVal = this.props.textName;
          this.props.appActions.updateDataSlot('ds_activeMenuName', newVal);
              
          //newVal = "0";
          //this.props.appActions.updateDataSlot('ds_iphone_mock_state', newVal);
          
      /*
          this.props.appActions.updateDataSlot('ds_brandPrimaryColor', this.props.dataSheetRow.primaryColor);
          this.props.appActions.updateDataSlot('ds_brandSecondaryColor', this.props.dataSheetRow.secondaryColor);
          this.props.appActions.updateDataSlot('ds_brandTitleColor', this.props.dataSheetRow.titleColor);
          this.props.appActions.updateDataSlot('ds_brandTextColor', this.props.dataSheetRow.textColor);
          //this.props.appActions.updateDataSlot('ds_brandInactiveIconColor', this.props.dataSheetRow.titleColor);
          //this.props.appActions.updateDataSlot('ds_brandActiveIconColor', this.props.dataSheetRow.titleColor);
          this.props.appActions.updateDataSlot('ds_brandBottomNavColor', this.props.dataSheetRow.bottonNavColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonColor', this.props.dataSheetRow.orderButtonColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonTextColor', this.props.dataSheetRow.orderButtonTextColor);
          this.props.appActions.updateDataSlot('ds_brandOrderButtonTextString', this.props.dataSheetRow.orderButtonCopy);
      */
      
      return input;
    }
    newVal = transformValue(newVal);
    
    this.props.appActions.updateDataSlot('ds_selected_location_key', newVal);
  
    newVal = this.getValue_state1_elFieldCopy();
    this.props.appActions.updateDataSlot('ds_activeLocationName', newVal);
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_currentBrand'], this.props.appActions.serviceOptions_fb_currentBrand, false);
  
    // Go to screen 'Location Details'
    this.props.appActions.goToScreen('locationDetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  textInputChanged_state1_elFieldCopy = (event) => {
    this.setState({fieldCopy: event.target.value});
  }
  
  getValue_state1_elFieldCopy = () => {
    return this.state.fieldCopy !== undefined ? this.state.fieldCopy : (this.props.name);
  }
  
  textInputChanged_state1_elField = (event) => {
    this.setState({field: event.target.value});
  }
  
  getValue_state1_elField = () => {
    return this.state.field !== undefined ? this.state.field : (this.props.order);
  }
  
  renderState1() {
    const style_state1_elCard1107104883 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard1107104883_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state1_elIconButtonCopy21593025761 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1593025761+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_851035281+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elButton1402916613 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldCopy = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elField = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconButtonCopy3 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1800672884+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state1_elIconButtonCopy4 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_485467112+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    return (
      <div className="LocationListItem">
        <div className="background">
          <div className="state1_elCard1107104883" style={style_state1_elCard1107104883_outer}>
            <div className="cardBg" style={style_state1_elCard1107104883} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state1_elIconButtonCopy21593025761" style={style_state1_elIconButtonCopy21593025761} onClick={this.onClick_state1_elIconButtonCopy21593025761}  />
          <button className="actionFont state1_elIconButtonCopy" style={style_state1_elIconButtonCopy} onClick={this.onClick_state1_elIconButtonCopy}  />
          <Button className="actionFont state1_elButton1402916613" style={style_state1_elButton1402916613}  color="accent" onClick={this.onClick_state1_elButton1402916613} >
            {this.props.locStrings.menulistitem2_button_1402916613}
          </Button>
          <input className="baseFont state1_elFieldCopy" style={style_state1_elFieldCopy} type="text" placeholder={this.props.locStrings.menulistitem2_fieldcopy_142629576} onChange={this.textInputChanged_state1_elFieldCopy} value={this.getValue_state1_elFieldCopy()}  />
          <input className="baseFont state1_elField" style={style_state1_elField} type="text" placeholder={this.props.locStrings.menulistitem2_field_153915789} onChange={this.textInputChanged_state1_elField} value={this.getValue_state1_elField()}  />
          <button className="actionFont state1_elIconButtonCopy3" style={style_state1_elIconButtonCopy3} />
          <button className="actionFont state1_elIconButtonCopy4" style={style_state1_elIconButtonCopy4} />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
