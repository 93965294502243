// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_fb_my_locations extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['geohash'] = "9q8yy";
    item['phone'] = "";
    item['updated'] = "1584034824453";
    item['geoLocation'] = {"_long":-121.358295,"_lat":44.055896};
    item['geohash_1k'] = "9rcdwk";
    item['document_path'] = "locations/2LIAsvgaDPSM8raIfi6V";
    item['associatedBrand'] = "VRTrgI2EypIycMkj9MC9";
    item['geohash_5k'] = "9rcdw";
    item['hours'] = {"friday":"","thursday_close_min":0,"saturdayy_close_min":null,"tuesday":"12","sunday_open_hour":12,"monday":{"close_min":0,"close_hour":0,"open_hour":"9","open_min":0},"saturday":"","saturday_close_hour":null,"sunday_open_min":15,"sunday":"","tuesday_open":600,"saturday_open_min":null,"monday_close_min":0,"sunday_open":-1,"thursday_close_hour":15,"monday_close":1100,"tuesday_close_hour":9,"friday_open_hour":12,"tuesday_close":900,"friday_close_hour":15,"monday_open":900,"sunday_close":null,"thursday":"","tuesday_close_min":0,"thursday_open_min":0,"wednesday_close_min":0,"saturday_open_hour":-1,"tuesday_open_min":0,"sunday_close_min":45,"wednesday":"","friday_open_min":0,"wednesday_close_hour":12,"sunday_close_hour":15,"saturday_close":null,"wednesday_open_hour":11,"thursday_open_hour":11,"tuesday_open_hour":6,"friday_close":1500,"friday_close_min":0,"monday_open_hour":9,"wednesday_close":1200,"wednesday_open":1100,"monday_close_hour":11,"thursday_close":1500,"friday_open":1200,"saturday_open":null,"thursday_open":1100,"wednesday_open_min":0,"monday_open_min":0};
    item['geohash_max'] = "9rcdwkj";
    item['address'] = {"zipCode":"","country":"","streetNumber":"","city":"","streetName":"","state":""};
    item['locationImageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2FVRTrgI2EypIycMkj9MC9?alt=media";
    item['document_key'] = "2LIAsvgaDPSM8raIfi6V";
    item['created'] = "1558049743237";
    item['geohash_10k'] = "9rcd";
    item['name'] = "10 Barrel West Bend";
    item['isActive'] = "0";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['geohash'] = "9rcdw";
    item['phone'] = "";
    item['updated'] = "1584034827297";
    item['geoLocation'] = {"_long":-121.333253,"_lat":44.043058};
    item['document_path'] = "locations/hiSUS9sSOnB1yBghXtTS";
    item['hours'] = {"monday":"","friday":"","sunday":"","monday_close":null,"tuesday":"","thursday":"","monday_open":null,"saturday":"","wednesday":""};
    item['associatedBrand'] = "VRTrgI2EypIycMkj9MC9";
    item['address'] = {"zipCode":"","country":"","streetNumber":"","city":"","streetName":"","state":""};
    item['locationImageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2FVRTrgI2EypIycMkj9MC9?alt=media";
    item['document_key'] = "hiSUS9sSOnB1yBghXtTS";
    item['created'] = "1557515712067";
    item['name'] = "10 Barrel East Bend";
    item['isActive'] = "0";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['geohash'] = "9q8yy";
    item['phone'] = "";
    item['updated'] = "1584034829833";
    item['geoLocation'] = {"_long":-116.202282,"_lat":43.617921};
    item['geohash_1k'] = "9rv25u";
    item['geohash_5k'] = "9rv25";
    item['associatedBrand'] = "VRTrgI2EypIycMkj9MC9";
    item['hours'] = {"monday":"","friday":"","sunday":"","tuesday":"","thursday":"","saturday":"","wednesday":""};
    item['geohash_max'] = "9rv25u2";
    item['address'] = {"zipCode":"83702","country":"","streetNumber":"825 W Bannock St","city":"Boise","streetName":"","state":"Idaho"};
    item['locationImageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2FVRTrgI2EypIycMkj9MC9?alt=media";
    item['document_key'] = "iueHMAJaLq8rSWsmVcuT";
    item['created'] = "1568064803917";
    item['geohash_10k'] = "9rv2";
    item['name'] = "10 Barrel Brewing Boise";
    item['isActive'] = "0";
    item.key = key++;
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    if (item.document_key && item.document_key.length > 0) {
      let parsedItem = (({ document_key, ...item }) => (item))( item );
      addItemPromise = collection.doc(item.document_key).set(parsedItem);
    } else {
      addItemPromise = collection.add(item);
    }
    addItemPromise
      .then((docRef) => {
        var addedKey="";
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          addedKey=docRef.id;
          item.document_key = docRef.id;
          item.document_path = docRef.path;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
          addedKey=item.document_key;
        }   
        if (this.onLastAddedDocumentKeyChanged != null) {
          this.onLastAddedDocumentKeyChanged(addedKey);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
  
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
      
    docRef.set((({ key, document_ref, document_key, ...item }) => (item))( item ), {merge: true})
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  

}
