import React, { Component } from 'react';
import './App.css';
import btn_icon_1228916491 from './images/btn_icon_1228916491.png';

export default class LeftDrawer3 extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elIconButton = async () => {
    this.props.closeDrawer();
  
  }
  
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#626262',
     };
    
    // Embedded HTML content for element 'embed'
    const htmlContent_embed = "\n<style>\n#outerdiv {\nwidth: 250px; position: relative;\n}\n#innerdiv {\nposition: absolute; \nleft: -209px; \ntop: -450px;\nclip:rect(510px 1000px 1660px 0px);\n}\n</style>\n<div id=\"outerdiv\">\n<div id=\"innerdiv\">      \n<iframe width=\"600\" height=\"1660\" src=\"https://pinetools.com/image-color-picker\" scrolling=\"no\" frameborder=\"10\"></iframe>\n</div>\n</div>";
    
    const style_elEmbed = {
      pointerEvents: 'auto',
     };
    
    const style_elRectangle = {
      background: 'rgba(97, 98, 97, 1.000)',
     };
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1228916491+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '62.963%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elRectangle2 = {
      background: 'rgba(97, 98, 97, 1.000)',
     };
    const style_elTextBlock = {
      color: '#eb771d',
      textAlign: 'center',
     };
    
    return (
      <div className="LeftDrawer3">
        <div className="background">
          <div className="elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="foreground">
          <div className="embeddedContent elEmbed" style={style_elEmbed}>
            <div dangerouslySetInnerHTML={{__html: htmlContent_embed}}></div>
          </div>
          <div className="elRectangle" style={style_elRectangle} />
          <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
          <div className="elRectangle2" style={style_elRectangle2} />
          <div className="baseFont elTextBlock" style={style_elTextBlock}>
            <div>{this.props.locStrings.leftdrawer3_textblock_368288034}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
