// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_fb_brands extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['stripePublicKey'] = "pk_test_cX8MCHMWe7tB2RzMYq2S3Wse00LF8UlcW6";
    item['stripeProcessor'] = "https://us-central1-fir-stripe-sandbox.cloudfunctions.net/payWithStripeConnect";
    item['creator'] = "QOiyK8ZhfrUKYf6R0a7IxJp2OWq2";
    item['document_ref'] = "";
    item['onboarding_screen3_line2'] = "Lets get started";
    item['onboarding_screen1_title'] = "Welcome!";
    item['onboarding_screen1_line1'] = "This is default text";
    item['onboarding_screen2_title'] = "Screen Two";
    item['orderButtonCopy'] = "Start New Order";
    item['associatedParent'] = "388PZzi08HGOItu7fPXC";
    item['onboarding_screen2_line1'] = "Desrcribe your app here";
    item['currentPickupETA'] = "30";
    item['serviceChargeBasisPoints'] = "0";
    item['onboarding_screen3_title'] = "Screen Three";
    item['updated'] = "1609136986709";
    item['onboarding_screen3_line1'] = "Without further ado";
    item['brandDefaultTip'] = "15";
    item['stripeAcctID'] = "acct_1FvYf5IfJ0cqiU5E";
    item['brandImageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['created'] = "1609136986709";
    item['description'] = "";
    item['contactEmail'] = "1@1.com";
    item['primaryColor'] = "#777777";
    item['brandBackgroundImageURL'] = "";
    item['bottonNavColor'] = "#EEEEEE";
    item['titleColor'] = "#000000";
    item['preference_sound_orderup'] = "0";
    item['name'] = "My new Brand";
    item['isActive'] = "1";
    item['bottomNavIconActiveColor'] = "#444444";
    item['preference_printer'] = "0";
    item['secondaryColor'] = "#777777";
    item['textColor'] = "#000000";
    item['document_key'] = "388PZzi08HGOItu7fPXC";
    item['brandOfferingOther'] = "";
    item['onboarding_screen0_color'] = "#ffffff";
    item['onboarding_screen1_line3'] = "screen of your new app";
    item['orderButtonColor'] = "#EEEEEE";
    item['onboarding_screen1_color'] = "#777777";
    item['onboarding_screen2_line3'] = "what to expect";
    item['document_path'] = "brands/388PZzi08HGOItu7fPXC";
    item['maintenanceInProgress'] = "";
    item['orderButtonTextColor'] = "#000000";
    item['onboarding_screen3_line3'] = "Cheers!";
    item['brandOfferingType'] = "";
    item['bottomNavIconColor'] = "#777777";
    item['onboarding_screen1_line2'] = "which will show on the onboarding";
    item['logoImageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['onboarding_screen3_color'] = "#dddddd";
    item['onboarding_screen2_color'] = "#aaaaaaa";
    item['onboarding_screen2_line2'] = "so that customers know";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['dummy'] = "dummy";
    item['document_key'] = "6PzYDu2qtpzjAsI2J25I";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['onboarding_screen2_color'] = "#658463";
    item['key'] = "0";
    item['id'] = "mNEdFor3G16WDziEXwlV";
    item['titleColor'] = "#000000";
    item['onboarding_screen3_line3'] = "We will have it waiting for you.";
    item['stripePublicKey'] = "pk_test_cX8MCHMWe7tB2RzMYq2S3Wse00LF8UlcW6";
    item['associatedMenuItems'] = [];
    item['onboarding_screen1_line1'] = "Welcome to Brother Jon's.";
    item['created'] = "1609285304281";
    item['orderButtonCopy'] = "I'll take it!";
    item['document_path'] = "brands/mNEdFor3G16WDziEXwlV";
    item['preference_printer'] = "0";
    item['onboarding_screen1_line2'] = "Thanks for stopping by.";
    item['document_ref'] = "";
    item['onboarding_screen0_color'] = "#ffffff";
    item['onboarding_screen1_line3'] = "We hope you enjoy your meal";
    item['iconHomeURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:658463,e_colorize:100,f_png,h_96,w_96/v1589822630/yesIcons/heartIcon.svg";
    item['description'] = "Established in 2009, Brother Jon’s is a restaurant and pub located on the west side of Bend, Oregon.";
    item['associatedParent'] = "mNEdFor3G16WDziEXwlV";
    item['primaryColor'] = "#658463";
    item['orderButtonTextColor'] = "#EDDDC4";
    item['brandDefaultTip'] = "15";
    item['bottonNavColor'] = "#EDDDC4";
    item['logoImageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['brandOfferingType'] = "beerPub";
    item['onboarding_screen2_title'] = "Reach out!";
    item['brandImageURL'] = "https://firebasestorage.googleapis.com/v0/b/yesoms-production01.appspot.com/o/images%2Fbrands%2Fc5086ed4-8769-48c0-8222-9cf8603f1dcd.png?alt=media&token=b52e2ce7-30c9-4173-bf02-a7a9af6b14a7";
    item['maintenanceInProgress'] = "";
    item['onboarding_screen3_color'] = "#EDDDC4";
    item['iconMoreURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:9C9F84,e_colorize:100,f_png,h_96,w_96/v1589862715/yesIcons/more-round.svg";
    item['brandBackgroundImageURL'] = "";
    item['onboarding_screen1_imageURL'] = "https://firebasestorage.googleapis.com/v0/b/yesoms-production01.appspot.com/o/images%2Fbrands%2Fb55c3c0d-271e-4dc5-b8cf-4dfe84ded421.png?alt=media&token=dcab2086-9f44-4703-93c8-1522a9387888";
    item['stripeProcessor'] = "https://us-central1-fir-stripe-sandbox.cloudfunctions.net/payWithStripeConnect";
    item['name'] = "BroJo's";
    item['onboarding_screen2_line1'] = "Please let us know if there ";
    item['serviceChargeBasisPoints'] = "0";
    item['iconMenuURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:9C9F84,e_colorize:100,f_png,h_96,w_96/v1589825284/yesIcons/menu-boxedIcon.svg";
    item['onboarding_screen2_line2'] = "is anything you may need";
    item['bottomNavIconActiveColor'] = "#658463";
    item['onboarding_screen1_color'] = "#EDDDC4";
    item['iconProfileURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:9C9F84,e_colorize:100,f_png,h_96,w_96/v1589825354/yesIcons/profileIcon.svg";
    item['bottomNavIconColor'] = "#9C9F84";
    item['onboarding_screen2_line3'] = "We are happy to help.";
    item['preference_sound_orderup'] = "0";
    item['brandOfferingOther'] = "";
    item['onboarding_screen3_title'] = "Let's get started.";
    item['stripeAcctID'] = "acct_1FvYf5IfJ0cqiU5E";
    item['document_key'] = "mNEdFor3G16WDziEXwlV";
    item['textColor'] = "#EDDDC4";
    item['currentPickupETA'] = "30";
    item['creator'] = "JEzIkE1AjDbJxaXx7GCGxuc3Nfg2";
    item['iconOrderURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:9C9F84,e_colorize:100,f_png,h_96,w_96/v1589825246/yesIcons/menu-roundIcon.svg";
    item['updated'] = "1609285304281";
    item['orderButtonColor'] = "#9C9F84";
    item['contactEmail'] = "brojokevin@gmail.com";
    item['onboarding_screen3_line1'] = "Order head of time..";
    item['secondaryColor'] = "#658463";
    item['onboarding_screen1_title'] = "Welcome!";
    item['onboarding_screen3_line2'] = "..and swing on by.";
    item['isActive'] = "1";
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    if (item.document_key && item.document_key.length > 0) {
      let parsedItem = (({ document_key, ...item }) => (item))( item );
      addItemPromise = collection.doc(item.document_key).set(parsedItem);
    } else {
      addItemPromise = collection.add(item);
    }
    addItemPromise
      .then((docRef) => {
        var addedKey="";
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          addedKey=docRef.id;
          item.document_key = docRef.id;
          item.document_path = docRef.path;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
          addedKey=item.document_key;
        }   
        if (this.onLastAddedDocumentKeyChanged != null) {
          this.onLastAddedDocumentKeyChanged(addedKey);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
  
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
      
    docRef.set((({ key, document_ref, document_key, ...item }) => (item))( item ), {merge: true})
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  

}
