import React, { Component } from 'react';
import './App.css';
import img_state0_elImage2764093 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import BrandButton2 from './BrandButton2';
import BrandButton from './BrandButton';
import btn_icon_478541 from './images/btn_icon_478541.png';
import MainScreenIntructionText from './MainScreenIntructionText';
import img_state0_elImage3 from './images/Ipadlandscape_state0_elImage3_1566452180.png';
import img_state0_elImage803127 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';
import AppIcon from './AppIcon';
import img_state1_elImage2764093 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elImage3 from './images/Ipadlandscape_state1_elImage3_519184.png';
import img_state1_elImage803127 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';

export default class Ipadlandscape extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elText446356: (<div>My Brands</div>),
      state0_elText446356_plainText: "My Brands",
      state0_elFieldTitle366937_visible: false,
      state0_elIconButton478541_visible: false,
      state1_elText446356: (<div>My Brands</div>),
      state1_elText446356_plainText: "My Brands",
      state1_elFieldTitle366937_visible: false,
      state1_elIconButton478541_visible: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elText446356 = async () => {
    this.setState({state0_elFieldTitle366937_visible: true});
  
    this.setState({state0_elIconButton478541_visible: true});
  
  }
  
  
  
  textInputChanged_state0_elFieldTitle366937 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state0_elFieldTitle366937 = () => {
    return this.state.fieldTitle || '';
  }
  
  onClick_state0_elIconButton478541 = async () => {
    this.setState({state0_elFieldTitle366937_visible: false});
  
    this.setState({state0_elIconButton478541_visible: false});
  
  }
  
  
  onClick_state0_elTextCopy320301766 = async () => {
  
  
  }
  
  
  
  
  renderState0() {
    // eslint-disable-next-line
    const dataSheet_mainScreenInstructions = this.props.appActions.getDataSheet('mainScreenInstructions');
    // eslint-disable-next-line
    const dataSheet_fb_myBrands = this.props.appActions.getDataSheet('fb_myBrands');
    
    const style_state0_elRectangle2785414 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_state0_elRectangle254210 = {
      background: 'rgba(0, 0, 0, 1.000)',
     };
    const style_state0_elRectangle254210_outer = {
      display: 'none',
     };
    const style_state0_elImage2764093 = {
      backgroundImage: 'url('+img_state0_elImage2764093+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state0_elText446356 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    listComps_list['_tail'] = <BrandButton2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    items_list = items_list.concat(this.props.appActions.getDataSheet('fb_myBrands').items);
    items_list.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._state0_elList104522_items = [];
    
    const style_state0_elList104522 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state0_elFieldTitle366937 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const state0_elFieldTitle366937 = this.state.state0_elFieldTitle366937_visible ? (
      <div className="state0_elFieldTitle366937">
        <input className="font-futuraMedium" style={style_state0_elFieldTitle366937} type="text" placeholder="{Home}" onChange={this.textInputChanged_state0_elFieldTitle366937} value={this.getValue_state0_elFieldTitle366937()}  />
      </div>
      
     ) : null;
    
    const style_state0_elIconButton478541 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_478541+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const state0_elIconButton478541 = this.state.state0_elIconButton478541_visible ? (
      <div className="state0_elIconButton478541">
        <button className="actionFont" style={style_state0_elIconButton478541} onClick={this.onClick_state0_elIconButton478541}  />
      </div>
      
     ) : null;
    const style_state0_elTextCopy320301766 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list copy 2'.
    let items_listCopy2 = [];
    let listComps_listCopy2 = {};
    items_listCopy2 = items_listCopy2.concat(this.props.appActions.getDataSheet('mainScreenInstructions').items);
    this._state0_elListCopy21872656635_items = [];
    
    const style_state0_elListCopy21872656635 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state0_elRectangle3850011 = {
      background: 'rgba(0, 0, 0, 1.000)',
      border: '0.5px solid rgba(255, 41, 0, 0.4500)',
      borderRadius: '40.0px',
     };
    const style_state0_elImage3 = {
      height: 'auto',
     };
    const style_state0_elImage803127 = {
      height: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list copy'.
    let items_listCopy = [];
    let listComps_listCopy = {};
    items_listCopy = items_listCopy.concat(this.props.appActions.getDataSheet('fb_myBrands').items);
    this._state0_elListCopy_items = [];
    
    
    return (
      <div className="Ipadlandscape">
        <div className="background">
          <div className="state0_elRectangle2785414" style={style_state0_elRectangle2785414} />
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elRectangle254210" style={style_state0_elRectangle254210_outer}>
            <div style={style_state0_elRectangle254210} />
          </div>
          
          <div className="state0_elImage2764093">
            <div style={style_state0_elImage2764093} />
          </div>
          
          <div className="state0_elText446356">
            <div className="font-futuraMedium" style={style_state0_elText446356} onClick={this.onClick_state0_elText446356} >
              <div>{this.state.state0_elText446356}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elList104522">
            <div style={style_state0_elList104522}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <BrandButton appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_myBrands'} dataSheetRow={row} {...{ 'name': row['name'], 'brandImageURL': row['brandImageURL'], 'document_key': row['document_key'], }} ref={(el) => {if (el) this._state0_elList104522_items.push(el)}} />;
                return (<div key={row.key}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._state0_elList104522_endMarker = el} />
            </div>
          </div>
          
          <div className="state0_elSpacer618747">
            <div />
          </div>
          
          { state0_elFieldTitle366937 }
          { state0_elIconButton478541 }
          <div className="state0_elSpacer257846">
            <div />
          </div>
          
          <div className="state0_elTextCopy320301766">
            <div className="font-futuraMedium" style={style_state0_elTextCopy320301766} onClick={this.onClick_state0_elTextCopy320301766} >
              <div>{this.props.locStrings.ipadlandscape_textcopy_320301766}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elListCopy21872656635">
            <div style={style_state0_elListCopy21872656635}>
              {items_listCopy2.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_listCopy2[row._componentId]
                    : <MainScreenIntructionText appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'mainScreenInstructions'} dataSheetRow={row} {...{ 'Number': row['Number'], 'Title': row['Title'], 'Text': row['Text'], }} ref={(el) => {if (el) this._state0_elListCopy21872656635_items.push(el)}} />;
                return (<div key={row.key}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._state0_elListCopy21872656635_endMarker = el} />
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <div className="state0_elRectangle3850011" style={style_state0_elRectangle3850011} />
          <img className="state0_elImage3" style={style_state0_elImage3} src={img_state0_elImage3} alt=""  />
          <img className="state0_elImage803127" style={style_state0_elImage803127} src={img_state0_elImage803127} alt=""  />
          <div className="hasNestedComps state0_elListCopy">
            <div>
              {items_listCopy.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_listCopy[row._componentId]
                    : <AppIcon appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_myBrands'} dataSheetRow={row} {...{ 'name': row['name'], 'brandImageURL': row['brandImageURL'], 'document_key': row['document_key'], 'primaryColor': row['primaryColor'], }} ref={(el) => {if (el) this._state0_elListCopy_items.push(el)}} />;
                return (<div key={row.key}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._state0_elListCopy_endMarker = el} />
            </div>
          </div>
          
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elText446356 = async () => {
    this.setState({state1_elFieldTitle366937_visible: true});
  
    this.setState({state1_elIconButton478541_visible: true});
  
  }
  
  
  
  textInputChanged_state1_elFieldTitle366937 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state1_elFieldTitle366937 = () => {
    return this.state.fieldTitle || '';
  }
  
  onClick_state1_elIconButton478541 = async () => {
    this.setState({state1_elFieldTitle366937_visible: false});
  
    this.setState({state1_elIconButton478541_visible: false});
  
  }
  
  
  onClick_state1_elTextCopy320301766 = async () => {
  
  
  }
  
  
  
  
  renderState1() {
    // eslint-disable-next-line
    const dataSheet_mainScreenInstructions = this.props.appActions.getDataSheet('mainScreenInstructions');
    // eslint-disable-next-line
    const dataSheet_fb_myBrands = this.props.appActions.getDataSheet('fb_myBrands');
    
    const style_state1_elRectangle2785414 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_state1_elRectangle254210 = {
      background: 'rgba(0, 0, 0, 1.000)',
     };
    const style_state1_elRectangle254210_outer = {
      display: 'none',
     };
    const style_state1_elImage2764093 = {
      backgroundImage: 'url('+img_state1_elImage2764093+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state1_elText446356 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    listComps_list['_tail'] = <BrandButton2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    items_list = items_list.concat(this.props.appActions.getDataSheet('fb_myBrands').items);
    items_list.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._state1_elList104522_items = [];
    
    const style_state1_elList104522 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state1_elFieldTitle366937 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const state1_elFieldTitle366937 = this.state.state1_elFieldTitle366937_visible ? (
      <div className="state1_elFieldTitle366937">
        <input className="font-futuraMedium" style={style_state1_elFieldTitle366937} type="text" placeholder="{Home}" onChange={this.textInputChanged_state1_elFieldTitle366937} value={this.getValue_state1_elFieldTitle366937()}  />
      </div>
      
     ) : null;
    
    const style_state1_elIconButton478541 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_478541+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const state1_elIconButton478541 = this.state.state1_elIconButton478541_visible ? (
      <div className="state1_elIconButton478541">
        <button className="actionFont" style={style_state1_elIconButton478541} onClick={this.onClick_state1_elIconButton478541}  />
      </div>
      
     ) : null;
    const style_state1_elTextCopy320301766 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list copy 2'.
    let items_listCopy2 = [];
    let listComps_listCopy2 = {};
    items_listCopy2 = items_listCopy2.concat(this.props.appActions.getDataSheet('mainScreenInstructions').items);
    this._state1_elListCopy21872656635_items = [];
    
    const style_state1_elListCopy21872656635 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state1_elRectangle3850011 = {
      background: 'rgba(0, 0, 0, 1.000)',
      border: '0.5px solid rgba(255, 41, 0, 0.4500)',
      borderRadius: '40.0px',
     };
    const style_state1_elImage3 = {
      height: 'auto',
     };
    const style_state1_elImage803127 = {
      height: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list copy'.
    let items_listCopy = [];
    let listComps_listCopy = {};
    items_listCopy = items_listCopy.concat(this.props.appActions.getDataSheet('fb_myBrands').items);
    this._state1_elListCopy_items = [];
    
    
    return (
      <div className="Ipadlandscape">
        <div className="background">
          <div className="state1_elRectangle2785414" style={style_state1_elRectangle2785414} />
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elRectangle254210" style={style_state1_elRectangle254210_outer}>
            <div style={style_state1_elRectangle254210} />
          </div>
          
          <div className="state1_elImage2764093">
            <div style={style_state1_elImage2764093} />
          </div>
          
          <div className="state1_elText446356">
            <div className="font-futuraMedium" style={style_state1_elText446356} onClick={this.onClick_state1_elText446356} >
              <div>{this.state.state1_elText446356}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elList104522">
            <div style={style_state1_elList104522}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <BrandButton appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_myBrands'} dataSheetRow={row} {...{ 'name': row['name'], 'brandImageURL': row['brandImageURL'], 'document_key': row['document_key'], }} ref={(el) => {if (el) this._state1_elList104522_items.push(el)}} />;
                return (<div key={row.key}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._state1_elList104522_endMarker = el} />
            </div>
          </div>
          
          <div className="state1_elSpacer618747">
            <div />
          </div>
          
          { state1_elFieldTitle366937 }
          { state1_elIconButton478541 }
          <div className="state1_elSpacer257846">
            <div />
          </div>
          
          <div className="state1_elTextCopy320301766">
            <div className="font-futuraMedium" style={style_state1_elTextCopy320301766} onClick={this.onClick_state1_elTextCopy320301766} >
              <div>{this.props.locStrings.ipadlandscape_textcopy_320301766}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elListCopy21872656635">
            <div style={style_state1_elListCopy21872656635}>
              {items_listCopy2.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_listCopy2[row._componentId]
                    : <MainScreenIntructionText appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'mainScreenInstructions'} dataSheetRow={row} {...{ 'Number': row['Number'], 'Title': row['Title'], 'Text': row['Text'], }} ref={(el) => {if (el) this._state1_elListCopy21872656635_items.push(el)}} />;
                return (<div key={row.key}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._state1_elListCopy21872656635_endMarker = el} />
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <div className="state1_elRectangle3850011" style={style_state1_elRectangle3850011} />
          <img className="state1_elImage3" style={style_state1_elImage3} src={img_state1_elImage3} alt=""  />
          <img className="state1_elImage803127" style={style_state1_elImage803127} src={img_state1_elImage803127} alt=""  />
          <div className="hasNestedComps state1_elListCopy">
            <div>
              {items_listCopy.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_listCopy[row._componentId]
                    : <AppIcon appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_myBrands'} dataSheetRow={row} {...{ 'name': row['name'], 'brandImageURL': row['brandImageURL'], 'document_key': row['document_key'], 'primaryColor': row['primaryColor'], }} ref={(el) => {if (el) this._state1_elListCopy_items.push(el)}} />;
                return (<div key={row.key}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._state1_elListCopy_endMarker = el} />
            </div>
          </div>
          
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
