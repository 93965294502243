import React, { Component } from 'react';
import './App.css';

export default class SetupVsSimulator extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    
    const style_elRectangle = {
      background: 'rgba(252, 254, 255, 1.000)',
      borderRadius: '10.0px',
     };
    
    return (
      <div className="SetupVsSimulator">
        <div className="foreground">
          <div className="elRectangle" style={style_elRectangle} />
        </div>
      </div>
    )
  }
  
}
