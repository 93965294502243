import React, { Component } from 'react';
import './App.css';
import img_elImage6 from './images/DetailsComponent_state1_elRoundimage_743990.png';
import btn_icon_1058173856 from './images/btn_icon_1058173856.png';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';
import img_elImageCopy from './images/DetailsComponent_state1_elRoundimage_743990.png';
import btn_icon_931586027 from './images/btn_icon_931586027.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Comp3 extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      text_brand_detailscopy3: (<div>Brand Images</div>),
      text_brand_detailscopy3_plainText: "Brand Images",
      textcopy7: (<div>Logo Image</div>),
      textcopy7_plainText: "Logo Image",
      textcopy12: (<div>Background Image</div>),
      textcopy12_plainText: "Background Image",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elIconButtonCopy = async () => {
    this.props.appActions.updateDataSlot('ds_backgroundImagefileStorageLocation', "");
    this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', "");
  
    await this.sendData_elIconButtonCopy_to_fb_currentBrand();
  
  }
  
  
  onClick_elIconButton3 = async () => {
    this.props.appActions.updateDataSlot('ds_backgroundImagefileStorageLocation', "");
  
    await this.sendData_elIconButton3_to_fb_currentBrand();
  
  }
  
  
  onClick_elButtonCopy2 = async () => {
    await this.sendData_elButtonCopy2_to_fb_currentBrand();
  
  }
  
  
  sendData_elButtonCopy2_to_fb_currentBrand = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_currentBrand');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
      logoImageURL: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''),
      brandBackgroundImageURL: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_backgroundImagefileStorageLocation'] : ''),
      brandImageURL: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''),
    };
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_currentBrand', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_currentBrand', row);
    }
  }
  
  
  sendData_elIconButtonCopy_to_fb_currentBrand = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_currentBrand');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
    };
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_currentBrand', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_currentBrand', row);
    }
  }
  
  
  sendData_elIconButton3_to_fb_currentBrand = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_currentBrand');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
    };
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_currentBrand', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_currentBrand', row);
    }
  }
  
  
  render() {
    const style_elText_brand_detailsCopy3 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elTextCopy7 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    const style_elImage6 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const style_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1058173856+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elFirebaseUpload = {
      pointerEvents: 'auto',
     };
    const style_elTextCopy12 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    const style_elImageCopy = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_backgroundImagefileStorageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const style_elIconButton3 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_931586027+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elFirebaseUploadCopy = {
      pointerEvents: 'auto',
     };
    
    const style_elButtonCopy2 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Comp3">
        <div className="layoutFlow">
          <div className="elText_brand_detailsCopy3">
            <div className="font-futuraMedium" style={style_elText_brand_detailsCopy3}>
              <div>{this.state.text_brand_detailscopy3}</div>
            </div>
          </div>
          
          <div className="elTextCopy7">
            <div className="font-avenirBook" style={style_elTextCopy7}>
              <div>{this.state.textcopy7}</div>
            </div>
          </div>
          
          <div className="elImage6">
            <div style={style_elImage6} />
          </div>
          
          <div className="elIconButtonCopy">
            <button className="actionFont" style={style_elIconButtonCopy} onClick={this.onClick_elIconButtonCopy}  />
          </div>
          
          <div className="elFirebaseUpload">
            <div style={style_elFirebaseUpload}>
              <FileUploader
                accept="image/*"
                randomizeFilename
                storageRef={firebase.storage().ref((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_fileStorageLocation'] : ''))}
                onUploadStart={() => {
                  this.props.appActions.setState({loading: true});
                }}
                onUploadError={error => {
                  this.props.appActions.setState({loading: false});
                  console.error('** Firebase upload failed: ', error);
                }}
                onUploadSuccess={filename => {
                  this.props.appActions.setState({loading: false});
                  firebase.storage().ref((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_fileStorageLocation'] : '')).child(filename).getDownloadURL().then(url => {
                    let setDataSlotValue = (v) => { this.props.appActions.updateDataSlot("ds_uploadedBrandImageLocation", v) };
                    setDataSlotValue(url);
                    let setfilenameDataSlotValue = (v) => { this.props.appActions.updateDataSlot("ds_uploadedBrandImageName", v) };
                    setfilenameDataSlotValue(filename);
                  }).catch(function(error) {
                    console.error('** Firebase upload failed: ', error);
                  });
                }}
                />
            </div>
          </div>
          
          <div className="elTextCopy12">
            <div className="font-avenirBook" style={style_elTextCopy12}>
              <div>{this.state.textcopy12}</div>
            </div>
          </div>
          
          <div className="elImageCopy">
            <div style={style_elImageCopy} />
          </div>
          
          <div className="elIconButton3">
            <button className="actionFont" style={style_elIconButton3} onClick={this.onClick_elIconButton3}  />
          </div>
          
          <div className="elFirebaseUploadCopy">
            <div style={style_elFirebaseUploadCopy}>
              <FileUploader
                accept="image/*"
                randomizeFilename
                storageRef={firebase.storage().ref((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_fileStorageLocation'] : ''))}
                onUploadStart={() => {
                  this.props.appActions.setState({loading: true});
                }}
                onUploadError={error => {
                  this.props.appActions.setState({loading: false});
                  console.error('** Firebase upload failed: ', error);
                }}
                onUploadSuccess={filename => {
                  this.props.appActions.setState({loading: false});
                  firebase.storage().ref((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_fileStorageLocation'] : '')).child(filename).getDownloadURL().then(url => {
                    let setDataSlotValue = (v) => { this.props.appActions.updateDataSlot("ds_backgroundImagefileStorageLocation", v) };
                    setDataSlotValue(url);
                    let setfilenameDataSlotValue = (v) => { this.props.appActions.updateDataSlot("ds_uploadedBrandImageName", v) };
                    setfilenameDataSlotValue(filename);
                  }).catch(function(error) {
                    console.error('** Firebase upload failed: ', error);
                  });
                }}
                />
            </div>
          </div>
          
          <div className="elButtonCopy2">
            <Button className="actionFont" style={style_elButtonCopy2}  color="accent" onClick={this.onClick_elButtonCopy2} >
              Save Images
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
