import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
// eslint-disable-next-line
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
// eslint-disable-next-line
import * as util from 'util';
import './App.css';
import MenuItemOptionsScreen from './MenuItemOptionsScreen.js';
import MenuItemDetailsScreen from './MenuItemDetailsScreen.js';
import MenuDetailsScreen from './MenuDetailsScreen.js';
import LocationDetailsScreen from './LocationDetailsScreen.js';
import BrandDetailsScreen from './BrandDetailsScreen.js';
import IPadLandscapeScreen from './IPadLandscapeScreen.js';
import MainScreen from './MainScreen.js';
import Login1Screen from './Login1Screen.js';
import Screen64 from './Screen64.js';
import LocationDetails2Screen from './LocationDetails2Screen.js';
import DataSheet_localizationSheet from './DataSheet_localizationSheet.js';
import DataSheet_sheet1 from './DataSheet_sheet1.js';
import DataSheet_breadcrumbs from './DataSheet_breadcrumbs.js';
import DataSheet_menuItems from './DataSheet_menuItems.js';
import DataSheet_fb_auth from './DataSheet_fb_auth.js';
import DataSheet_fb_brands from './DataSheet_fb_brands.js';
import DataSheet_fb_myBrands from './DataSheet_fb_myBrands.js';
import DataSheet_ds_steps_to_view from './DataSheet_ds_steps_to_view.js';
import DataSheet_currentColorScheme from './DataSheet_currentColorScheme.js';
import DataSheet_fb_menuItems from './DataSheet_fb_menuItems.js';
import DataSheet_fd_menuItemCustomizations from './DataSheet_fd_menuItemCustomizations.js';
import DataSheet_fb_menuItem_options from './DataSheet_fb_menuItem_options.js';
import DataSheet_fb_menus from './DataSheet_fb_menus.js';
import DataSheet_fb_my_menus from './DataSheet_fb_my_menus.js';
import DataSheet_fb_my_menu_items from './DataSheet_fb_my_menu_items.js';
import DataSheet_fb_users from './DataSheet_fb_users.js';
import DataSheet_ds_brand_offerings from './DataSheet_ds_brand_offerings.js';
import DataSheet_fb_newsItems from './DataSheet_fb_newsItems.js';
import DataSheet_fb_currentBrand from './DataSheet_fb_currentBrand.js';
import DataSheet_mainScreenInstructions from './DataSheet_mainScreenInstructions.js';
import DataSheet_fb_onboardingConfigs from './DataSheet_fb_onboardingConfigs.js';
import DataSheet_fb_locations from './DataSheet_fb_locations.js';
import DataSheet_fb_my_locations from './DataSheet_fb_my_locations.js';
import DataSheet_ds_list_of_hours from './DataSheet_ds_list_of_hours.js';
import DataSheet_ds_list_of_minutes from './DataSheet_ds_list_of_minutes.js';
import DataSheet_ds_list_of_times from './DataSheet_ds_list_of_times.js';
import DataSheet_fb_holidays from './DataSheet_fb_holidays.js';
import DataSheet_fb_my_menuItems_sorted from './DataSheet_fb_my_menuItems_sorted.js';
import firebase from 'firebase';
import firestore from 'firebase/firestore';


class App extends Component {
  constructor(props) {
    super(props);

    this.dataSheets = {};
    this.dataSheets['localizationSheet'] = new DataSheet_localizationSheet('localizationSheet', this.dataSheetDidUpdate);
    this.dataSheets['sheet1'] = new DataSheet_sheet1('sheet1', this.dataSheetDidUpdate);
    this.dataSheets['breadcrumbs'] = new DataSheet_breadcrumbs('breadcrumbs', this.dataSheetDidUpdate);
    this.dataSheets['menuItems'] = new DataSheet_menuItems('menuItems', this.dataSheetDidUpdate);
    this.dataSheets['fb_auth'] = new DataSheet_fb_auth('fb_auth', this.dataSheetDidUpdate);
    this.dataSheets['fb_brands'] = new DataSheet_fb_brands('fb_brands', this.dataSheetDidUpdate);
    this.dataSheets['fb_myBrands'] = new DataSheet_fb_myBrands('fb_myBrands', this.dataSheetDidUpdate);
    this.dataSheets['ds_steps_to_view'] = new DataSheet_ds_steps_to_view('ds_steps_to_view', this.dataSheetDidUpdate);
    this.dataSheets['currentColorScheme'] = new DataSheet_currentColorScheme('currentColorScheme', this.dataSheetDidUpdate);
    this.dataSheets['fb_menuItems'] = new DataSheet_fb_menuItems('fb_menuItems', this.dataSheetDidUpdate);
    this.dataSheets['fd_menuItemCustomizations'] = new DataSheet_fd_menuItemCustomizations('fd_menuItemCustomizations', this.dataSheetDidUpdate);
    this.dataSheets['fb_menuItem_options'] = new DataSheet_fb_menuItem_options('fb_menuItem_options', this.dataSheetDidUpdate);
    this.dataSheets['fb_menus'] = new DataSheet_fb_menus('fb_menus', this.dataSheetDidUpdate);
    this.dataSheets['fb_my_menus'] = new DataSheet_fb_my_menus('fb_my_menus', this.dataSheetDidUpdate);
    this.dataSheets['fb_my_menu_items'] = new DataSheet_fb_my_menu_items('fb_my_menu_items', this.dataSheetDidUpdate);
    this.dataSheets['fb_users'] = new DataSheet_fb_users('fb_users', this.dataSheetDidUpdate);
    this.dataSheets['ds_brand_offerings'] = new DataSheet_ds_brand_offerings('ds_brand_offerings', this.dataSheetDidUpdate);
    this.dataSheets['fb_newsItems'] = new DataSheet_fb_newsItems('fb_newsItems', this.dataSheetDidUpdate);
    this.dataSheets['fb_currentBrand'] = new DataSheet_fb_currentBrand('fb_currentBrand', this.dataSheetDidUpdate);
    this.dataSheets['mainScreenInstructions'] = new DataSheet_mainScreenInstructions('mainScreenInstructions', this.dataSheetDidUpdate);
    this.dataSheets['fb_onboardingConfigs'] = new DataSheet_fb_onboardingConfigs('fb_onboardingConfigs', this.dataSheetDidUpdate);
    this.dataSheets['fb_locations'] = new DataSheet_fb_locations('fb_locations', this.dataSheetDidUpdate);
    this.dataSheets['fb_my_locations'] = new DataSheet_fb_my_locations('fb_my_locations', this.dataSheetDidUpdate);
    this.dataSheets['ds_list_of_hours'] = new DataSheet_ds_list_of_hours('ds_list_of_hours', this.dataSheetDidUpdate);
    this.dataSheets['ds_list_of_minutes'] = new DataSheet_ds_list_of_minutes('ds_list_of_minutes', this.dataSheetDidUpdate);
    this.dataSheets['ds_list_of_times'] = new DataSheet_ds_list_of_times('ds_list_of_times', this.dataSheetDidUpdate);
    this.dataSheets['fb_holidays'] = new DataSheet_fb_holidays('fb_holidays', this.dataSheetDidUpdate);
    this.dataSheets['fb_my_menuItems_sorted'] = new DataSheet_fb_my_menuItems_sorted('fb_my_menuItems_sorted', this.dataSheetDidUpdate);
    this.dataSheetLoaded = {};

    this.dataSlots = {};
    this.dataSlots['ds_activeLang'] = "en";
    this.dataSlots['ds_mainScreenState'] = "0";
    this.dataSlots['ds_activeBrandName'] = "yesoms";
    this.dataSlots['ds_activeMenuItemName'] = "menuItem";
    this.dataSlots['ds_activeCustomizationName'] = "size";
    this.dataSlots['ds_activeOptionName'] = "large";
    this.dataSlots['ds_showMenuItemState'] = "";
    this.dataSlots['ds_showCustomizations'] = "";
    this.dataSlots['ds_userName'] = "";
    this.dataSlots['ds_userEmail'] = "";
    this.dataSlots['ds_userPhoto'] = "";
    this.dataSlots['ds_userID'] = "";
    this.dataSlots['ds_selectedbrandkey'] = "mNEdFor3G16WDziEXwlV";
    this.dataSlots['ds_brandCreateState'] = "";
    this.dataSlots['ds_uploadedBrandImageLocation'] = "";
    this.dataSlots['ds_defaultBrandColor'] = "#000000";
    this.dataSlots['ds_uploadedBrandImageName'] = "";
    this.dataSlots['ds_value_true'] = "1";
    this.dataSlots['ds_value_false'] = "0";
    this.dataSlots['ds_value_space'] = "    ";
    this.dataSlots['ds_default_brandLogoURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    this.dataSlots['ds_newBrandDefaultName'] = "My New Brand";
    this.dataSlots['ds_fileStorageLocation'] = "/images/brands/";
    this.dataSlots['ds_MenuItemfileStorageLocation'] = "/images/menuItems/";
    this.dataSlots['ds_brandPrimaryColor'] = "";
    this.dataSlots['ds_brandSecondaryColor'] = "";
    this.dataSlots['ds_brandTitleColor'] = "";
    this.dataSlots['ds_brandTextColor'] = "";
    this.dataSlots['ds_selected_menuItem_key'] = "dummy";
    this.dataSlots['ds_selected_menuItemOption_key'] = "dummy";
    this.dataSlots['ds_selected_menu_key'] = "dummy";
    this.dataSlots['ds_show_debug_info'] = "";
    this.dataSlots['ds_signedAgreement'] = "false";
    this.dataSlots['ds_uploadedMenuItemImageLocation'] = "";
    this.dataSlots['ds_uploadedMenuItemmageName'] = "";
    this.dataSlots['ds_hideNews'] = "0";
    this.dataSlots['ds_showUserDetails'] = "";
    this.dataSlots['ds_dummy_selectedItem'] = "1";
    this.dataSlots['ds_backgroundImagefileStorageLocation'] = "";
    this.dataSlots['ds_iphone_mock_state'] = "";
    this.dataSlots['ds_activeMenuName'] = "";
    this.dataSlots['ds_brandInactiveIconColor'] = "";
    this.dataSlots['ds_brandBottomNavColor'] = "";
    this.dataSlots['ds_brandOrderButtonColor'] = "";
    this.dataSlots['ds_brandOrderButtonTextColor'] = "";
    this.dataSlots['ds_brandActiveIconColor'] = "";
    this.dataSlots['ds_brandOrderButtonTextString'] = "";
    this.dataSlots['ds_nullvalue'] = "";
    this.dataSlots['ds_showHelpOverlay'] = "false";
    this.dataSlots['ds_simulatorEmbedHTML'] = "";
    this.dataSlots['ds_forumHelpURL'] = "https://discuss.yesoms.com/";
    this.dataSlots['ds_brandpromoitemflag'] = "";
    this.dataSlots['ds_brandpromoTempBrandID'] = "";
    this.dataSlots['ds_sampleImageURL'] = "";
    this.dataSlots['ds_activeMenuItemPrice'] = "";
    this.dataSlots['ds_default_menuItemURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fb70136b6-cbc5-4f56-8b3d-5739ad722e7b.png?alt=media";
    this.dataSlots['ds_selected_location_key'] = "hiSUS9sSOnB1yBghXtTS";
    this.dataSlots['ds_activeLocationName'] = "";
    this.dataSlots['ds_filter'] = "z";
    this.dataSlots['ds_uploaded_Screen0_ImageURL'] = "";

    this.updateLocalizationFromDataSheet(this.dataSheets['localizationSheet']);


    this.serviceOptions_fb_auth = {
      dataSlots: this.dataSlots,
      servicePath: "users",
      query: "",
    };
    this.dataSheets['fb_auth'].appActions = this;
    this.dataSheets['fb_auth'].firebase = firebase;
    
    this.serviceOptions_fb_brands = {
      dataSlots: this.dataSlots,
      servicePath: "brands",
      query: "",
    };
    this.dataSheets['fb_brands'].appActions = this;
    this.dataSheets['fb_brands'].firebase = firebase;
    
    this.serviceOptions_fb_myBrands = {
      dataSlots: this.dataSlots,
      servicePath: "brands",
      query: "where(\"creator\",\"==\",\"$slot(ds_userID)\").orderBy(\"created\", \"desc\")",
    };
    this.dataSheets['fb_myBrands'].appActions = this;
    this.dataSheets['fb_myBrands'].firebase = firebase;
    
    this.serviceOptions_fb_menuItems = {
      dataSlots: this.dataSlots,
      servicePath: "menuItems",
      query: "",
    };
    this.dataSheets['fb_menuItems'].appActions = this;
    this.dataSheets['fb_menuItems'].firebase = firebase;
    
    this.serviceOptions_fd_menuItemCustomizations = {
      dataSlots: this.dataSlots,
      servicePath: "menuItems/$slot(ds_selected_menuItem_key)/customizations",
      query: "",
    };
    this.dataSheets['fd_menuItemCustomizations'].appActions = this;
    this.dataSheets['fd_menuItemCustomizations'].firebase = firebase;
    
    this.serviceOptions_fb_menuItem_options = {
      dataSlots: this.dataSlots,
      servicePath: "menuItems/$slot(ds_selected_menuItem_key)/customizations/$slot(ds_selected_menuItemOption_key)/options",
      query: "orderBy('order','asc')",
    };
    this.dataSheets['fb_menuItem_options'].appActions = this;
    this.dataSheets['fb_menuItem_options'].firebase = firebase;
    
    this.serviceOptions_fb_menus = {
      dataSlots: this.dataSlots,
      servicePath: "/menus/",
      query: "",
    };
    this.dataSheets['fb_menus'].appActions = this;
    this.dataSheets['fb_menus'].firebase = firebase;
    
    this.serviceOptions_fb_my_menus = {
      dataSlots: this.dataSlots,
      servicePath: "menus",
      query: "where(\"associatedBrand\", \"==\", \"$slot(ds_selectedbrandkey)\")",
    };
    this.dataSheets['fb_my_menus'].appActions = this;
    this.dataSheets['fb_my_menus'].firebase = firebase;
    
    this.serviceOptions_fb_my_menu_items = {
      dataSlots: this.dataSlots,
      servicePath: "menuItems",
      query: "where(\"associatedMenu\", \"==\", \"$slot(ds_selected_menu_key)\")",
    };
    this.dataSheets['fb_my_menu_items'].appActions = this;
    this.dataSheets['fb_my_menu_items'].firebase = firebase;
    
    this.serviceOptions_fb_users = {
      dataSlots: this.dataSlots,
      servicePath: "users",
      query: "",
    };
    this.dataSheets['fb_users'].appActions = this;
    this.dataSheets['fb_users'].firebase = firebase;
    
    this.serviceOptions_fb_newsItems = {
      dataSlots: this.dataSlots,
      servicePath: "configs/build.yesoms.com/news",
      query: "where('showNews', '==', true)",
    };
    this.dataSheets['fb_newsItems'].appActions = this;
    this.dataSheets['fb_newsItems'].firebase = firebase;
    
    this.serviceOptions_fb_currentBrand = {
      dataSlots: this.dataSlots,
      servicePath: "/brands/",
      query: "where(\"id\",\"==\",\"$slot(ds_selectedbrandkey)\")",
    };
    this.dataSheets['fb_currentBrand'].appActions = this;
    this.dataSheets['fb_currentBrand'].firebase = firebase;
    
    this.serviceOptions_fb_onboardingConfigs = {
      dataSlots: this.dataSlots,
      servicePath: "/brands/dummy/onboardingConfigs/",
      query: "where(\"document_key\",\"==\",\"production\")",
    };
    this.dataSheets['fb_onboardingConfigs'].appActions = this;
    this.dataSheets['fb_onboardingConfigs'].firebase = firebase;
    
    this.serviceOptions_fb_locations = {
      dataSlots: this.dataSlots,
      servicePath: "/locations/",
      query: "",
    };
    this.dataSheets['fb_locations'].appActions = this;
    this.dataSheets['fb_locations'].firebase = firebase;
    
    this.serviceOptions_fb_my_locations = {
      dataSlots: this.dataSlots,
      servicePath: "/locations/",
      query: "where(\"associatedBrand\", \"==\", \"$slot(ds_selectedbrandkey)\")",
    };
    this.dataSheets['fb_my_locations'].appActions = this;
    this.dataSheets['fb_my_locations'].firebase = firebase;
    
    this.serviceOptions_fb_holidays = {
      dataSlots: this.dataSlots,
      servicePath: "/locations/$slot('ds_selected_location_key')/holidays",
      query: "",
    };
    this.dataSheets['fb_holidays'].appActions = this;
    this.dataSheets['fb_holidays'].firebase = firebase;
    
    this.serviceOptions_fb_my_menuItems_sorted = {
      dataSlots: this.dataSlots,
      servicePath: "menuItems",
      query: "where(\"associatedMenu\", \"==\", \"$slot(ds_selected_menu_key)\").orderBy(\"order\",\"desc\")",
    };
    this.dataSheets['fb_my_menuItems_sorted'].appActions = this;
    this.dataSheets['fb_my_menuItems_sorted'].firebase = firebase;
    
    // Initialize web service plugin 'firebase 1'
    firebase.initializeApp({
        apiKey: "AIzaSyBfmMJP1UqdJapx0Rm_y-4vgtWWpBbkh0k",
        authDomain: "yesoms-production01.firebaseapp.com",
        projectId: "yesoms-production01",
        storageBucket: "yesoms-production01.appspot.com",
        messagingSenderId: "532019231245",
        appId: "1:532019231245:web:d74e36c4d943024cbef344",
        measurementId: "G-WEY9RBVM01"
      });
    firebase.firestore().settings({});
    

    this.state = {
      screenTransitionForward: true,
    }

  }

  windowDidResize = () => {
    let w = window.innerWidth;
    let formatId;
    if (w < 576) formatId = 'narrow-phone';
    else if (w < 768) formatId = 'wide-phone';
    else if (w < 1024) formatId = 'narrow-tablet';
    else formatId = 'wide-tablet';
    if (formatId !== this.state.screenFormatId) {
      this.setState({screenFormatId: formatId});
    }
  }

  componentDidMount() {
    this.windowDidResize();
    window.addEventListener('resize', this.windowDidResize);

    this.serviceOptions_fb_newsItems.servicePath = this.dataSheets['fb_newsItems'].expandSlotTemplateString("configs/build.yesoms.com/news", this.dataSlots);
    this.loadData_firebase1(this.dataSheets['fb_newsItems'], this.serviceOptions_fb_newsItems, true);
    
    this.serviceOptions_fb_currentBrand.servicePath = this.dataSheets['fb_currentBrand'].expandSlotTemplateString("/brands/", this.dataSlots);
    this.loadData_firebase1(this.dataSheets['fb_currentBrand'], this.serviceOptions_fb_currentBrand, true);
    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowDidResize);
  }

  isLoading() {
    return this.state.loading;
  }

  goToScreen = (screenId, props) => {
    // This method is the default implementation and could be customized by a navigation plugin.
    this.props.history.push('/'+screenId, {...props, appActions: null, locStrings: null, dataSheets: null});
    window.scrollTo(0, 0);
  }

  goBack = () => {
    // This method is the default implementation and could be customized by a navigation plugin.
    this.props.history.goBack();
  }

  getDataSheet = (sheetId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    return this.dataSheets[sheetId];
  }

  addToDataSheet = (sheetId, newRow, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && newRow) {
      let promise = sheet.addItem(newRow, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  updateInDataSheet = (sheetId, row, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      let promise = sheet.replaceItemByKey(row.key, row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  removeFromDataSheet = (sheetId, row) => {
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      let promise = sheet.removeItem(row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  updateDataSlot = (slotId, value, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    if (value === this.dataSlots[slotId])
      return;

    this.dataSlots[slotId] = value;

    if (slotId === 'ds_activeLang') {
      this.locStrings.setLanguage(value);
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_auth'].expandSlotTemplateString("users", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_auth.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_auth'], this.serviceOptions_fb_auth, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_brands'].expandSlotTemplateString("brands", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_brands.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_brands'], this.serviceOptions_fb_brands, false);
      }
    }
    if (this.serviceOptions_fb_myBrands.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['fb_myBrands'].expandSlotTemplateString(this.serviceOptions_fb_myBrands.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebase1(this.dataSheets['fb_myBrands'], this.serviceOptions_fb_myBrands, false);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_myBrands'].expandSlotTemplateString("brands", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_myBrands.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_myBrands'], this.serviceOptions_fb_myBrands, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_menuItems'].expandSlotTemplateString("menuItems", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_menuItems.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_menuItems'], this.serviceOptions_fb_menuItems, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fd_menuItemCustomizations'].expandSlotTemplateString("menuItems/$slot(ds_selected_menuItem_key)/customizations", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fd_menuItemCustomizations.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fd_menuItemCustomizations'], this.serviceOptions_fd_menuItemCustomizations, false);
      }
    }
    if (this.serviceOptions_fb_menuItem_options.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['fb_menuItem_options'].expandSlotTemplateString(this.serviceOptions_fb_menuItem_options.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebase1(this.dataSheets['fb_menuItem_options'], this.serviceOptions_fb_menuItem_options, false);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_menuItem_options'].expandSlotTemplateString("menuItems/$slot(ds_selected_menuItem_key)/customizations/$slot(ds_selected_menuItemOption_key)/options", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_menuItem_options.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_menuItem_options'], this.serviceOptions_fb_menuItem_options, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_menus'].expandSlotTemplateString("/menus/", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_menus.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_menus'], this.serviceOptions_fb_menus, false);
      }
    }
    if (this.serviceOptions_fb_my_menus.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['fb_my_menus'].expandSlotTemplateString(this.serviceOptions_fb_my_menus.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebase1(this.dataSheets['fb_my_menus'], this.serviceOptions_fb_my_menus, false);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_my_menus'].expandSlotTemplateString("menus", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_my_menus.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_my_menus'], this.serviceOptions_fb_my_menus, false);
      }
    }
    if (this.serviceOptions_fb_my_menu_items.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['fb_my_menu_items'].expandSlotTemplateString(this.serviceOptions_fb_my_menu_items.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebase1(this.dataSheets['fb_my_menu_items'], this.serviceOptions_fb_my_menu_items, false);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_my_menu_items'].expandSlotTemplateString("menuItems", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_my_menu_items.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_my_menu_items'], this.serviceOptions_fb_my_menu_items, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_users'].expandSlotTemplateString("users", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_users.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_users'], this.serviceOptions_fb_users, false);
      }
    }
    if (this.serviceOptions_fb_newsItems.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['fb_newsItems'].expandSlotTemplateString(this.serviceOptions_fb_newsItems.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebase1(this.dataSheets['fb_newsItems'], this.serviceOptions_fb_newsItems, false);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_newsItems'].expandSlotTemplateString("configs/build.yesoms.com/news", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_newsItems.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_newsItems'], this.serviceOptions_fb_newsItems, false);
      }
    }
    if (this.serviceOptions_fb_currentBrand.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['fb_currentBrand'].expandSlotTemplateString(this.serviceOptions_fb_currentBrand.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebase1(this.dataSheets['fb_currentBrand'], this.serviceOptions_fb_currentBrand, false);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_currentBrand'].expandSlotTemplateString("/brands/", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_currentBrand.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_currentBrand'], this.serviceOptions_fb_currentBrand, false);
      }
    }
    if (this.serviceOptions_fb_onboardingConfigs.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['fb_onboardingConfigs'].expandSlotTemplateString(this.serviceOptions_fb_onboardingConfigs.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebase1(this.dataSheets['fb_onboardingConfigs'], this.serviceOptions_fb_onboardingConfigs, false);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_onboardingConfigs'].expandSlotTemplateString("/brands/dummy/onboardingConfigs/", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_onboardingConfigs.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_onboardingConfigs'], this.serviceOptions_fb_onboardingConfigs, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_locations'].expandSlotTemplateString("/locations/", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_locations.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_locations'], this.serviceOptions_fb_locations, false);
      }
    }
    if (this.serviceOptions_fb_my_locations.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['fb_my_locations'].expandSlotTemplateString(this.serviceOptions_fb_my_locations.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebase1(this.dataSheets['fb_my_locations'], this.serviceOptions_fb_my_locations, false);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_my_locations'].expandSlotTemplateString("/locations/", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_my_locations.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_my_locations'], this.serviceOptions_fb_my_locations, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_holidays'].expandSlotTemplateString("/locations/$slot('ds_selected_location_key')/holidays", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_holidays.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_holidays'], this.serviceOptions_fb_holidays, false);
      }
    }
    if (this.serviceOptions_fb_my_menuItems_sorted.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['fb_my_menuItems_sorted'].expandSlotTemplateString(this.serviceOptions_fb_my_menuItems_sorted.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebase1(this.dataSheets['fb_my_menuItems_sorted'], this.serviceOptions_fb_my_menuItems_sorted, false);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['fb_my_menuItems_sorted'].expandSlotTemplateString("menuItems", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_fb_my_menuItems_sorted.servicePath = servicePath;
        this.loadData_firebase1(this.dataSheets['fb_my_menuItems_sorted'], this.serviceOptions_fb_my_menuItems_sorted, false);
      }
    }
    this.setState({});
  }

  dataSheetDidUpdate = (dataSheet) => {
    // This method is the default implementation and could be customized by a state management plugin.
    this.setState({});
  }

  updateLocalizationFromDataSheet = (dataSheet) => {
    const stringsObj = dataSheet.getStringsByLanguage();
    if (stringsObj && Object.keys(stringsObj).length > 0) {
      this.locStrings = new LocalizedStrings(stringsObj);
    } else {
      this.locStrings = new LocalizedStrings({en: {}});
    }
    this.locStrings.setLanguage(this.dataSlots['ds_activeLang']);
  }

  loadData_firebase1 = (dataSheet, options, firstLoad) => {
    // This method was written by data plugin 'Firebase (Cloud Firestore)'.
    this.setState({loading: true});
    
    // clear any placeholder data before load
    if (firstLoad) {
      dataSheet.items = [];
    }
    
    const fetchComplete = (err) => {
      if (err) {
        // This error handling comes from React Studio
        // and currently doesn't do anything useful.
        console.error('** Web service load failed: ', err);
      } else {
      }
      this.setState({loading: false});
    }
    
    const db = firebase.firestore();
    let isCollectionGroup = options.servicePath.startsWith("group:");
    let collection;
    if (isCollectionGroup) {
      collection = db.collectionGroup(options.servicePath.substring(6));
    } else {
      let path = options.servicePath.trim();
      if (path.startsWith("/")) path = path.substring(1);
      if (path.endsWith("/")) path = path.substring(0, path.length-1);
      if ((path.split("/").length-1)%2==0) {
        collection = db.collection(path);
      } else {
        collection = db.doc(path);
      }
    }
    const query = dataSheet.expandSlotTemplateString(options.query, this.dataSlots);
    let queryObj;
    
    if (query.length < 1) {
      queryObj = collection;
    } else {
      console.log("loading firebase data for '%s' with query: %s", options.servicePath, query);
      try {
        queryObj = eval(`(function(c){ return c.${query}; })(collection)`);
      } catch (e) {
        console.log("** error creating firebase query object from '%s': ", query, e)
        return;
      }
    }
    
    queryObj.onSnapshot(
      (querySnapshot) => {
        let jsonArr = [];
        
        if (querySnapshot.docs) {
          querySnapshot.forEach((doc) => {
            const data = { ...doc.data(), document_key: doc.id, document_path: doc.ref.path };
            jsonArr.push(data);
          });
        } else if (querySnapshot.data) {
          const doc = querySnapshot;
          const data = { ...doc.data(), document_key: doc.id, document_path: doc.ref.path };
          jsonArr.push(data);
        }    
            
        dataSheet.loadFromJson(jsonArr);
        fetchComplete(null, options);  
      },
      (err) => {
        fetchComplete(err, options);
      });  
    
    
     /*
    dbLoadingPromise.get().then((querySnapshot) => {
        let jsonArr = [];
    
        querySnapshot.forEach((doc) => {
          const data = { ...doc.data(), key: doc.id };
          jsonArr.push(data);
        });
            
        dataSheet.loadFromJson(jsonArr);
        fetchComplete(null, options);
      },
      (err) => {
        fetchComplete(err, options);
      });  
      */
    
  }

  createImageUrlFromProp = (prop) => {
    if (prop instanceof Object) {
      if (prop.type != null && prop.type === 'image' && prop.path != null) {
        return "(null)"+prop.path;
      }
    }
    return prop;
  }

  render() {
    let makeElementForScreen = (screenId, baseProps, atTop, forward) => {
      let screenProps = {
        ...baseProps,
        atTopOfScreenStack: atTop,
        transitionForward: forward,
        appActions: this,
        dataSheets: this.dataSheets,
        locStrings: this.locStrings,
        deviceInfo: {
          screenFormatId: this.state.screenFormatId
        },
        'ds_activeLang': this.dataSlots['ds_activeLang'],
        'ds_mainScreenState': this.dataSlots['ds_mainScreenState'],
        'ds_activeBrandName': this.dataSlots['ds_activeBrandName'],
        'ds_activeMenuItemName': this.dataSlots['ds_activeMenuItemName'],
        'ds_activeCustomizationName': this.dataSlots['ds_activeCustomizationName'],
        'ds_activeOptionName': this.dataSlots['ds_activeOptionName'],
        'ds_showMenuItemState': this.dataSlots['ds_showMenuItemState'],
        'ds_showCustomizations': this.dataSlots['ds_showCustomizations'],
        'ds_userName': this.dataSlots['ds_userName'],
        'ds_userEmail': this.dataSlots['ds_userEmail'],
        'ds_userPhoto': this.dataSlots['ds_userPhoto'],
        'ds_userID': this.dataSlots['ds_userID'],
        'ds_selectedbrandkey': this.dataSlots['ds_selectedbrandkey'],
        'ds_brandCreateState': this.dataSlots['ds_brandCreateState'],
        'ds_uploadedBrandImageLocation': this.dataSlots['ds_uploadedBrandImageLocation'],
        'ds_defaultBrandColor': this.dataSlots['ds_defaultBrandColor'],
        'ds_uploadedBrandImageName': this.dataSlots['ds_uploadedBrandImageName'],
        'ds_value_true': this.dataSlots['ds_value_true'],
        'ds_value_false': this.dataSlots['ds_value_false'],
        'ds_value_space': this.dataSlots['ds_value_space'],
        'ds_default_brandLogoURL': this.dataSlots['ds_default_brandLogoURL'],
        'ds_newBrandDefaultName': this.dataSlots['ds_newBrandDefaultName'],
        'ds_fileStorageLocation': this.dataSlots['ds_fileStorageLocation'],
        'ds_MenuItemfileStorageLocation': this.dataSlots['ds_MenuItemfileStorageLocation'],
        'ds_brandPrimaryColor': this.dataSlots['ds_brandPrimaryColor'],
        'ds_brandSecondaryColor': this.dataSlots['ds_brandSecondaryColor'],
        'ds_brandTitleColor': this.dataSlots['ds_brandTitleColor'],
        'ds_brandTextColor': this.dataSlots['ds_brandTextColor'],
        'ds_selected_menuItem_key': this.dataSlots['ds_selected_menuItem_key'],
        'ds_selected_menuItemOption_key': this.dataSlots['ds_selected_menuItemOption_key'],
        'ds_selected_menu_key': this.dataSlots['ds_selected_menu_key'],
        'ds_show_debug_info': this.dataSlots['ds_show_debug_info'],
        'ds_signedAgreement': this.dataSlots['ds_signedAgreement'],
        'ds_uploadedMenuItemImageLocation': this.dataSlots['ds_uploadedMenuItemImageLocation'],
        'ds_uploadedMenuItemmageName': this.dataSlots['ds_uploadedMenuItemmageName'],
        'ds_hideNews': this.dataSlots['ds_hideNews'],
        'ds_showUserDetails': this.dataSlots['ds_showUserDetails'],
        'ds_dummy_selectedItem': this.dataSlots['ds_dummy_selectedItem'],
        'ds_backgroundImagefileStorageLocation': this.dataSlots['ds_backgroundImagefileStorageLocation'],
        'ds_iphone_mock_state': this.dataSlots['ds_iphone_mock_state'],
        'ds_activeMenuName': this.dataSlots['ds_activeMenuName'],
        'ds_brandInactiveIconColor': this.dataSlots['ds_brandInactiveIconColor'],
        'ds_brandBottomNavColor': this.dataSlots['ds_brandBottomNavColor'],
        'ds_brandOrderButtonColor': this.dataSlots['ds_brandOrderButtonColor'],
        'ds_brandOrderButtonTextColor': this.dataSlots['ds_brandOrderButtonTextColor'],
        'ds_brandActiveIconColor': this.dataSlots['ds_brandActiveIconColor'],
        'ds_brandOrderButtonTextString': this.dataSlots['ds_brandOrderButtonTextString'],
        'ds_nullvalue': this.dataSlots['ds_nullvalue'],
        'ds_showHelpOverlay': this.dataSlots['ds_showHelpOverlay'],
        'ds_simulatorEmbedHTML': this.dataSlots['ds_simulatorEmbedHTML'],
        'ds_forumHelpURL': this.dataSlots['ds_forumHelpURL'],
        'ds_brandpromoitemflag': this.dataSlots['ds_brandpromoitemflag'],
        'ds_brandpromoTempBrandID': this.dataSlots['ds_brandpromoTempBrandID'],
        'ds_sampleImageURL': this.dataSlots['ds_sampleImageURL'],
        'ds_activeMenuItemPrice': this.dataSlots['ds_activeMenuItemPrice'],
        'ds_default_menuItemURL': this.dataSlots['ds_default_menuItemURL'],
        'ds_selected_location_key': this.dataSlots['ds_selected_location_key'],
        'ds_activeLocationName': this.dataSlots['ds_activeLocationName'],
        'ds_filter': this.dataSlots['ds_filter'],
        'ds_uploaded_Screen0_ImageURL': this.dataSlots['ds_uploaded_Screen0_ImageURL'],
      };
      // A data sheet row was specified as the data source for this screen, so carry those props + 'dataSheetRow'.
      const dataSheetRow_MainScreen = this.dataSheets['fb_newsItems'].items[0];
      const screenData_MainScreen = {
        ...dataSheetRow_MainScreen,
        dataSheetRow: dataSheetRow_MainScreen,
      }
      // A data sheet row was specified as the data source for this screen, so carry those props + 'dataSheetRow'.
      const dataSheetRow_Screen64 = this.dataSheets['currentColorScheme'].items[0];
      const screenData_Screen64 = {
        ...dataSheetRow_Screen64,
        dataSheetRow: dataSheetRow_Screen64,
      }
      switch (screenId) {
        default:
          return null;
        case 'menuItemOptions':
          return (<MenuItemOptionsScreen {...screenProps} />)
        case 'menuItemDetails':
          return (<MenuItemDetailsScreen {...screenProps} />)
        case 'menuDetails':
          return (<MenuDetailsScreen {...screenProps} />)
        case 'locationDetails':
          return (<LocationDetailsScreen {...screenProps} />)
        case 'brandDetails':
          return (<BrandDetailsScreen {...screenProps} />)
        case 'iPadLandscape':
          return (<IPadLandscapeScreen {...screenProps} />)
        case 'mainScreen':
          return (<MainScreen {...screenProps} {...screenData_MainScreen} />)
        case 'login1':
          return (<Login1Screen {...screenProps} />)
        case 'screen64':
          return (<Screen64 {...screenProps} {...screenData_Screen64} />)
        case 'locationDetails2':
          return (<LocationDetails2Screen {...screenProps} />)
      }
    }

    return (
      <div className="App">
        <Switch>
          <Route path="/" render={(props) => {
            return (firebase.auth().currentUser != null) ? <Redirect to="/mainScreen" /> : <Redirect to="/login1" />;
          }} exact />
          <Route path="/menuItemOptions" render={(props) => {
            return makeElementForScreen('menuItemOptions', props.location.state, true, true);
          }} />
          <Route path="/menuItemDetails" render={(props) => {
            return makeElementForScreen('menuItemDetails', props.location.state, true, true);
          }} />
          <Route path="/menuDetails" render={(props) => {
            return makeElementForScreen('menuDetails', props.location.state, true, true);
          }} />
          <Route path="/locationDetails" render={(props) => {
            return makeElementForScreen('locationDetails', props.location.state, true, true);
          }} />
          <Route path="/brandDetails" render={(props) => {
            return makeElementForScreen('brandDetails', props.location.state, true, true);
          }} />
          <Route path="/iPadLandscape" render={(props) => {
            return makeElementForScreen('iPadLandscape', props.location.state, true, true);
          }} />
          <Route path="/mainScreen" render={(props) => {
            return makeElementForScreen('mainScreen', props.location.state, true, true);
          }} />
          <Route path="/login1" render={(props) => {
            return makeElementForScreen('login1', props.location.state, true, true);
          }} />
          <Route path="/screen64" render={(props) => {
            return makeElementForScreen('screen64', props.location.state, true, true);
          }} />
          <Route path="/locationDetails2" render={(props) => {
            return makeElementForScreen('locationDetails2', props.location.state, true, true);
          }} />
          <Route render={(props) => makeElementForScreen('mainScreen', props.location.state, true, true)} />
        </Switch>
      </div>
    );
  }
}
export default withRouter(App)
