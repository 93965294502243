// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';
import datasheet_ds_brand_offerings_row0_sampleImage from './images/datasheet_ds_brand_offerings_row0_sampleImage.png';

export default class DataSheet_ds_brand_offerings extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['value'] = "beerPub";
    item['name'] = "Beer Pub";
    item['sampleImage'] = datasheet_ds_brand_offerings_row0_sampleImage;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "Coffee Shop";
    item['value'] = "coffeeShop";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "Food Truck";
    item['value'] = "foodTruck";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "Restaurant - Fast Casual";
    item['value'] = "restaurantFastCasual";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "Restaurant - Fine Dining";
    item['value'] = "restaurantFineDining";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "Event Space";
    item['value'] = "eventSpace";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "Apparel";
    item['value'] = "Apparel";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "Other (please specify)";
    item['value'] = "Other";
    item.key = key++;
  }

}
