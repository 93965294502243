import React, { Component } from 'react';
import './App.css';
import btn_icon_564661 from './images/btn_icon_564661.png';

export default class ColorPicker extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state1_elText: (<div>Color codes start with a “#” and are six hexi-decimal digits long. To find a particular color, click on the color below to open a color picker, select your color and then press the copy button and paste it in.</div>),
      state1_elText_plainText: "Color codes start with a “#” and are six hexi-decimal digits long. To find a particular color, click on the color below to open a color picker, select your color and then press the copy button and paste it in.",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elText2 = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  renderState0() {
    const style_state0_elText2 = {
      fontSize: 11.9,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ColorPicker">
        <div className="layoutFlow">
          <div className="state0_elText2">
            <div className="font-avenirNextRegular" style={style_state0_elText2} onClick={this.onClick_state0_elText2} >
              <div>{this.props.locStrings.colorpicker_text2_829891}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elIconButtonCopy = async () => {
    this.setState({visualStateIndexOverride: 0})
  
  }
  
  
  renderState1() {
    
    const style_state1_elRectangle = {
      background: 'rgba(252, 254, 255, 1.000)',
      borderRadius: '10.0px',
      boxShadow: '0.0px 2.0px 3px rgba(0, 0, 0, 0.3000)',
     };
    
    // Embedded HTML content for element 'embed'
    const htmlContent_embed = "<iframe src=\"http://yesoms.com/colorpicker/picker.html\" frameborder=0 width=250 height=40></iframe>";
    
    const style_state1_elEmbed = {
      pointerEvents: 'auto',
     };
    
    // Embedded HTML content for element 'embed 2'
    const htmlContent_embed2 = "<input type=\"color\" id=\"myColor\" value=\"#eb771d\" onChange=\"myFunction()\">\n\n<input maxlength=\"7\" size=\"8\"  id=\"hex\" value=\"\">\n\n<script>\nfunction myFunction() {\n  var x = document.getElementById(\"myColor\").value;\n  document.getElementById(\"demo\").innerHTML = \"Copy\";\n  document.getElementById(\"hex\").value = x;     \n}\n\nfunction copyFunction() {\n  var copyText = document.getElementById(\"hex\");\n  copyText.select();\n  copyText.setSelectionRange(0, 99999)\n  document.execCommand(\"copy\");\n  //alert(\"Copied your hex code: \" + copyText.value);\n  document.getElementById(\"demo\").innerHTML = \"Copied!\";\n}\n</script>\n\n<button id=\"demo\" onclick=\"copyFunction()\">Copy</button>";
    
    const style_state1_elEmbed2 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state1_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_564661+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '74.227%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ColorPicker">
        <div className="foreground">
          <div className="state1_elRectangle" style={style_state1_elRectangle} />
          <div className="embeddedContent state1_elEmbed" style={style_state1_elEmbed}>
            <div dangerouslySetInnerHTML={{__html: htmlContent_embed}}></div>
          </div>
          <div className="embeddedContent state1_elEmbed2" style={style_state1_elEmbed2}>
            <div dangerouslySetInnerHTML={{__html: htmlContent_embed2}}></div>
          </div>
          <div className="baseFont state1_elText" style={style_state1_elText}>
            <div>{this.state.state1_elText}</div>
          </div>
          <button className="actionFont state1_elIconButtonCopy" style={style_state1_elIconButtonCopy} onClick={this.onClick_state1_elIconButtonCopy}  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
