import React, { Component } from 'react';
import './App.css';
import img_elIphoneOverlay from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';

export default class IPhoneSim2 extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elTextCopy2 = async () => {
    const transformPropValue = (input) => {
      // this is the appetize simulator used for the ipad:
      // login is appetize-ipad@taylorm.33mail.com
      
      let constructed_htmlContent_embed = "https://appetize.io/embed/7gbanur4jack4u2dwbu44az5j4?device=ipadair&scale=75&orientation=portrait&osVersion=13.7&deviceColor=black&launchUrl=yes:%2F%2Fdemo%2F";
      constructed_htmlContent_embed += this.props.appActions.dataSlots['ds_selectedbrandkey'];
      
      return constructed_htmlContent_embed;
    }
    window.open(transformPropValue((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : '')), '_blank');
  
  }
  
  
  onClick_elTextCopy = async () => {
    const transformPropValue = (input) => {
      // This is the simulator that shows when clicking the "iPhone XS Max" link on the "Open in a new window" in the right drawer.
      // Appetize login is: appetize@taylorm.33mail.com
      
      let constructed_htmlContent_embed = "https://appetize.io/embed/7gbanur4jack4u2dwbu44az5j4?device=iphonexsmax&scale=75&orientation=portrait&osVersion=13.7&deviceColor=black&launchUrl=yes:%2F%2Fdemo%2F";
      constructed_htmlContent_embed += this.props.appActions.dataSlots['ds_selectedbrandkey'];
      
      
      return constructed_htmlContent_embed;
    }
    window.open(transformPropValue((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : '')), '_blank');
  
  }
  
  
  onClick_elTextCopy3 = async () => {
    window.open('', '_blank');
  
  }
  
  
  render() {
    let transformPropValue_embed = (input) => {
      // this is the appetize simulator used for the main iPhone:
      // login is appetize@taylorm.33mail.com
      
      let constructed_htmlContent_embed = "<iframe src=\"https://appetize.io/embed/7gbanur4jack4u2dwbu44az5j4?iphonex&scale=75&orientation=portrait&osVersion=13.7&deviceColor=black&launchUrl=yes:%2F%2Fdemo%2F";
      constructed_htmlContent_embed += this.props.appActions.dataSlots['ds_selectedbrandkey'];
      constructed_htmlContent_embed += "\" width=\"378px\" height=\"800px\" frameborder=\"0\" scrolling=\"no\"></iframe>";
      
      return constructed_htmlContent_embed;
    }
    
    // Embedded HTML content for element 'embed'
    const htmlContent_embed = transformPropValue_embed((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''));
    
    const style_elEmbed = {
      pointerEvents: 'auto',
     };
    const style_elIphoneOverlay = {
      height: 'auto',
     };
    const style_elCard = {
      width: '100%',
      height: '100%',
      borderRadius: '10.0px',
     };
    const style_elCard_outer = {
      display: 'none',
      boxSizing: 'border-box',
      backgroundColor: 'white',
      borderRadius: '10.0px',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    const style_elRectangle = {
      background: 'rgba(255, 255, 255, 1.000)',
      borderRadius: '11.0px',
      boxShadow: '0.0px 2.0px 3px rgba(0, 0, 0, 0.3000)',
     };
    const style_elTextCopy2 = {
      color: '#eb771d',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextCopy = {
      color: '#eb771d',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextCopy3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="IPhoneSim2">
        <div className="foreground">
          <div className="embeddedContent elEmbed" style={style_elEmbed}>
            <div dangerouslySetInnerHTML={{__html: htmlContent_embed}}></div>
          </div>
          <img className="elIphoneOverlay" style={style_elIphoneOverlay} src={img_elIphoneOverlay} alt=""  />
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" style={style_elCard} />
          </div>
          
          <div className="elRectangle" style={style_elRectangle} />
          <div className="baseFont elTextCopy2" style={style_elTextCopy2} onClick={this.onClick_elTextCopy2} >
            <div>{this.props.locStrings.iphonesim2_textcopy2_386021439}</div>
          </div>
          <div className="baseFont elTextCopy" style={style_elTextCopy} onClick={this.onClick_elTextCopy} >
            <div>{this.props.locStrings.iphonesim2_textcopy_1131574905}</div>
          </div>
          <div className="baseFont elTextCopy3" style={style_elTextCopy3} onClick={this.onClick_elTextCopy3} >
            <div>{this.props.locStrings.iphonesim2_textcopy3_1010379971}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
