import React, { Component } from 'react';
import './App.css';
import btn_icon_176111 from './images/btn_icon_176111.png';
import img_elImage2 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import btn_icon_387223 from './images/btn_icon_387223.png';
import Comp5 from './Comp5';
import Comp1 from './Comp1';
import Comp3 from './Comp3';
import MenuListItemAdd from './MenuListItemAdd';
import MenuListItem from './MenuListItem';
import LocationListItemAdd from './LocationListItemAdd';
import LocationListItem from './LocationListItem';
import Onboarding_Configs from './Onboarding_Configs';
import img_elImage4 from './images/BrandDetailsScreen_elImage4_440051652.jpg';
import BottomNavBar from './BottomNavBar';
import img_elImage3 from './images/DetailsComponent_state1_elRoundimage_743990.png';
import img_elBrandLogoImage from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_elImage from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';
import IPhoneMockupComponent from './IPhoneMockupComponent';
import Footer from './Footer';
import Header from './Header';
import Component4 from './Component4';
import HelpOverlayScreen from './HelpOverlayScreen';
import PublishOverlayScreen from './PublishOverlayScreen';
import RightDrawer from './RightDrawer';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Button from 'muicss/lib/react/button';
import Checkbox from 'muicss/lib/react/checkbox';

export default class BrandDetailsScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, dataSheetRow, bottomNavIconActiveColor, bottomNavIconColor, iconHomeURL, iconMenuURL, iconProfileURL, iconMoreURL, secondScreenTitle, onboarding_secondScreenTitle

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div>Brand Name</div>),
      text_plainText: "Brand Name",
      elFieldTitle_visible: false,
      elIconButton_visible: false,
      text_brand_details: (<div>Brand Details</div>),
      text_brand_details_plainText: "Brand Details",
      text_brand_name: (<div>Brand Name</div>),
      text_brand_name_plainText: "Brand Name",
      textcopy: (<div>Description</div>),
      textcopy_plainText: "Description",
      textcopy9: (<div>Primary Offering</div>),
      textcopy9_plainText: "Primary Offering",
      textcopy10: (<div>Other:</div>),
      textcopy10_plainText: "Other:",
      text_brand_detailscopy: (<div>Menus</div>),
      text_brand_detailscopy_plainText: "Menus",
      elValidator_visible: true,
      validator: (<div> </div>),
      validator_plainText: " ",
      text2: (<div>Start New Order</div>),
      text2_plainText: "Start New Order",
      titletext: (<div>Sample Item</div>),
      titletext_plainText: "Sample Item",
      pricetext: (<div>$5.00</div>),
      pricetext_plainText: "$5.00",
      elPublishOverlayScreen_visible: false,
      rightDrawer_isOpen: false,
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['fb_my_menus'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_my_menus;
      if ( !this.props.appActions.dataSheetLoaded['fb_my_menus']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menus", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_my_menus'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_my_menu_items'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_my_menu_items;
      if ( !this.props.appActions.dataSheetLoaded['fb_my_menu_items']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_my_menu_items'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_myBrands'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_myBrands;
      if ( !this.props.appActions.dataSheetLoaded['fb_myBrands']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("brands", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_myBrands'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menus'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menus;
      if ( !this.props.appActions.dataSheetLoaded['fb_menus']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/menus/", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menus'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_my_locations'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_my_locations;
      if ( !this.props.appActions.dataSheetLoaded['fb_my_locations']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/locations/", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_my_locations'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_locations'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_locations;
      if ( !this.props.appActions.dataSheetLoaded['fb_locations']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/locations/", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_locations'] = true;
      }
    }
    let dsArr =this.props.appActions.getDataSheet("fb_currentBrand");
    var arrayLength = dsArr.items.length;
    for (var i = 0; i < arrayLength; i++) {
    console.log("Found brand: "+dsArr[i]);
    }
 
    
    let newVal = "https://discuss.yesoms.com/t/walkthrough-the-brand-details-screen/";
    this.props.appActions.updateDataSlot('ds_forumHelpURL', "https://discuss.yesoms.com/t/walkthrough-the-brand-details-screen/");

	this.props.appActions.updateDataSlot('ds_sampleImageURL', this.state.fieldPicker);
 
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_iphone_mock_state', newVal);
    
    this.props.appActions.updateDataSlot('ds_brandPrimaryColor', this.props.dataSheetRow.primaryColor);
    this.props.appActions.updateDataSlot('ds_brandSecondaryColor', this.props.dataSheetRow.secondaryColor);
    this.props.appActions.updateDataSlot('ds_brandTitleColor', this.props.dataSheetRow.titleColor);
    this.props.appActions.updateDataSlot('ds_brandTextColor', this.props.dataSheetRow.textColor);
    this.props.appActions.updateDataSlot('ds_brandBottomNavColor', this.props.dataSheetRow.bottonNavColor);
    this.props.appActions.updateDataSlot('ds_brandOrderButtonColor', this.props.dataSheetRow.orderButtonColor);
    this.props.appActions.updateDataSlot('ds_brandOrderButtonTextColor', this.props.dataSheetRow.orderButtonTextColor);
    this.props.appActions.updateDataSlot('ds_brandOrderButtonTextString', this.props.dataSheetRow.orderButtonCopy);
    
    //this.props.appActions.updateDataSlot('ds_brandInactiveIconColor', this.props.dataSheetRow.bottomNavIconColor);
    //this.props.appActions.updateDataSlot('ds_brandActiveIconColor', this.props.dataSheetRow.bottomNavIconActiveColor);


    
    this.props.appActions.updateDataSlot('ds_brandCreateState', newVal);
    
    newVal = this.props.dataSheetRow.brandImageURL;
    this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', newVal);
    
    newVal = this.props.dataSheetRow.brandBackgroundImageURL;
    this.props.appActions.updateDataSlot('ds_backgroundImagefileStorageLocation', newVal);
        
    newVal = this.props.name;
    this.props.appActions.updateDataSlot('ds_activeBrandName', newVal);
    
    
      
    newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selectedbrandkey', newVal);
            
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elIconButton2 = async () => {
    let newVal = "yyyy";
    this.props.appActions.updateDataSlot('ds_selectedbrandkey', newVal);
  
    // Refresh data sheet
    await this.props.appActions.loadData_firebase1(this.props.appActions.dataSheets['fb_myBrands'], this.props.appActions.serviceOptions_fb_myBrands, false);
  
    // Go to screen 'Main Screen'
    this.props.appActions.goToScreen('mainScreen', { transitionId: 'fadeIn' });
  
  }
  
  
  textInputChanged_elFieldTitle = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_elFieldTitle = () => {
    return this.state.fieldTitle !== undefined ? this.state.fieldTitle : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  onClick_elIconButton = async () => {
    this.setState({elFieldTitle_visible: false});
  
    this.setState({elIconButton_visible: false});
  
  }
  
  
  textInputChanged_elField_brand_name = (event) => {
    this.setState({field_brand_name: event.target.value});
  }
  
  getValue_elField_brand_name = () => {
    return this.state.field_brand_name !== undefined ? this.state.field_brand_name : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  textAreaChanged_elFieldDescription = (event) => {
    this.setState({fieldDescription: event.target.value});
  }
  
  getValue_elFieldDescription = () => {
    return this.state.fieldDescription !== undefined ? this.state.fieldDescription : (this.props.dataSheetRow ? this.props.dataSheetRow.description : '');
  }
  
  
  getValue_elFieldPicker = () => {
    return this.state.fieldPicker !== undefined ? this.state.fieldPicker : (this.props.dataSheetRow ? this.props.dataSheetRow.brandOfferingType : '');
  }
  
  pickerValueChanged_elFieldPicker = (event) => {
    this.setState({fieldPicker: event.target.value});
    
    {
      let newVal = event.target.value;
      
      let transformValue = (input) => {
        // This function modifies the value written to the target data slot.
        // There is a variable named 'input' that provides the input value.
        
        // Get data slot value
        //
        // var exampleValue="";
        // exampleValue=this.props.appActions.dataSlots['ds_Example'];
        //
        // Update data slot value
        //
        // var exampleValue="foo";
        // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
        
        /*
        var sampleURLstring="https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fb70136b6-cbc5-4f56-8b3d-5739ad722e7b.png?alt=media";
        
        if(this.state.fieldPicker === "beerPub")
        	{
            sampleURLstring="https://www.clipartkey.com/mpngs/m/151-1512415_coffee-mug-3d-png-png-transparent-background-coffee.png";
            };
        
        
        this.props.appActions.updateDataSlot('ds_sampleImageURL', sampleURLstring);
        
        */
        
        return input;
      }
      newVal = transformValue(newVal);
      
      this.props.appActions.updateDataSlot("ds_sampleImageURL", newVal);
    }
  }
  
  textInputChanged_elFieldBrandOther = (event) => {
    this.setState({fieldBrandOther: event.target.value});
  }
  
  getValue_elFieldBrandOther = () => {
    return this.state.fieldBrandOther !== undefined ? this.state.fieldBrandOther : (this.props.dataSheetRow ? this.props.dataSheetRow.brandOfferingOther : '');
  }
  
  onClick_elComp3 = async () => {
    await this.sendData_elComp3_to_fb_myBrands();
  
  }
  
  
  
  
  onClick_elButtonCopy = async () => {
    this.toggle_rightDrawer();
  
  }
  
  
  onClick_elButtonCopy3 = async () => {
    this.setState({elPublishOverlayScreen_visible: true});
  
  }
  
  
  
  getValue_elField_isActiveCheckbox = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : (this.props.dataSheetRow ? this.props.dataSheetRow.isActive : '');
  }
  
  checkboxChanged_elField_isActiveCheckbox = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')});
  }
  
  toggle_rightDrawer = () => {
    this.setState({rightDrawer_isOpen: !this.state.rightDrawer_isOpen});
  }
  
  sendData_elComp3_to_fb_myBrands = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_myBrands');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "secondScreenTitle": this.props.secondScreenTitle,
      "onboarding_secondScreenTitle": this.props.onboarding_secondScreenTitle,
    };
    row = { ...row, 
      brandOfferingOther: this.getValue_elFieldBrandOther(),
      id: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''),
      name: this.getValue_elField_brand_name(),
      description: this.getValue_elFieldDescription(),
      brandOfferingType: this.getValue_elFieldPicker(),
    };
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_myBrands', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_myBrands', row);
    }
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_fb_currentBrand = this.props.dataSheets['fb_currentBrand'];
    // eslint-disable-next-line
    const dataSheet_fb_my_locations = this.props.dataSheets['fb_my_locations'];
    // eslint-disable-next-line
    const dataSheet_fb_my_menus = this.props.dataSheets['fb_my_menus'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    
    const style_elRectangle2 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_elIconButton2 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_176111+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImage2 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
    
    const style_elText = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const placeholderValue_fieldTitle = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_elFieldTitle = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const elFieldTitle = this.state.elFieldTitle_visible ? (
      <div className="elFieldTitle">
        <input className="font-futuraMedium" style={style_elFieldTitle} type="text" placeholder={placeholderValue_fieldTitle} onChange={this.textInputChanged_elFieldTitle} value={this.getValue_elFieldTitle()}  />
      </div>
      
     ) : null;
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_387223+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elIconButton = this.state.elIconButton_visible ? (
      <div className="elIconButton">
        <button className="actionFont" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
      </div>
      
     ) : null;
    const style_elText_brand_details = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elText_brand_name = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const placeholderValue_field_brand_name = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_elField_brand_name = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elFieldDescription = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    const style_elTextCopy9 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_fieldPicker = this.getValue_elFieldPicker();
    // Source datasheet and selected data column for picker element 'fieldPicker'
    const dataSource_fieldPicker = this.props.appActions.getDataSheet('ds_brand_offerings');
    const valueColumnName_fieldPicker = 'value';
    const labelColumnName_fieldPicker = 'name';
    
    const style_elFieldPicker = {
      pointerEvents: 'auto',
     };
    const style_elTextCopy10 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'right',
     };
    
    const style_elFieldBrandOther = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elComp3 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elText_brand_detailsCopy = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    listComps_list['_tail'] = <MenuListItemAdd appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    items_list = items_list.concat(this.props.appActions.getDataSheet('fb_my_menus').items);
    items_list.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elText_locations = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list_locations'.
    let items_list_locations = [];
    let listComps_list_locations = {};
    listComps_list_locations['_tail'] = <LocationListItemAdd appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    items_list_locations = items_list_locations.concat(this.props.appActions.getDataSheet('fb_my_locations').items);
    items_list_locations.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._elList_locations_items = [];
    
    const style_elList_locations = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elText_brand_detailsCopy2 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elValidator = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const elValidator = this.state.elValidator_visible ? (
      <div className="elValidator">
        <div className="baseFont" style={style_elValidator}>
          <div>{this.state.validator}</div>
        </div>
      </div>
      
     ) : null;
    
    const style_elButtonCopy = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const enabledValue_buttonCopy3 = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_false'] : '');
    
    const style_elButtonCopy3 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elText3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText_brand_detailsCopy3 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elText_brand_detailsCopy3_outer = {
      display: 'none',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_elField_isActiveCheckbox();
    
    const style_elField_isActiveCheckbox = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elField_isActiveCheckbox_outer = {
      display: 'none',
     };
    
    const fillColorValue_rectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_elRectangle = {
      background: (fillColorValue_rectangle && fillColorValue_rectangle.length > 0) ? fillColorValue_rectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const style_elImage4 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_backgroundImagefileStorageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      display: 'none',
     };
    const style_elBottomNavBar = {
      display: 'none',
     };
    
    const style_elRectangleCopy = {
      background: 'rgba(255, 255, 255, 1.000)',
      border: '0.5px solid rgba(43, 79, 255, 0.4500)',
      borderRadius: '6.0px',
      display: 'none',
     };
    const style_elText2 = {
      fontSize: 15.9,
      color: '#0c81fe',
      textAlign: 'center',
      display: 'none',
     };
    let transformColorPropValue_titleText = (input) => {
      // This function modifies the value for property 'color'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      return input;
    }
    const style_elTitleText = {
      fontSize: 31.0,
      color: transformColorPropValue_titleText(this.props.dataSheetRow ? this.props.dataSheetRow.titleColor : ''),
      textAlign: 'center',
      display: 'none',
     };
    const style_elPriceText = {
      color: this.props.dataSheetRow ? this.props.dataSheetRow.textColor : '',
      textAlign: 'center',
      display: 'none',
     };
    const style_elImage3 = {
      backgroundImage: 'url('+img_elImage3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      display: 'none',
     };
    const fillColorValue_card2 = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    const style_elCard2 = {
      width: '100%',
      height: '100%',
     };
    const style_elCard2_outer = {
      display: 'none',
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_elBrandLogoImage = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      display: 'none',
     };
    const style_elImage = {
      height: 'auto',
      display: 'none',
     };
    const style_elComponent2 = {
      display: 'none',
     };
    const elComponent = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps elComponent">
        <Component4 {...dataSheet_fb_currentBrand.items[0]} ref={(el)=> this._elComponent = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const elHelpOverlayScreen = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_showHelpOverlay'] : '')) ? (
      <div className="hasNestedComps elHelpOverlayScreen">
        <HelpOverlayScreen visualStateIndex={1} ref={(el)=> this._elHelpOverlayScreen = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const elPublishOverlayScreen = this.state.elPublishOverlayScreen_visible ? (
      <div className="hasNestedComps elPublishOverlayScreen">
        <PublishOverlayScreen ref={(el)=> this._elPublishOverlayScreen = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const style_rightDrawer = {
      pointerEvents: (this.state.rightDrawer_isOpen) ? 'auto' : 'none'
    }
    
    return (
      <div className="AppScreen BrandDetailsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elRectangle2">
            <div style={style_elRectangle2} />
          </div>
          
          <div className="elIconButton2">
            <button className="actionFont" style={style_elIconButton2} onClick={this.onClick_elIconButton2}  />
          </div>
          
          <div className="elImage2">
            <div style={style_elImage2} />
          </div>
          
          <div className="elText">
            <div className="font-futuraMedium" style={style_elText}>
              <div>{value_text}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
          
          { elFieldTitle }
          { elIconButton }
          <div className="elText_brand_details">
            <div className="font-futuraMedium" style={style_elText_brand_details}>
              <div>{this.state.text_brand_details}</div>
            </div>
          </div>
          
          <div className="elText_brand_name">
            <div className="font-avenirBook" style={style_elText_brand_name}>
              <div>{this.state.text_brand_name}</div>
            </div>
          </div>
          
          <div className="elField_brand_name">
            <input className="font-avenirNextRegular" style={style_elField_brand_name} type="text" placeholder={placeholderValue_field_brand_name} onChange={this.textInputChanged_elField_brand_name} value={this.getValue_elField_brand_name()}  />
          </div>
          
          <div className="elTextCopy">
            <div className="font-avenirBook" style={style_elTextCopy}>
              <div>{this.state.textcopy}</div>
            </div>
          </div>
          
          <div className="elFieldDescription">
            <textarea className="font-avenirNextRegular" style={style_elFieldDescription}  placeholder="{Optional - Describe your brand to your audience on the App Store}" onChange={this.textAreaChanged_elFieldDescription} value={this.getValue_elFieldDescription()}  />
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
          
          <div className="elTextCopy9">
            <div className="font-avenirBook" style={style_elTextCopy9}>
              <div>{this.state.textcopy9}</div>
            </div>
          </div>
          
          <div className="elFieldPicker">
            <Select className="baseFont" style={style_elFieldPicker}  onChange={this.pickerValueChanged_elFieldPicker} value={selection_fieldPicker} >
              {dataSource_fieldPicker.items.every(item => {
                return item[valueColumnName_fieldPicker] !== selection_fieldPicker;
              }) ? <Option value=''/> : null}
              {dataSource_fieldPicker.items.map(item => {
                const colValue = item[valueColumnName_fieldPicker];
                const labelColValue = item[labelColumnName_fieldPicker];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="elTextCopy10">
            <div className="font-avenirBook" style={style_elTextCopy10}>
              <div>{this.state.textcopy10}</div>
            </div>
          </div>
          
          <div className="elFieldBrandOther">
            <input className="font-avenirNextRegular" style={style_elFieldBrandOther} type="text" placeholder="{other}" onChange={this.textInputChanged_elFieldBrandOther} value={this.getValue_elFieldBrandOther()}  />
          </div>
          
          <div className="hasNestedComps elComp3">
            <div style={style_elComp3} onClick={this.onClick_elComp3} >
              <Comp5 ref={(el)=> this._elComp3 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="hasNestedComps elComp">
            <div>
              <Comp1 {...this.props} ref={(el)=> this._elComp = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="hasNestedComps elComp2">
            <div>
              <Comp3 {...this.props} ref={(el)=> this._elComp2 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elText_brand_detailsCopy">
            <div className="font-futuraMedium" style={style_elText_brand_detailsCopy}>
              <div>{this.state.text_brand_detailscopy}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elList">
            <ul style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menus'} dataSheetRow={row} {...{ 'name': row['name'], 'order': row['order'], 'document_key': row['document_key'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div>
          
          <div className="elText_locations">
            <div className="font-futuraMedium" style={style_elText_locations}>
              <div>{this.props.locStrings.branddetails_text_brand_detailscopy4_805833904}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elList_locations">
            <ul style={style_elList_locations}>
              {items_list_locations.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list_locations[row._componentId]
                    : <LocationListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_locations'} dataSheetRow={row} {...{ 'name': row['name'], 'order': row['order'], 'document_key': row['document_key'], }} ref={(el) => {if (el) this._elList_locations_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_locations_endMarker = el} />
            </ul>
          </div>
          
          <div className="hasNestedComps elOnboarding_Configs">
            <div>
              <Onboarding_Configs onboarding_secondScreenTitle={this.props.onboarding_secondScreenTitle} {...this.props} ref={(el)=> this._elOnboarding_Configs = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elText_brand_detailsCopy2">
            <div className="font-futuraMedium" style={style_elText_brand_detailsCopy2}>
              <div>{this.props.locStrings.branddetails_text_brand_detailscopy2_403866044}</div>
            </div>
          </div>
          
          { elValidator }
          <div className="elButtonCopy">
            <Button className="actionFont" style={style_elButtonCopy}  color="accent" onClick={this.onClick_elButtonCopy} >
              Preview App
            </Button>
          </div>
          
          <div className="elButtonCopy3">
            <Button className="actionFont" style={style_elButtonCopy3}  color="accent" disabled={''+enabledValue_buttonCopy3 !== 'true'} onClick={this.onClick_elButtonCopy3} >
              {this.props.locStrings.branddetails_buttoncopy3_159021495}
            </Button>
          </div>
          
          <div className="elText3">
            <div className="baseFont" style={style_elText3}>
              <div>{this.props.locStrings.branddetails_text3_120737078}</div>
            </div>
          </div>
          
          <div className="elText_brand_detailsCopy3" style={style_elText_brand_detailsCopy3_outer}>
            <div className="font-futuraMedium" style={style_elText_brand_detailsCopy3}>
              <div>{this.props.locStrings.branddetails_text_brand_detailscopy3_1195324614}</div>
            </div>
          </div>
          
          <div className="elField_isActiveCheckbox" style={style_elField_isActiveCheckbox_outer}>
            <Checkbox className="font-openSans" style={style_elField_isActiveCheckbox}  defaultChecked label="Brand Is Active" checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_elField_isActiveCheckbox} />
          </div>
          
          <div className="elSpacer3">
            <div />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elRectangle" style={style_elRectangle} />
            <div className="elImage4" style={style_elImage4} />
            <div className="hasNestedComps elBottomNavBar" style={style_elBottomNavBar}>
              <BottomNavBar ref={(el)=> this._elBottomNavBar = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="elRectangleCopy" style={style_elRectangleCopy} />
            <div className="systemFontBold  elText2" style={style_elText2}>
              <div>{this.state.text2}</div>
            </div>
            <div className="systemFontBold  elTitleText" style={style_elTitleText}>
              <div>{this.state.titletext}</div>
            </div>
            <div className="baseFont elPriceText" style={style_elPriceText}>
              <div>{this.state.pricetext}</div>
            </div>
            <div className="elImage3" style={style_elImage3} />
            <div className="elCard2" style={style_elCard2_outer}>
              <div style={style_elCard2} />
            </div>
            
            <div className="elBrandLogoImage" style={style_elBrandLogoImage} />
            <img className="elImage" style={style_elImage} src={img_elImage} alt=""  />
            <div className="hasNestedComps elIPhoneMockupComponent">
              <IPhoneMockupComponent {...dataSheet_fb_currentBrand.items[0]} ref={(el)=> this._elIPhoneMockupComponent = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps elFooter2">
              <Footer {...this.props} ref={(el)=> this._elFooter2 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps elHeader">
              <Header ref={(el)=> this._elHeader = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps elComponent2" style={style_elComponent2}>
              <Component4 ref={(el)=> this._elComponent2 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { elComponent }
            { elHelpOverlayScreen }
            { elPublishOverlayScreen }
          </div>
        </div>
        <div className="drawer rightDrawer" style={style_rightDrawer} onClick={this.toggle_rightDrawer}>
          <div className={"drawerContent" + (this.state.rightDrawer_isOpen ? " drawerOpen": " drawerClosedRight")} onClick={(e) => {e.stopPropagation();}}>
            <RightDrawer appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} closeDrawer={this.toggle_rightDrawer} />
          </div>
        </div>
      </div>
    )
  }
  
}
