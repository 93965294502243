import React, { Component } from 'react';
import './App.css';

export default class Screen64 extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, currentPrimaryColor

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    const fillColorValue_card = this.props.currentPrimaryColor;
    const style_elCard_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card && fillColorValue_card.length > 0) ? fillColorValue_card : 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    return (
      <div className="AppScreen Screen64" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elCard" style={style_elCard_outer}>
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
