// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_fb_menus extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['description'] = "";
    item['geohash'] = "9q8yy";
    item['updated'] = "1609287049987";
    item['geoLocation'] = {"_long":0,"_lat":0};
    item['document_path'] = "locations/2JaWmV1aOQgjvQezYZuE";
    item['hours'] = {"friday_open":1100,"sunday_close":2200,"saturday_open":1100,"sunday_open":900,"thursday_open":1100,"wednesday_open":1100,"friday_close":2200,"monday_open":-1,"wednesday_close":2200,"tuesday_open":1100,"saturday_close":2200,"thursday_close":2200,"monday_close":-1,"tuesday_close":2200};
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['key'] = "0";
    item['address'] = {"zipCode":"","country":"","streetNumber":"","city":"","streetName":"","state":""};
    item['document_key'] = "2JaWmV1aOQgjvQezYZuE";
    item['created'] = "1609286537714";
    item['name'] = "West Side";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['updated'] = "1609286465955";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['document_path'] = "menus/4NkVg2kQ44Y5Fqmg0qs9";
    item['order'] = "2";
    item['document_key'] = "4NkVg2kQ44Y5Fqmg0qs9";
    item['created'] = "1609286332218";
    item['key'] = "0";
    item['description'] = "";
    item['name'] = "Salads";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['updated'] = "1609286470081";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['document_path'] = "menus/6epN4qo5ZCNgD5RDlGEK";
    item['order'] = "5";
    item['document_key'] = "6epN4qo5ZCNgD5RDlGEK";
    item['created'] = "1609286316160";
    item['key'] = "1";
    item['description'] = "";
    item['name'] = "Pub House Favorites";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['updated'] = "1609286473802";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['document_path'] = "menus/LgHPtc3ldUANjXg8vQzG";
    item['order'] = "8";
    item['document_key'] = "LgHPtc3ldUANjXg8vQzG";
    item['created'] = "1609286345722";
    item['key'] = "2";
    item['description'] = "";
    item['name'] = "Beers On Tap";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['updated'] = "1609286471358";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['document_path'] = "menus/OWGY2RpUFfup81Sy0P8q";
    item['order'] = "6";
    item['document_key'] = "OWGY2RpUFfup81Sy0P8q";
    item['created'] = "1609286321313";
    item['key'] = "3";
    item['description'] = "";
    item['name'] = "Kids Menu";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['updated'] = "1609286468863";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['document_path'] = "menus/U8PCFXDcFsBVaReOZlow";
    item['order'] = "4";
    item['document_key'] = "U8PCFXDcFsBVaReOZlow";
    item['created'] = "1609286309647";
    item['key'] = "4";
    item['description'] = "";
    item['name'] = "Burgers";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['updated'] = "1609286464285";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['document_path'] = "menus/eAEcfuan5eVEL1EDm3am";
    item['order'] = "1";
    item['document_key'] = "eAEcfuan5eVEL1EDm3am";
    item['created'] = "1609286327945";
    item['key'] = "5";
    item['description'] = "";
    item['name'] = "Appetizers";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['updated'] = "1609286467808";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['document_path'] = "menus/lubixZZIuWRzRvYllP4b";
    item['order'] = "3";
    item['document_key'] = "lubixZZIuWRzRvYllP4b";
    item['created'] = "1609286336999";
    item['key'] = "6";
    item['description'] = "";
    item['name'] = "Sandwiches";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['dummy'] = "dummy";
    item['document_key'] = "s1IzsLL2ZohXDRpLjh47";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['updated'] = "1609286472897";
    item['associatedBrand'] = "mNEdFor3G16WDziEXwlV";
    item['document_path'] = "menus/uP1z9FEeZSaRLnlT6pzu";
    item['order'] = "7";
    item['document_key'] = "uP1z9FEeZSaRLnlT6pzu";
    item['created'] = "1609286349650";
    item['key'] = "7";
    item['description'] = "";
    item['name'] = "Specials";
    item['isActive'] = "1";
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    if (item.document_key && item.document_key.length > 0) {
      let parsedItem = (({ document_key, ...item }) => (item))( item );
      addItemPromise = collection.doc(item.document_key).set(parsedItem);
    } else {
      addItemPromise = collection.add(item);
    }
    addItemPromise
      .then((docRef) => {
        var addedKey="";
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          addedKey=docRef.id;
          item.document_key = docRef.id;
          item.document_path = docRef.path;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
          addedKey=item.document_key;
        }   
        if (this.onLastAddedDocumentKeyChanged != null) {
          this.onLastAddedDocumentKeyChanged(addedKey);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
  
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
      
    docRef.set((({ key, document_ref, document_key, ...item }) => (item))( item ), {merge: true})
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  

}
