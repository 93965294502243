import React, { Component } from 'react';
import './App.css';
import btn_icon_43207 from './images/btn_icon_43207.png';
import img_elImage from './images/BottomNavBar_elImage_355043.jpg';
import btn_icon_986651 from './images/btn_icon_986651.png';
import btn_icon_939316 from './images/btn_icon_939316.png';
import btn_icon_88705 from './images/btn_icon_88705.png';
import img_elHomeIcon from './images/BottomNavBar_elHomeIcon_748753715.png';
import img_elMenuIcon from './images/BottomNavBar_elMenuIcon_1516905259.png';
import img_elOrderIcon from './images/BottomNavBar_elOrderIcon_1472790689.png';
import img_elProfileIcon from './images/BottomNavBar_elProfileIcon_1905245662.png';
import img_elMoreIcon from './images/BottomNavBar_elMoreIcon_442964798.png';

export default class BottomNavBar extends Component {

  // Properties used by this component:
  // bottomNavIconActiveColor, bottomNavIconColor, iconMenuURL, iconProfileURL, iconMoreURL, iconHomeURL

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div>Profile</div>),
      text_plainText: "Profile",
      textcopy: (<div>Home</div>),
      textcopy_plainText: "Home",
      textcopy2: (<div>My Orders</div>),
      textcopy2_plainText: "My Orders",
      textcopy3: (<div>Menu</div>),
      textcopy3_plainText: "Menu",
      textcopy4: (<div>More</div>),
      textcopy4_plainText: "More",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    const fillColorValue_background = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandBottomNavColor'] : '');
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_background && fillColorValue_background.length > 0) ? fillColorValue_background : 'white',
     };
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_43207+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    const style_elIconButton_outer = {
      display: 'none',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elImage_outer = {
      display: 'none',
     };
    
    const style_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_986651+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '97.530%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    const style_elIconButtonCopy_outer = {
      display: 'none',
     };
    
    const style_elIconButtonCopy2 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_939316+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    const style_elIconButtonCopy2_outer = {
      display: 'none',
     };
    
    const style_elIconButtonCopy3 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_88705+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    const style_elIconButtonCopy3_outer = {
      display: 'none',
     };
    const style_elHomeIcon = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.iconHomeURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_elHomeIcon_outer = {
      display: 'none',
     };
    let transformPropValue_menuIcon = (input) => {
      // This function modifies the value for property 'image'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:3D315B,e_colorize:100,f_png,h_96,w_96/v1589821146/yesIcons/homeIcon.svg
      
      
      //this.props.bottomNavIconActiveColor
      
      
      /*
      // this is trying to generate the image with just the color string, not the whole URL in firebase.
      
      var iconURLString = "";
      
      if(this.props.bottomNavIconColor === "")
      	{
          iconURLString="https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:";
          iconURLString+="666666";
         	iconURLString+=",e_colorize:100,f_png,h_96,w_96/v1589821146/yesIcons/homeIcon.svg";
          };
      
      if(this.props.bottomNavIconColor != "")
      	{
          var iconColor = this.props.bottomNavIconColor.substr(this.props.bottomNavIconColor.length - 6);
          iconURLString="https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:";
          iconURLString+=iconColor; 
         	iconURLString+=",e_colorize:100,f_png,h_96,w_96/v1589821146/yesIcons/homeIcon.svg";
          };
      
      input.iconHomeURL = iconURLString;
      
      */
      
      return input;
    }
    const style_elMenuIcon = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(transformPropValue_menuIcon(this.props.iconMenuURL))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_elMenuIcon_outer = {
      display: 'none',
     };
    let transformPropValue_orderIcon = (input) => {
      // This function modifies the value for property 'image'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:3D315B,e_colorize:100,f_png,h_96,w_96/v1589821146/yesIcons/homeIcon.svg
      
      
      //this.props.bottomNavIconActiveColor
      
      
      /*
      // this is trying to generate the image with just the color string, not the whole URL in firebase.
      
      var iconURLString = "";
      var iconColor = this.props.bottomNavIconActiveColor.substr(this.props.bottomNavIconActiveColor.length - 6);
      
      if(this.props.bottomNavIconActiveColor === "")
      	{
          iconURLString="https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:";
          iconURLString+="666666";
         	iconURLString+=",e_colorize:100,f_png,h_96,w_96/v1589821146/yesIcons/homeIcon.svg";
          };
      
      if(this.props.bottomNavIconActiveColor != "")
      	{
          iconURLString="https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:";
          iconURLString+=iconColor; 
         	iconURLString+=",e_colorize:100,f_png,h_96,w_96/v1589821146/yesIcons/homeIcon.svg";
          };
      
      input.iconHomeURL = iconURLString;
      */
      
      return input;
    }
    const style_elOrderIcon = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(transformPropValue_orderIcon(this.props.iconMenuURL))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_elOrderIcon_outer = {
      display: 'none',
     };
    let transformPropValue_profileIcon = (input) => {
      // This function modifies the value for property 'image'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:3D315B,e_colorize:100,f_png,h_96,w_96/v1589821146/yesIcons/homeIcon.svg
      
      
      //this.props.bottomNavIconActiveColor
      
      /*
      
      // this is trying to generate the image with just the color string, not the whole URL in firebase.
      
      var iconURLString = "";
      var iconColor = this.props.bottomNavIconActiveColor.substr(this.props.bottomNavIconActiveColor.length - 6);
      
      if(this.props.bottomNavIconActiveColor === "")
      	{
          iconURLString="https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:";
          iconURLString+="666666";
         	iconURLString+=",e_colorize:100,f_png,h_96,w_96/v1589821146/yesIcons/homeIcon.svg";
          };
      
      if(this.props.bottomNavIconActiveColor != "")
      	{
          iconURLString="https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:";
          iconURLString+=iconColor; 
         	iconURLString+=",e_colorize:100,f_png,h_96,w_96/v1589821146/yesIcons/homeIcon.svg";
          };
      
      input.iconHomeURL = iconURLString;
      
      */
      return input;
    }
    const style_elProfileIcon = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(transformPropValue_profileIcon(this.props.iconProfileURL))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_elProfileIcon_outer = {
      display: 'none',
     };
    let transformPropValue_moreIcon = (input) => {
      // This function modifies the value for property 'image'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:3D315B,e_colorize:100,f_png,h_96,w_96/v1589821146/yesIcons/homeIcon.svg
      
      
      //this.props.bottomNavIconActiveColor
      
      /*
      
      // this is trying to generate the image with just the color string, not the whole URL in firebase.
      
      var iconURLString = "";
      var iconColor = this.props.bottomNavIconActiveColor.substr(this.props.bottomNavIconActiveColor.length - 6);
      
      if(this.props.bottomNavIconActiveColor === "")
      	{
          iconURLString="https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:";
          iconURLString+="666666";
         	iconURLString+=",e_colorize:100,f_png,h_96,w_96/v1589821146/yesIcons/homeIcon.svg";
          };
      
      if(this.props.bottomNavIconActiveColor != "")
      	{
          iconURLString="https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:";
          iconURLString+=iconColor; 
         	iconURLString+=",e_colorize:100,f_png,h_96,w_96/v1589821146/yesIcons/homeIcon.svg";
          };
      
      input.iconHomeURL = iconURLString;
      
      */
      return input;
    }
    const style_elMoreIcon = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(transformPropValue_moreIcon(this.props.iconMoreURL))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_elMoreIcon_outer = {
      display: 'none',
     };
    let transformPropValue_heartSVG = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      
      let activeColor = this.props.appActions.dataSlots['ds_brandActiveIconColor']; // Data slot value
      
      var HTML = '<Svg width="35" height="35" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >';
      HTML = HTML + '<Path fill-rule="evenodd" clip-rule="evenodd"';
      HTML = HTML + 'd="M12.0122 5.57169L10.9252 4.48469C8.77734 2.33681 5.29493 2.33681 3.14705 4.48469C0.999162 6.63258 0.999162 10.115 3.14705 12.2629L11.9859 21.1017L11.9877 21.0999L12.014 21.1262L20.8528 12.2874C23.0007 10.1395 23.0007 6.65711 20.8528 4.50923C18.705 2.36134 15.2226 2.36134 13.0747 4.50923L12.0122 5.57169ZM11.9877 18.2715L16.9239 13.3352L18.3747 11.9342L18.3762 11.9356L19.4386 10.8732C20.8055 9.50635 20.8055 7.29028 19.4386 5.92344C18.0718 4.55661 15.8557 4.55661 14.4889 5.92344L12.0133 8.39904L12.006 8.3918L12.005 8.39287L9.51101 5.89891C8.14417 4.53207 5.92809 4.53207 4.56126 5.89891C3.19442 7.26574 3.19442 9.48182 4.56126 10.8487L7.10068 13.3881L7.10248 13.3863L11.9877 18.2715Z"';
      HTML = HTML + 'fill= "';
      HTML = HTML + activeColor;
      HTML = HTML + '" /> </Svg> ';
      return HTML;
    }
    
    // Embedded HTML content for element 'heart SVG'
    const htmlContent_heartSVG = transformPropValue_heartSVG((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandActiveIconColor'] : ''));
    
    const style_elHeartSVG = {
      pointerEvents: 'auto',
     };
    let transformPropValue_menuSVG = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      let inactiveColor = this.props.appActions.dataSlots['ds_brandInactiveIconColor']; // Data slot value
      
      var HTML = '<svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path   d="M8.01562 6.98193C7.46334 6.98193 7.01562 7.43285 7.01562 7.98513C7.01562 8.53742 7.46334 8.98833 8.01563 8.98833H15.9659C16.5182 8.98833 16.9659 8.53742 16.9659 7.98513C16.9659 7.43285 16.5182 6.98193 15.9659 6.98193H8.01562Z" fill="';
      HTML = HTML + inactiveColor;
      HTML = HTML + '"/> <path   d="M7.01562 12C7.01562 11.4477 7.46334 10.9968 8.01562 10.9968H15.9659C16.5182 10.9968 16.9659 11.4477 16.9659 12C16.9659 12.5523 16.5182 13.0032 15.9659 13.0032H8.01563C7.46334 13.0032 7.01562 12.5523 7.01562 12Z" fill="';
      HTML = HTML + inactiveColor;
      HTML = HTML + '"/> <path   d="M8.0249 15.0122C7.47262 15.0122 7.0249 15.4631 7.0249 16.0154C7.0249 16.5677 7.47262 17.0186 8.0249 17.0186H15.9752C16.5275 17.0186 16.9752 16.5677 16.9752 16.0154C16.9752 15.4631 16.5275 15.0122 15.9752 15.0122H8.0249Z" fill="';
      HTML = HTML + inactiveColor;
      HTML = HTML + '"/> <path   fill-rule="evenodd"   clip-rule="evenodd"   d="M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6ZM6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V6C5 5.44772 5.44772 5 6 5Z" fill="';
      HTML = HTML + inactiveColor;
      HTML = HTML + '"/> </svg>';
      
      return HTML;
    }
    
    // Embedded HTML content for element 'Menu SVG'
    const htmlContent_menuSVG = transformPropValue_menuSVG((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandInactiveIconColor'] : ''));
    
    const style_elMenuSVG = {
      pointerEvents: 'auto',
     };
    let transformPropValue_ordersSVG = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      
      let inactiveColor = this.props.appActions.dataSlots['ds_brandInactiveIconColor']; // Data slot value
      
      var HTML = '<svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z" fill="';
      HTML = HTML + inactiveColor;
      HTML = HTML + '" /> <path fill-rule="evenodd" clip-rule="evenodd" d="M13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H13V7Z" fill="';
      HTML = HTML + inactiveColor;
      HTML = HTML + '" /> </svg>';
      
      return HTML;
    }
    
    // Embedded HTML content for element 'Orders SVG'
    const htmlContent_ordersSVG = transformPropValue_ordersSVG((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandActiveIconColor'] : ''));
    
    const style_elOrdersSVG = {
      pointerEvents: 'auto',
     };
    let transformPropValue_profileSVG = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      
      let inactiveColor = this.props.appActions.dataSlots['ds_brandInactiveIconColor']; // Data slot value
      
      var HTML = '<svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path fill-rule="evenodd" clip-rule="evenodd" d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z" fill="';
      HTML = HTML + inactiveColor;
      HTML = HTML + '" /> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z" fill="';
      HTML = HTML + inactiveColor;
      HTML = HTML + '" /> </svg>';
      
      return HTML;
    }
    
    // Embedded HTML content for element 'Profile SVG'
    const htmlContent_profileSVG = transformPropValue_profileSVG((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandActiveIconColor'] : ''));
    
    const style_elProfileSVG = {
      pointerEvents: 'auto',
     };
    let transformPropValue_moreSVG = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      
      let inactiveColor = this.props.appActions.dataSlots['ds_brandInactiveIconColor']; // Data slot value
      
      var HTML = '<svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M7 14C8.10457 14 9 13.1046 9 12C9 10.8954 8.10457 10 7 10C5.89543 10 5 10.8954 5 12C5 13.1046 5.89543 14 7 14Z" fill="';
      HTML = HTML + inactiveColor;
      HTML = HTML + '" /> <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="';
      HTML = HTML + inactiveColor;
      HTML = HTML + '" /> <path d="M17 14C18.1046 14 19 13.1046 19 12C19 10.8954 18.1046 10 17 10C15.8954 10 15 10.8954 15 12C15 13.1046 15.8954 14 17 14Z" fill="';
      HTML = HTML + inactiveColor;
      HTML = HTML + '" /> <path fill-rule="evenodd" clip-rule="evenodd" d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="';
      HTML = HTML + inactiveColor;
      HTML = HTML + '" /> </svg>';
      
      return HTML;
    }
    
    // Embedded HTML content for element 'More SVG'
    const htmlContent_moreSVG = transformPropValue_moreSVG((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandActiveIconColor'] : ''));
    
    const style_elMoreSVG = {
      pointerEvents: 'auto',
     };
    const style_elText = {
      fontSize: 9.0,
      color: this.props.bottomNavIconColor,
      textAlign: 'center',
     };
    const style_elTextCopy = {
      fontSize: 9.0,
      color: this.props.bottomNavIconActiveColor,
      textAlign: 'center',
     };
    const style_elTextCopy2 = {
      fontSize: 9.0,
      color: this.props.bottomNavIconColor,
      textAlign: 'center',
     };
    let transformColorPropValue_textCopy3 = (input) => {
      // This function modifies the value for property 'color'.
      // There is a variable named 'input' that provides the property value.
      //
      
      // get color from here?
      return input;
    }
    const style_elTextCopy3 = {
      fontSize: 9.0,
      color: transformColorPropValue_textCopy3(this.props.bottomNavIconColor),
      textAlign: 'center',
     };
    const style_elTextCopy4 = {
      fontSize: 9.0,
      color: this.props.bottomNavIconColor,
      textAlign: 'center',
     };
    
    return (
      <div className="BottomNavBar">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="flowRow flowRow_BottomNavBar_elIconButton_43207">
          <div className="elIconButton" style={style_elIconButton_outer}>
            <button className="actionFont" style={style_elIconButton} />
          </div>
          
          <div className="elImage" style={style_elImage_outer}>
            <div style={style_elImage} />
          </div>
          
          <div className="elIconButtonCopy" style={style_elIconButtonCopy_outer}>
            <button className="actionFont" style={style_elIconButtonCopy} />
          </div>
          
          <div className="elIconButtonCopy2" style={style_elIconButtonCopy2_outer}>
            <button className="actionFont" style={style_elIconButtonCopy2} />
          </div>
          
          <div className="elIconButtonCopy3" style={style_elIconButtonCopy3_outer}>
            <button className="actionFont" style={style_elIconButtonCopy3} />
          </div>
          
          <div className="elHomeIcon" style={style_elHomeIcon_outer}>
            <div style={style_elHomeIcon} />
          </div>
          
          <div className="elMenuIcon" style={style_elMenuIcon_outer}>
            <div style={style_elMenuIcon} />
          </div>
          
          <div className="elOrderIcon" style={style_elOrderIcon_outer}>
            <div style={style_elOrderIcon} />
          </div>
          
          <div className="elProfileIcon" style={style_elProfileIcon_outer}>
            <div style={style_elProfileIcon} />
          </div>
          
          <div className="elMoreIcon" style={style_elMoreIcon_outer}>
            <div style={style_elMoreIcon} />
          </div>
          
          <div className="embeddedContent elHeartSVG">
            <div style={style_elHeartSVG}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_heartSVG}}></div>
            </div>
          </div>
          
          <div className="embeddedContent elMenuSVG">
            <div style={style_elMenuSVG}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_menuSVG}}></div>
            </div>
          </div>
          
          <div className="embeddedContent elOrdersSVG">
            <div style={style_elOrdersSVG}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_ordersSVG}}></div>
            </div>
          </div>
          
          <div className="embeddedContent elProfileSVG">
            <div style={style_elProfileSVG}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_profileSVG}}></div>
            </div>
          </div>
          
          <div className="embeddedContent elMoreSVG">
            <div style={style_elMoreSVG}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_moreSVG}}></div>
            </div>
          </div>
          
          <div className="elText">
            <div className="systemFontRegular" style={style_elText}>
              <div>{this.state.text}</div>
            </div>
          </div>
          
          <div className="elTextCopy">
            <div className="systemFontRegular" style={style_elTextCopy}>
              <div>{this.state.textcopy}</div>
            </div>
          </div>
          
          <div className="elTextCopy2">
            <div className="systemFontRegular" style={style_elTextCopy2}>
              <div>{this.state.textcopy2}</div>
            </div>
          </div>
          
          <div className="elTextCopy3">
            <div className="systemFontRegular" style={style_elTextCopy3}>
              <div>{this.state.textcopy3}</div>
            </div>
          </div>
          
          <div className="elTextCopy4">
            <div className="systemFontRegular" style={style_elTextCopy4}>
              <div>{this.state.textcopy4}</div>
            </div>
          </div>
          
          </div>
        </div>
        
      </div>
    )
  }
  
}
