import React, { Component } from 'react';
import './App.css';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Comp5 extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elButton_SaveCopy = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  renderState0() {
    
    const style_state0_elButton_SaveCopy = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Comp5">
        <div className="layoutFlow">
          <div className="state0_elButton_SaveCopy">
            <Button className="actionFont" style={style_state0_elButton_SaveCopy}  color="accent" onClick={this.onClick_state0_elButton_SaveCopy} >
              {this.props.locStrings.comp5_button_savecopy_224288968}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elButton_SaveCopy = async () => {
    this.setState({visualStateIndexOverride: 0})
  
  }
  
  
  renderState1() {
    
    const style_state1_elButton_SaveCopy = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Comp5">
        <div className="layoutFlow">
          <div className="state1_elButton_SaveCopy">
            <Button className="actionFont" style={style_state1_elButton_SaveCopy}  color="accent" onClick={this.onClick_state1_elButton_SaveCopy} >
              {this.props.locStrings.comp5_button_savecopy_1156941593}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
