import React, { Component } from 'react';
import './App.css';
import btn_icon_353159 from './images/btn_icon_353159.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Component4 extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      textcopy9: (<div>Version:</div>),
      textcopy9_plainText: "Version:",
      textcopy10: (<div>2.5</div>),
      textcopy10_plainText: "2.5",
      text: (<div>ds_selectedbrandkey</div>),
      text_plainText: "ds_selectedbrandkey",
      textcopy2: (<div>ds_selected_menu_key</div>),
      textcopy2_plainText: "ds_selected_menu_key",
      textcopy4: (<div>ds_selected_menuItem_key</div>),
      textcopy4_plainText: "ds_selected_menuItem_key",
      textcopy6: (<div>ds_selected_menu_option_key</div>),
      textcopy6_plainText: "ds_selected_menu_option_key",
    };
  }

  componentDidMount() {
    let dsArr =this.props.appActions.getDataSheet("fb_currentBrand");
    var arrayLength = dsArr.items.length;
    for (var i = 0; i < arrayLength; i++) {
    console.log("Found item: "+dsArr[i]);
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elFab = async () => {
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_show_debug_info', newVal);
  
  }
  
  
  textInputChanged_elField = (event) => {
    this.setState({field: event.target.value});
  }
  
  getValue_elField = () => {
    return this.state.field !== undefined ? this.state.field : ((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''));
  }
  
  textInputChanged_elFieldCopy = (event) => {
    this.setState({fieldCopy: event.target.value});
  }
  
  getValue_elFieldCopy = () => {
    return this.state.fieldCopy !== undefined ? this.state.fieldCopy : ((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_menu_key'] : ''));
  }
  
  textInputChanged_elFieldCopy2 = (event) => {
    this.setState({fieldCopy2: event.target.value});
  }
  
  getValue_elFieldCopy2 = () => {
    return this.state.fieldCopy2 !== undefined ? this.state.fieldCopy2 : ((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_menuItem_key'] : ''));
  }
  
  textInputChanged_elFieldCopy3 = (event) => {
    this.setState({fieldCopy3: event.target.value});
  }
  
  getValue_elFieldCopy3 = () => {
    return this.state.fieldCopy3 !== undefined ? this.state.fieldCopy3 : ((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_menuItemOption_key'] : ''));
  }
  
  textInputChanged_elFieldCopy4 = (event) => {
    this.setState({fieldCopy4: event.target.value});
  }
  
  getValue_elFieldCopy4 = () => {
    return this.state.fieldCopy4 !== undefined ? this.state.fieldCopy4 : ((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_sampleImageURL'] : ''));
  }
  
  render() {
    
    const style_elRectangle = {
      background: 'rgba(252, 254, 255, 1.000)',
      borderRadius: '10.0px',
      boxShadow: '0.0px 2.0px 3px rgba(0, 0, 0, 0.3000)',
     };
    
    const style_elFab = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextCopy9 = {
      color: '#eb771d',
      textAlign: 'left',
     };
    const style_elTextCopy10 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText = {
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy2 = {
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elFieldCopy = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy4 = {
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elFieldCopy2 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy6 = {
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elFieldCopy3 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy8 = {
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elFieldCopy4 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Component4">
        <div className="background">
          <div className="elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="elFab">
            <Button className="actionFont" style={style_elFab}  variant="fab" color="accent" onClick={this.onClick_elFab} >
              <img src={btn_icon_353159} alt="" style={{width: '50.000%', marginTop: '25.000%'}} />
            </Button>
          </div>
          
          <div className="elTextCopy9">
            <div className="baseFont" style={style_elTextCopy9}>
              <div>{this.state.textcopy9}</div>
            </div>
          </div>
          
          <div className="elTextCopy10">
            <div className="baseFont" style={style_elTextCopy10}>
              <div>{this.state.textcopy10}</div>
            </div>
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{this.state.text}</div>
            </div>
          </div>
          
          <div className="elField">
            <input className="baseFont" style={style_elField} type="text" placeholder="" onChange={this.textInputChanged_elField} value={this.getValue_elField()}  />
          </div>
          
          <div className="elTextCopy2">
            <div className="baseFont" style={style_elTextCopy2}>
              <div>{this.state.textcopy2}</div>
            </div>
          </div>
          
          <div className="elFieldCopy">
            <input className="baseFont" style={style_elFieldCopy} type="text" placeholder="" onChange={this.textInputChanged_elFieldCopy} value={this.getValue_elFieldCopy()}  />
          </div>
          
          <div className="elTextCopy4">
            <div className="baseFont" style={style_elTextCopy4}>
              <div>{this.state.textcopy4}</div>
            </div>
          </div>
          
          <div className="elFieldCopy2">
            <input className="baseFont" style={style_elFieldCopy2} type="text" placeholder="" onChange={this.textInputChanged_elFieldCopy2} value={this.getValue_elFieldCopy2()}  />
          </div>
          
          <div className="elTextCopy6">
            <div className="baseFont" style={style_elTextCopy6}>
              <div>{this.state.textcopy6}</div>
            </div>
          </div>
          
          <div className="elFieldCopy3">
            <input className="baseFont" style={style_elFieldCopy3} type="text" placeholder="" onChange={this.textInputChanged_elFieldCopy3} value={this.getValue_elFieldCopy3()}  />
          </div>
          
          <div className="elTextCopy8">
            <div className="baseFont" style={style_elTextCopy8}>
              <div>{this.props.locStrings.component4_textcopy8_1852328442}</div>
            </div>
          </div>
          
          <div className="elFieldCopy4">
            <input className="baseFont" style={style_elFieldCopy4} type="text" placeholder={this.props.locStrings.component4_fieldcopy4_118749176} onChange={this.textInputChanged_elFieldCopy4} value={this.getValue_elFieldCopy4()}  />
          </div>
        </div>
        
      </div>
    )
  }
  
}
