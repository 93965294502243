import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/DetailsComponent_state1_elRoundimage_743990.png';

export default class BrandButton extends Component {

  // Properties used by this component:
  // name, brandImageURL, document_key

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div> </div>),
      text_plainText: " ",
    };
  }

  componentDidMount() {
    /*
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_brandCreateState', newVal);
        
    newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selectedbrandkey', newVal);
        
    newVal = this.props.dataSheetRow.brandImageURL;
    this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', newVal);
    
    newVal = this.props.dataSheetRow.brandBackgroundImageURL;
    this.props.appActions.updateDataSlot('ds_backgroundImagefileStorageLocation', newVal);
        
    newVal = this.props.name;
    this.props.appActions.updateDataSlot('ds_activeBrandName', newVal);
        
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_iphone_mock_state', newVal);
    
    this.props.appActions.updateDataSlot('ds_brandPrimaryColor', this.props.dataSheetRow.primaryColor);
    this.props.appActions.updateDataSlot('ds_brandSecondaryColor', this.props.dataSheetRow.secondaryColor);
    this.props.appActions.updateDataSlot('ds_brandTitleColor', this.props.dataSheetRow.titleColor);
    this.props.appActions.updateDataSlot('ds_brandTextColor', this.props.dataSheetRow.textColor);
    //this.props.appActions.updateDataSlot('ds_brandInactiveIconColor', this.props.dataSheetRow.titleColor);
    //this.props.appActions.updateDataSlot('ds_brandActiveIconColor', this.props.dataSheetRow.titleColor);
    this.props.appActions.updateDataSlot('ds_brandBottomNavColor', this.props.dataSheetRow.bottonNavColor);
    this.props.appActions.updateDataSlot('ds_brandOrderButtonColor', this.props.dataSheetRow.orderButtonColor);
    this.props.appActions.updateDataSlot('ds_brandOrderButtonTextColor', this.props.dataSheetRow.orderButtonTextColor);
    this.props.appActions.updateDataSlot('ds_brandOrderButtonTextString', this.props.dataSheetRow.orderButtonCopy);
    
    */
            
    
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elText = async () => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_brandCreateState', newVal);
  
    newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selectedbrandkey', newVal);
  
    newVal = this.props.brandImageURL;
    this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', newVal);
  
    // Go to screen 'Brand Details'
    this.props.appActions.goToScreen('brandDetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elHotspot = async () => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_brandCreateState', newVal);
  
    newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selectedbrandkey', newVal);
  
    newVal = this.props.brandImageURL;
    this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', newVal);
  
    await this.sendData_elHotspot_to_fb_myBrands();
  
    // Go to screen 'Brand Details'
    this.props.appActions.goToScreen('brandDetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  sendData_elHotspot_to_fb_myBrands = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_myBrands');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "brandImageURL": this.props.brandImageURL,
      "document_key": this.props.document_key,
    };
    row = { ...row, 
      associatedParent: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''),
    };
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_myBrands', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_myBrands', row);
    }
  }
  
  
  render() {
    
    const style_elRectangle = {
      background: 'rgba(255, 255, 255, 1.000)',
      borderRadius: '10.0px',
      boxShadow: '0.0px 2.0px 3px rgba(0, 0, 0, 0.3000)',
     };
    const style_elImage = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.brandImageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.name);
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elHotspot = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="BrandButton">
        <div className="background">
          <div className="elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="foreground">
          <div className="elImage" style={style_elImage} />
          <div className="baseFont elText" style={style_elText} onClick={this.onClick_elText} >
            <div>{value_text}</div>
          </div>
          <div className="actionFont elHotspot" style={style_elHotspot} onClick={this.onClick_elHotspot}  />
        </div>
      </div>
    )
  }
  
}
