import React, { Component } from 'react';
import './App.css';
import img_elRoundimage from './images/BrandsList_elRoundimage_301691.png';
import btn_icon_638484 from './images/btn_icon_638484.png';
import btn_icon_1188560608 from './images/btn_icon_1188560608.png';
import img_elImageCopy from './images/Header_elImageCopy_86148.png';
import UserDetailDrawer from './UserDetailDrawer';

// UI framework component imports

export default class Header extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div> </div>),
      text_plainText: " ",
      userDetailDrawer_isOpen: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elIconButton2 = async () => {
    this.toggle_userDetailDrawer();
  
  }
  
  
  onClick_elIconButton = async () => {
    window.open((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_forumHelpURL'] : ''), '_blank');
  
  }
  
  
  toggle_userDetailDrawer = () => {
    this.setState({userDetailDrawer_isOpen: !this.state.userDetailDrawer_isOpen});
  }
  
  render() {
    const style_elHeader = {
      width: '100%',
      height: '100%',
     };
    const style_elHeader_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_userEmail'] : ''));
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'right',
     };
    
    const value_roundimage = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_userPhoto'] : '');
    
    const style_elRoundimage = {
      height: 'auto',
     };
    
    const style_elIconButton2 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_638484+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1188560608+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImageCopy = {
      backgroundImage: 'url('+img_elImageCopy+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_userDetailDrawer = {
      pointerEvents: (this.state.userDetailDrawer_isOpen) ? 'auto' : 'none'
    }
    
    return (
      <div className="Header">
        <div className="foreground">
          <div className="elHeader" style={style_elHeader_outer}>
            <div className="cardBg" style={style_elHeader} />
          </div>
          
          <div className="baseFont elText" style={style_elText}>
            <div>{value_text}</div>
          </div>
          <img className="elRoundimage" style={style_elRoundimage} src={this.props.appActions.createImageUrlFromProp(value_roundimage)} alt=""  />
          <button className="actionFont elIconButton2" style={style_elIconButton2} onClick={this.onClick_elIconButton2}  />
          <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
          <div className="elImageCopy" style={style_elImageCopy} />
        </div>
        <div className="drawer userDetailDrawer" style={style_userDetailDrawer} onClick={this.toggle_userDetailDrawer}>
          <div className={"drawerContent" + (this.state.userDetailDrawer_isOpen ? " drawerOpen": " drawerClosedRight")} onClick={(e) => {e.stopPropagation();}}>
            <UserDetailDrawer appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} closeDrawer={this.toggle_userDetailDrawer} />
          </div>
        </div>
      </div>
    )
  }
  
}
