import React, { Component } from 'react';
import './App.css';
import Comp_image_upload from './Comp_image_upload';
import Comp5 from './Comp5';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Onboarding_Configs extends Component {

  // Properties used by this component:
  // dataSheetRow, onboarding_secondScreenTitle

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elField_scene1_color = (event) => {
    this.setState({field_scene1_color: event.target.value});
  }
  
  getValue_elField_scene1_color = () => {
    return this.state.field_scene1_color !== undefined ? this.state.field_scene1_color : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen1_color : '');
  }
  
  textInputChanged_elField_scene1_title = (event) => {
    this.setState({field_scene1_title: event.target.value});
  }
  
  getValue_elField_scene1_title = () => {
    return this.state.field_scene1_title !== undefined ? this.state.field_scene1_title : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen1_title : '');
  }
  
  textInputChanged_elField_scene1_line1 = (event) => {
    this.setState({field_scene1_line1: event.target.value});
  }
  
  getValue_elField_scene1_line1 = () => {
    return this.state.field_scene1_line1 !== undefined ? this.state.field_scene1_line1 : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen1_line1 : '');
  }
  
  textInputChanged_elField_scene1_line2 = (event) => {
    this.setState({field_scene1_line2: event.target.value});
  }
  
  getValue_elField_scene1_line2 = () => {
    return this.state.field_scene1_line2 !== undefined ? this.state.field_scene1_line2 : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen1_line2 : '');
  }
  
  textInputChanged_elField_scene1_line3 = (event) => {
    this.setState({field_scene1_line3: event.target.value});
  }
  
  getValue_elField_scene1_line3 = () => {
    return this.state.field_scene1_line3 !== undefined ? this.state.field_scene1_line3 : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen1_line3 : '');
  }
  
  textInputChanged_elField_scene2_color = (event) => {
    this.setState({field_scene2_color: event.target.value});
  }
  
  getValue_elField_scene2_color = () => {
    return this.state.field_scene2_color !== undefined ? this.state.field_scene2_color : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen2_color : '');
  }
  
  textInputChanged_elField_scene2_title = (event) => {
    this.setState({field_scene2_title: event.target.value});
  }
  
  getValue_elField_scene2_title = () => {
    return this.state.field_scene2_title !== undefined ? this.state.field_scene2_title : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen2_title : '');
  }
  
  textInputChanged_elField_scene2_line1 = (event) => {
    this.setState({field_scene2_line1: event.target.value});
  }
  
  getValue_elField_scene2_line1 = () => {
    return this.state.field_scene2_line1 !== undefined ? this.state.field_scene2_line1 : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen2_line1 : '');
  }
  
  textInputChanged_elField_scene2_line2 = (event) => {
    this.setState({field_scene2_line2: event.target.value});
  }
  
  getValue_elField_scene2_line2 = () => {
    return this.state.field_scene2_line2 !== undefined ? this.state.field_scene2_line2 : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen2_line2 : '');
  }
  
  textInputChanged_elField_scene2_line3 = (event) => {
    this.setState({field_scene2_line3: event.target.value});
  }
  
  getValue_elField_scene2_line3 = () => {
    return this.state.field_scene2_line3 !== undefined ? this.state.field_scene2_line3 : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen2_line3 : '');
  }
  
  textInputChanged_elField_scene3_color = (event) => {
    this.setState({field_scene3_color: event.target.value});
  }
  
  getValue_elField_scene3_color = () => {
    return this.state.field_scene3_color !== undefined ? this.state.field_scene3_color : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen3_color : '');
  }
  
  textInputChanged_elField_scene3_title = (event) => {
    this.setState({field_scene3_title: event.target.value});
  }
  
  getValue_elField_scene3_title = () => {
    return this.state.field_scene3_title !== undefined ? this.state.field_scene3_title : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen3_title : '');
  }
  
  textInputChanged_elField_scene3_line1 = (event) => {
    this.setState({field_scene3_line1: event.target.value});
  }
  
  getValue_elField_scene3_line1 = () => {
    return this.state.field_scene3_line1 !== undefined ? this.state.field_scene3_line1 : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen3_line1 : '');
  }
  
  textInputChanged_elField_scene3_line2 = (event) => {
    this.setState({field_scene3_line2: event.target.value});
  }
  
  getValue_elField_scene3_line2 = () => {
    return this.state.field_scene3_line2 !== undefined ? this.state.field_scene3_line2 : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen3_line2 : '');
  }
  
  textInputChanged_elField_scene3_line3 = (event) => {
    this.setState({field_scene3_line3: event.target.value});
  }
  
  getValue_elField_scene3_line3 = () => {
    return this.state.field_scene3_line3 !== undefined ? this.state.field_scene3_line3 : (this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen3_line3 : '');
  }
  
  onClick_elButton = async () => {
    await this.sendData_elButton_to_fb_currentBrand();
  
  }
  
  
  onClick_elComp = async () => {
    await this.sendData_elComp_to_fb_currentBrand();
  
  }
  
  
  sendData_elButton_to_fb_currentBrand = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_currentBrand');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "onboarding_secondScreenTitle": this.props.onboarding_secondScreenTitle,
    };
    row = { ...row, 
      secondScreenTitle: this.getValue_elField_scene1_title(),
    };
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_currentBrand', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_currentBrand', row);
    }
  }
  
  
  sendData_elComp_to_fb_currentBrand = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_currentBrand');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "onboarding_secondScreenTitle": this.props.onboarding_secondScreenTitle,
    };
    row = { ...row, 
      onboarding_screen1_line3: this.getValue_elField_scene1_line3(),
      onboarding_screen3_line2: this.getValue_elField_scene3_line2(),
      onboarding_screen2_color: this.getValue_elField_scene2_color(),
      onboarding_screen2_line1: this.getValue_elField_scene2_line1(),
      onboarding_screen1_title: this.getValue_elField_scene1_title(),
      onboarding_screen3_line3: this.getValue_elField_scene3_color(),
      onboarding_screen2_line2: this.getValue_elField_scene2_line2(),
      onboarding_screen3_title: this.getValue_elField_scene3_title(),
      onboarding_screen1_color: this.getValue_elField_scene1_color(),
      onboarding_screen1_line1: this.getValue_elField_scene1_line1(),
      onboarding_screen2_line3: this.getValue_elField_scene2_line3(),
      onboarding_screen1_line2: this.getValue_elField_scene1_line2(),
      onboarding_screen2_title: this.getValue_elField_scene2_title(),
      onboarding_screen3_color: this.getValue_elField_scene3_color(),
      onboarding_screen3_line1: this.getValue_elField_scene3_line1(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      /*
      input.onboarding_screen1_color = this.state.field_scene1_color;
      input.onboarding_screen1_title = this.state.field_scene1_title;
      input.onboarding_screen1_line1 = this.state.field_scene1_line1;
      input.onboarding_screen1_line2 = this.state.field_scene1_line2;
      input.onboarding_screen1_line3 = this.state.field_scene1_line3;
      
      input.onboarding_screen2_color = this.state.field_scene2_color;
      input.onboarding_screen2_title = this.state.field_scene2_title;
      input.onboarding_screen2_line1 = this.state.field_scene2_line1;
      input.onboarding_screen2_line2 = this.state.field_scene2_line2;
      input.onboarding_screen2_line3 = this.state.field_scene2_line3;
      
      input.onboarding_screen3_color = this.state.field_scene3_color;
      input.onboarding_screen3_title = this.state.field_scene3_title;
      input.onboarding_screen3_line1 = this.state.field_scene3_line1;
      input.onboarding_screen3_line2 = this.state.field_scene3_line2;
      input.onboarding_screen3_line3 = this.state.field_scene3_line3;
      */
      
      return input;
      
      
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_currentBrand', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_currentBrand', row);
    }
  }
  
  
  render() {
    const style_elText_brand_detailsCopy2 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elText_brand_detailsCopy4 = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elTextCopy21 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene1_color = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy22 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene1_title = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy23 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene1_line1 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy24 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene1_line2 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy25 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene1_line3 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elText_brand_detailsCopy3 = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elTextCopy15 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene2_color = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy16 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene2_title = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy17 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene2_line1 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy18 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene2_line2 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy20 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene2_line3 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elText_brand_detailsCopy = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_elTextCopy10 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene3_color = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy11 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene3_title = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy12 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene3_line1 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy13 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene3_line2 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextCopy14 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_elField_scene3_line3 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elButton = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButton_outer = {
      display: 'none',
     };
    const style_elComp = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Onboarding_Configs">
        <div className="layoutFlow">
          <div className="elText_brand_detailsCopy2">
            <div className="font-futuraMedium" style={style_elText_brand_detailsCopy2}>
              <div>{this.props.locStrings.onboarding_configs_text_brand_detailscopy2_333339993}</div>
            </div>
          </div>
          
          <div className="elText_brand_detailsCopy4">
            <div className="actionFont" style={style_elText_brand_detailsCopy4}>
              <div>{this.props.locStrings.onboarding_configs_text_brand_detailscopy4_400767581}</div>
            </div>
          </div>
          
          <div className="elTextCopy21">
            <div className="font-avenirBook" style={style_elTextCopy21}>
              <div>{this.props.locStrings.onboarding_configs_textcopy21_1118022401}</div>
            </div>
          </div>
          
          <div className="elField_scene1_color">
            <input className="actionFont" style={style_elField_scene1_color} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreenline3copy_1499502159} onChange={this.textInputChanged_elField_scene1_color} value={this.getValue_elField_scene1_color()}  />
          </div>
          
          <div className="elTextCopy22">
            <div className="font-avenirBook" style={style_elTextCopy22}>
              <div>{this.props.locStrings.onboarding_configs_textcopy22_2012426126}</div>
            </div>
          </div>
          
          <div className="elField_scene1_title">
            <input className="actionFont" style={style_elField_scene1_title} type="text" placeholder={this.props.locStrings.onboarding_configs_field_2114272836} onChange={this.textInputChanged_elField_scene1_title} value={this.getValue_elField_scene1_title()}  />
          </div>
          
          <div className="elTextCopy23">
            <div className="font-avenirBook" style={style_elTextCopy23}>
              <div>{this.props.locStrings.onboarding_configs_textcopy23_1942841711}</div>
            </div>
          </div>
          
          <div className="elField_scene1_line1">
            <input className="actionFont" style={style_elField_scene1_line1} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreentitlecopy_388705051} onChange={this.textInputChanged_elField_scene1_line1} value={this.getValue_elField_scene1_line1()}  />
          </div>
          
          <div className="elTextCopy24">
            <div className="font-avenirBook" style={style_elTextCopy24}>
              <div>{this.props.locStrings.onboarding_configs_textcopy24_1411534464}</div>
            </div>
          </div>
          
          <div className="elField_scene1_line2">
            <input className="actionFont" style={style_elField_scene1_line2} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreenline1copy_1012659949} onChange={this.textInputChanged_elField_scene1_line2} value={this.getValue_elField_scene1_line2()}  />
          </div>
          
          <div className="elTextCopy25">
            <div className="font-avenirBook" style={style_elTextCopy25}>
              <div>{this.props.locStrings.onboarding_configs_textcopy25_165741599}</div>
            </div>
          </div>
          
          <div className="elField_scene1_line3">
            <input className="actionFont" style={style_elField_scene1_line3} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreenline2copy_1360977178} onChange={this.textInputChanged_elField_scene1_line3} value={this.getValue_elField_scene1_line3()}  />
          </div>
          
          <div className="hasNestedComps elComp_image_uploadCopy">
            <div>
              <Comp_image_upload dataSheetRow={this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen1_imageURL : ''} {...this.props} ref={(el)=> this._elComp_image_uploadCopy = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elText_brand_detailsCopy3">
            <div className="actionFont" style={style_elText_brand_detailsCopy3}>
              <div>{this.props.locStrings.onboarding_configs_text_brand_detailscopy3_1660866168}</div>
            </div>
          </div>
          
          <div className="elTextCopy15">
            <div className="font-avenirBook" style={style_elTextCopy15}>
              <div>{this.props.locStrings.onboarding_configs_textcopy15_215087146}</div>
            </div>
          </div>
          
          <div className="elField_scene2_color">
            <input className="actionFont" style={style_elField_scene2_color} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreencolorcopy_547320243} onChange={this.textInputChanged_elField_scene2_color} value={this.getValue_elField_scene2_color()}  />
          </div>
          
          <div className="elTextCopy16">
            <div className="font-avenirBook" style={style_elTextCopy16}>
              <div>{this.props.locStrings.onboarding_configs_textcopy16_183463399}</div>
            </div>
          </div>
          
          <div className="elField_scene2_title">
            <input className="actionFont" style={style_elField_scene2_title} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreentitlecopy_374752625} onChange={this.textInputChanged_elField_scene2_title} value={this.getValue_elField_scene2_title()}  />
          </div>
          
          <div className="elTextCopy17">
            <div className="font-avenirBook" style={style_elTextCopy17}>
              <div>{this.props.locStrings.onboarding_configs_textcopy17_1038231649}</div>
            </div>
          </div>
          
          <div className="elField_scene2_line1">
            <input className="actionFont" style={style_elField_scene2_line1} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreenline1copy_1698275474} onChange={this.textInputChanged_elField_scene2_line1} value={this.getValue_elField_scene2_line1()}  />
          </div>
          
          <div className="elTextCopy18">
            <div className="font-avenirBook" style={style_elTextCopy18}>
              <div>{this.props.locStrings.onboarding_configs_textcopy18_1847085132}</div>
            </div>
          </div>
          
          <div className="elField_scene2_line2">
            <input className="actionFont" style={style_elField_scene2_line2} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreenline2copy_1627795895} onChange={this.textInputChanged_elField_scene2_line2} value={this.getValue_elField_scene2_line2()}  />
          </div>
          
          <div className="elTextCopy20">
            <div className="font-avenirBook" style={style_elTextCopy20}>
              <div>{this.props.locStrings.onboarding_configs_textcopy20_1979134437}</div>
            </div>
          </div>
          
          <div className="elField_scene2_line3">
            <input className="actionFont" style={style_elField_scene2_line3} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreenline3copy_1846597398} onChange={this.textInputChanged_elField_scene2_line3} value={this.getValue_elField_scene2_line3()}  />
          </div>
          
          <div className="hasNestedComps elComp_image_upload">
            <div>
              <Comp_image_upload dataSheetRow={this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen2_imageURL : ''} ref={(el)=> this._elComp_image_upload = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elText_brand_detailsCopy">
            <div className="actionFont" style={style_elText_brand_detailsCopy}>
              <div>{this.props.locStrings.onboarding_configs_text_brand_detailscopy_1626067540}</div>
            </div>
          </div>
          
          <div className="elTextCopy10">
            <div className="font-avenirBook" style={style_elTextCopy10}>
              <div>{this.props.locStrings.onboarding_configs_textcopy10_1203427254}</div>
            </div>
          </div>
          
          <div className="elField_scene3_color">
            <input className="actionFont" style={style_elField_scene3_color} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreencolorcopy2_1273354536} onChange={this.textInputChanged_elField_scene3_color} value={this.getValue_elField_scene3_color()}  />
          </div>
          
          <div className="elTextCopy11">
            <div className="font-avenirBook" style={style_elTextCopy11}>
              <div>{this.props.locStrings.onboarding_configs_textcopy11_162103881}</div>
            </div>
          </div>
          
          <div className="elField_scene3_title">
            <input className="actionFont" style={style_elField_scene3_title} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreentitlecopy2_2018958186} onChange={this.textInputChanged_elField_scene3_title} value={this.getValue_elField_scene3_title()}  />
          </div>
          
          <div className="elTextCopy12">
            <div className="font-avenirBook" style={style_elTextCopy12}>
              <div>{this.props.locStrings.onboarding_configs_textcopy12_1936095099}</div>
            </div>
          </div>
          
          <div className="elField_scene3_line1">
            <input className="actionFont" style={style_elField_scene3_line1} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreenline1copy2_261890440} onChange={this.textInputChanged_elField_scene3_line1} value={this.getValue_elField_scene3_line1()}  />
          </div>
          
          <div className="elTextCopy13">
            <div className="font-avenirBook" style={style_elTextCopy13}>
              <div>{this.props.locStrings.onboarding_configs_textcopy13_1179353465}</div>
            </div>
          </div>
          
          <div className="elField_scene3_line2">
            <input className="actionFont" style={style_elField_scene3_line2} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreenline2copy2_1326592301} onChange={this.textInputChanged_elField_scene3_line2} value={this.getValue_elField_scene3_line2()}  />
          </div>
          
          <div className="elTextCopy14">
            <div className="font-avenirBook" style={style_elTextCopy14}>
              <div>{this.props.locStrings.onboarding_configs_textcopy14_886582246}</div>
            </div>
          </div>
          
          <div className="elField_scene3_line3">
            <input className="actionFont" style={style_elField_scene3_line3} type="text" placeholder={this.props.locStrings.onboarding_configs_field_secondscreenline3copy2_1610622803} onChange={this.textInputChanged_elField_scene3_line3} value={this.getValue_elField_scene3_line3()}  />
          </div>
          
          <div className="hasNestedComps elComp_image_uploadCopy2">
            <div>
              <Comp_image_upload dataSheetRow={this.props.dataSheetRow ? this.props.dataSheetRow.onboarding_screen3_imageURL : ''} ref={(el)=> this._elComp_image_uploadCopy2 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elButton" style={style_elButton_outer}>
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.props.locStrings.onboarding_configs_button_1494765313}
            </Button>
          </div>
          
          <div className="hasNestedComps elComp">
            <div style={style_elComp} onClick={this.onClick_elComp} >
              <Comp5 ref={(el)=> this._elComp = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
