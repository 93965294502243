import React, { Component } from 'react';
import './App.css';
import btn_icon_692247 from './images/btn_icon_692247.png';
import btn_icon_263926 from './images/btn_icon_263926.png';
import btn_icon_113645 from './images/btn_icon_113645.png';
import btn_icon_374566 from './images/btn_icon_374566.png';
import btn_icon_195428 from './images/btn_icon_195428.png';
import btn_icon_395915 from './images/btn_icon_395915.png';
import btn_icon_37421 from './images/btn_icon_37421.png';
import btn_icon_323838 from './images/btn_icon_323838.png';
import btn_icon_234263 from './images/btn_icon_234263.png';
import btn_icon_640346 from './images/btn_icon_640346.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class MenuItemCustomizationListItem extends Component {

  // Properties used by this component:
  // name, sort, document_key, priceadd, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  onClick_state0_elIconDelete692247 = async () => {
    // Remove row from connected sheet
    await this.props.appActions.removeFromDataSheet(this.props.dataSheetId, this.props.dataSheetRow);
  
  }
  
  
  onClick_state0_elText = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  onClick_state0_elButtonManage468967975 = async () => {
    let newVal = this.props.name;
    this.props.appActions.updateDataSlot('ds_activeCustomizationName', newVal);
  
    newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selected_menuItemOption_key', newVal);
  
    // Go to screen 'MenuItem Options'
    this.props.appActions.goToScreen('menuItemOptions', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  renderState0() {
    const style_state0_elCard99248 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard99248_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state0_elIconDelete692247 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_692247+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconHeart = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_263926+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.name);
    
    const style_state0_elText = {
      color: '#eb771d',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconButtonCopy3 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_113645+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '86.294%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state0_elIconButtonCopy4 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_374566+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '74.547%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state0_elButtonManage468967975 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemCustomizationListItem">
        <div className="background">
          <div className="state0_elCard99248" style={style_state0_elCard99248_outer}>
            <div className="cardBg" style={style_state0_elCard99248} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elIconDelete692247" style={style_state0_elIconDelete692247} onClick={this.onClick_state0_elIconDelete692247}  />
          <button className="actionFont state0_elIconHeart" style={style_state0_elIconHeart} />
          <div className="baseFont state0_elText" style={style_state0_elText} onClick={this.onClick_state0_elText} >
            <div>{value_text}</div>
          </div>
          <button className="actionFont state0_elIconButtonCopy3" style={style_state0_elIconButtonCopy3} />
          <button className="actionFont state0_elIconButtonCopy4" style={style_state0_elIconButtonCopy4} />
          <Button className="actionFont state0_elButtonManage468967975" style={style_state0_elButtonManage468967975}  color="accent" onClick={this.onClick_state0_elButtonManage468967975} >
            {this.props.locStrings.menuitemcustomizationlistitem_buttonmanage_468967975}
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  onClick_state1_elIconDelete692247 = async () => {
    // Remove row from connected sheet
    await this.props.appActions.removeFromDataSheet(this.props.dataSheetId, this.props.dataSheetRow);
  
  }
  
  
  onClick_state1_elIconSave = async () => {
    await this.sendData_state1_elIconSave_to_fd_menuItemCustomizations();
  
    this.setState({visualStateIndexOverride: 0})
  
  }
  
  
  textInputChanged_state1_elFieldPrice676397 = (event) => {
    this.setState({fieldPrice: event.target.value});
  }
  
  getValue_state1_elFieldPrice676397 = () => {
    return this.state.fieldPrice !== undefined ? this.state.fieldPrice : (this.props.priceadd);
  }
  
  textInputChanged_state1_elFieldName681569 = (event) => {
    this.setState({fieldName: event.target.value});
  }
  
  getValue_state1_elFieldName681569 = () => {
    return this.state.fieldName !== undefined ? this.state.fieldName : (this.props.name);
  }
  
  textInputChanged_state1_elFieldOrder628433 = (event) => {
    this.setState({fieldOrder: event.target.value});
  }
  
  getValue_state1_elFieldOrder628433 = () => {
    return this.state.fieldOrder !== undefined ? this.state.fieldOrder : (this.props.sort);
  }
  
  onClick_state1_elButtonManage468967975 = async () => {
    let newVal = this.props.name;
    this.props.appActions.updateDataSlot('ds_activeCustomizationName', newVal);
  
    newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selected_menuItemOption_key', newVal);
  
    // Go to screen 'MenuItem Options'
    this.props.appActions.goToScreen('menuItemOptions', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  sendData_state1_elIconSave_to_fd_menuItemCustomizations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fd_menuItemCustomizations');
  
    let row = this.props.dataSheetRow || {
      "name": this.props.name,
      "sort": this.props.sort,
      "document_key": this.props.document_key,
      "priceadd": this.props.priceadd,
    };
    row = { ...row, 
      order: this.getValue_state1_elFieldOrder628433(),
      name: this.getValue_state1_elFieldName681569(),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fd_menuItemCustomizations', row);
    } else {
      return this.props.appActions.addToDataSheet('fd_menuItemCustomizations', row);
    }
  }
  
  
  renderState1() {
    const style_state1_elCard99248 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard99248_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state1_elIconDelete692247 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_692247+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconSave = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_195428+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconHeart395915 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_395915+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state1_elFieldPrice676397 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
      display: 'none',
     };
    
    const style_state1_elFieldName681569 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldOrder628433 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state1_elIconButtonCopy337421 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_37421+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state1_elIconButtonCopy4323838 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_323838+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state1_elButtonManage468967975 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemCustomizationListItem">
        <div className="background">
          <div className="state1_elCard99248" style={style_state1_elCard99248_outer}>
            <div className="cardBg" style={style_state1_elCard99248} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state1_elIconDelete692247" style={style_state1_elIconDelete692247} onClick={this.onClick_state1_elIconDelete692247}  />
          <button className="actionFont state1_elIconSave" style={style_state1_elIconSave} onClick={this.onClick_state1_elIconSave}  />
          <button className="actionFont state1_elIconHeart395915" style={style_state1_elIconHeart395915} />
          <input className="baseFont state1_elFieldPrice676397" style={style_state1_elFieldPrice676397} type="text" placeholder="{base price}" onChange={this.textInputChanged_state1_elFieldPrice676397} value={this.getValue_state1_elFieldPrice676397()}  />
          <input className="baseFont state1_elFieldName681569" style={style_state1_elFieldName681569} type="text" placeholder="{customization name}" onChange={this.textInputChanged_state1_elFieldName681569} value={this.getValue_state1_elFieldName681569()}  />
          <input className="baseFont state1_elFieldOrder628433" style={style_state1_elFieldOrder628433} type="text" placeholder="{order}" onChange={this.textInputChanged_state1_elFieldOrder628433} value={this.getValue_state1_elFieldOrder628433()}  />
          <button className="actionFont state1_elIconButtonCopy337421" style={style_state1_elIconButtonCopy337421} />
          <button className="actionFont state1_elIconButtonCopy4323838" style={style_state1_elIconButtonCopy4323838} />
          <Button className="actionFont state1_elButtonManage468967975" style={style_state1_elButtonManage468967975}  color="accent" onClick={this.onClick_state1_elButtonManage468967975} >
            {this.props.locStrings.menuitemcustomizationlistitem_buttonmanage_468967975}
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  onClick_state2_elIconSave = async () => {
    let newVal = this.props.name;
    this.props.appActions.updateDataSlot('ds_activeCustomizationName', newVal);
  
    newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selected_menuItemOption_key', newVal);
  
    // Go to screen 'MenuItem Options'
    this.props.appActions.goToScreen('menuItemOptions', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  textInputChanged_state2_elFieldPrice676397 = (event) => {
    this.setState({fieldPrice: event.target.value});
  }
  
  getValue_state2_elFieldPrice676397 = () => {
    return this.state.fieldPrice !== undefined ? this.state.fieldPrice : (this.props.priceadd);
  }
  
  textInputChanged_state2_elFieldName681569 = (event) => {
    this.setState({fieldName: event.target.value});
  }
  
  getValue_state2_elFieldName681569 = () => {
    return this.state.fieldName !== undefined ? this.state.fieldName : (this.props.name);
  }
  
  textInputChanged_state2_elFieldOrder628433 = (event) => {
    this.setState({fieldOrder: event.target.value});
  }
  
  getValue_state2_elFieldOrder628433 = () => {
    return this.state.fieldOrder !== undefined ? this.state.fieldOrder : (this.props.sort);
  }
  
  onClick_state2_elButtonManage468967975 = async () => {
    let newVal = this.props.name;
    this.props.appActions.updateDataSlot('ds_activeCustomizationName', newVal);
  
    newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selected_menuItemOption_key', newVal);
  
    // Go to screen 'MenuItem Options'
    this.props.appActions.goToScreen('menuItemOptions', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  renderState2() {
    const style_state2_elCard99248 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elCard99248_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_state2_elIconDelete = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_234263+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '55.556%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    const style_state2_elIconSave = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_640346+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state2_elIconHeart395915 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_395915+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state2_elFieldPrice676397 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
      display: 'none',
     };
    
    const style_state2_elFieldName681569 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state2_elFieldOrder628433 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state2_elIconButtonCopy337421 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_37421+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state2_elIconButtonCopy4323838 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_323838+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90.210%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      display: 'none',
     };
    
    const style_state2_elButtonManage468967975 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemCustomizationListItem">
        <div className="background">
          <div className="state2_elCard99248" style={style_state2_elCard99248_outer}>
            <div className="cardBg" style={style_state2_elCard99248} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state2_elIconDelete" style={style_state2_elIconDelete} />
          <button className="actionFont state2_elIconSave" style={style_state2_elIconSave} onClick={this.onClick_state2_elIconSave}  />
          <button className="actionFont state2_elIconHeart395915" style={style_state2_elIconHeart395915} />
          <input className="baseFont state2_elFieldPrice676397" style={style_state2_elFieldPrice676397} type="text" placeholder="{base price}" onChange={this.textInputChanged_state2_elFieldPrice676397} value={this.getValue_state2_elFieldPrice676397()}  />
          <input className="baseFont state2_elFieldName681569" style={style_state2_elFieldName681569} type="text" placeholder="{customization name}" onChange={this.textInputChanged_state2_elFieldName681569} value={this.getValue_state2_elFieldName681569()}  />
          <input className="baseFont state2_elFieldOrder628433" style={style_state2_elFieldOrder628433} type="text" placeholder="{order}" onChange={this.textInputChanged_state2_elFieldOrder628433} value={this.getValue_state2_elFieldOrder628433()}  />
          <button className="actionFont state2_elIconButtonCopy337421" style={style_state2_elIconButtonCopy337421} />
          <button className="actionFont state2_elIconButtonCopy4323838" style={style_state2_elIconButtonCopy4323838} />
          <Button className="actionFont state2_elButtonManage468967975" style={style_state2_elButtonManage468967975}  color="accent" onClick={this.onClick_state2_elButtonManage468967975} >
            {this.props.locStrings.menuitemcustomizationlistitem_buttonmanage_468967975}
          </Button>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}
