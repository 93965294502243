import React, { Component } from 'react';
import './App.css';
import btn_icon_559683104 from './images/btn_icon_559683104.png';
import img_state0_elImage2 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import btn_icon_651867547 from './images/btn_icon_651867547.png';
import Comp5 from './Comp5';
import MenuItemListItem from './MenuItemListItem';
import btn_icon_230227441 from './images/btn_icon_230227441.png';
import btn_icon_1620037154 from './images/btn_icon_1620037154.png';
import btn_icon_1980097976 from './images/btn_icon_1980097976.png';
import btn_icon_508569842 from './images/btn_icon_508569842.png';
import btn_icon_1807120280 from './images/btn_icon_1807120280.png';
import btn_icon_1020403101 from './images/btn_icon_1020403101.png';
import btn_icon_1031436030 from './images/btn_icon_1031436030.png';
import btn_icon_1357499455 from './images/btn_icon_1357499455.png';
import BottomNavBar from './BottomNavBar';
import BrandListForDropdown from './BrandListForDropdown';
import img_state0_elBrandLogoImage1800908446 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state0_elImage444972618 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';
import IPhoneMockupComponent from './IPhoneMockupComponent';
import Footer from './Footer';
import Header from './Header';
import Component4 from './Component4';
import HelpOverlayScreen from './HelpOverlayScreen';
import img_state1_elImage2 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elBrandLogoImage1800908446 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elImage444972618 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';

export default class LocationDetails2Screen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, dataSheetRow, brandImageURL, bottomNavIconActiveColor, bottomNavIconColor, iconHomeURL, iconMenuURL, iconProfileURL, iconMoreURL, hours

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menus'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menus;
      if ( !this.props.appActions.dataSheetLoaded['fb_menus']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/menus/", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menus'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_my_locations'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_my_locations;
      if ( !this.props.appActions.dataSheetLoaded['fb_my_locations']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/locations/", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_my_locations'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_my_menu_items'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_my_menu_items;
      if ( !this.props.appActions.dataSheetLoaded['fb_my_menu_items']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_my_menu_items'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['fb_menuItems'];
      let serviceOptions = this.props.appActions.serviceOptions_fb_menuItems;
      if ( !this.props.appActions.dataSheetLoaded['fb_menuItems']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("menuItems", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['fb_menuItems'] = true;
      }
    }
    let newVal = "https://discuss.yesoms.com/t/walkthrough-the-menu-detail-screen/";
    this.props.appActions.updateDataSlot('ds_forumHelpURL', newVal);
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elIconButton2559683104 = async () => {
    let newVal = "yyyy";
    this.props.appActions.updateDataSlot('(null)', newVal);
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  textInputChanged_state0_elFieldTitle99653855 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state0_elFieldTitle99653855 = () => {
    return this.state.fieldTitle !== undefined ? this.state.fieldTitle : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  onClick_state0_elIconButton651867547 = async () => {
  
  
  }
  
  
  textInputChanged_state0_elField_brand_name1242185714 = (event) => {
    this.setState({field_brand_name: event.target.value});
  }
  
  getValue_state0_elField_brand_name1242185714 = () => {
    return this.state.field_brand_name !== undefined ? this.state.field_brand_name : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  textAreaChanged_state0_elFieldDescription1145018293 = (event) => {
    this.setState({fieldDescription: event.target.value});
  }
  
  getValue_state0_elFieldDescription1145018293 = () => {
    return this.state.fieldDescription !== undefined ? this.state.fieldDescription : (this.props.dataSheetRow ? this.props.dataSheetRow.description : '');
  }
  
  
  getValue_state0_elField_isActiveCheckbox1073107411 = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : (this.props.dataSheetRow ? this.props.dataSheetRow.isActive : '');
  }
  
  checkboxChanged_state0_elField_isActiveCheckbox1073107411 = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')});
  }
  
  onClick_state0_elComp1908354527 = async () => {
    await this.sendData_state0_elComp1908354527_to_fb_menus();
  
  }
  
  
  
  textInputChanged_state0_elField438439920 = (event) => {
    this.setState({field: event.target.value});
  }
  
  getValue_state0_elField438439920 = () => {
    let transformPropValue_field = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input._lat;
    }
    
    return this.state.field !== undefined ? this.state.field : (transformPropValue_field(this.props.dataSheetRow ? this.props.dataSheetRow.geoLocation : ''));
  }
  
  textInputChanged_state0_elFieldCopy1140453513 = (event) => {
    this.setState({fieldCopy: event.target.value});
  }
  
  getValue_state0_elFieldCopy1140453513 = () => {
    let transformPropValue_fieldCopy = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input._long;
    }
    
    return this.state.fieldCopy !== undefined ? this.state.fieldCopy : (transformPropValue_fieldCopy(this.props.dataSheetRow ? this.props.dataSheetRow.geoLocation : ''));
  }
  
  textInputChanged_state0_elFieldCopy21744963917 = (event) => {
    this.setState({fieldCopy2: event.target.value});
  }
  
  getValue_state0_elFieldCopy21744963917 = () => {
    let transformPropValue_fieldCopy2 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    }
    
    return this.state.fieldCopy2 !== undefined ? this.state.fieldCopy2 : (transformPropValue_fieldCopy2(this.props.dataSheetRow ? this.props.dataSheetRow.geohash : ''));
  }
  
  
  getValue_state0_elPicker_open_hour742737910 = () => {
    let transformPropValue_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.monday_open_hour;
    }
    
    return this.state.picker_open_hour !== undefined ? this.state.picker_open_hour : (transformPropValue_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elPicker_open_hour742737910 = (event) => {
    this.setState({picker_open_hour: event.target.value});
  }
  
  
  getValue_state0_elPicker_open_min565745480 = () => {
    let transformPropValue_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.monday_open_min;
    }
    
    return this.state.picker_open_min !== undefined ? this.state.picker_open_min : (transformPropValue_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elPicker_open_min565745480 = (event) => {
    this.setState({picker_open_min: event.target.value});
  }
  
  
  getValue_state0_elPicker_close_hour1229333456 = () => {
    let transformPropValue_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.monday_close_hour;
    }
    
    return this.state.picker_close_hour !== undefined ? this.state.picker_close_hour : (transformPropValue_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elPicker_close_hour1229333456 = (event) => {
    this.setState({picker_close_hour: event.target.value});
  }
  
  
  getValue_state0_elPicker_close_min424045031 = () => {
    let transformPropValue_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.monday_close_min;
    }
    
    return this.state.picker_close_min !== undefined ? this.state.picker_close_min : (transformPropValue_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elPicker_close_min424045031 = (event) => {
    this.setState({picker_close_min: event.target.value});
  }
  
  onClick_state0_elIcon_save_hours230227441 = async () => {
    await this.sendData_state0_elIcon_save_hours230227441_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elTuesday_picker_open_hour1177371880 = () => {
    let transformPropValue_tuesday_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.tuesday_open_hour;
    }
    
    return this.state.tuesday_picker_open_hour !== undefined ? this.state.tuesday_picker_open_hour : (transformPropValue_tuesday_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elTuesday_picker_open_hour1177371880 = (event) => {
    this.setState({tuesday_picker_open_hour: event.target.value});
  }
  
  
  getValue_state0_elTuesday_picker_open_min1352318114 = () => {
    let transformPropValue_tuesday_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.tuesday_open_min;
    }
    
    return this.state.tuesday_picker_open_min !== undefined ? this.state.tuesday_picker_open_min : (transformPropValue_tuesday_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elTuesday_picker_open_min1352318114 = (event) => {
    this.setState({tuesday_picker_open_min: event.target.value});
  }
  
  
  getValue_state0_elTuesday_picker_close_hour1656665465 = () => {
    let transformPropValue_tuesday_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.tuesday_close_hour;
    }
    
    return this.state.tuesday_picker_close_hour !== undefined ? this.state.tuesday_picker_close_hour : (transformPropValue_tuesday_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elTuesday_picker_close_hour1656665465 = (event) => {
    this.setState({tuesday_picker_close_hour: event.target.value});
  }
  
  
  getValue_state0_elTuesday_picker_close_min940125295 = () => {
    let transformPropValue_tuesday_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.tuesday_close_min;
    }
    
    return this.state.tuesday_picker_close_min !== undefined ? this.state.tuesday_picker_close_min : (transformPropValue_tuesday_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elTuesday_picker_close_min940125295 = (event) => {
    this.setState({tuesday_picker_close_min: event.target.value});
  }
  
  onClick_state0_elTuesday_icon_save_hours1620037154 = async () => {
    await this.sendData_state0_elTuesday_icon_save_hours1620037154_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elWednesday_picker_open_hour1678556584 = () => {
    let transformPropValue_wednesday_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.wednesday_open_hour;
    }
    
    return this.state.wednesday_picker_open_hour !== undefined ? this.state.wednesday_picker_open_hour : (transformPropValue_wednesday_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elWednesday_picker_open_hour1678556584 = (event) => {
    this.setState({wednesday_picker_open_hour: event.target.value});
  }
  
  
  getValue_state0_elWednesday_picker_open_min432759839 = () => {
    let transformPropValue_wednesday_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.wednesday_open_min;
    }
    
    return this.state.wednesday_picker_open_min !== undefined ? this.state.wednesday_picker_open_min : (transformPropValue_wednesday_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elWednesday_picker_open_min432759839 = (event) => {
    this.setState({wednesday_picker_open_min: event.target.value});
  }
  
  
  getValue_state0_elWednesday_picker_close_hour311786884 = () => {
    let transformPropValue_wednesday_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.wednesday_close_hour;
    }
    
    return this.state.wednesday_picker_close_hour !== undefined ? this.state.wednesday_picker_close_hour : (transformPropValue_wednesday_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elWednesday_picker_close_hour311786884 = (event) => {
    this.setState({wednesday_picker_close_hour: event.target.value});
  }
  
  
  getValue_state0_elWednesday_picker_close_min669773325 = () => {
    let transformPropValue_wednesday_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.wednesday_close_min;
    }
    
    return this.state.wednesday_picker_close_min !== undefined ? this.state.wednesday_picker_close_min : (transformPropValue_wednesday_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elWednesday_picker_close_min669773325 = (event) => {
    this.setState({wednesday_picker_close_min: event.target.value});
  }
  
  onClick_state0_elWednesday_icon_save_hours1980097976 = async () => {
    await this.sendData_state0_elWednesday_icon_save_hours1980097976_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elThursday_picker_open_hour1345330484 = () => {
    let transformPropValue_thursday_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.thursday_open_hour;
    }
    
    return this.state.thursday_picker_open_hour !== undefined ? this.state.thursday_picker_open_hour : (transformPropValue_thursday_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elThursday_picker_open_hour1345330484 = (event) => {
    this.setState({thursday_picker_open_hour: event.target.value});
  }
  
  
  getValue_state0_elThursday_picker_open_min1101739936 = () => {
    let transformPropValue_thursday_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.thursday_open_min;
    }
    
    return this.state.thursday_picker_open_min !== undefined ? this.state.thursday_picker_open_min : (transformPropValue_thursday_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elThursday_picker_open_min1101739936 = (event) => {
    this.setState({thursday_picker_open_min: event.target.value});
  }
  
  
  getValue_state0_elThursday_picker_close_hour1129660952 = () => {
    let transformPropValue_thursday_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.thursday_close_hour;
    }
    
    return this.state.thursday_picker_close_hour !== undefined ? this.state.thursday_picker_close_hour : (transformPropValue_thursday_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elThursday_picker_close_hour1129660952 = (event) => {
    this.setState({thursday_picker_close_hour: event.target.value});
  }
  
  
  getValue_state0_elThursday_picker_close_min951025400 = () => {
    let transformPropValue_thursday_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.thursday_close_min;
    }
    
    return this.state.thursday_picker_close_min !== undefined ? this.state.thursday_picker_close_min : (transformPropValue_thursday_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elThursday_picker_close_min951025400 = (event) => {
    this.setState({thursday_picker_close_min: event.target.value});
  }
  
  onClick_state0_elIcon_save_hoursCopy508569842 = async () => {
    await this.sendData_state0_elIcon_save_hoursCopy508569842_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elFriday_picker_open_hour2037701498 = () => {
    let transformPropValue_friday_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.friday_open_hour;
    }
    
    return this.state.friday_picker_open_hour !== undefined ? this.state.friday_picker_open_hour : (transformPropValue_friday_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elFriday_picker_open_hour2037701498 = (event) => {
    this.setState({friday_picker_open_hour: event.target.value});
  }
  
  
  getValue_state0_elFriday_picker_open_min933567234 = () => {
    let transformPropValue_friday_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.friday_open_min;
    }
    
    return this.state.friday_picker_open_min !== undefined ? this.state.friday_picker_open_min : (transformPropValue_friday_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elFriday_picker_open_min933567234 = (event) => {
    this.setState({friday_picker_open_min: event.target.value});
  }
  
  
  getValue_state0_elFriday_picker_close_hour2056849236 = () => {
    let transformPropValue_friday_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.friday_close_hour;
    }
    
    return this.state.friday_picker_close_hour !== undefined ? this.state.friday_picker_close_hour : (transformPropValue_friday_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elFriday_picker_close_hour2056849236 = (event) => {
    this.setState({friday_picker_close_hour: event.target.value});
  }
  
  
  getValue_state0_elFriday_picker_close_min1293089168 = () => {
    let transformPropValue_friday_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.friday_close_min;
    }
    
    return this.state.friday_picker_close_min !== undefined ? this.state.friday_picker_close_min : (transformPropValue_friday_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elFriday_picker_close_min1293089168 = (event) => {
    this.setState({friday_picker_close_min: event.target.value});
  }
  
  onClick_state0_elFriday_icon_save_hours1807120280 = async () => {
    await this.sendData_state0_elFriday_icon_save_hours1807120280_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elSaturday_picker_open_hour1198957772 = () => {
    let transformPropValue_saturday_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.saturday_open_hour;
    }
    
    return this.state.saturday_picker_open_hour !== undefined ? this.state.saturday_picker_open_hour : (transformPropValue_saturday_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSaturday_picker_open_hour1198957772 = (event) => {
    this.setState({saturday_picker_open_hour: event.target.value});
  }
  
  
  getValue_state0_elSaturday_picker_open_min1257709624 = () => {
    let transformPropValue_saturday_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.saturday_open_min;
    }
    
    return this.state.saturday_picker_open_min !== undefined ? this.state.saturday_picker_open_min : (transformPropValue_saturday_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSaturday_picker_open_min1257709624 = (event) => {
    this.setState({saturday_picker_open_min: event.target.value});
  }
  
  
  getValue_state0_elSaturday_picker_close_hour602414436 = () => {
    let transformPropValue_saturday_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.saturday_close_hour;
    }
    
    return this.state.saturday_picker_close_hour !== undefined ? this.state.saturday_picker_close_hour : (transformPropValue_saturday_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSaturday_picker_close_hour602414436 = (event) => {
    this.setState({saturday_picker_close_hour: event.target.value});
  }
  
  
  getValue_state0_elSaturday_picker_close_min627398637 = () => {
    let transformPropValue_saturday_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.saturday_close_min;
    }
    
    return this.state.saturday_picker_close_min !== undefined ? this.state.saturday_picker_close_min : (transformPropValue_saturday_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSaturday_picker_close_min627398637 = (event) => {
    this.setState({saturday_picker_close_min: event.target.value});
  }
  
  onClick_state0_elSaturday_icon_save_hours1020403101 = async () => {
    await this.sendData_state0_elSaturday_icon_save_hours1020403101_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elSunday_picker_open_hour1045627075 = () => {
    let transformPropValue_sunday_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.sunday_open_hour;
    }
    
    return this.state.sunday_picker_open_hour !== undefined ? this.state.sunday_picker_open_hour : (transformPropValue_sunday_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSunday_picker_open_hour1045627075 = (event) => {
    this.setState({sunday_picker_open_hour: event.target.value});
  }
  
  
  getValue_state0_elSunday_picker_open_min1053969761 = () => {
    let transformPropValue_sunday_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.sunday_open_min;
    }
    
    return this.state.sunday_picker_open_min !== undefined ? this.state.sunday_picker_open_min : (transformPropValue_sunday_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSunday_picker_open_min1053969761 = (event) => {
    this.setState({sunday_picker_open_min: event.target.value});
  }
  
  
  getValue_state0_elSunday_picker_close_hour153938075 = () => {
    let transformPropValue_sunday_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.sunday_close_hour;
    }
    
    return this.state.sunday_picker_close_hour !== undefined ? this.state.sunday_picker_close_hour : (transformPropValue_sunday_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSunday_picker_close_hour153938075 = (event) => {
    this.setState({sunday_picker_close_hour: event.target.value});
  }
  
  
  getValue_state0_elSunday_picker_close_min1800771603 = () => {
    let transformPropValue_sunday_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      if (this.state.sunday_picker_open_hour === '-1') 
      { return ''; } 
      else {
      return input.sunday_close_min;
      }
    }
    
    return this.state.sunday_picker_close_min !== undefined ? this.state.sunday_picker_close_min : (transformPropValue_sunday_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSunday_picker_close_min1800771603 = (event) => {
    this.setState({sunday_picker_close_min: event.target.value});
  }
  
  onClick_state0_elSunday_icon_save_hours1031436030 = async () => {
    await this.sendData_state0_elSunday_icon_save_hours1031436030_to_fb_my_locations();
  
  }
  
  
  textInputChanged_state0_elFieldCopy31972143863 = (event) => {
    this.setState({fieldCopy3: event.target.value});
  }
  
  getValue_state0_elFieldCopy31972143863 = () => {
    let transformPropValue_fieldCopy3 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    }
    
    return this.state.fieldCopy3 !== undefined ? this.state.fieldCopy3 : (transformPropValue_fieldCopy3(this.props.hours));
  }
  
  onClick_state0_elCompCopy42447062 = async () => {
    await this.sendData_state0_elCompCopy42447062_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elSunday_picker_open342161455 = () => {
    let transformPropValue_sunday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.sunday_open;
    }
    
    return this.state.sunday_picker_open !== undefined ? this.state.sunday_picker_open : (transformPropValue_sunday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSunday_picker_open342161455 = (event) => {
    this.setState({sunday_picker_open: event.target.value});
  }
  
  
  getValue_state0_elSunday_picker_closed728360308 = () => {
    let transformPropValue_sunday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.sunday_close;
    }
    
    return this.state.sunday_picker_closed !== undefined ? this.state.sunday_picker_closed : (transformPropValue_sunday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state0_elSunday_picker_closed728360308 = (event) => {
    this.setState({sunday_picker_closed: event.target.value});
  }
  
  onClick_state0_elSunday_icon_save_hoursCopy1357499455 = async () => {
    await this.sendData_state0_elSunday_icon_save_hoursCopy1357499455_to_fb_my_locations();
  
  }
  
  
  
  getValue_state0_elPicker656661521 = () => {
    return this.state.picker !== undefined ? this.state.picker : '';
  }
  
  pickerValueChanged_state0_elPicker656661521 = (event) => {
    this.setState({picker: event.target.value});
  }
  
  
  sendData_state0_elTuesday_icon_save_hours1620037154_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.tuesday_open_hour = parseInt(this.state.tuesday_picker_open_hour);
      input.hours.tuesday_open_min = parseInt(this.state.tuesday_picker_open_min);
      input.hours.tuesday_close_hour = parseInt(this.state.tuesday_picker_close_hour);
      input.hours.tuesday_close_min = parseInt(this.state.tuesday_picker_close_min);
      
      
      input.hours.tuesday_open = (input.hours.tuesday_open_hour * 100) + input.hours.tuesday_open_min;
      input.hours.tuesday_close = (input.hours.tuesday_close_hour * 100) + input.hours.tuesday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state0_elIcon_save_hours230227441_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.monday_open_hour = parseInt(this.state.picker_open_hour);
      input.hours.monday_open_min = parseInt(this.state.picker_open_min);
      input.hours.monday_close_hour = parseInt(this.state.picker_close_hour);
      input.hours.monday_close_min = parseInt(this.state.picker_close_min);
      
      
      input.hours.monday_open = (input.hours.monday_open_hour * 100) + input.hours.monday_open_min;
      input.hours.monday_close = (input.hours.monday_close_hour * 100) + input.hours.monday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state0_elComp1908354527_to_fb_menus = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menus');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        
      // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      
      if(this.state.field_isActiveCheckbox === "true" || 
         this.state.field_isActiveCheckbox === true ||
         this.state.field_isActiveCheckbox === "1")
      	{
            input.isActive=true
          };
      
      if(this.state.field_isActiveCheckbox === "false" || 
         this.state.field_isActiveCheckbox === false ||
         this.state.field_isActiveCheckbox === "0")
      	{
            input.isActive=false
          };
      
      
      return input;
      
      
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_menus', row);
  }
  
  
  sendData_state0_elFriday_icon_save_hours1807120280_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.friday_open_hour = parseInt(this.state.friday_picker_open_hour);
      input.hours.friday_open_min = parseInt(this.state.friday_picker_open_min);
      input.hours.friday_close_hour = parseInt(this.state.friday_picker_close_hour);
      input.hours.friday_close_min = parseInt(this.state.friday_picker_close_min);
      
      
      input.hours.friday_open = (input.hours.friday_open_hour * 100) + input.hours.friday_open_min;
      input.hours.friday_close = (input.hours.friday_close_hour * 100) + input.hours.friday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state0_elSunday_icon_save_hoursCopy1357499455_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.sunday_open = parseInt(this.state.sunday_picker_open);
      input.hours.sunday_close = parseInt(this.state.sunday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state0_elWednesday_icon_save_hours1980097976_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.wednesday_open_hour = parseInt(this.state.wednesday_picker_open_hour);
      input.hours.wednesday_open_min = parseInt(this.state.wednesday_picker_open_min);
      input.hours.wednesday_close_hour = parseInt(this.state.wednesday_picker_close_hour);
      input.hours.wednesday_close_min = parseInt(this.state.wednesday_picker_close_min);
      
      
      input.hours.wednesday_open = (input.hours.wednesday_open_hour * 100) + input.hours.wednesday_open_min;
      input.hours.wednesday_close = (input.hours.wednesday_close_hour * 100) + input.hours.wednesday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state0_elSaturday_icon_save_hours1020403101_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.saturday_open_hour = parseInt(this.state.saturday_picker_open_hour);
      input.hours.saturday_open_min = parseInt(this.state.saturday_picker_open_min);
      input.hours.saturday_close_hour = parseInt(this.state.saturday_picker_close_hour);
      input.hours.saturdayy_close_min = parseInt(this.state.saturday_picker_close_min);
      
      
      input.hours.saturday_open = (input.hours.saturday_open_hour * 100) + input.hours.saturday_open_min;
      input.hours.saturday_close = (input.hours.saturday_close_hour * 100) + input.hours.saturday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state0_elIcon_save_hoursCopy508569842_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.thursday_open_hour = parseInt(this.state.thursday_picker_open_hour);
      input.hours.thursday_open_min = parseInt(this.state.thursday_picker_open_min);
      input.hours.thursday_close_hour = parseInt(this.state.thursday_picker_close_hour);
      input.hours.thursday_close_min = parseInt(this.state.thursday_picker_close_min);
      
      
      input.hours.thursday_open = (input.hours.thursday_open_hour * 100) + input.hours.thursday_open_min;
      input.hours.thursday_close = (input.hours.thursday_close_hour * 100) + input.hours.thursday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state0_elSunday_icon_save_hours1031436030_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.sunday_open_hour = parseInt(this.state.sunday_picker_open_hour);
      input.hours.sunday_open_min = parseInt(this.state.sunday_picker_open_min);
      input.hours.sunday_close_hour = parseInt(this.state.sunday_picker_close_hour);
      input.hours.sunday_close_min = parseInt(this.state.sunday_picker_close_min);
      
      
      input.hours.sunday_open = (input.hours.sunday_open_hour * 100) + input.hours.sunday_open_min;
      input.hours.sunday_close = (input.hours.sunday_close_hour * 100) + input.hours.sunday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state0_elCompCopy42447062_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        
      // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      
      if(this.state.field_isActiveCheckbox === "true" || 
         this.state.field_isActiveCheckbox === true ||
         this.state.field_isActiveCheckbox === "1")
      	{
            input.isActive=true
          };
      
      if(this.state.field_isActiveCheckbox === "false" || 
         this.state.field_isActiveCheckbox === false ||
         this.state.field_isActiveCheckbox === "0")
      	{
            input.isActive=false
          };
      
      
      return input;
      
      
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_fb_currentBrand = this.props.dataSheets['fb_currentBrand'];
    // eslint-disable-next-line
    const dataSheet_fb_my_menu_items = this.props.dataSheets['fb_my_menu_items'];
    const style_state0_elBackground1723714325 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground1723714325_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    
    const style_state0_elRectangle21770221688 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_state0_elIconButton2559683104 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_559683104+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elImage2 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_textCopy2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_activeMenuName'] : ''));
    
    const style_state0_elTextCopy21793590620 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const placeholderValue_fieldTitle = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_state0_elFieldTitle99653855 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_state0_elFieldTitle99653855_outer = {
      display: 'none',
     };
    
    const style_state0_elIconButton651867547 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_651867547+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elIconButton651867547_outer = {
      display: 'none',
     };
    const style_state0_elTextCopy61637138038 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state0_elText_brand_name1573344117 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elField_brand_name1242185714 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy2056673683 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elFieldDescription1145018293 = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_state0_elField_isActiveCheckbox1073107411();
    
    const style_state0_elField_isActiveCheckbox1073107411 = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elComp1908354527 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy31675179831 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    this._state0_elList1846632348_items = [];
    
    const style_state0_elList1846632348 = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_state0_elTextCopy4804967323 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state0_elText_brand_nameCopy278136668 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elField438439920 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy2498852163 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elFieldCopy1140453513 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy31364001294 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state0_elFieldCopy21744963917 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state0_elTextCopy51741951507 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state0_elText_brand_nameCopy51986261561 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_picker_open_hour = this.getValue_state0_elPicker_open_hour742737910();
    // Source datasheet and selected data column for picker element 'picker_open_hour'
    const dataSource_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_picker_open_hour = 'value';
    const labelColumnName_picker_open_hour = 'display';
    
    const style_state0_elPicker_open_hour742737910 = {
      pointerEvents: 'auto',
     };
    
    let selection_picker_open_min = this.getValue_state0_elPicker_open_min565745480();
    // Source datasheet and selected data column for picker element 'picker_open_min'
    const dataSource_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_picker_open_min = 'value';
    const labelColumnName_picker_open_min = 'display';
    
    const style_state0_elPicker_open_min565745480 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_to1613344467 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_picker_close_hour = this.getValue_state0_elPicker_close_hour1229333456();
    // Source datasheet and selected data column for picker element 'picker_close_hour'
    const dataSource_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_picker_close_hour = 'value';
    const labelColumnName_picker_close_hour = 'display';
    
    const style_state0_elPicker_close_hour1229333456 = {
      pointerEvents: 'auto',
     };
    
    let selection_picker_close_min = this.getValue_state0_elPicker_close_min424045031();
    // Source datasheet and selected data column for picker element 'picker_close_min'
    const dataSource_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_picker_close_min = 'value';
    const labelColumnName_picker_close_min = 'display';
    
    const style_state0_elPicker_close_min424045031 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elIcon_save_hours230227441 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_230227441+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy71933899430 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_tuesday_picker_open_hour = this.getValue_state0_elTuesday_picker_open_hour1177371880();
    // Source datasheet and selected data column for picker element 'tuesday_picker_open_hour'
    const dataSource_tuesday_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_tuesday_picker_open_hour = 'value';
    const labelColumnName_tuesday_picker_open_hour = 'display';
    
    const style_state0_elTuesday_picker_open_hour1177371880 = {
      pointerEvents: 'auto',
     };
    
    let selection_tuesday_picker_open_min = this.getValue_state0_elTuesday_picker_open_min1352318114();
    // Source datasheet and selected data column for picker element 'tuesday_picker_open_min'
    const dataSource_tuesday_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_tuesday_picker_open_min = 'value';
    const labelColumnName_tuesday_picker_open_min = 'display';
    
    const style_state0_elTuesday_picker_open_min1352318114 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy40335797 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_tuesday_picker_close_hour = this.getValue_state0_elTuesday_picker_close_hour1656665465();
    // Source datasheet and selected data column for picker element 'tuesday_picker_close_hour'
    const dataSource_tuesday_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_tuesday_picker_close_hour = 'value';
    const labelColumnName_tuesday_picker_close_hour = 'display';
    
    const style_state0_elTuesday_picker_close_hour1656665465 = {
      pointerEvents: 'auto',
     };
    
    let selection_tuesday_picker_close_min = this.getValue_state0_elTuesday_picker_close_min940125295();
    // Source datasheet and selected data column for picker element 'tuesday_picker_close_min'
    const dataSource_tuesday_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_tuesday_picker_close_min = 'value';
    const labelColumnName_tuesday_picker_close_min = 'display';
    
    const style_state0_elTuesday_picker_close_min940125295 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elTuesday_icon_save_hours1620037154 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1620037154+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy8109237073 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_wednesday_picker_open_hour = this.getValue_state0_elWednesday_picker_open_hour1678556584();
    // Source datasheet and selected data column for picker element 'wednesday_picker_open_hour'
    const dataSource_wednesday_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_wednesday_picker_open_hour = 'value';
    const labelColumnName_wednesday_picker_open_hour = 'display';
    
    const style_state0_elWednesday_picker_open_hour1678556584 = {
      pointerEvents: 'auto',
     };
    
    let selection_wednesday_picker_open_min = this.getValue_state0_elWednesday_picker_open_min432759839();
    // Source datasheet and selected data column for picker element 'wednesday_picker_open_min'
    const dataSource_wednesday_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_wednesday_picker_open_min = 'value';
    const labelColumnName_wednesday_picker_open_min = 'display';
    
    const style_state0_elWednesday_picker_open_min432759839 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy21673427800 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_wednesday_picker_close_hour = this.getValue_state0_elWednesday_picker_close_hour311786884();
    // Source datasheet and selected data column for picker element 'wednesday_picker_close_hour'
    const dataSource_wednesday_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_wednesday_picker_close_hour = 'value';
    const labelColumnName_wednesday_picker_close_hour = 'display';
    
    const style_state0_elWednesday_picker_close_hour311786884 = {
      pointerEvents: 'auto',
     };
    
    let selection_wednesday_picker_close_min = this.getValue_state0_elWednesday_picker_close_min669773325();
    // Source datasheet and selected data column for picker element 'wednesday_picker_close_min'
    const dataSource_wednesday_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_wednesday_picker_close_min = 'value';
    const labelColumnName_wednesday_picker_close_min = 'display';
    
    const style_state0_elWednesday_picker_close_min669773325 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elWednesday_icon_save_hours1980097976 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1980097976+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy9884498782 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_thursday_picker_open_hour = this.getValue_state0_elThursday_picker_open_hour1345330484();
    // Source datasheet and selected data column for picker element 'thursday_picker_open_hour'
    const dataSource_thursday_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_thursday_picker_open_hour = 'value';
    const labelColumnName_thursday_picker_open_hour = 'display';
    
    const style_state0_elThursday_picker_open_hour1345330484 = {
      pointerEvents: 'auto',
     };
    
    let selection_thursday_picker_open_min = this.getValue_state0_elThursday_picker_open_min1101739936();
    // Source datasheet and selected data column for picker element 'thursday_picker_open_min'
    const dataSource_thursday_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_thursday_picker_open_min = 'value';
    const labelColumnName_thursday_picker_open_min = 'display';
    
    const style_state0_elThursday_picker_open_min1101739936 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy3383044374 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_thursday_picker_close_hour = this.getValue_state0_elThursday_picker_close_hour1129660952();
    // Source datasheet and selected data column for picker element 'thursday_picker_close_hour'
    const dataSource_thursday_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_thursday_picker_close_hour = 'value';
    const labelColumnName_thursday_picker_close_hour = 'display';
    
    const style_state0_elThursday_picker_close_hour1129660952 = {
      pointerEvents: 'auto',
     };
    
    let selection_thursday_picker_close_min = this.getValue_state0_elThursday_picker_close_min951025400();
    // Source datasheet and selected data column for picker element 'thursday_picker_close_min'
    const dataSource_thursday_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_thursday_picker_close_min = 'value';
    const labelColumnName_thursday_picker_close_min = 'display';
    
    const style_state0_elThursday_picker_close_min951025400 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elIcon_save_hoursCopy508569842 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_508569842+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy101909927481 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_friday_picker_open_hour = this.getValue_state0_elFriday_picker_open_hour2037701498();
    // Source datasheet and selected data column for picker element 'friday_picker_open_hour'
    const dataSource_friday_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_friday_picker_open_hour = 'value';
    const labelColumnName_friday_picker_open_hour = 'display';
    
    const style_state0_elFriday_picker_open_hour2037701498 = {
      pointerEvents: 'auto',
     };
    
    let selection_friday_picker_open_min = this.getValue_state0_elFriday_picker_open_min933567234();
    // Source datasheet and selected data column for picker element 'friday_picker_open_min'
    const dataSource_friday_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_friday_picker_open_min = 'value';
    const labelColumnName_friday_picker_open_min = 'display';
    
    const style_state0_elFriday_picker_open_min933567234 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy4326040225 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_friday_picker_close_hour = this.getValue_state0_elFriday_picker_close_hour2056849236();
    // Source datasheet and selected data column for picker element 'friday_picker_close_hour'
    const dataSource_friday_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_friday_picker_close_hour = 'value';
    const labelColumnName_friday_picker_close_hour = 'display';
    
    const style_state0_elFriday_picker_close_hour2056849236 = {
      pointerEvents: 'auto',
     };
    
    let selection_friday_picker_close_min = this.getValue_state0_elFriday_picker_close_min1293089168();
    // Source datasheet and selected data column for picker element 'friday_picker_close_min'
    const dataSource_friday_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_friday_picker_close_min = 'value';
    const labelColumnName_friday_picker_close_min = 'display';
    
    const style_state0_elFriday_picker_close_min1293089168 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elFriday_icon_save_hours1807120280 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1807120280+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy11567029538 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_saturday_picker_open_hour = this.getValue_state0_elSaturday_picker_open_hour1198957772();
    // Source datasheet and selected data column for picker element 'saturday_picker_open_hour'
    const dataSource_saturday_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_saturday_picker_open_hour = 'value';
    const labelColumnName_saturday_picker_open_hour = 'display';
    
    const style_state0_elSaturday_picker_open_hour1198957772 = {
      pointerEvents: 'auto',
     };
    
    let selection_saturday_picker_open_min = this.getValue_state0_elSaturday_picker_open_min1257709624();
    // Source datasheet and selected data column for picker element 'saturday_picker_open_min'
    const dataSource_saturday_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_saturday_picker_open_min = 'value';
    const labelColumnName_saturday_picker_open_min = 'display';
    
    const style_state0_elSaturday_picker_open_min1257709624 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy5576650834 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_saturday_picker_close_hour = this.getValue_state0_elSaturday_picker_close_hour602414436();
    // Source datasheet and selected data column for picker element 'saturday_picker_close_hour'
    const dataSource_saturday_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_saturday_picker_close_hour = 'value';
    const labelColumnName_saturday_picker_close_hour = 'display';
    
    const style_state0_elSaturday_picker_close_hour602414436 = {
      pointerEvents: 'auto',
     };
    
    let selection_saturday_picker_close_min = this.getValue_state0_elSaturday_picker_close_min627398637();
    // Source datasheet and selected data column for picker element 'saturday_picker_close_min'
    const dataSource_saturday_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_saturday_picker_close_min = 'value';
    const labelColumnName_saturday_picker_close_min = 'display';
    
    const style_state0_elSaturday_picker_close_min627398637 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elSaturday_icon_save_hours1020403101 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1020403101+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy121553344194 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_sunday_picker_open_hour = this.getValue_state0_elSunday_picker_open_hour1045627075();
    // Source datasheet and selected data column for picker element 'sunday_picker_open_hour'
    const dataSource_sunday_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_sunday_picker_open_hour = 'value';
    const labelColumnName_sunday_picker_open_hour = 'display';
    
    const style_state0_elSunday_picker_open_hour1045627075 = {
      pointerEvents: 'auto',
     };
    
    let selection_sunday_picker_open_min = this.getValue_state0_elSunday_picker_open_min1053969761();
    // Source datasheet and selected data column for picker element 'sunday_picker_open_min'
    const dataSource_sunday_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_sunday_picker_open_min = 'value';
    const labelColumnName_sunday_picker_open_min = 'display';
    
    const style_state0_elSunday_picker_open_min1053969761 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy61272070915 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_sunday_picker_close_hour = this.getValue_state0_elSunday_picker_close_hour153938075();
    // Source datasheet and selected data column for picker element 'sunday_picker_close_hour'
    const dataSource_sunday_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_sunday_picker_close_hour = 'value';
    const labelColumnName_sunday_picker_close_hour = 'display';
    
    const style_state0_elSunday_picker_close_hour153938075 = {
      pointerEvents: 'auto',
     };
    
    let selection_sunday_picker_close_min = this.getValue_state0_elSunday_picker_close_min1800771603();
    // Source datasheet and selected data column for picker element 'sunday_picker_close_min'
    const dataSource_sunday_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_sunday_picker_close_min = 'value';
    const labelColumnName_sunday_picker_close_min = 'display';
    
    const style_state0_elSunday_picker_close_min1800771603 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elSunday_icon_save_hours1031436030 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1031436030+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const placeholderValue_fieldCopy3 = this.props.hours;
    
    const style_state0_elFieldCopy31972143863 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    let transformPropValue_text_brand_nameCopy6 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input.tuesday;
    }
    
    const value_text_brand_nameCopy6 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_text_brand_nameCopy6(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
    
    const style_state0_elText_brand_nameCopy61993646502 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    const style_state0_elCompCopy42447062 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy131103607058 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_sunday_picker_open = this.getValue_state0_elSunday_picker_open342161455();
    // Source datasheet and selected data column for picker element 'sunday_picker_open'
    const dataSource_sunday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_sunday_picker_open = 'value';
    const labelColumnName_sunday_picker_open = 'display';
    
    const style_state0_elSunday_picker_open342161455 = {
      pointerEvents: 'auto',
     };
    const style_state0_elText_toCopy71469243261 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_sunday_picker_closed = this.getValue_state0_elSunday_picker_closed728360308();
    // Source datasheet and selected data column for picker element 'sunday_picker_closed'
    const dataSource_sunday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_sunday_picker_closed = 'value';
    const labelColumnName_sunday_picker_closed = 'display';
    
    const style_state0_elSunday_picker_closed728360308 = {
      pointerEvents: 'auto',
     };
    
    const style_state0_elSunday_icon_save_hoursCopy1357499455 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1357499455+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elText_brand_nameCopy41809084484 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_picker = this.getValue_state0_elPicker656661521();
    
    const style_state0_elPicker656661521 = {
      pointerEvents: 'auto',
     };
    
    const fillColorValue_rectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_state0_elRectangle423617523 = {
      background: (fillColorValue_rectangle && fillColorValue_rectangle.length > 0) ? fillColorValue_rectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const style_state0_elBottomNavBar131258742 = {
      display: 'none',
     };
    
    // Source items and any special components used for list/grid element 'menu items list'.
    let items_menuItemsList = [];
    let listComps_menuItemsList = {};
    items_menuItemsList = items_menuItemsList.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    this._state0_elMenuItemsList1161594848_items = [];
    
    const style_state0_elMenuItemsList1161594848_outer = {
      display: 'none',
     };
    const style_state0_elBrandLogoImage1800908446 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.brandImageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      display: 'none',
     };
    const style_state0_elImage444972618 = {
      height: 'auto',
      display: 'none',
     };
    const state0_elComponent322793260 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state0_elComponent322793260">
        <Component4 ref={(el)=> this._state0_elComponent322793260 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const state0_elHelpOverlayScreen370331840 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_showHelpOverlay'] : '')) ? (
      <div className="hasNestedComps state0_elHelpOverlayScreen370331840">
        <HelpOverlayScreen visualStateIndex={2} ref={(el)=> this._state0_elHelpOverlayScreen370331840 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen LocationDetails2Screen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBackground1723714325" style={style_state0_elBackground1723714325_outer}>
            <div className="appBg" style={style_state0_elBackground1723714325} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state0_elRectangle21770221688">
            <div style={style_state0_elRectangle21770221688} />
          </div>
          
          <div className="state0_elIconButton2559683104">
            <button className="actionFont" style={style_state0_elIconButton2559683104} onClick={this.onClick_state0_elIconButton2559683104}  />
          </div>
          
          <div className="state0_elImage2">
            <div style={style_state0_elImage2} />
          </div>
          
          <div className="state0_elSpacer1309209868">
            <div />
          </div>
          
          <div className="state0_elTextCopy21793590620">
            <div className="font-futuraMedium" style={style_state0_elTextCopy21793590620}>
              <div>{value_textCopy2}</div>
            </div>
          </div>
          
          <div className="state0_elFieldTitle99653855" style={style_state0_elFieldTitle99653855_outer}>
            <input className="font-futuraMedium" style={style_state0_elFieldTitle99653855} type="text" placeholder={placeholderValue_fieldTitle} onChange={this.textInputChanged_state0_elFieldTitle99653855} value={this.getValue_state0_elFieldTitle99653855()}  />
          </div>
          
          <div className="state0_elIconButton651867547" style={style_state0_elIconButton651867547_outer}>
            <button className="actionFont" style={style_state0_elIconButton651867547} onClick={this.onClick_state0_elIconButton651867547}  />
          </div>
          
          <div className="state0_elSpacer2260911496">
            <div />
          </div>
          
          <div className="state0_elTextCopy61637138038">
            <div className="font-futuraMedium" style={style_state0_elTextCopy61637138038}>
              <div>{this.props.locStrings.locationdetails2_textcopy6_1637138038}</div>
            </div>
          </div>
          
          <div className="state0_elText_brand_name1573344117">
            <div className="font-avenirBook" style={style_state0_elText_brand_name1573344117}>
              <div>{this.props.locStrings.locationdetails2_text_brand_name_1573344117}</div>
            </div>
          </div>
          
          <div className="state0_elField_brand_name1242185714">
            <input className="font-avenirNextRegular" style={style_state0_elField_brand_name1242185714} type="text" placeholder={this.props.locStrings.locationdetails2_field_brand_name_1242185714} onChange={this.textInputChanged_state0_elField_brand_name1242185714} value={this.getValue_state0_elField_brand_name1242185714()}  />
          </div>
          
          <div className="state0_elTextCopy2056673683">
            <div className="font-avenirBook" style={style_state0_elTextCopy2056673683}>
              <div>{this.props.locStrings.locationdetails2_textcopy_2056673683}</div>
            </div>
          </div>
          
          <div className="state0_elFieldDescription1145018293">
            <textarea className="font-avenirNextRegular" style={style_state0_elFieldDescription1145018293}  placeholder={this.props.locStrings.locationdetails2_fielddescription_1145018293} onChange={this.textAreaChanged_state0_elFieldDescription1145018293} value={this.getValue_state0_elFieldDescription1145018293()}  />
          </div>
          
          <div className="state0_elField_isActiveCheckbox1073107411">
            <Checkbox className="font-openSans" style={style_state0_elField_isActiveCheckbox1073107411}  label={this.props.locStrings.locationdetails2_field_isactivecheckbox_1073107411} checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_state0_elField_isActiveCheckbox1073107411} />
          </div>
          
          <div className="hasNestedComps state0_elComp1908354527">
            <div style={style_state0_elComp1908354527} onClick={this.onClick_state0_elComp1908354527} >
              <Comp5 ref={(el)=> this._state0_elComp1908354527 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state0_elTextCopy31675179831">
            <div className="font-futuraMedium" style={style_state0_elTextCopy31675179831}>
              <div>{this.props.locStrings.locationdetails2_textcopy3_1675179831}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elList1846632348">
            <ul style={style_state0_elList1846632348}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'name': row['name'], 'price': row['price'], 'order': row['order'], 'document_key': row['document_key'], 'imageURL': row['imageURL'], }} ref={(el) => {if (el) this._state0_elList1846632348_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state0_elList1846632348_endMarker = el} />
            </ul>
          </div>
          
          <div className="state0_elSpacer398837008">
            <div />
          </div>
          
          <div className="state0_elTextCopy4804967323">
            <div className="font-futuraMedium" style={style_state0_elTextCopy4804967323}>
              <div>{this.props.locStrings.locationdetails2_textcopy4_804967323}</div>
            </div>
          </div>
          
          <div className="state0_elText_brand_nameCopy278136668">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy278136668}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy_278136668}</div>
            </div>
          </div>
          
          <div className="state0_elField438439920">
            <input className="baseFont" style={style_state0_elField438439920} type="text" placeholder={this.props.locStrings.locationdetails2_field_438439920} onChange={this.textInputChanged_state0_elField438439920} value={this.getValue_state0_elField438439920()}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy2498852163">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy2498852163}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy2_498852163}</div>
            </div>
          </div>
          
          <div className="state0_elFieldCopy1140453513">
            <input className="baseFont" style={style_state0_elFieldCopy1140453513} type="text" placeholder={this.props.locStrings.locationdetails2_fieldcopy_1140453513} onChange={this.textInputChanged_state0_elFieldCopy1140453513} value={this.getValue_state0_elFieldCopy1140453513()}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy31364001294">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy31364001294}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy3_1364001294}</div>
            </div>
          </div>
          
          <div className="state0_elFieldCopy21744963917">
            <input className="baseFont" style={style_state0_elFieldCopy21744963917} type="text" placeholder={this.props.locStrings.locationdetails2_fieldcopy2_1744963917} onChange={this.textInputChanged_state0_elFieldCopy21744963917} value={this.getValue_state0_elFieldCopy21744963917()}  />
          </div>
          
          <div className="state0_elTextCopy51741951507">
            <div className="font-futuraMedium" style={style_state0_elTextCopy51741951507}>
              <div>{this.props.locStrings.locationdetails2_textcopy5_1741951507}</div>
            </div>
          </div>
          
          <div className="state0_elText_brand_nameCopy51986261561">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy51986261561}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy5_1986261561}</div>
            </div>
          </div>
          
          <div className="state0_elPicker_open_hour742737910">
            <Select className="baseFont" style={style_state0_elPicker_open_hour742737910}  onChange={this.pickerValueChanged_state0_elPicker_open_hour742737910} value={selection_picker_open_hour} >
              {dataSource_picker_open_hour.items.every(item => {
                return item[valueColumnName_picker_open_hour] !== selection_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_picker_open_hour];
                const labelColValue = item[labelColumnName_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elPicker_open_min565745480">
            <Select className="baseFont" style={style_state0_elPicker_open_min565745480}  onChange={this.pickerValueChanged_state0_elPicker_open_min565745480} value={selection_picker_open_min} >
              {dataSource_picker_open_min.items.every(item => {
                return item[valueColumnName_picker_open_min] !== selection_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_picker_open_min];
                const labelColValue = item[labelColumnName_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_to1613344467">
            <div className="baseFont" style={style_state0_elText_to1613344467}>
              <div>{this.props.locStrings.locationdetails2_text_to_1613344467}</div>
            </div>
          </div>
          
          <div className="state0_elPicker_close_hour1229333456">
            <Select className="baseFont" style={style_state0_elPicker_close_hour1229333456}  onChange={this.pickerValueChanged_state0_elPicker_close_hour1229333456} value={selection_picker_close_hour} >
              {dataSource_picker_close_hour.items.every(item => {
                return item[valueColumnName_picker_close_hour] !== selection_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_picker_close_hour];
                const labelColValue = item[labelColumnName_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elPicker_close_min424045031">
            <Select className="baseFont" style={style_state0_elPicker_close_min424045031}  onChange={this.pickerValueChanged_state0_elPicker_close_min424045031} value={selection_picker_close_min} >
              {dataSource_picker_close_min.items.every(item => {
                return item[valueColumnName_picker_close_min] !== selection_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_picker_close_min];
                const labelColValue = item[labelColumnName_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elIcon_save_hours230227441">
            <button className="actionFont" style={style_state0_elIcon_save_hours230227441} onClick={this.onClick_state0_elIcon_save_hours230227441}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy71933899430">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy71933899430}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy7_1933899430}</div>
            </div>
          </div>
          
          <div className="state0_elTuesday_picker_open_hour1177371880">
            <Select className="baseFont" style={style_state0_elTuesday_picker_open_hour1177371880}  onChange={this.pickerValueChanged_state0_elTuesday_picker_open_hour1177371880} value={selection_tuesday_picker_open_hour} >
              {dataSource_tuesday_picker_open_hour.items.every(item => {
                return item[valueColumnName_tuesday_picker_open_hour] !== selection_tuesday_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_tuesday_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_tuesday_picker_open_hour];
                const labelColValue = item[labelColumnName_tuesday_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elTuesday_picker_open_min1352318114">
            <Select className="baseFont" style={style_state0_elTuesday_picker_open_min1352318114}  onChange={this.pickerValueChanged_state0_elTuesday_picker_open_min1352318114} value={selection_tuesday_picker_open_min} >
              {dataSource_tuesday_picker_open_min.items.every(item => {
                return item[valueColumnName_tuesday_picker_open_min] !== selection_tuesday_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_tuesday_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_tuesday_picker_open_min];
                const labelColValue = item[labelColumnName_tuesday_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy40335797">
            <div className="baseFont" style={style_state0_elText_toCopy40335797}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy_40335797}</div>
            </div>
          </div>
          
          <div className="state0_elTuesday_picker_close_hour1656665465">
            <Select className="baseFont" style={style_state0_elTuesday_picker_close_hour1656665465}  onChange={this.pickerValueChanged_state0_elTuesday_picker_close_hour1656665465} value={selection_tuesday_picker_close_hour} >
              {dataSource_tuesday_picker_close_hour.items.every(item => {
                return item[valueColumnName_tuesday_picker_close_hour] !== selection_tuesday_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_tuesday_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_tuesday_picker_close_hour];
                const labelColValue = item[labelColumnName_tuesday_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elTuesday_picker_close_min940125295">
            <Select className="baseFont" style={style_state0_elTuesday_picker_close_min940125295}  onChange={this.pickerValueChanged_state0_elTuesday_picker_close_min940125295} value={selection_tuesday_picker_close_min} >
              {dataSource_tuesday_picker_close_min.items.every(item => {
                return item[valueColumnName_tuesday_picker_close_min] !== selection_tuesday_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_tuesday_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_tuesday_picker_close_min];
                const labelColValue = item[labelColumnName_tuesday_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elTuesday_icon_save_hours1620037154">
            <button className="actionFont" style={style_state0_elTuesday_icon_save_hours1620037154} onClick={this.onClick_state0_elTuesday_icon_save_hours1620037154}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy8109237073">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy8109237073}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy8_109237073}</div>
            </div>
          </div>
          
          <div className="state0_elWednesday_picker_open_hour1678556584">
            <Select className="baseFont" style={style_state0_elWednesday_picker_open_hour1678556584}  onChange={this.pickerValueChanged_state0_elWednesday_picker_open_hour1678556584} value={selection_wednesday_picker_open_hour} >
              {dataSource_wednesday_picker_open_hour.items.every(item => {
                return item[valueColumnName_wednesday_picker_open_hour] !== selection_wednesday_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_wednesday_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_wednesday_picker_open_hour];
                const labelColValue = item[labelColumnName_wednesday_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elWednesday_picker_open_min432759839">
            <Select className="baseFont" style={style_state0_elWednesday_picker_open_min432759839}  onChange={this.pickerValueChanged_state0_elWednesday_picker_open_min432759839} value={selection_wednesday_picker_open_min} >
              {dataSource_wednesday_picker_open_min.items.every(item => {
                return item[valueColumnName_wednesday_picker_open_min] !== selection_wednesday_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_wednesday_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_wednesday_picker_open_min];
                const labelColValue = item[labelColumnName_wednesday_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy21673427800">
            <div className="baseFont" style={style_state0_elText_toCopy21673427800}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy2_1673427800}</div>
            </div>
          </div>
          
          <div className="state0_elWednesday_picker_close_hour311786884">
            <Select className="baseFont" style={style_state0_elWednesday_picker_close_hour311786884}  onChange={this.pickerValueChanged_state0_elWednesday_picker_close_hour311786884} value={selection_wednesday_picker_close_hour} >
              {dataSource_wednesday_picker_close_hour.items.every(item => {
                return item[valueColumnName_wednesday_picker_close_hour] !== selection_wednesday_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_wednesday_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_wednesday_picker_close_hour];
                const labelColValue = item[labelColumnName_wednesday_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elWednesday_picker_close_min669773325">
            <Select className="baseFont" style={style_state0_elWednesday_picker_close_min669773325}  onChange={this.pickerValueChanged_state0_elWednesday_picker_close_min669773325} value={selection_wednesday_picker_close_min} >
              {dataSource_wednesday_picker_close_min.items.every(item => {
                return item[valueColumnName_wednesday_picker_close_min] !== selection_wednesday_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_wednesday_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_wednesday_picker_close_min];
                const labelColValue = item[labelColumnName_wednesday_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elWednesday_icon_save_hours1980097976">
            <button className="actionFont" style={style_state0_elWednesday_icon_save_hours1980097976} onClick={this.onClick_state0_elWednesday_icon_save_hours1980097976}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy9884498782">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy9884498782}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy9_884498782}</div>
            </div>
          </div>
          
          <div className="state0_elThursday_picker_open_hour1345330484">
            <Select className="baseFont" style={style_state0_elThursday_picker_open_hour1345330484}  onChange={this.pickerValueChanged_state0_elThursday_picker_open_hour1345330484} value={selection_thursday_picker_open_hour} >
              {dataSource_thursday_picker_open_hour.items.every(item => {
                return item[valueColumnName_thursday_picker_open_hour] !== selection_thursday_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_thursday_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_thursday_picker_open_hour];
                const labelColValue = item[labelColumnName_thursday_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elThursday_picker_open_min1101739936">
            <Select className="baseFont" style={style_state0_elThursday_picker_open_min1101739936}  onChange={this.pickerValueChanged_state0_elThursday_picker_open_min1101739936} value={selection_thursday_picker_open_min} >
              {dataSource_thursday_picker_open_min.items.every(item => {
                return item[valueColumnName_thursday_picker_open_min] !== selection_thursday_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_thursday_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_thursday_picker_open_min];
                const labelColValue = item[labelColumnName_thursday_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy3383044374">
            <div className="baseFont" style={style_state0_elText_toCopy3383044374}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy3_383044374}</div>
            </div>
          </div>
          
          <div className="state0_elThursday_picker_close_hour1129660952">
            <Select className="baseFont" style={style_state0_elThursday_picker_close_hour1129660952}  onChange={this.pickerValueChanged_state0_elThursday_picker_close_hour1129660952} value={selection_thursday_picker_close_hour} >
              {dataSource_thursday_picker_close_hour.items.every(item => {
                return item[valueColumnName_thursday_picker_close_hour] !== selection_thursday_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_thursday_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_thursday_picker_close_hour];
                const labelColValue = item[labelColumnName_thursday_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elThursday_picker_close_min951025400">
            <Select className="baseFont" style={style_state0_elThursday_picker_close_min951025400}  onChange={this.pickerValueChanged_state0_elThursday_picker_close_min951025400} value={selection_thursday_picker_close_min} >
              {dataSource_thursday_picker_close_min.items.every(item => {
                return item[valueColumnName_thursday_picker_close_min] !== selection_thursday_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_thursday_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_thursday_picker_close_min];
                const labelColValue = item[labelColumnName_thursday_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elIcon_save_hoursCopy508569842">
            <button className="actionFont" style={style_state0_elIcon_save_hoursCopy508569842} onClick={this.onClick_state0_elIcon_save_hoursCopy508569842}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy101909927481">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy101909927481}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy10_1909927481}</div>
            </div>
          </div>
          
          <div className="state0_elFriday_picker_open_hour2037701498">
            <Select className="baseFont" style={style_state0_elFriday_picker_open_hour2037701498}  onChange={this.pickerValueChanged_state0_elFriday_picker_open_hour2037701498} value={selection_friday_picker_open_hour} >
              {dataSource_friday_picker_open_hour.items.every(item => {
                return item[valueColumnName_friday_picker_open_hour] !== selection_friday_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_friday_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_friday_picker_open_hour];
                const labelColValue = item[labelColumnName_friday_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elFriday_picker_open_min933567234">
            <Select className="baseFont" style={style_state0_elFriday_picker_open_min933567234}  onChange={this.pickerValueChanged_state0_elFriday_picker_open_min933567234} value={selection_friday_picker_open_min} >
              {dataSource_friday_picker_open_min.items.every(item => {
                return item[valueColumnName_friday_picker_open_min] !== selection_friday_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_friday_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_friday_picker_open_min];
                const labelColValue = item[labelColumnName_friday_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy4326040225">
            <div className="baseFont" style={style_state0_elText_toCopy4326040225}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy4_326040225}</div>
            </div>
          </div>
          
          <div className="state0_elFriday_picker_close_hour2056849236">
            <Select className="baseFont" style={style_state0_elFriday_picker_close_hour2056849236}  onChange={this.pickerValueChanged_state0_elFriday_picker_close_hour2056849236} value={selection_friday_picker_close_hour} >
              {dataSource_friday_picker_close_hour.items.every(item => {
                return item[valueColumnName_friday_picker_close_hour] !== selection_friday_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_friday_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_friday_picker_close_hour];
                const labelColValue = item[labelColumnName_friday_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elFriday_picker_close_min1293089168">
            <Select className="baseFont" style={style_state0_elFriday_picker_close_min1293089168}  onChange={this.pickerValueChanged_state0_elFriday_picker_close_min1293089168} value={selection_friday_picker_close_min} >
              {dataSource_friday_picker_close_min.items.every(item => {
                return item[valueColumnName_friday_picker_close_min] !== selection_friday_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_friday_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_friday_picker_close_min];
                const labelColValue = item[labelColumnName_friday_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elFriday_icon_save_hours1807120280">
            <button className="actionFont" style={style_state0_elFriday_icon_save_hours1807120280} onClick={this.onClick_state0_elFriday_icon_save_hours1807120280}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy11567029538">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy11567029538}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy11_567029538}</div>
            </div>
          </div>
          
          <div className="state0_elSaturday_picker_open_hour1198957772">
            <Select className="baseFont" style={style_state0_elSaturday_picker_open_hour1198957772}  onChange={this.pickerValueChanged_state0_elSaturday_picker_open_hour1198957772} value={selection_saturday_picker_open_hour} >
              {dataSource_saturday_picker_open_hour.items.every(item => {
                return item[valueColumnName_saturday_picker_open_hour] !== selection_saturday_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_saturday_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_saturday_picker_open_hour];
                const labelColValue = item[labelColumnName_saturday_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elSaturday_picker_open_min1257709624">
            <Select className="baseFont" style={style_state0_elSaturday_picker_open_min1257709624}  onChange={this.pickerValueChanged_state0_elSaturday_picker_open_min1257709624} value={selection_saturday_picker_open_min} >
              {dataSource_saturday_picker_open_min.items.every(item => {
                return item[valueColumnName_saturday_picker_open_min] !== selection_saturday_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_saturday_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_saturday_picker_open_min];
                const labelColValue = item[labelColumnName_saturday_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy5576650834">
            <div className="baseFont" style={style_state0_elText_toCopy5576650834}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy5_576650834}</div>
            </div>
          </div>
          
          <div className="state0_elSaturday_picker_close_hour602414436">
            <Select className="baseFont" style={style_state0_elSaturday_picker_close_hour602414436}  onChange={this.pickerValueChanged_state0_elSaturday_picker_close_hour602414436} value={selection_saturday_picker_close_hour} >
              {dataSource_saturday_picker_close_hour.items.every(item => {
                return item[valueColumnName_saturday_picker_close_hour] !== selection_saturday_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_saturday_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_saturday_picker_close_hour];
                const labelColValue = item[labelColumnName_saturday_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elSaturday_picker_close_min627398637">
            <Select className="baseFont" style={style_state0_elSaturday_picker_close_min627398637}  onChange={this.pickerValueChanged_state0_elSaturday_picker_close_min627398637} value={selection_saturday_picker_close_min} >
              {dataSource_saturday_picker_close_min.items.every(item => {
                return item[valueColumnName_saturday_picker_close_min] !== selection_saturday_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_saturday_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_saturday_picker_close_min];
                const labelColValue = item[labelColumnName_saturday_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elSaturday_icon_save_hours1020403101">
            <button className="actionFont" style={style_state0_elSaturday_icon_save_hours1020403101} onClick={this.onClick_state0_elSaturday_icon_save_hours1020403101}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy121553344194">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy121553344194}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy12_1553344194}</div>
            </div>
          </div>
          
          <div className="state0_elSunday_picker_open_hour1045627075">
            <Select className="baseFont" style={style_state0_elSunday_picker_open_hour1045627075}  onChange={this.pickerValueChanged_state0_elSunday_picker_open_hour1045627075} value={selection_sunday_picker_open_hour} >
              {dataSource_sunday_picker_open_hour.items.every(item => {
                return item[valueColumnName_sunday_picker_open_hour] !== selection_sunday_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_open_hour];
                const labelColValue = item[labelColumnName_sunday_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elSunday_picker_open_min1053969761">
            <Select className="baseFont" style={style_state0_elSunday_picker_open_min1053969761}  onChange={this.pickerValueChanged_state0_elSunday_picker_open_min1053969761} value={selection_sunday_picker_open_min} >
              {dataSource_sunday_picker_open_min.items.every(item => {
                return item[valueColumnName_sunday_picker_open_min] !== selection_sunday_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_open_min];
                const labelColValue = item[labelColumnName_sunday_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy61272070915">
            <div className="baseFont" style={style_state0_elText_toCopy61272070915}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy6_1272070915}</div>
            </div>
          </div>
          
          <div className="state0_elSunday_picker_close_hour153938075">
            <Select className="baseFont" style={style_state0_elSunday_picker_close_hour153938075}  onChange={this.pickerValueChanged_state0_elSunday_picker_close_hour153938075} value={selection_sunday_picker_close_hour} >
              {dataSource_sunday_picker_close_hour.items.every(item => {
                return item[valueColumnName_sunday_picker_close_hour] !== selection_sunday_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_close_hour];
                const labelColValue = item[labelColumnName_sunday_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elSunday_picker_close_min1800771603">
            <Select className="baseFont" style={style_state0_elSunday_picker_close_min1800771603}  onChange={this.pickerValueChanged_state0_elSunday_picker_close_min1800771603} value={selection_sunday_picker_close_min} >
              {dataSource_sunday_picker_close_min.items.every(item => {
                return item[valueColumnName_sunday_picker_close_min] !== selection_sunday_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_close_min];
                const labelColValue = item[labelColumnName_sunday_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elSunday_icon_save_hours1031436030">
            <button className="actionFont" style={style_state0_elSunday_icon_save_hours1031436030} onClick={this.onClick_state0_elSunday_icon_save_hours1031436030}  />
          </div>
          
          <div className="state0_elFieldCopy31972143863">
            <input className="baseFont" style={style_state0_elFieldCopy31972143863} type="text" placeholder={placeholderValue_fieldCopy3} onChange={this.textInputChanged_state0_elFieldCopy31972143863} value={this.getValue_state0_elFieldCopy31972143863()}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy61993646502">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy61993646502}>
              <div>{value_text_brand_nameCopy6}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state0_elCompCopy42447062">
            <div style={style_state0_elCompCopy42447062} onClick={this.onClick_state0_elCompCopy42447062} >
              <Comp5 ref={(el)=> this._state0_elCompCopy42447062 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state0_elText_brand_nameCopy131103607058">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy131103607058}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy13_1103607058}</div>
            </div>
          </div>
          
          <div className="state0_elSunday_picker_open342161455">
            <Select className="baseFont" style={style_state0_elSunday_picker_open342161455}  onChange={this.pickerValueChanged_state0_elSunday_picker_open342161455} value={selection_sunday_picker_open} >
              {dataSource_sunday_picker_open.items.every(item => {
                return item[valueColumnName_sunday_picker_open] !== selection_sunday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_open];
                const labelColValue = item[labelColumnName_sunday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elText_toCopy71469243261">
            <div className="baseFont" style={style_state0_elText_toCopy71469243261}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy7_1469243261}</div>
            </div>
          </div>
          
          <div className="state0_elSunday_picker_closed728360308">
            <Select className="baseFont" style={style_state0_elSunday_picker_closed728360308}  onChange={this.pickerValueChanged_state0_elSunday_picker_closed728360308} value={selection_sunday_picker_closed} >
              {dataSource_sunday_picker_closed.items.every(item => {
                return item[valueColumnName_sunday_picker_closed] !== selection_sunday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_closed];
                const labelColValue = item[labelColumnName_sunday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state0_elSunday_icon_save_hoursCopy1357499455">
            <button className="actionFont" style={style_state0_elSunday_icon_save_hoursCopy1357499455} onClick={this.onClick_state0_elSunday_icon_save_hoursCopy1357499455}  />
          </div>
          
          <div className="state0_elText_brand_nameCopy41809084484">
            <div className="font-avenirBook" style={style_state0_elText_brand_nameCopy41809084484}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy4_1809084484}</div>
            </div>
          </div>
          
          <div className="state0_elPicker656661521">
            <Select className="baseFont" style={style_state0_elPicker656661521}  onChange={this.pickerValueChanged_state0_elPicker656661521} value={selection_picker}  />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state0_elRectangle423617523" style={style_state0_elRectangle423617523} />
            <div className="hasNestedComps state0_elBottomNavBar131258742" style={style_state0_elBottomNavBar131258742}>
              <BottomNavBar ref={(el)=> this._state0_elBottomNavBar131258742 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elMenuItemsList1161594848" style={style_state0_elMenuItemsList1161594848_outer}>
              <ul>
                {items_menuItemsList.map((row, index) => {
                  let itemComp = (row._componentId)
                      ? listComps_menuItemsList[row._componentId]
                      : <BrandListForDropdown appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'imageURL': row['imageURL'], 'name': row['name'], 'price': row['price'], }} ref={(el) => {if (el) this._state0_elMenuItemsList1161594848_items.push(el)}} />;
                  return (<li key={row.key}>
                      {itemComp}
                    </li>);
                })}
                <div className="marker" ref={(el)=> this._state0_elMenuItemsList1161594848_endMarker = el} />
              </ul>
            </div>
            
            <div className="state0_elBrandLogoImage1800908446" style={style_state0_elBrandLogoImage1800908446} />
            <img className="state0_elImage444972618" style={style_state0_elImage444972618} src={img_state0_elImage444972618} alt=""  />
            <div className="hasNestedComps state0_elIPhoneMockupComponent789002727">
              <IPhoneMockupComponent {...dataSheet_fb_currentBrand.items[0]} visualStateIndex={1} ref={(el)=> this._state0_elIPhoneMockupComponent789002727 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elFooter1933041120">
              <Footer ref={(el)=> this._state0_elFooter1933041120 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elHeader799796556">
              <Header ref={(el)=> this._state0_elHeader799796556 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state0_elComponent322793260 }
            { state0_elHelpOverlayScreen370331840 }
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elIconButton2559683104 = async () => {
    let newVal = "yyyy";
    this.props.appActions.updateDataSlot('(null)', newVal);
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  textInputChanged_state1_elFieldTitle99653855 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state1_elFieldTitle99653855 = () => {
    return this.state.fieldTitle !== undefined ? this.state.fieldTitle : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  onClick_state1_elIconButton651867547 = async () => {
  
  
  }
  
  
  textInputChanged_state1_elField_brand_name1242185714 = (event) => {
    this.setState({field_brand_name: event.target.value});
  }
  
  getValue_state1_elField_brand_name1242185714 = () => {
    return this.state.field_brand_name !== undefined ? this.state.field_brand_name : (this.props.dataSheetRow ? this.props.dataSheetRow.name : '');
  }
  
  textAreaChanged_state1_elFieldDescription1145018293 = (event) => {
    this.setState({fieldDescription: event.target.value});
  }
  
  getValue_state1_elFieldDescription1145018293 = () => {
    return this.state.fieldDescription !== undefined ? this.state.fieldDescription : (this.props.dataSheetRow ? this.props.dataSheetRow.description : '');
  }
  
  
  getValue_state1_elField_isActiveCheckbox1073107411 = () => {
    return this.state.field_isActiveCheckbox !== undefined ? this.state.field_isActiveCheckbox : (this.props.dataSheetRow ? this.props.dataSheetRow.isActive : '');
  }
  
  checkboxChanged_state1_elField_isActiveCheckbox1073107411 = (event) => {
    this.setState({field_isActiveCheckbox: (event.target.checked ? 'true' : 'false')});
  }
  
  onClick_state1_elComp1908354527 = async () => {
    await this.sendData_state1_elComp1908354527_to_fb_menus();
  
  }
  
  
  
  textInputChanged_state1_elField438439920 = (event) => {
    this.setState({field: event.target.value});
  }
  
  getValue_state1_elField438439920 = () => {
    let transformPropValue_field = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input._lat;
    }
    
    return this.state.field !== undefined ? this.state.field : (transformPropValue_field(this.props.dataSheetRow ? this.props.dataSheetRow.geoLocation : ''));
  }
  
  textInputChanged_state1_elFieldCopy1140453513 = (event) => {
    this.setState({fieldCopy: event.target.value});
  }
  
  getValue_state1_elFieldCopy1140453513 = () => {
    let transformPropValue_fieldCopy = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input._long;
    }
    
    return this.state.fieldCopy !== undefined ? this.state.fieldCopy : (transformPropValue_fieldCopy(this.props.dataSheetRow ? this.props.dataSheetRow.geoLocation : ''));
  }
  
  textInputChanged_state1_elFieldCopy21744963917 = (event) => {
    this.setState({fieldCopy2: event.target.value});
  }
  
  getValue_state1_elFieldCopy21744963917 = () => {
    let transformPropValue_fieldCopy2 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    }
    
    return this.state.fieldCopy2 !== undefined ? this.state.fieldCopy2 : (transformPropValue_fieldCopy2(this.props.dataSheetRow ? this.props.dataSheetRow.geohash : ''));
  }
  
  
  getValue_state1_elPicker_open_hour742737910 = () => {
    let transformPropValue_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.monday_open_hour;
    }
    
    return this.state.picker_open_hour !== undefined ? this.state.picker_open_hour : (transformPropValue_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elPicker_open_hour742737910 = (event) => {
    this.setState({picker_open_hour: event.target.value});
  }
  
  
  getValue_state1_elPicker_open_min565745480 = () => {
    let transformPropValue_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.monday_open_min;
    }
    
    return this.state.picker_open_min !== undefined ? this.state.picker_open_min : (transformPropValue_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elPicker_open_min565745480 = (event) => {
    this.setState({picker_open_min: event.target.value});
  }
  
  
  getValue_state1_elPicker_close_hour1229333456 = () => {
    let transformPropValue_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.monday_close_hour;
    }
    
    return this.state.picker_close_hour !== undefined ? this.state.picker_close_hour : (transformPropValue_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elPicker_close_hour1229333456 = (event) => {
    this.setState({picker_close_hour: event.target.value});
  }
  
  
  getValue_state1_elPicker_close_min424045031 = () => {
    let transformPropValue_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.monday_close_min;
    }
    
    return this.state.picker_close_min !== undefined ? this.state.picker_close_min : (transformPropValue_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elPicker_close_min424045031 = (event) => {
    this.setState({picker_close_min: event.target.value});
  }
  
  onClick_state1_elIcon_save_hours230227441 = async () => {
    await this.sendData_state1_elIcon_save_hours230227441_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elTuesday_picker_open_hour1177371880 = () => {
    let transformPropValue_tuesday_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.tuesday_open_hour;
    }
    
    return this.state.tuesday_picker_open_hour !== undefined ? this.state.tuesday_picker_open_hour : (transformPropValue_tuesday_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elTuesday_picker_open_hour1177371880 = (event) => {
    this.setState({tuesday_picker_open_hour: event.target.value});
  }
  
  
  getValue_state1_elTuesday_picker_open_min1352318114 = () => {
    let transformPropValue_tuesday_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.tuesday_open_min;
    }
    
    return this.state.tuesday_picker_open_min !== undefined ? this.state.tuesday_picker_open_min : (transformPropValue_tuesday_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elTuesday_picker_open_min1352318114 = (event) => {
    this.setState({tuesday_picker_open_min: event.target.value});
  }
  
  
  getValue_state1_elTuesday_picker_close_hour1656665465 = () => {
    let transformPropValue_tuesday_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.tuesday_close_hour;
    }
    
    return this.state.tuesday_picker_close_hour !== undefined ? this.state.tuesday_picker_close_hour : (transformPropValue_tuesday_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elTuesday_picker_close_hour1656665465 = (event) => {
    this.setState({tuesday_picker_close_hour: event.target.value});
  }
  
  
  getValue_state1_elTuesday_picker_close_min940125295 = () => {
    let transformPropValue_tuesday_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.tuesday_close_min;
    }
    
    return this.state.tuesday_picker_close_min !== undefined ? this.state.tuesday_picker_close_min : (transformPropValue_tuesday_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elTuesday_picker_close_min940125295 = (event) => {
    this.setState({tuesday_picker_close_min: event.target.value});
  }
  
  onClick_state1_elTuesday_icon_save_hours1620037154 = async () => {
    await this.sendData_state1_elTuesday_icon_save_hours1620037154_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elWednesday_picker_open_hour1678556584 = () => {
    let transformPropValue_wednesday_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.wednesday_open_hour;
    }
    
    return this.state.wednesday_picker_open_hour !== undefined ? this.state.wednesday_picker_open_hour : (transformPropValue_wednesday_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elWednesday_picker_open_hour1678556584 = (event) => {
    this.setState({wednesday_picker_open_hour: event.target.value});
  }
  
  
  getValue_state1_elWednesday_picker_open_min432759839 = () => {
    let transformPropValue_wednesday_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.wednesday_open_min;
    }
    
    return this.state.wednesday_picker_open_min !== undefined ? this.state.wednesday_picker_open_min : (transformPropValue_wednesday_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elWednesday_picker_open_min432759839 = (event) => {
    this.setState({wednesday_picker_open_min: event.target.value});
  }
  
  
  getValue_state1_elWednesday_picker_close_hour311786884 = () => {
    let transformPropValue_wednesday_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.wednesday_close_hour;
    }
    
    return this.state.wednesday_picker_close_hour !== undefined ? this.state.wednesday_picker_close_hour : (transformPropValue_wednesday_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elWednesday_picker_close_hour311786884 = (event) => {
    this.setState({wednesday_picker_close_hour: event.target.value});
  }
  
  
  getValue_state1_elWednesday_picker_close_min669773325 = () => {
    let transformPropValue_wednesday_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.wednesday_close_min;
    }
    
    return this.state.wednesday_picker_close_min !== undefined ? this.state.wednesday_picker_close_min : (transformPropValue_wednesday_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elWednesday_picker_close_min669773325 = (event) => {
    this.setState({wednesday_picker_close_min: event.target.value});
  }
  
  onClick_state1_elWednesday_icon_save_hours1980097976 = async () => {
    await this.sendData_state1_elWednesday_icon_save_hours1980097976_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elThursday_picker_open_hour1345330484 = () => {
    let transformPropValue_thursday_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.thursday_open_hour;
    }
    
    return this.state.thursday_picker_open_hour !== undefined ? this.state.thursday_picker_open_hour : (transformPropValue_thursday_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elThursday_picker_open_hour1345330484 = (event) => {
    this.setState({thursday_picker_open_hour: event.target.value});
  }
  
  
  getValue_state1_elThursday_picker_open_min1101739936 = () => {
    let transformPropValue_thursday_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.thursday_open_min;
    }
    
    return this.state.thursday_picker_open_min !== undefined ? this.state.thursday_picker_open_min : (transformPropValue_thursday_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elThursday_picker_open_min1101739936 = (event) => {
    this.setState({thursday_picker_open_min: event.target.value});
  }
  
  
  getValue_state1_elThursday_picker_close_hour1129660952 = () => {
    let transformPropValue_thursday_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.thursday_close_hour;
    }
    
    return this.state.thursday_picker_close_hour !== undefined ? this.state.thursday_picker_close_hour : (transformPropValue_thursday_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elThursday_picker_close_hour1129660952 = (event) => {
    this.setState({thursday_picker_close_hour: event.target.value});
  }
  
  
  getValue_state1_elThursday_picker_close_min951025400 = () => {
    let transformPropValue_thursday_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.thursday_close_min;
    }
    
    return this.state.thursday_picker_close_min !== undefined ? this.state.thursday_picker_close_min : (transformPropValue_thursday_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elThursday_picker_close_min951025400 = (event) => {
    this.setState({thursday_picker_close_min: event.target.value});
  }
  
  onClick_state1_elIcon_save_hoursCopy508569842 = async () => {
    await this.sendData_state1_elIcon_save_hoursCopy508569842_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elFriday_picker_open_hour2037701498 = () => {
    let transformPropValue_friday_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.friday_open_hour;
    }
    
    return this.state.friday_picker_open_hour !== undefined ? this.state.friday_picker_open_hour : (transformPropValue_friday_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elFriday_picker_open_hour2037701498 = (event) => {
    this.setState({friday_picker_open_hour: event.target.value});
  }
  
  
  getValue_state1_elFriday_picker_open_min933567234 = () => {
    let transformPropValue_friday_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.friday_open_min;
    }
    
    return this.state.friday_picker_open_min !== undefined ? this.state.friday_picker_open_min : (transformPropValue_friday_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elFriday_picker_open_min933567234 = (event) => {
    this.setState({friday_picker_open_min: event.target.value});
  }
  
  
  getValue_state1_elFriday_picker_close_hour2056849236 = () => {
    let transformPropValue_friday_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.friday_close_hour;
    }
    
    return this.state.friday_picker_close_hour !== undefined ? this.state.friday_picker_close_hour : (transformPropValue_friday_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elFriday_picker_close_hour2056849236 = (event) => {
    this.setState({friday_picker_close_hour: event.target.value});
  }
  
  
  getValue_state1_elFriday_picker_close_min1293089168 = () => {
    let transformPropValue_friday_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.friday_close_min;
    }
    
    return this.state.friday_picker_close_min !== undefined ? this.state.friday_picker_close_min : (transformPropValue_friday_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elFriday_picker_close_min1293089168 = (event) => {
    this.setState({friday_picker_close_min: event.target.value});
  }
  
  onClick_state1_elFriday_icon_save_hours1807120280 = async () => {
    await this.sendData_state1_elFriday_icon_save_hours1807120280_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elSaturday_picker_open_hour1198957772 = () => {
    let transformPropValue_saturday_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.saturday_open_hour;
    }
    
    return this.state.saturday_picker_open_hour !== undefined ? this.state.saturday_picker_open_hour : (transformPropValue_saturday_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSaturday_picker_open_hour1198957772 = (event) => {
    this.setState({saturday_picker_open_hour: event.target.value});
  }
  
  
  getValue_state1_elSaturday_picker_open_min1257709624 = () => {
    let transformPropValue_saturday_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.saturday_open_min;
    }
    
    return this.state.saturday_picker_open_min !== undefined ? this.state.saturday_picker_open_min : (transformPropValue_saturday_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSaturday_picker_open_min1257709624 = (event) => {
    this.setState({saturday_picker_open_min: event.target.value});
  }
  
  
  getValue_state1_elSaturday_picker_close_hour602414436 = () => {
    let transformPropValue_saturday_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.saturday_close_hour;
    }
    
    return this.state.saturday_picker_close_hour !== undefined ? this.state.saturday_picker_close_hour : (transformPropValue_saturday_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSaturday_picker_close_hour602414436 = (event) => {
    this.setState({saturday_picker_close_hour: event.target.value});
  }
  
  
  getValue_state1_elSaturday_picker_close_min627398637 = () => {
    let transformPropValue_saturday_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.saturday_close_min;
    }
    
    return this.state.saturday_picker_close_min !== undefined ? this.state.saturday_picker_close_min : (transformPropValue_saturday_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSaturday_picker_close_min627398637 = (event) => {
    this.setState({saturday_picker_close_min: event.target.value});
  }
  
  onClick_state1_elSaturday_icon_save_hours1020403101 = async () => {
    await this.sendData_state1_elSaturday_icon_save_hours1020403101_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elSunday_picker_open_hour1045627075 = () => {
    let transformPropValue_sunday_picker_open_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.sunday_open_hour;
    }
    
    return this.state.sunday_picker_open_hour !== undefined ? this.state.sunday_picker_open_hour : (transformPropValue_sunday_picker_open_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSunday_picker_open_hour1045627075 = (event) => {
    this.setState({sunday_picker_open_hour: event.target.value});
  }
  
  
  getValue_state1_elSunday_picker_open_min1053969761 = () => {
    let transformPropValue_sunday_picker_open_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      return input.sunday_open_min;
    }
    
    return this.state.sunday_picker_open_min !== undefined ? this.state.sunday_picker_open_min : (transformPropValue_sunday_picker_open_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSunday_picker_open_min1053969761 = (event) => {
    this.setState({sunday_picker_open_min: event.target.value});
  }
  
  
  getValue_state1_elSunday_picker_close_hour153938075 = () => {
    let transformPropValue_sunday_picker_close_hour = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      return input.sunday_close_hour;
    }
    
    return this.state.sunday_picker_close_hour !== undefined ? this.state.sunday_picker_close_hour : (transformPropValue_sunday_picker_close_hour(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSunday_picker_close_hour153938075 = (event) => {
    this.setState({sunday_picker_close_hour: event.target.value});
  }
  
  
  getValue_state1_elSunday_picker_close_min1800771603 = () => {
    let transformPropValue_sunday_picker_close_min = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      //return input;
      
      if (this.state.sunday_picker_open_hour === '-1') 
      { return ''; } 
      else {
      return input.sunday_close_min;
      }
    }
    
    return this.state.sunday_picker_close_min !== undefined ? this.state.sunday_picker_close_min : (transformPropValue_sunday_picker_close_min(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSunday_picker_close_min1800771603 = (event) => {
    this.setState({sunday_picker_close_min: event.target.value});
  }
  
  onClick_state1_elSunday_icon_save_hours1031436030 = async () => {
    await this.sendData_state1_elSunday_icon_save_hours1031436030_to_fb_my_locations();
  
  }
  
  
  textInputChanged_state1_elFieldCopy31972143863 = (event) => {
    this.setState({fieldCopy3: event.target.value});
  }
  
  getValue_state1_elFieldCopy31972143863 = () => {
    let transformPropValue_fieldCopy3 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    }
    
    return this.state.fieldCopy3 !== undefined ? this.state.fieldCopy3 : (transformPropValue_fieldCopy3(this.props.hours));
  }
  
  onClick_state1_elCompCopy42447062 = async () => {
    await this.sendData_state1_elCompCopy42447062_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elSunday_picker_open342161455 = () => {
    let transformPropValue_sunday_picker_open = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.sunday_open;
    }
    
    return this.state.sunday_picker_open !== undefined ? this.state.sunday_picker_open : (transformPropValue_sunday_picker_open(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSunday_picker_open342161455 = (event) => {
    this.setState({sunday_picker_open: event.target.value});
  }
  
  
  getValue_state1_elSunday_picker_closed728360308 = () => {
    let transformPropValue_sunday_picker_closed = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //
      
      //return input.tuesday;
      return input.sunday_close;
    }
    
    return this.state.sunday_picker_closed !== undefined ? this.state.sunday_picker_closed : (transformPropValue_sunday_picker_closed(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
  }
  
  pickerValueChanged_state1_elSunday_picker_closed728360308 = (event) => {
    this.setState({sunday_picker_closed: event.target.value});
  }
  
  onClick_state1_elSunday_icon_save_hoursCopy1357499455 = async () => {
    await this.sendData_state1_elSunday_icon_save_hoursCopy1357499455_to_fb_my_locations();
  
  }
  
  
  
  getValue_state1_elPicker656661521 = () => {
    return this.state.picker !== undefined ? this.state.picker : '';
  }
  
  pickerValueChanged_state1_elPicker656661521 = (event) => {
    this.setState({picker: event.target.value});
  }
  
  
  sendData_state1_elCompCopy42447062_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        
      // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      
      if(this.state.field_isActiveCheckbox === "true" || 
         this.state.field_isActiveCheckbox === true ||
         this.state.field_isActiveCheckbox === "1")
      	{
            input.isActive=true
          };
      
      if(this.state.field_isActiveCheckbox === "false" || 
         this.state.field_isActiveCheckbox === false ||
         this.state.field_isActiveCheckbox === "0")
      	{
            input.isActive=false
          };
      
      
      return input;
      
      
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state1_elSunday_icon_save_hoursCopy1357499455_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.sunday_open = parseInt(this.state.sunday_picker_open);
      input.hours.sunday_close = parseInt(this.state.sunday_picker_closed);
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state1_elFriday_icon_save_hours1807120280_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.friday_open_hour = parseInt(this.state.friday_picker_open_hour);
      input.hours.friday_open_min = parseInt(this.state.friday_picker_open_min);
      input.hours.friday_close_hour = parseInt(this.state.friday_picker_close_hour);
      input.hours.friday_close_min = parseInt(this.state.friday_picker_close_min);
      
      
      input.hours.friday_open = (input.hours.friday_open_hour * 100) + input.hours.friday_open_min;
      input.hours.friday_close = (input.hours.friday_close_hour * 100) + input.hours.friday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state1_elIcon_save_hoursCopy508569842_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.thursday_open_hour = parseInt(this.state.thursday_picker_open_hour);
      input.hours.thursday_open_min = parseInt(this.state.thursday_picker_open_min);
      input.hours.thursday_close_hour = parseInt(this.state.thursday_picker_close_hour);
      input.hours.thursday_close_min = parseInt(this.state.thursday_picker_close_min);
      
      
      input.hours.thursday_open = (input.hours.thursday_open_hour * 100) + input.hours.thursday_open_min;
      input.hours.thursday_close = (input.hours.thursday_close_hour * 100) + input.hours.thursday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state1_elSunday_icon_save_hours1031436030_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.sunday_open_hour = parseInt(this.state.sunday_picker_open_hour);
      input.hours.sunday_open_min = parseInt(this.state.sunday_picker_open_min);
      input.hours.sunday_close_hour = parseInt(this.state.sunday_picker_close_hour);
      input.hours.sunday_close_min = parseInt(this.state.sunday_picker_close_min);
      
      
      input.hours.sunday_open = (input.hours.sunday_open_hour * 100) + input.hours.sunday_open_min;
      input.hours.sunday_close = (input.hours.sunday_close_hour * 100) + input.hours.sunday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state1_elComp1908354527_to_fb_menus = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menus');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        
      // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      
      if(this.state.field_isActiveCheckbox === "true" || 
         this.state.field_isActiveCheckbox === true ||
         this.state.field_isActiveCheckbox === "1")
      	{
            input.isActive=true
          };
      
      if(this.state.field_isActiveCheckbox === "false" || 
         this.state.field_isActiveCheckbox === false ||
         this.state.field_isActiveCheckbox === "0")
      	{
            input.isActive=false
          };
      
      
      return input;
      
      
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_menus', row);
  }
  
  
  sendData_state1_elSaturday_icon_save_hours1020403101_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.saturday_open_hour = parseInt(this.state.saturday_picker_open_hour);
      input.hours.saturday_open_min = parseInt(this.state.saturday_picker_open_min);
      input.hours.saturday_close_hour = parseInt(this.state.saturday_picker_close_hour);
      input.hours.saturdayy_close_min = parseInt(this.state.saturday_picker_close_min);
      
      
      input.hours.saturday_open = (input.hours.saturday_open_hour * 100) + input.hours.saturday_open_min;
      input.hours.saturday_close = (input.hours.saturday_close_hour * 100) + input.hours.saturday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state1_elIcon_save_hours230227441_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.monday_open_hour = parseInt(this.state.picker_open_hour);
      input.hours.monday_open_min = parseInt(this.state.picker_open_min);
      input.hours.monday_close_hour = parseInt(this.state.picker_close_hour);
      input.hours.monday_close_min = parseInt(this.state.picker_close_min);
      
      
      input.hours.monday_open = (input.hours.monday_open_hour * 100) + input.hours.monday_open_min;
      input.hours.monday_close = (input.hours.monday_close_hour * 100) + input.hours.monday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state1_elTuesday_icon_save_hours1620037154_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.tuesday_open_hour = parseInt(this.state.tuesday_picker_open_hour);
      input.hours.tuesday_open_min = parseInt(this.state.tuesday_picker_open_min);
      input.hours.tuesday_close_hour = parseInt(this.state.tuesday_picker_close_hour);
      input.hours.tuesday_close_min = parseInt(this.state.tuesday_picker_close_min);
      
      
      input.hours.tuesday_open = (input.hours.tuesday_open_hour * 100) + input.hours.tuesday_open_min;
      input.hours.tuesday_close = (input.hours.tuesday_close_hour * 100) + input.hours.tuesday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  sendData_state1_elWednesday_icon_save_hours1980097976_to_fb_my_locations = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_my_locations');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "brandImageURL": this.props.brandImageURL,
      "bottomNavIconActiveColor": this.props.bottomNavIconActiveColor,
      "bottomNavIconColor": this.props.bottomNavIconColor,
      "iconHomeURL": this.props.iconHomeURL,
      "iconMenuURL": this.props.iconMenuURL,
      "iconProfileURL": this.props.iconProfileURL,
      "iconMoreURL": this.props.iconMoreURL,
      "hours": this.props.hours,
    };
    row = { ...row, 
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      
      //input.hours.tuesday = this.state.picker_open_hour;
      
      input.hours.wednesday_open_hour = parseInt(this.state.wednesday_picker_open_hour);
      input.hours.wednesday_open_min = parseInt(this.state.wednesday_picker_open_min);
      input.hours.wednesday_close_hour = parseInt(this.state.wednesday_picker_close_hour);
      input.hours.wednesday_close_min = parseInt(this.state.wednesday_picker_close_min);
      
      
      input.hours.wednesday_open = (input.hours.wednesday_open_hour * 100) + input.hours.wednesday_open_min;
      input.hours.wednesday_close = (input.hours.wednesday_close_hour * 100) + input.hours.wednesday_close_min;
      
      
      
      return input;
    }
    row = transformData(row);
  
    return this.props.appActions.addToDataSheet('fb_my_locations', row);
  }
  
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_fb_currentBrand = this.props.dataSheets['fb_currentBrand'];
    // eslint-disable-next-line
    const dataSheet_fb_my_menu_items = this.props.dataSheets['fb_my_menu_items'];
    const style_state1_elBackground1723714325 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground1723714325_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#cbcbcb',
     };
    
    const style_state1_elRectangle21770221688 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    
    const style_state1_elIconButton2559683104 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_559683104+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elImage2 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_uploadedBrandImageLocation'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_textCopy2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_activeMenuName'] : ''));
    
    const style_state1_elTextCopy21793590620 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const placeholderValue_fieldTitle = this.props.dataSheetRow ? this.props.dataSheetRow.name : '';
    
    const style_state1_elFieldTitle99653855 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_state1_elFieldTitle99653855_outer = {
      display: 'none',
     };
    
    const style_state1_elIconButton651867547 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_651867547+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elIconButton651867547_outer = {
      display: 'none',
     };
    const style_state1_elTextCopy61637138038 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state1_elText_brand_name1573344117 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elField_brand_name1242185714 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: 'rgba(0, 0, 0, 0.5000)',
      fontSize: 12.9,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state1_elTextCopy2056673683 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elFieldDescription1145018293 = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 12.9,
      pointerEvents: 'auto',
     };
    
    let checked_field_isActiveCheckbox = this.getValue_state1_elField_isActiveCheckbox1073107411();
    
    const style_state1_elField_isActiveCheckbox1073107411 = {
      color: '#eb771d',
      fontSize: 11.9,
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elComp1908354527 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elTextCopy31675179831 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    this._state1_elList1846632348_items = [];
    
    const style_state1_elList1846632348 = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_state1_elTextCopy4804967323 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state1_elText_brand_nameCopy278136668 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elField438439920 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy2498852163 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elFieldCopy1140453513 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy31364001294 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    const style_state1_elFieldCopy21744963917 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state1_elTextCopy51741951507 = {
      fontSize: 19.9,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state1_elText_brand_nameCopy51986261561 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_picker_open_hour = this.getValue_state1_elPicker_open_hour742737910();
    // Source datasheet and selected data column for picker element 'picker_open_hour'
    const dataSource_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_picker_open_hour = 'value';
    const labelColumnName_picker_open_hour = 'display';
    
    const style_state1_elPicker_open_hour742737910 = {
      pointerEvents: 'auto',
     };
    
    let selection_picker_open_min = this.getValue_state1_elPicker_open_min565745480();
    // Source datasheet and selected data column for picker element 'picker_open_min'
    const dataSource_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_picker_open_min = 'value';
    const labelColumnName_picker_open_min = 'display';
    
    const style_state1_elPicker_open_min565745480 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_to1613344467 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_picker_close_hour = this.getValue_state1_elPicker_close_hour1229333456();
    // Source datasheet and selected data column for picker element 'picker_close_hour'
    const dataSource_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_picker_close_hour = 'value';
    const labelColumnName_picker_close_hour = 'display';
    
    const style_state1_elPicker_close_hour1229333456 = {
      pointerEvents: 'auto',
     };
    
    let selection_picker_close_min = this.getValue_state1_elPicker_close_min424045031();
    // Source datasheet and selected data column for picker element 'picker_close_min'
    const dataSource_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_picker_close_min = 'value';
    const labelColumnName_picker_close_min = 'display';
    
    const style_state1_elPicker_close_min424045031 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elIcon_save_hours230227441 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_230227441+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy71933899430 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_tuesday_picker_open_hour = this.getValue_state1_elTuesday_picker_open_hour1177371880();
    // Source datasheet and selected data column for picker element 'tuesday_picker_open_hour'
    const dataSource_tuesday_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_tuesday_picker_open_hour = 'value';
    const labelColumnName_tuesday_picker_open_hour = 'display';
    
    const style_state1_elTuesday_picker_open_hour1177371880 = {
      pointerEvents: 'auto',
     };
    
    let selection_tuesday_picker_open_min = this.getValue_state1_elTuesday_picker_open_min1352318114();
    // Source datasheet and selected data column for picker element 'tuesday_picker_open_min'
    const dataSource_tuesday_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_tuesday_picker_open_min = 'value';
    const labelColumnName_tuesday_picker_open_min = 'display';
    
    const style_state1_elTuesday_picker_open_min1352318114 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy40335797 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_tuesday_picker_close_hour = this.getValue_state1_elTuesday_picker_close_hour1656665465();
    // Source datasheet and selected data column for picker element 'tuesday_picker_close_hour'
    const dataSource_tuesday_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_tuesday_picker_close_hour = 'value';
    const labelColumnName_tuesday_picker_close_hour = 'display';
    
    const style_state1_elTuesday_picker_close_hour1656665465 = {
      pointerEvents: 'auto',
     };
    
    let selection_tuesday_picker_close_min = this.getValue_state1_elTuesday_picker_close_min940125295();
    // Source datasheet and selected data column for picker element 'tuesday_picker_close_min'
    const dataSource_tuesday_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_tuesday_picker_close_min = 'value';
    const labelColumnName_tuesday_picker_close_min = 'display';
    
    const style_state1_elTuesday_picker_close_min940125295 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elTuesday_icon_save_hours1620037154 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1620037154+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy8109237073 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_wednesday_picker_open_hour = this.getValue_state1_elWednesday_picker_open_hour1678556584();
    // Source datasheet and selected data column for picker element 'wednesday_picker_open_hour'
    const dataSource_wednesday_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_wednesday_picker_open_hour = 'value';
    const labelColumnName_wednesday_picker_open_hour = 'display';
    
    const style_state1_elWednesday_picker_open_hour1678556584 = {
      pointerEvents: 'auto',
     };
    
    let selection_wednesday_picker_open_min = this.getValue_state1_elWednesday_picker_open_min432759839();
    // Source datasheet and selected data column for picker element 'wednesday_picker_open_min'
    const dataSource_wednesday_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_wednesday_picker_open_min = 'value';
    const labelColumnName_wednesday_picker_open_min = 'display';
    
    const style_state1_elWednesday_picker_open_min432759839 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy21673427800 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_wednesday_picker_close_hour = this.getValue_state1_elWednesday_picker_close_hour311786884();
    // Source datasheet and selected data column for picker element 'wednesday_picker_close_hour'
    const dataSource_wednesday_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_wednesday_picker_close_hour = 'value';
    const labelColumnName_wednesday_picker_close_hour = 'display';
    
    const style_state1_elWednesday_picker_close_hour311786884 = {
      pointerEvents: 'auto',
     };
    
    let selection_wednesday_picker_close_min = this.getValue_state1_elWednesday_picker_close_min669773325();
    // Source datasheet and selected data column for picker element 'wednesday_picker_close_min'
    const dataSource_wednesday_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_wednesday_picker_close_min = 'value';
    const labelColumnName_wednesday_picker_close_min = 'display';
    
    const style_state1_elWednesday_picker_close_min669773325 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elWednesday_icon_save_hours1980097976 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1980097976+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy9884498782 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_thursday_picker_open_hour = this.getValue_state1_elThursday_picker_open_hour1345330484();
    // Source datasheet and selected data column for picker element 'thursday_picker_open_hour'
    const dataSource_thursday_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_thursday_picker_open_hour = 'value';
    const labelColumnName_thursday_picker_open_hour = 'display';
    
    const style_state1_elThursday_picker_open_hour1345330484 = {
      pointerEvents: 'auto',
     };
    
    let selection_thursday_picker_open_min = this.getValue_state1_elThursday_picker_open_min1101739936();
    // Source datasheet and selected data column for picker element 'thursday_picker_open_min'
    const dataSource_thursday_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_thursday_picker_open_min = 'value';
    const labelColumnName_thursday_picker_open_min = 'display';
    
    const style_state1_elThursday_picker_open_min1101739936 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy3383044374 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_thursday_picker_close_hour = this.getValue_state1_elThursday_picker_close_hour1129660952();
    // Source datasheet and selected data column for picker element 'thursday_picker_close_hour'
    const dataSource_thursday_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_thursday_picker_close_hour = 'value';
    const labelColumnName_thursday_picker_close_hour = 'display';
    
    const style_state1_elThursday_picker_close_hour1129660952 = {
      pointerEvents: 'auto',
     };
    
    let selection_thursday_picker_close_min = this.getValue_state1_elThursday_picker_close_min951025400();
    // Source datasheet and selected data column for picker element 'thursday_picker_close_min'
    const dataSource_thursday_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_thursday_picker_close_min = 'value';
    const labelColumnName_thursday_picker_close_min = 'display';
    
    const style_state1_elThursday_picker_close_min951025400 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elIcon_save_hoursCopy508569842 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_508569842+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy101909927481 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_friday_picker_open_hour = this.getValue_state1_elFriday_picker_open_hour2037701498();
    // Source datasheet and selected data column for picker element 'friday_picker_open_hour'
    const dataSource_friday_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_friday_picker_open_hour = 'value';
    const labelColumnName_friday_picker_open_hour = 'display';
    
    const style_state1_elFriday_picker_open_hour2037701498 = {
      pointerEvents: 'auto',
     };
    
    let selection_friday_picker_open_min = this.getValue_state1_elFriday_picker_open_min933567234();
    // Source datasheet and selected data column for picker element 'friday_picker_open_min'
    const dataSource_friday_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_friday_picker_open_min = 'value';
    const labelColumnName_friday_picker_open_min = 'display';
    
    const style_state1_elFriday_picker_open_min933567234 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy4326040225 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_friday_picker_close_hour = this.getValue_state1_elFriday_picker_close_hour2056849236();
    // Source datasheet and selected data column for picker element 'friday_picker_close_hour'
    const dataSource_friday_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_friday_picker_close_hour = 'value';
    const labelColumnName_friday_picker_close_hour = 'display';
    
    const style_state1_elFriday_picker_close_hour2056849236 = {
      pointerEvents: 'auto',
     };
    
    let selection_friday_picker_close_min = this.getValue_state1_elFriday_picker_close_min1293089168();
    // Source datasheet and selected data column for picker element 'friday_picker_close_min'
    const dataSource_friday_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_friday_picker_close_min = 'value';
    const labelColumnName_friday_picker_close_min = 'display';
    
    const style_state1_elFriday_picker_close_min1293089168 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elFriday_icon_save_hours1807120280 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1807120280+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy11567029538 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_saturday_picker_open_hour = this.getValue_state1_elSaturday_picker_open_hour1198957772();
    // Source datasheet and selected data column for picker element 'saturday_picker_open_hour'
    const dataSource_saturday_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_saturday_picker_open_hour = 'value';
    const labelColumnName_saturday_picker_open_hour = 'display';
    
    const style_state1_elSaturday_picker_open_hour1198957772 = {
      pointerEvents: 'auto',
     };
    
    let selection_saturday_picker_open_min = this.getValue_state1_elSaturday_picker_open_min1257709624();
    // Source datasheet and selected data column for picker element 'saturday_picker_open_min'
    const dataSource_saturday_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_saturday_picker_open_min = 'value';
    const labelColumnName_saturday_picker_open_min = 'display';
    
    const style_state1_elSaturday_picker_open_min1257709624 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy5576650834 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_saturday_picker_close_hour = this.getValue_state1_elSaturday_picker_close_hour602414436();
    // Source datasheet and selected data column for picker element 'saturday_picker_close_hour'
    const dataSource_saturday_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_saturday_picker_close_hour = 'value';
    const labelColumnName_saturday_picker_close_hour = 'display';
    
    const style_state1_elSaturday_picker_close_hour602414436 = {
      pointerEvents: 'auto',
     };
    
    let selection_saturday_picker_close_min = this.getValue_state1_elSaturday_picker_close_min627398637();
    // Source datasheet and selected data column for picker element 'saturday_picker_close_min'
    const dataSource_saturday_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_saturday_picker_close_min = 'value';
    const labelColumnName_saturday_picker_close_min = 'display';
    
    const style_state1_elSaturday_picker_close_min627398637 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elSaturday_icon_save_hours1020403101 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1020403101+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy121553344194 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_sunday_picker_open_hour = this.getValue_state1_elSunday_picker_open_hour1045627075();
    // Source datasheet and selected data column for picker element 'sunday_picker_open_hour'
    const dataSource_sunday_picker_open_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_sunday_picker_open_hour = 'value';
    const labelColumnName_sunday_picker_open_hour = 'display';
    
    const style_state1_elSunday_picker_open_hour1045627075 = {
      pointerEvents: 'auto',
     };
    
    let selection_sunday_picker_open_min = this.getValue_state1_elSunday_picker_open_min1053969761();
    // Source datasheet and selected data column for picker element 'sunday_picker_open_min'
    const dataSource_sunday_picker_open_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_sunday_picker_open_min = 'value';
    const labelColumnName_sunday_picker_open_min = 'display';
    
    const style_state1_elSunday_picker_open_min1053969761 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy61272070915 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_sunday_picker_close_hour = this.getValue_state1_elSunday_picker_close_hour153938075();
    // Source datasheet and selected data column for picker element 'sunday_picker_close_hour'
    const dataSource_sunday_picker_close_hour = this.props.appActions.getDataSheet('ds_list_of_hours');
    const valueColumnName_sunday_picker_close_hour = 'value';
    const labelColumnName_sunday_picker_close_hour = 'display';
    
    const style_state1_elSunday_picker_close_hour153938075 = {
      pointerEvents: 'auto',
     };
    
    let selection_sunday_picker_close_min = this.getValue_state1_elSunday_picker_close_min1800771603();
    // Source datasheet and selected data column for picker element 'sunday_picker_close_min'
    const dataSource_sunday_picker_close_min = this.props.appActions.getDataSheet('ds_list_of_minutes');
    const valueColumnName_sunday_picker_close_min = 'value';
    const labelColumnName_sunday_picker_close_min = 'display';
    
    const style_state1_elSunday_picker_close_min1800771603 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elSunday_icon_save_hours1031436030 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1031436030+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const placeholderValue_fieldCopy3 = this.props.hours;
    
    const style_state1_elFieldCopy31972143863 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    let transformPropValue_text_brand_nameCopy6 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input.tuesday;
    }
    
    const value_text_brand_nameCopy6 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(transformPropValue_text_brand_nameCopy6(this.props.dataSheetRow ? this.props.dataSheetRow.hours : ''));
    
    const style_state1_elText_brand_nameCopy61993646502 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    const style_state1_elCompCopy42447062 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy131103607058 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_sunday_picker_open = this.getValue_state1_elSunday_picker_open342161455();
    // Source datasheet and selected data column for picker element 'sunday_picker_open'
    const dataSource_sunday_picker_open = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_sunday_picker_open = 'value';
    const labelColumnName_sunday_picker_open = 'display';
    
    const style_state1_elSunday_picker_open342161455 = {
      pointerEvents: 'auto',
     };
    const style_state1_elText_toCopy71469243261 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_sunday_picker_closed = this.getValue_state1_elSunday_picker_closed728360308();
    // Source datasheet and selected data column for picker element 'sunday_picker_closed'
    const dataSource_sunday_picker_closed = this.props.appActions.getDataSheet('ds_list_of_times');
    const valueColumnName_sunday_picker_closed = 'value';
    const labelColumnName_sunday_picker_closed = 'display';
    
    const style_state1_elSunday_picker_closed728360308 = {
      pointerEvents: 'auto',
     };
    
    const style_state1_elSunday_icon_save_hoursCopy1357499455 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1357499455+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elText_brand_nameCopy41809084484 = {
      fontSize: 10.9,
      color: '#eb771d',
      textAlign: 'left',
     };
    
    let selection_picker = this.getValue_state1_elPicker656661521();
    
    const style_state1_elPicker656661521 = {
      pointerEvents: 'auto',
     };
    
    const fillColorValue_rectangle = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_brandPrimaryColor'] : '');
    
    const style_state1_elRectangle423617523 = {
      background: (fillColorValue_rectangle && fillColorValue_rectangle.length > 0) ? fillColorValue_rectangle : 'rgba(252, 254, 255, 1.000)',
      display: 'none',
     };
    const style_state1_elBottomNavBar131258742 = {
      display: 'none',
     };
    
    // Source items and any special components used for list/grid element 'menu items list'.
    let items_menuItemsList = [];
    let listComps_menuItemsList = {};
    items_menuItemsList = items_menuItemsList.concat(this.props.appActions.getDataSheet('fb_my_menu_items').items);
    this._state1_elMenuItemsList1161594848_items = [];
    
    const style_state1_elMenuItemsList1161594848_outer = {
      display: 'none',
     };
    const style_state1_elBrandLogoImage1800908446 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.brandImageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      display: 'none',
     };
    const style_state1_elImage444972618 = {
      height: 'auto',
      display: 'none',
     };
    const state1_elComponent322793260 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_show_debug_info'] : '')) ? (
      <div className="hasNestedComps state1_elComponent322793260">
        <Component4 ref={(el)=> this._state1_elComponent322793260 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const state1_elHelpOverlayScreen370331840 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_showHelpOverlay'] : '')) ? (
      <div className="hasNestedComps state1_elHelpOverlayScreen370331840">
        <HelpOverlayScreen visualStateIndex={2} ref={(el)=> this._state1_elHelpOverlayScreen370331840 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen LocationDetails2Screen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state1_elBackground1723714325" style={style_state1_elBackground1723714325_outer}>
            <div className="appBg" style={style_state1_elBackground1723714325} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state1_elRectangle21770221688">
            <div style={style_state1_elRectangle21770221688} />
          </div>
          
          <div className="state1_elIconButton2559683104">
            <button className="actionFont" style={style_state1_elIconButton2559683104} onClick={this.onClick_state1_elIconButton2559683104}  />
          </div>
          
          <div className="state1_elImage2">
            <div style={style_state1_elImage2} />
          </div>
          
          <div className="state1_elSpacer1309209868">
            <div />
          </div>
          
          <div className="state1_elTextCopy21793590620">
            <div className="font-futuraMedium" style={style_state1_elTextCopy21793590620}>
              <div>{value_textCopy2}</div>
            </div>
          </div>
          
          <div className="state1_elFieldTitle99653855" style={style_state1_elFieldTitle99653855_outer}>
            <input className="font-futuraMedium" style={style_state1_elFieldTitle99653855} type="text" placeholder={placeholderValue_fieldTitle} onChange={this.textInputChanged_state1_elFieldTitle99653855} value={this.getValue_state1_elFieldTitle99653855()}  />
          </div>
          
          <div className="state1_elIconButton651867547" style={style_state1_elIconButton651867547_outer}>
            <button className="actionFont" style={style_state1_elIconButton651867547} onClick={this.onClick_state1_elIconButton651867547}  />
          </div>
          
          <div className="state1_elSpacer2260911496">
            <div />
          </div>
          
          <div className="state1_elTextCopy61637138038">
            <div className="font-futuraMedium" style={style_state1_elTextCopy61637138038}>
              <div>{this.props.locStrings.locationdetails2_textcopy6_1637138038}</div>
            </div>
          </div>
          
          <div className="state1_elText_brand_name1573344117">
            <div className="font-avenirBook" style={style_state1_elText_brand_name1573344117}>
              <div>{this.props.locStrings.locationdetails2_text_brand_name_1573344117}</div>
            </div>
          </div>
          
          <div className="state1_elField_brand_name1242185714">
            <input className="font-avenirNextRegular" style={style_state1_elField_brand_name1242185714} type="text" placeholder={this.props.locStrings.locationdetails2_field_brand_name_1242185714} onChange={this.textInputChanged_state1_elField_brand_name1242185714} value={this.getValue_state1_elField_brand_name1242185714()}  />
          </div>
          
          <div className="state1_elTextCopy2056673683">
            <div className="font-avenirBook" style={style_state1_elTextCopy2056673683}>
              <div>{this.props.locStrings.locationdetails2_textcopy_2056673683}</div>
            </div>
          </div>
          
          <div className="state1_elFieldDescription1145018293">
            <textarea className="font-avenirNextRegular" style={style_state1_elFieldDescription1145018293}  placeholder={this.props.locStrings.locationdetails2_fielddescription_1145018293} onChange={this.textAreaChanged_state1_elFieldDescription1145018293} value={this.getValue_state1_elFieldDescription1145018293()}  />
          </div>
          
          <div className="state1_elField_isActiveCheckbox1073107411">
            <Checkbox className="font-openSans" style={style_state1_elField_isActiveCheckbox1073107411}  label={this.props.locStrings.locationdetails2_field_isactivecheckbox_1073107411} checked={checked_field_isActiveCheckbox === 'true' || checked_field_isActiveCheckbox === true || ''+checked_field_isActiveCheckbox === '1'}  onChange={this.checkboxChanged_state1_elField_isActiveCheckbox1073107411} />
          </div>
          
          <div className="hasNestedComps state1_elComp1908354527">
            <div style={style_state1_elComp1908354527} onClick={this.onClick_state1_elComp1908354527} >
              <Comp5 ref={(el)=> this._state1_elComp1908354527 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state1_elTextCopy31675179831">
            <div className="font-futuraMedium" style={style_state1_elTextCopy31675179831}>
              <div>{this.props.locStrings.locationdetails2_textcopy3_1675179831}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elList1846632348">
            <ul style={style_state1_elList1846632348}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'name': row['name'], 'price': row['price'], 'order': row['order'], 'document_key': row['document_key'], 'imageURL': row['imageURL'], }} ref={(el) => {if (el) this._state1_elList1846632348_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state1_elList1846632348_endMarker = el} />
            </ul>
          </div>
          
          <div className="state1_elSpacer398837008">
            <div />
          </div>
          
          <div className="state1_elTextCopy4804967323">
            <div className="font-futuraMedium" style={style_state1_elTextCopy4804967323}>
              <div>{this.props.locStrings.locationdetails2_textcopy4_804967323}</div>
            </div>
          </div>
          
          <div className="state1_elText_brand_nameCopy278136668">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy278136668}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy_278136668}</div>
            </div>
          </div>
          
          <div className="state1_elField438439920">
            <input className="baseFont" style={style_state1_elField438439920} type="text" placeholder={this.props.locStrings.locationdetails2_field_438439920} onChange={this.textInputChanged_state1_elField438439920} value={this.getValue_state1_elField438439920()}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy2498852163">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy2498852163}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy2_498852163}</div>
            </div>
          </div>
          
          <div className="state1_elFieldCopy1140453513">
            <input className="baseFont" style={style_state1_elFieldCopy1140453513} type="text" placeholder={this.props.locStrings.locationdetails2_fieldcopy_1140453513} onChange={this.textInputChanged_state1_elFieldCopy1140453513} value={this.getValue_state1_elFieldCopy1140453513()}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy31364001294">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy31364001294}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy3_1364001294}</div>
            </div>
          </div>
          
          <div className="state1_elFieldCopy21744963917">
            <input className="baseFont" style={style_state1_elFieldCopy21744963917} type="text" placeholder={this.props.locStrings.locationdetails2_fieldcopy2_1744963917} onChange={this.textInputChanged_state1_elFieldCopy21744963917} value={this.getValue_state1_elFieldCopy21744963917()}  />
          </div>
          
          <div className="state1_elTextCopy51741951507">
            <div className="font-futuraMedium" style={style_state1_elTextCopy51741951507}>
              <div>{this.props.locStrings.locationdetails2_textcopy5_1741951507}</div>
            </div>
          </div>
          
          <div className="state1_elText_brand_nameCopy51986261561">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy51986261561}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy5_1986261561}</div>
            </div>
          </div>
          
          <div className="state1_elPicker_open_hour742737910">
            <Select className="baseFont" style={style_state1_elPicker_open_hour742737910}  onChange={this.pickerValueChanged_state1_elPicker_open_hour742737910} value={selection_picker_open_hour} >
              {dataSource_picker_open_hour.items.every(item => {
                return item[valueColumnName_picker_open_hour] !== selection_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_picker_open_hour];
                const labelColValue = item[labelColumnName_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elPicker_open_min565745480">
            <Select className="baseFont" style={style_state1_elPicker_open_min565745480}  onChange={this.pickerValueChanged_state1_elPicker_open_min565745480} value={selection_picker_open_min} >
              {dataSource_picker_open_min.items.every(item => {
                return item[valueColumnName_picker_open_min] !== selection_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_picker_open_min];
                const labelColValue = item[labelColumnName_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_to1613344467">
            <div className="baseFont" style={style_state1_elText_to1613344467}>
              <div>{this.props.locStrings.locationdetails2_text_to_1613344467}</div>
            </div>
          </div>
          
          <div className="state1_elPicker_close_hour1229333456">
            <Select className="baseFont" style={style_state1_elPicker_close_hour1229333456}  onChange={this.pickerValueChanged_state1_elPicker_close_hour1229333456} value={selection_picker_close_hour} >
              {dataSource_picker_close_hour.items.every(item => {
                return item[valueColumnName_picker_close_hour] !== selection_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_picker_close_hour];
                const labelColValue = item[labelColumnName_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elPicker_close_min424045031">
            <Select className="baseFont" style={style_state1_elPicker_close_min424045031}  onChange={this.pickerValueChanged_state1_elPicker_close_min424045031} value={selection_picker_close_min} >
              {dataSource_picker_close_min.items.every(item => {
                return item[valueColumnName_picker_close_min] !== selection_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_picker_close_min];
                const labelColValue = item[labelColumnName_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elIcon_save_hours230227441">
            <button className="actionFont" style={style_state1_elIcon_save_hours230227441} onClick={this.onClick_state1_elIcon_save_hours230227441}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy71933899430">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy71933899430}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy7_1933899430}</div>
            </div>
          </div>
          
          <div className="state1_elTuesday_picker_open_hour1177371880">
            <Select className="baseFont" style={style_state1_elTuesday_picker_open_hour1177371880}  onChange={this.pickerValueChanged_state1_elTuesday_picker_open_hour1177371880} value={selection_tuesday_picker_open_hour} >
              {dataSource_tuesday_picker_open_hour.items.every(item => {
                return item[valueColumnName_tuesday_picker_open_hour] !== selection_tuesday_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_tuesday_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_tuesday_picker_open_hour];
                const labelColValue = item[labelColumnName_tuesday_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elTuesday_picker_open_min1352318114">
            <Select className="baseFont" style={style_state1_elTuesday_picker_open_min1352318114}  onChange={this.pickerValueChanged_state1_elTuesday_picker_open_min1352318114} value={selection_tuesday_picker_open_min} >
              {dataSource_tuesday_picker_open_min.items.every(item => {
                return item[valueColumnName_tuesday_picker_open_min] !== selection_tuesday_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_tuesday_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_tuesday_picker_open_min];
                const labelColValue = item[labelColumnName_tuesday_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy40335797">
            <div className="baseFont" style={style_state1_elText_toCopy40335797}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy_40335797}</div>
            </div>
          </div>
          
          <div className="state1_elTuesday_picker_close_hour1656665465">
            <Select className="baseFont" style={style_state1_elTuesday_picker_close_hour1656665465}  onChange={this.pickerValueChanged_state1_elTuesday_picker_close_hour1656665465} value={selection_tuesday_picker_close_hour} >
              {dataSource_tuesday_picker_close_hour.items.every(item => {
                return item[valueColumnName_tuesday_picker_close_hour] !== selection_tuesday_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_tuesday_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_tuesday_picker_close_hour];
                const labelColValue = item[labelColumnName_tuesday_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elTuesday_picker_close_min940125295">
            <Select className="baseFont" style={style_state1_elTuesday_picker_close_min940125295}  onChange={this.pickerValueChanged_state1_elTuesday_picker_close_min940125295} value={selection_tuesday_picker_close_min} >
              {dataSource_tuesday_picker_close_min.items.every(item => {
                return item[valueColumnName_tuesday_picker_close_min] !== selection_tuesday_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_tuesday_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_tuesday_picker_close_min];
                const labelColValue = item[labelColumnName_tuesday_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elTuesday_icon_save_hours1620037154">
            <button className="actionFont" style={style_state1_elTuesday_icon_save_hours1620037154} onClick={this.onClick_state1_elTuesday_icon_save_hours1620037154}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy8109237073">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy8109237073}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy8_109237073}</div>
            </div>
          </div>
          
          <div className="state1_elWednesday_picker_open_hour1678556584">
            <Select className="baseFont" style={style_state1_elWednesday_picker_open_hour1678556584}  onChange={this.pickerValueChanged_state1_elWednesday_picker_open_hour1678556584} value={selection_wednesday_picker_open_hour} >
              {dataSource_wednesday_picker_open_hour.items.every(item => {
                return item[valueColumnName_wednesday_picker_open_hour] !== selection_wednesday_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_wednesday_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_wednesday_picker_open_hour];
                const labelColValue = item[labelColumnName_wednesday_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elWednesday_picker_open_min432759839">
            <Select className="baseFont" style={style_state1_elWednesday_picker_open_min432759839}  onChange={this.pickerValueChanged_state1_elWednesday_picker_open_min432759839} value={selection_wednesday_picker_open_min} >
              {dataSource_wednesday_picker_open_min.items.every(item => {
                return item[valueColumnName_wednesday_picker_open_min] !== selection_wednesday_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_wednesday_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_wednesday_picker_open_min];
                const labelColValue = item[labelColumnName_wednesday_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy21673427800">
            <div className="baseFont" style={style_state1_elText_toCopy21673427800}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy2_1673427800}</div>
            </div>
          </div>
          
          <div className="state1_elWednesday_picker_close_hour311786884">
            <Select className="baseFont" style={style_state1_elWednesday_picker_close_hour311786884}  onChange={this.pickerValueChanged_state1_elWednesday_picker_close_hour311786884} value={selection_wednesday_picker_close_hour} >
              {dataSource_wednesday_picker_close_hour.items.every(item => {
                return item[valueColumnName_wednesday_picker_close_hour] !== selection_wednesday_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_wednesday_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_wednesday_picker_close_hour];
                const labelColValue = item[labelColumnName_wednesday_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elWednesday_picker_close_min669773325">
            <Select className="baseFont" style={style_state1_elWednesday_picker_close_min669773325}  onChange={this.pickerValueChanged_state1_elWednesday_picker_close_min669773325} value={selection_wednesday_picker_close_min} >
              {dataSource_wednesday_picker_close_min.items.every(item => {
                return item[valueColumnName_wednesday_picker_close_min] !== selection_wednesday_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_wednesday_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_wednesday_picker_close_min];
                const labelColValue = item[labelColumnName_wednesday_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elWednesday_icon_save_hours1980097976">
            <button className="actionFont" style={style_state1_elWednesday_icon_save_hours1980097976} onClick={this.onClick_state1_elWednesday_icon_save_hours1980097976}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy9884498782">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy9884498782}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy9_884498782}</div>
            </div>
          </div>
          
          <div className="state1_elThursday_picker_open_hour1345330484">
            <Select className="baseFont" style={style_state1_elThursday_picker_open_hour1345330484}  onChange={this.pickerValueChanged_state1_elThursday_picker_open_hour1345330484} value={selection_thursday_picker_open_hour} >
              {dataSource_thursday_picker_open_hour.items.every(item => {
                return item[valueColumnName_thursday_picker_open_hour] !== selection_thursday_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_thursday_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_thursday_picker_open_hour];
                const labelColValue = item[labelColumnName_thursday_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elThursday_picker_open_min1101739936">
            <Select className="baseFont" style={style_state1_elThursday_picker_open_min1101739936}  onChange={this.pickerValueChanged_state1_elThursday_picker_open_min1101739936} value={selection_thursday_picker_open_min} >
              {dataSource_thursday_picker_open_min.items.every(item => {
                return item[valueColumnName_thursday_picker_open_min] !== selection_thursday_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_thursday_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_thursday_picker_open_min];
                const labelColValue = item[labelColumnName_thursday_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy3383044374">
            <div className="baseFont" style={style_state1_elText_toCopy3383044374}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy3_383044374}</div>
            </div>
          </div>
          
          <div className="state1_elThursday_picker_close_hour1129660952">
            <Select className="baseFont" style={style_state1_elThursday_picker_close_hour1129660952}  onChange={this.pickerValueChanged_state1_elThursday_picker_close_hour1129660952} value={selection_thursday_picker_close_hour} >
              {dataSource_thursday_picker_close_hour.items.every(item => {
                return item[valueColumnName_thursday_picker_close_hour] !== selection_thursday_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_thursday_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_thursday_picker_close_hour];
                const labelColValue = item[labelColumnName_thursday_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elThursday_picker_close_min951025400">
            <Select className="baseFont" style={style_state1_elThursday_picker_close_min951025400}  onChange={this.pickerValueChanged_state1_elThursday_picker_close_min951025400} value={selection_thursday_picker_close_min} >
              {dataSource_thursday_picker_close_min.items.every(item => {
                return item[valueColumnName_thursday_picker_close_min] !== selection_thursday_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_thursday_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_thursday_picker_close_min];
                const labelColValue = item[labelColumnName_thursday_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elIcon_save_hoursCopy508569842">
            <button className="actionFont" style={style_state1_elIcon_save_hoursCopy508569842} onClick={this.onClick_state1_elIcon_save_hoursCopy508569842}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy101909927481">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy101909927481}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy10_1909927481}</div>
            </div>
          </div>
          
          <div className="state1_elFriday_picker_open_hour2037701498">
            <Select className="baseFont" style={style_state1_elFriday_picker_open_hour2037701498}  onChange={this.pickerValueChanged_state1_elFriday_picker_open_hour2037701498} value={selection_friday_picker_open_hour} >
              {dataSource_friday_picker_open_hour.items.every(item => {
                return item[valueColumnName_friday_picker_open_hour] !== selection_friday_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_friday_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_friday_picker_open_hour];
                const labelColValue = item[labelColumnName_friday_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elFriday_picker_open_min933567234">
            <Select className="baseFont" style={style_state1_elFriday_picker_open_min933567234}  onChange={this.pickerValueChanged_state1_elFriday_picker_open_min933567234} value={selection_friday_picker_open_min} >
              {dataSource_friday_picker_open_min.items.every(item => {
                return item[valueColumnName_friday_picker_open_min] !== selection_friday_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_friday_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_friday_picker_open_min];
                const labelColValue = item[labelColumnName_friday_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy4326040225">
            <div className="baseFont" style={style_state1_elText_toCopy4326040225}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy4_326040225}</div>
            </div>
          </div>
          
          <div className="state1_elFriday_picker_close_hour2056849236">
            <Select className="baseFont" style={style_state1_elFriday_picker_close_hour2056849236}  onChange={this.pickerValueChanged_state1_elFriday_picker_close_hour2056849236} value={selection_friday_picker_close_hour} >
              {dataSource_friday_picker_close_hour.items.every(item => {
                return item[valueColumnName_friday_picker_close_hour] !== selection_friday_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_friday_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_friday_picker_close_hour];
                const labelColValue = item[labelColumnName_friday_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elFriday_picker_close_min1293089168">
            <Select className="baseFont" style={style_state1_elFriday_picker_close_min1293089168}  onChange={this.pickerValueChanged_state1_elFriday_picker_close_min1293089168} value={selection_friday_picker_close_min} >
              {dataSource_friday_picker_close_min.items.every(item => {
                return item[valueColumnName_friday_picker_close_min] !== selection_friday_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_friday_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_friday_picker_close_min];
                const labelColValue = item[labelColumnName_friday_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elFriday_icon_save_hours1807120280">
            <button className="actionFont" style={style_state1_elFriday_icon_save_hours1807120280} onClick={this.onClick_state1_elFriday_icon_save_hours1807120280}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy11567029538">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy11567029538}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy11_567029538}</div>
            </div>
          </div>
          
          <div className="state1_elSaturday_picker_open_hour1198957772">
            <Select className="baseFont" style={style_state1_elSaturday_picker_open_hour1198957772}  onChange={this.pickerValueChanged_state1_elSaturday_picker_open_hour1198957772} value={selection_saturday_picker_open_hour} >
              {dataSource_saturday_picker_open_hour.items.every(item => {
                return item[valueColumnName_saturday_picker_open_hour] !== selection_saturday_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_saturday_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_saturday_picker_open_hour];
                const labelColValue = item[labelColumnName_saturday_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elSaturday_picker_open_min1257709624">
            <Select className="baseFont" style={style_state1_elSaturday_picker_open_min1257709624}  onChange={this.pickerValueChanged_state1_elSaturday_picker_open_min1257709624} value={selection_saturday_picker_open_min} >
              {dataSource_saturday_picker_open_min.items.every(item => {
                return item[valueColumnName_saturday_picker_open_min] !== selection_saturday_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_saturday_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_saturday_picker_open_min];
                const labelColValue = item[labelColumnName_saturday_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy5576650834">
            <div className="baseFont" style={style_state1_elText_toCopy5576650834}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy5_576650834}</div>
            </div>
          </div>
          
          <div className="state1_elSaturday_picker_close_hour602414436">
            <Select className="baseFont" style={style_state1_elSaturday_picker_close_hour602414436}  onChange={this.pickerValueChanged_state1_elSaturday_picker_close_hour602414436} value={selection_saturday_picker_close_hour} >
              {dataSource_saturday_picker_close_hour.items.every(item => {
                return item[valueColumnName_saturday_picker_close_hour] !== selection_saturday_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_saturday_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_saturday_picker_close_hour];
                const labelColValue = item[labelColumnName_saturday_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elSaturday_picker_close_min627398637">
            <Select className="baseFont" style={style_state1_elSaturday_picker_close_min627398637}  onChange={this.pickerValueChanged_state1_elSaturday_picker_close_min627398637} value={selection_saturday_picker_close_min} >
              {dataSource_saturday_picker_close_min.items.every(item => {
                return item[valueColumnName_saturday_picker_close_min] !== selection_saturday_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_saturday_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_saturday_picker_close_min];
                const labelColValue = item[labelColumnName_saturday_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elSaturday_icon_save_hours1020403101">
            <button className="actionFont" style={style_state1_elSaturday_icon_save_hours1020403101} onClick={this.onClick_state1_elSaturday_icon_save_hours1020403101}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy121553344194">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy121553344194}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy12_1553344194}</div>
            </div>
          </div>
          
          <div className="state1_elSunday_picker_open_hour1045627075">
            <Select className="baseFont" style={style_state1_elSunday_picker_open_hour1045627075}  onChange={this.pickerValueChanged_state1_elSunday_picker_open_hour1045627075} value={selection_sunday_picker_open_hour} >
              {dataSource_sunday_picker_open_hour.items.every(item => {
                return item[valueColumnName_sunday_picker_open_hour] !== selection_sunday_picker_open_hour;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_open_hour.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_open_hour];
                const labelColValue = item[labelColumnName_sunday_picker_open_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elSunday_picker_open_min1053969761">
            <Select className="baseFont" style={style_state1_elSunday_picker_open_min1053969761}  onChange={this.pickerValueChanged_state1_elSunday_picker_open_min1053969761} value={selection_sunday_picker_open_min} >
              {dataSource_sunday_picker_open_min.items.every(item => {
                return item[valueColumnName_sunday_picker_open_min] !== selection_sunday_picker_open_min;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_open_min.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_open_min];
                const labelColValue = item[labelColumnName_sunday_picker_open_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy61272070915">
            <div className="baseFont" style={style_state1_elText_toCopy61272070915}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy6_1272070915}</div>
            </div>
          </div>
          
          <div className="state1_elSunday_picker_close_hour153938075">
            <Select className="baseFont" style={style_state1_elSunday_picker_close_hour153938075}  onChange={this.pickerValueChanged_state1_elSunday_picker_close_hour153938075} value={selection_sunday_picker_close_hour} >
              {dataSource_sunday_picker_close_hour.items.every(item => {
                return item[valueColumnName_sunday_picker_close_hour] !== selection_sunday_picker_close_hour;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_close_hour.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_close_hour];
                const labelColValue = item[labelColumnName_sunday_picker_close_hour];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elSunday_picker_close_min1800771603">
            <Select className="baseFont" style={style_state1_elSunday_picker_close_min1800771603}  onChange={this.pickerValueChanged_state1_elSunday_picker_close_min1800771603} value={selection_sunday_picker_close_min} >
              {dataSource_sunday_picker_close_min.items.every(item => {
                return item[valueColumnName_sunday_picker_close_min] !== selection_sunday_picker_close_min;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_close_min.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_close_min];
                const labelColValue = item[labelColumnName_sunday_picker_close_min];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elSunday_icon_save_hours1031436030">
            <button className="actionFont" style={style_state1_elSunday_icon_save_hours1031436030} onClick={this.onClick_state1_elSunday_icon_save_hours1031436030}  />
          </div>
          
          <div className="state1_elFieldCopy31972143863">
            <input className="baseFont" style={style_state1_elFieldCopy31972143863} type="text" placeholder={placeholderValue_fieldCopy3} onChange={this.textInputChanged_state1_elFieldCopy31972143863} value={this.getValue_state1_elFieldCopy31972143863()}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy61993646502">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy61993646502}>
              <div>{value_text_brand_nameCopy6}</div>
            </div>
          </div>
          
          <div className="hasNestedComps state1_elCompCopy42447062">
            <div style={style_state1_elCompCopy42447062} onClick={this.onClick_state1_elCompCopy42447062} >
              <Comp5 ref={(el)=> this._state1_elCompCopy42447062 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="state1_elText_brand_nameCopy131103607058">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy131103607058}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy13_1103607058}</div>
            </div>
          </div>
          
          <div className="state1_elSunday_picker_open342161455">
            <Select className="baseFont" style={style_state1_elSunday_picker_open342161455}  onChange={this.pickerValueChanged_state1_elSunday_picker_open342161455} value={selection_sunday_picker_open} >
              {dataSource_sunday_picker_open.items.every(item => {
                return item[valueColumnName_sunday_picker_open] !== selection_sunday_picker_open;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_open.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_open];
                const labelColValue = item[labelColumnName_sunday_picker_open];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elText_toCopy71469243261">
            <div className="baseFont" style={style_state1_elText_toCopy71469243261}>
              <div>{this.props.locStrings.locationdetails2_text_tocopy7_1469243261}</div>
            </div>
          </div>
          
          <div className="state1_elSunday_picker_closed728360308">
            <Select className="baseFont" style={style_state1_elSunday_picker_closed728360308}  onChange={this.pickerValueChanged_state1_elSunday_picker_closed728360308} value={selection_sunday_picker_closed} >
              {dataSource_sunday_picker_closed.items.every(item => {
                return item[valueColumnName_sunday_picker_closed] !== selection_sunday_picker_closed;
              }) ? <Option value=''/> : null}
              {dataSource_sunday_picker_closed.items.map(item => {
                const colValue = item[valueColumnName_sunday_picker_closed];
                const labelColValue = item[labelColumnName_sunday_picker_closed];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="state1_elSunday_icon_save_hoursCopy1357499455">
            <button className="actionFont" style={style_state1_elSunday_icon_save_hoursCopy1357499455} onClick={this.onClick_state1_elSunday_icon_save_hoursCopy1357499455}  />
          </div>
          
          <div className="state1_elText_brand_nameCopy41809084484">
            <div className="font-avenirBook" style={style_state1_elText_brand_nameCopy41809084484}>
              <div>{this.props.locStrings.locationdetails2_text_brand_namecopy4_1809084484}</div>
            </div>
          </div>
          
          <div className="state1_elPicker656661521">
            <Select className="baseFont" style={style_state1_elPicker656661521}  onChange={this.pickerValueChanged_state1_elPicker656661521} value={selection_picker}  />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state1_elRectangle423617523" style={style_state1_elRectangle423617523} />
            <div className="hasNestedComps state1_elBottomNavBar131258742" style={style_state1_elBottomNavBar131258742}>
              <BottomNavBar ref={(el)=> this._state1_elBottomNavBar131258742 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state1_elMenuItemsList1161594848" style={style_state1_elMenuItemsList1161594848_outer}>
              <ul>
                {items_menuItemsList.map((row, index) => {
                  let itemComp = (row._componentId)
                      ? listComps_menuItemsList[row._componentId]
                      : <BrandListForDropdown appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_my_menu_items'} dataSheetRow={row} {...{ 'imageURL': row['imageURL'], 'name': row['name'], 'price': row['price'], }} ref={(el) => {if (el) this._state1_elMenuItemsList1161594848_items.push(el)}} />;
                  return (<li key={row.key}>
                      {itemComp}
                    </li>);
                })}
                <div className="marker" ref={(el)=> this._state1_elMenuItemsList1161594848_endMarker = el} />
              </ul>
            </div>
            
            <div className="state1_elBrandLogoImage1800908446" style={style_state1_elBrandLogoImage1800908446} />
            <img className="state1_elImage444972618" style={style_state1_elImage444972618} src={img_state1_elImage444972618} alt=""  />
            <div className="hasNestedComps state1_elIPhoneMockupComponent789002727">
              <IPhoneMockupComponent {...dataSheet_fb_currentBrand.items[0]} visualStateIndex={1} ref={(el)=> this._state1_elIPhoneMockupComponent789002727 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state1_elFooter1933041120">
              <Footer ref={(el)=> this._state1_elFooter1933041120 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state1_elHeader799796556">
              <Header ref={(el)=> this._state1_elHeader799796556 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            { state1_elComponent322793260 }
            { state1_elHelpOverlayScreen370331840 }
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (0) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
