import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/DetailsComponent_state1_elRoundimage_743990.png';

export default class AppIcon extends Component {

  // Properties used by this component:
  // name, brandImageURL, document_key, primaryColor

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div>Name</div>),
      text_plainText: "Name",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elHotspot = async () => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_brandCreateState', newVal);
  
    newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selectedbrandkey', newVal);
  
    newVal = this.props.brandImageURL;
    this.props.appActions.updateDataSlot('ds_uploadedBrandImageLocation', newVal);
  
    newVal = this.props.name;
    this.props.appActions.updateDataSlot('ds_activeBrandName', newVal);
  
    newVal = this.props.primaryColor;
    this.props.appActions.updateDataSlot('ds_brandPrimaryColor', newVal);
  
    // Go to screen 'Brand Details'
    this.props.appActions.goToScreen('brandDetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    
    const style_elRectangle = {
      background: 'rgba(255, 255, 255, 1.000)',
      borderRadius: '12.0px',
     };
    const style_elImage = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.brandImageURL)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.name);
    
    const style_elText = {
      fontSize: 8.9,
      color: '#fffef8',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elText_inner = {
      textAlign: 'center',
     };
    
    const style_elHotspot = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppIcon">
        <div className="background">
          <div className="elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="foreground">
          <div className="elImage" style={style_elImage} />
          <div className="systemFontRegular  elText" style={style_elText}>
            <div className="bottomAlignedContent">{value_text}</div>
          </div>
          <div className="actionFont elHotspot" style={style_elHotspot} onClick={this.onClick_elHotspot}  />
        </div>
      </div>
    )
  }
  
}
