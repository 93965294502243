// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_fb_my_menu_items extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['description'] = "    ";
    item['promoBrandID'] = "EWTRJGrUPQsEDirphF4c";
    item['isBrandPromo'] = "0";
    item['updated'] = "1595891399191";
    item['document_path'] = "menuItems/sDgjNIN8q2PM7aWoK5rF";
    item['associatedBrand'] = "EWTRJGrUPQsEDirphF4c";
    item['key'] = "0";
    item['price'] = "450";
    item['order'] = "    ";
    item['imageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2FmenuItems%2F5504ae6c-5310-4a6d-af04-45e9f9be1e9b.png?alt=media&token=b53378fc-a5fd-46bc-b15e-faf0087ff457";
    item['document_key'] = "sDgjNIN8q2PM7aWoK5rF";
    item['created'] = "1594445195302";
    item['promoBrand'] = " ";
    item['customizations'] = [{"updated":1594445363645,"order":"    ","created":1594445363645,"description":"    ","name":"size"},{"updated":1594530468110,"document_path":"menuItems/sDgjNIN8q2PM7aWoK5rF/customizations/h0Z38EzZMnqgaclgRFHC","order":"","created":1594445569423,"description":"    ","name":"size"},{"updated":1594530697825,"document_path":"menuItems/sDgjNIN8q2PM7aWoK5rF/customizations/h0Z38EzZMnqgaclgRFHC","order":"","created":1594445569423,"description":"    ","options":[{"updated":1595990889670,"document_path":"menuItems/sDgjNIN8q2PM7aWoK5rF/customizations/h0Z38EzZMnqgaclgRFHC/options/xrpBpeuSZ4vQYTylzwNV","order":"    ","price":0,"created":1595990883589,"name":"small"}],"name":"size"},{"updated":1594530697825,"document_path":"menuItems/sDgjNIN8q2PM7aWoK5rF/customizations/h0Z38EzZMnqgaclgRFHC","order":"","created":1594445569423,"description":"    ","options":[{"updated":1595990883589,"order":"    ","price":0,"created":1595990883589,"name":"small"},{"updated":1595992373570,"document_path":"menuItems/sDgjNIN8q2PM7aWoK5rF/customizations/h0Z38EzZMnqgaclgRFHC/options/xrpBpeuSZ4vQYTylzwNV","order":"    ","price":0,"created":1595990883589,"name":"small"}],"name":"size"}];
    item['associatedMenu'] = "l6PjUmzg00k2G8KF1Uw0";
    item['document_ref'] = "    ";
    item['name'] = "new5";
    item['isActive'] = "1";
    
    item = {};
    this.items.push(item);
    item['description'] = "    ";
    item['updated'] = "1595889962263";
    item['document_path'] = "menuItems/y7OYzJIRhJE0JKqNzdhI";
    item['associatedBrand'] = "EWTRJGrUPQsEDirphF4c";
    item['key'] = "1";
    item['isBrandPromo'] = "1";
    item['order'] = "    ";
    item['imageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2FmenuItems%2F6746fd8e-d46f-458d-8e51-9f2c24a01ef3.jpg?alt=media&token=51fbb71c-3dc3-4223-bb2c-915be5ddc2d6";
    item['document_key'] = "y7OYzJIRhJE0JKqNzdhI";
    item['created'] = "1594489632670";
    item['promoBrand'] = "EWTRJGrUPQsEDirphF4c";
    item['document_ref'] = "    ";
    item['associatedMenu'] = "l6PjUmzg00k2G8KF1Uw0";
    item['name'] = "test";
    item['isActive'] = "1";
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    if (item.document_key && item.document_key.length > 0) {
      let parsedItem = (({ document_key, ...item }) => (item))( item );
      addItemPromise = collection.doc(item.document_key).set(parsedItem);
    } else {
      addItemPromise = collection.add(item);
    }
    addItemPromise
      .then((docRef) => {
        var addedKey="";
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          addedKey=docRef.id;
          item.document_key = docRef.id;
          item.document_path = docRef.path;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
          addedKey=item.document_key;
        }   
        if (this.onLastAddedDocumentKeyChanged != null) {
          this.onLastAddedDocumentKeyChanged(addedKey);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
  
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
      
    docRef.set((({ key, document_ref, document_key, ...item }) => (item))( item ), {merge: true})
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  

}
