// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_mainScreenInstructions extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['Title'] = "CREATE";
    item['Number'] = "1";
    item['Text'] = "Click the Plus sign to create a brand or two.\n";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['Title'] = "CUSTOMIZE";
    item['Number'] = "2";
    item['Text'] = "Add logos, colors, menus, menu items, prices, etc.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['Title'] = "PREVIEW";
    item['Number'] = "3";
    item['Text'] = "Preview your app on your own device.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['Title'] = "PUBLISH";
    item['Number'] = "4";
    item['Text'] = "Submit your app for review into the app store.";
    item.key = key++;
  }

}
