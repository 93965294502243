import React, { Component } from 'react';
import './App.css';
import btn_icon_109771760 from './images/btn_icon_109771760.png';
import btn_icon_1745501912 from './images/btn_icon_1745501912.png';

export default class Icon_BrandPromo extends Component {

  // Properties used by this component:
  // dataSheetRow, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elIconHeart = async () => {
    this.setState({visualStateIndexOverride: 1})
  
    await this.sendData_state0_elIconHeart_to_fb_menuItems();
  
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_brandpromoitemflag', newVal);
  
    newVal = this.props.dataSheetRow.associatedBrand;
    this.props.appActions.updateDataSlot('ds_brandpromoTempBrandID', newVal);
  
  }
  
  
  sendData_state0_elIconHeart_to_fb_menuItems = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItems');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
    };
    row = { ...row, 
      isBrandPromo: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_true'] : ''),
      promoBrand: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedbrandkey'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      //input.isBrandPromo=true;
      
      return input;  }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItems', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItems', row);
    }
  }
  
  
  renderState0() {
    
    const style_state0_elIconHeart = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_109771760+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Icon_BrandPromo">
        <div className="foreground">
          <button className="actionFont state0_elIconHeart" style={style_state0_elIconHeart} onClick={this.onClick_state0_elIconHeart}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elIconHeart = async () => {
    await this.sendData_state1_elIconHeart_to_fb_menuItems();
  
    this.setState({visualStateIndexOverride: 0})
  
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_brandpromoitemflag', newVal);
  
    newVal = " ";
    this.props.appActions.updateDataSlot('ds_brandpromoTempBrandID', newVal);
  
  }
  
  
  sendData_state1_elIconHeart_to_fb_menuItems = () => {
    const dataSheet = this.props.appActions.getDataSheet('fb_menuItems');
  
    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
    };
    row = { ...row, 
      isBrandPromo: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_false'] : ''),
      promoBrand: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_value_space'] : ''),
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.updated = currentDate.getTime();
      //input.isBrandPromo=false;
      
      return input;
      
      
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('fb_menuItems', row);
    } else {
      return this.props.appActions.addToDataSheet('fb_menuItems', row);
    }
  }
  
  
  renderState1() {
    
    const style_state1_elIconHeart = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1745501912+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Icon_BrandPromo">
        <div className="foreground">
          <button className="actionFont state1_elIconHeart" style={style_state1_elIconHeart} onClick={this.onClick_state1_elIconHeart}  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
