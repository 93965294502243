// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_menuItems extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['order'] = "0";
    item['key'] = "Xxxxxxyyyyyyy";
    item['name'] = "MenuItem One\n";
    item['price'] = "500";
    item['associatedBrand'] = "xxxxxxxyyyyyyy";
    
    item = {};
    this.items.push(item);
    item['order'] = "1";
    item['key'] = "XYXYXYXYYXYXY";
    item['name'] = "MenuItem Two";
    item['price'] = "600";
    item['associatedBrand'] = "XYXYXYXYXYYXYX";
  }

}
