import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/Login1Screen_elImage_149074.png';
import FirebaseLogin from './FirebaseLogin';
import LiscenseAgreementPopup from './LiscenseAgreementPopup';
import PrivacyPolicy from './PrivacyPolicy';
import btn_icon_251883 from './images/btn_icon_251883.png';
import firebase from 'firebase';
import firestore from 'firebase/firestore';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Login1Screen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      text2: (<div>Sign in to get started or access your existing account.</div>),
      text2_plainText: "Sign in to get started or access your existing account.",
      text: (<div>By creating an account, you agree to our &lt;u&gt;Terms of Service&lt;/u&gt;<br />and have read and understood the &lt;u&gt;Privacy Policy&lt;/u&gt;</div>),
      text_plainText: "By creating an account, you agree to our <u>Terms of Service</u>\nand have read and understood the <u>Privacy Policy</u>",
      elLiscenseAgreementPopup_visible: false,
      elPrivacyPolicy_visible: false,
      elFab_visible: false,
    };
  }

  componentDidMount() {
    // Check if Firebase login has been completed.
    let unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          if (firebase.auth().currentUser) {
            if (this._elFirebaseLogin)
              this._elFirebaseLogin.saveCurrentUserDataInApp();
            
            sessionStorage.setItem('loginStatus_login1', 'active');
            this.props.appActions.goToScreen('mainScreen');
            
          }
        }
        unregisterAuthObserver();
      }
    );
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elHotspot = async () => {
    this.setState({elLiscenseAgreementPopup_visible: true});
  
    this.setState({elFab_visible: true});
  
  }
  
  
  onClick_elHotspotCopy = async () => {
    this.setState({elPrivacyPolicy_visible: true});
  
    this.setState({elFab_visible: true});
  
  }
  
  
  onClick_elFab = async () => {
    this.setState({elLiscenseAgreementPopup_visible: false});
  
    this.setState({elFab_visible: false});
  
    this.setState({elPrivacyPolicy_visible: false});
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#fffef8',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText = {
      fontSize: 9.0,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elFirebaseLogin = {
      pointerEvents: 'auto',
     };
    
    const style_elHotspot = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elLiscenseAgreementPopup = this.state.elLiscenseAgreementPopup_visible ? (
      <div className="hasNestedComps elLiscenseAgreementPopup">
        <LiscenseAgreementPopup ref={(el)=> this._elLiscenseAgreementPopup = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const elPrivacyPolicy = this.state.elPrivacyPolicy_visible ? (
      <div className="hasNestedComps elPrivacyPolicy">
        <PrivacyPolicy ref={(el)=> this._elPrivacyPolicy = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    const style_elHotspotCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elFab = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elFab = this.state.elFab_visible ? (
      <Button className="actionFont elFab" style={style_elFab}  variant="fab" color="accent" onClick={this.onClick_elFab} >
        <img src={btn_icon_251883} alt="" style={{width: '50.000%', marginTop: '25.000%'}} />
      </Button>
     ) : null;
    
    return (
      <div className="AppScreen Login1Screen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="primaryBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elImage">
            <div style={style_elImage} />
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{this.state.text2}</div>
            </div>
          </div>
          
          <div className="elText">
            <div className="systemFontRegular" style={style_elText}>
              <div><div dangerouslySetInnerHTML={{__html: this.state.text_plainText.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="elFirebaseLogin">
            <div style={style_elFirebaseLogin}>
              <FirebaseLogin ref={(el)=> this._elFirebaseLogin = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="actionFont elHotspot" style={style_elHotspot} onClick={this.onClick_elHotspot}  />
            { elLiscenseAgreementPopup }
            { elPrivacyPolicy }
            <div className="actionFont elHotspotCopy" style={style_elHotspotCopy} onClick={this.onClick_elHotspotCopy}  />
            { elFab }
          </div>
        </div>
      </div>
    )
  }
  
}
