import React, { Component } from 'react';
import './App.css';
import img_state0_elImage2550461 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import btn_icon_123751 from './images/btn_icon_123751.png';
import MenuItemOptionsListItem from './MenuItemOptionsListItem';
import BottomNavBar from './BottomNavBar';
import img_state0_elImage3521271 from './images/MenuItemsipadlandscape_state0_elImage3444232_444232.png';
import img_state0_elBrandLogoImage8588 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state0_elImage312403 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';
import btn_icon_900613 from './images/btn_icon_900613.png';
import img_state1_elImage2550461 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elImage3521271 from './images/MenuItemsipadlandscape_state0_elImage3444232_444232.png';
import img_state1_elBrandLogoImage8588 from './images/MenuItemOptionsScreen_state0_elImage2_822818.png';
import img_state1_elImage312403 from './images/MenuItemOptionsScreen_state0_elImage166520_166520.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class MenuItemOptionsipadlandscape extends Component {

  // Properties used by this component:
  // dataSheetRow, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elText713381: (<div>Options</div>),
      state0_elText713381_plainText: "Options",
      state0_elText2957797: (<div>Start New Order</div>),
      state0_elText2957797_plainText: "Start New Order",
      state0_elTitleText301658: (<div>Sample Item</div>),
      state0_elTitleText301658_plainText: "Sample Item",
      state0_elPriceText48989: (<div>$5.00</div>),
      state0_elPriceText48989_plainText: "$5.00",
      state1_elText713381: (<div>Options</div>),
      state1_elText713381_plainText: "Options",
      state1_elText2957797: (<div>Start New Order</div>),
      state1_elText2957797_plainText: "Start New Order",
      state1_elTitleText301658: (<div>Sample Item</div>),
      state1_elTitleText301658_plainText: "Sample Item",
      state1_elPriceText48989: (<div>$5.00</div>),
      state1_elPriceText48989_plainText: "$5.00",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elText713381 = async () => {
  
  
  }
  
  
  textInputChanged_state0_elFieldTitle438419 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state0_elFieldTitle438419 = () => {
    return this.state.fieldTitle || '';
  }
  
  onClick_state0_elIconButton123751 = async () => {
  
  
  }
  
  
  
  onClick_state0_elIconButton2900613 = async () => {
    let newVal = "3";
    this.props.appActions.updateDataSlot('ds_mainScreenState', newVal);
  
  }
  
  
  renderState0() {
    // eslint-disable-next-line
    const dataSheet_fb_menuItem_options = this.props.appActions.getDataSheet('fb_menuItem_options');
    
    const style_state0_elRectangle2546133 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    const style_state0_elImage2550461 = {
      backgroundImage: 'url('+img_state0_elImage2550461+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state0_elText713381 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elFieldTitle438419 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_state0_elFieldTitle438419_outer = {
      display: 'none',
     };
    
    const style_state0_elIconButton123751 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_123751+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state0_elIconButton123751_outer = {
      display: 'none',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('fb_menuItem_options').items);
    this._state0_elList191235_items = [];
    
    const style_state0_elList191235 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state0_elButton678065 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
     };
    
    const style_state0_elRectangle3263545 = {
      background: 'rgba(0, 0, 0, 1.000)',
      border: '0.5px solid rgba(255, 41, 0, 0.4500)',
      borderRadius: '40.0px',
     };
    
    const style_state0_elRectangleCopy960267 = {
      background: 'rgba(255, 255, 255, 1.000)',
      border: '0.5px solid rgba(43, 79, 255, 0.4500)',
      borderRadius: '6.0px',
     };
    const style_state0_elText2957797 = {
      fontSize: 15.9,
      color: '#0c81fe',
      textAlign: 'center',
     };
    let transformColorPropValue_titleText = (input) => {
      // This function modifies the value for property 'color'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      return input;
    }
    const style_state0_elTitleText301658 = {
      fontSize: 31.0,
      color: transformColorPropValue_titleText(this.props.dataSheetRow ? this.props.dataSheetRow.titleColor : ''),
      textAlign: 'center',
     };
    const style_state0_elPriceText48989 = {
      color: this.props.dataSheetRow ? this.props.dataSheetRow.textColor : '',
      textAlign: 'center',
     };
    const style_state0_elImage3521271 = {
      backgroundImage: 'url('+img_state0_elImage3521271+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const fillColorValue_card2 = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    const style_state0_elCard2757312 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elCard2757312_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state0_elBrandLogoImage8588 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow.brandImageURL : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state0_elImage312403 = {
      height: 'auto',
     };
    
    const style_state0_elIconButton2900613 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_900613+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemOptionsipadlandscape">
        <div className="background">
          <div className="state0_elRectangle2546133" style={style_state0_elRectangle2546133} />
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elImage2550461">
            <div style={style_state0_elImage2550461} />
          </div>
          
          <div className="state0_elText713381">
            <div className="font-futuraMedium" style={style_state0_elText713381} onClick={this.onClick_state0_elText713381} >
              <div>{this.state.state0_elText713381}</div>
            </div>
          </div>
          
          <div className="state0_elSpacer621157">
            <div />
          </div>
          
          <div className="state0_elFieldTitle438419" style={style_state0_elFieldTitle438419_outer}>
            <input className="font-futuraMedium" style={style_state0_elFieldTitle438419} type="text" placeholder="{Menu Items}" onChange={this.textInputChanged_state0_elFieldTitle438419} value={this.getValue_state0_elFieldTitle438419()}  />
          </div>
          
          <div className="state0_elIconButton123751" style={style_state0_elIconButton123751_outer}>
            <button className="actionFont" style={style_state0_elIconButton123751} onClick={this.onClick_state0_elIconButton123751}  />
          </div>
          
          <div className="hasNestedComps state0_elList191235">
            <ul style={style_state0_elList191235}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemOptionsListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_menuItem_options'} dataSheetRow={row} {...{ 'name': row['name'], 'price': row['price'], 'order': row['order'], }} ref={(el) => {if (el) this._state0_elList191235_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state0_elList191235_endMarker = el} />
            </ul>
          </div>
          
          <div className="state0_elButton678065">
            <Button className="actionFont" style={style_state0_elButton678065}  color="accent" >
              New button
            </Button>
          </div>
          
          <div className="state0_elSpacer2915836">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="state0_elRectangle3263545" style={style_state0_elRectangle3263545} />
          <div className="hasNestedComps state0_elBottomNavBar570624">
            <BottomNavBar ref={(el)=> this._state0_elBottomNavBar570624 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="state0_elRectangleCopy960267" style={style_state0_elRectangleCopy960267} />
          <div className="systemFontBold  state0_elText2957797" style={style_state0_elText2957797}>
            <div>{this.state.state0_elText2957797}</div>
          </div>
          <div className="systemFontBold  state0_elTitleText301658" style={style_state0_elTitleText301658}>
            <div>{this.state.state0_elTitleText301658}</div>
          </div>
          <div className="baseFont state0_elPriceText48989" style={style_state0_elPriceText48989}>
            <div>{this.state.state0_elPriceText48989}</div>
          </div>
          <div className="state0_elImage3521271" style={style_state0_elImage3521271} />
          <div className="state0_elCard2757312" style={style_state0_elCard2757312_outer}>
            <div style={style_state0_elCard2757312} />
          </div>
          
          <div className="state0_elBrandLogoImage8588" style={style_state0_elBrandLogoImage8588} />
          <img className="state0_elImage312403" style={style_state0_elImage312403} src={img_state0_elImage312403} alt=""  />
          <button className="actionFont state0_elIconButton2900613" style={style_state0_elIconButton2900613} onClick={this.onClick_state0_elIconButton2900613}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elText713381 = async () => {
  
  
  }
  
  
  textInputChanged_state1_elFieldTitle438419 = (event) => {
    this.setState({fieldTitle: event.target.value});
  }
  
  getValue_state1_elFieldTitle438419 = () => {
    return this.state.fieldTitle || '';
  }
  
  onClick_state1_elIconButton123751 = async () => {
  
  
  }
  
  
  
  onClick_state1_elIconButton2900613 = async () => {
    let newVal = "3";
    this.props.appActions.updateDataSlot('ds_mainScreenState', newVal);
  
  }
  
  
  renderState1() {
    // eslint-disable-next-line
    const dataSheet_fb_menuItem_options = this.props.appActions.getDataSheet('fb_menuItem_options');
    
    const style_state1_elRectangle2546133 = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.5px solid rgba(98, 98, 98, 0.4500)',
      borderRadius: '10.0px',
     };
    const style_state1_elImage2550461 = {
      backgroundImage: 'url('+img_state1_elImage2550461+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state1_elText713381 = {
      fontSize: 40.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elFieldTitle438419 = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      fontSize: 40.0,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_state1_elFieldTitle438419_outer = {
      display: 'none',
     };
    
    const style_state1_elIconButton123751 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_123751+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elIconButton123751_outer = {
      display: 'none',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('fb_menuItem_options').items);
    this._state1_elList191235_items = [];
    
    const style_state1_elList191235 = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state1_elButton678065 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
     };
    
    const style_state1_elRectangle3263545 = {
      background: 'rgba(0, 0, 0, 1.000)',
      border: '0.5px solid rgba(255, 41, 0, 0.4500)',
      borderRadius: '40.0px',
     };
    
    const style_state1_elRectangleCopy960267 = {
      background: 'rgba(255, 255, 255, 1.000)',
      border: '0.5px solid rgba(43, 79, 255, 0.4500)',
      borderRadius: '6.0px',
     };
    const style_state1_elText2957797 = {
      fontSize: 15.9,
      color: '#0c81fe',
      textAlign: 'center',
     };
    let transformColorPropValue_titleText = (input) => {
      // This function modifies the value for property 'color'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      return input;
    }
    const style_state1_elTitleText301658 = {
      fontSize: 31.0,
      color: transformColorPropValue_titleText(this.props.dataSheetRow ? this.props.dataSheetRow.titleColor : ''),
      textAlign: 'center',
     };
    const style_state1_elPriceText48989 = {
      color: this.props.dataSheetRow ? this.props.dataSheetRow.textColor : '',
      textAlign: 'center',
     };
    const style_state1_elImage3521271 = {
      backgroundImage: 'url('+img_state1_elImage3521271+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const fillColorValue_card2 = this.props.dataSheetRow ? this.props.dataSheetRow.secondaryColor : '';
    const style_state1_elCard2757312 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCard2757312_outer = {
      boxSizing: 'border-box',
      backgroundColor: (fillColorValue_card2 && fillColorValue_card2.length > 0) ? fillColorValue_card2 : 'white',
     };
    const style_state1_elBrandLogoImage8588 = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow.brandImageURL : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_state1_elImage312403 = {
      height: 'auto',
     };
    
    const style_state1_elIconButton2900613 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_900613+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '77.778%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItemOptionsipadlandscape">
        <div className="background">
          <div className="state1_elRectangle2546133" style={style_state1_elRectangle2546133} />
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elImage2550461">
            <div style={style_state1_elImage2550461} />
          </div>
          
          <div className="state1_elText713381">
            <div className="font-futuraMedium" style={style_state1_elText713381} onClick={this.onClick_state1_elText713381} >
              <div>{this.state.state1_elText713381}</div>
            </div>
          </div>
          
          <div className="state1_elSpacer621157">
            <div />
          </div>
          
          <div className="state1_elFieldTitle438419" style={style_state1_elFieldTitle438419_outer}>
            <input className="font-futuraMedium" style={style_state1_elFieldTitle438419} type="text" placeholder="{Menu Items}" onChange={this.textInputChanged_state1_elFieldTitle438419} value={this.getValue_state1_elFieldTitle438419()}  />
          </div>
          
          <div className="state1_elIconButton123751" style={style_state1_elIconButton123751_outer}>
            <button className="actionFont" style={style_state1_elIconButton123751} onClick={this.onClick_state1_elIconButton123751}  />
          </div>
          
          <div className="hasNestedComps state1_elList191235">
            <ul style={style_state1_elList191235}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <MenuItemOptionsListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'fb_menuItem_options'} dataSheetRow={row} {...{ 'name': row['name'], 'price': row['price'], 'order': row['order'], }} ref={(el) => {if (el) this._state1_elList191235_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state1_elList191235_endMarker = el} />
            </ul>
          </div>
          
          <div className="state1_elButton678065">
            <Button className="actionFont" style={style_state1_elButton678065}  color="accent" >
              New button
            </Button>
          </div>
          
          <div className="state1_elSpacer2915836">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="state1_elRectangle3263545" style={style_state1_elRectangle3263545} />
          <div className="hasNestedComps state1_elBottomNavBar570624">
            <BottomNavBar ref={(el)=> this._state1_elBottomNavBar570624 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="state1_elRectangleCopy960267" style={style_state1_elRectangleCopy960267} />
          <div className="systemFontBold  state1_elText2957797" style={style_state1_elText2957797}>
            <div>{this.state.state1_elText2957797}</div>
          </div>
          <div className="systemFontBold  state1_elTitleText301658" style={style_state1_elTitleText301658}>
            <div>{this.state.state1_elTitleText301658}</div>
          </div>
          <div className="baseFont state1_elPriceText48989" style={style_state1_elPriceText48989}>
            <div>{this.state.state1_elPriceText48989}</div>
          </div>
          <div className="state1_elImage3521271" style={style_state1_elImage3521271} />
          <div className="state1_elCard2757312" style={style_state1_elCard2757312_outer}>
            <div style={style_state1_elCard2757312} />
          </div>
          
          <div className="state1_elBrandLogoImage8588" style={style_state1_elBrandLogoImage8588} />
          <img className="state1_elImage312403" style={style_state1_elImage312403} src={img_state1_elImage312403} alt=""  />
          <button className="actionFont state1_elIconButton2900613" style={style_state1_elIconButton2900613} onClick={this.onClick_state1_elIconButton2900613}  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
