// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_ds_list_of_hours extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['value'] = "-1";
    item['display'] = "Closed";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0";
    item['display'] = "AM- 12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1";
    item['display'] = "AM- 1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "2";
    item['display'] = "AM- 2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "3";
    item['display'] = "AM- 3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "4";
    item['display'] = "AM- 4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "5";
    item['display'] = "AM- 5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "6";
    item['display'] = "AM- 6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "7";
    item['display'] = "AM- 7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "8";
    item['display'] = "AM- 8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "9";
    item['display'] = "AM- 9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "10";
    item['display'] = "AM- 10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "11";
    item['display'] = "AM- 11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "12";
    item['display'] = "PM- 12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "13";
    item['display'] = "PM- 1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "14";
    item['display'] = "PM- 2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "15";
    item['display'] = "PM- 3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "16";
    item['display'] = "PM- 4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "17";
    item['display'] = "PM- 5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "18";
    item['display'] = "PM- 6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "19";
    item['display'] = "PM- 7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "20";
    item['display'] = "PM- 8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "21";
    item['display'] = "PM- 9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "22";
    item['display'] = "PM- 10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "23";
    item['display'] = "PM- 11";
    item.key = key++;
  }

}
