// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_fb_currentBrand extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['onboarding_screen2_color'] = "#658463";
    item['key'] = "0";
    item['id'] = "mNEdFor3G16WDziEXwlV";
    item['titleColor'] = "#000000";
    item['onboarding_screen3_line3'] = "We will have it waiting for you.";
    item['stripePublicKey'] = "pk_live_1IycP3jm3EF3tY2n0awhIvgA00dVfAbwd0";
    item['associatedMenuItems'] = [{"price":1300,"key":"4j2kR30ycLbLdldMeu1E","imageURL":"https://firebasestorage.googleapis.com/v0/b/yesoms-production01.appspot.com/o/images%2FmenuItems%2F0992f30e-a292-4f39-8fb4-1c0ed6c99fe2.jpg?alt=media&token=7b87497e-b0b1-498e-a487-230dfcc8dea3","type":"BrandPromo","name":"BBQ pulled Pork"},{"price":1250,"key":"69f51k31ci6Nlc7P2kJ1","imageURL":"https://firebasestorage.googleapis.com/v0/b/yesoms-production01.appspot.com/o/images%2FmenuItems%2Feb7bf024-9bdd-492f-aba6-3cc1a7dab19c.jpg?alt=media&token=3582c4eb-943e-42fb-a743-0577882a0114","type":"BrandPromo","name":"Salmon Cobb"},{"price":1500,"key":"6mh1DrvzVyBFW3YZUgZi","imageURL":"https://firebasestorage.googleapis.com/v0/b/yesoms-production01.appspot.com/o/images%2FmenuItems%2F07b4fe9f-db03-4d6c-9ead-694f22fa93f4.jpg?alt=media&token=ce1fbeda-2214-4e98-a920-9ef61b7a407f","type":"BrandPromo","name":"Spicy Buffalo Mac"},{"price":900,"key":"8DGUIddhf7pNIkbPuZS6","imageURL":"https://firebasestorage.googleapis.com/v0/b/yesoms-production01.appspot.com/o/images%2FmenuItems%2F3a55b46b-f6a2-4a82-a889-e5f5ed6385c8.jpg?alt=media&token=147b0303-babb-4ff2-ad42-a51ebe65a466","type":"BrandPromo","name":"Habañero Cheese Dip"},{"price":1200,"key":"V4CGipDtY9suxZOCdpg3","imageURL":"https://firebasestorage.googleapis.com/v0/b/yesoms-production01.appspot.com/o/images%2FmenuItems%2Fa23e3a20-4f46-430a-90aa-e30b78d0d6ee.jpg?alt=media&token=8034255a-3017-4bdd-8911-13a8974a0b8b","type":"BrandPromo","name":"CheeseSteak"},{"price":1400,"key":"wXlYyGonr8Xu4LrbRDR9","imageURL":"https://firebasestorage.googleapis.com/v0/b/yesoms-production01.appspot.com/o/images%2FmenuItems%2F934ed127-fc29-45a5-95ac-5aa675cb7945.jpg?alt=media&token=2683b2de-4446-4242-83ad-ccade6ac3051","type":"BrandPromo","name":"Western"}];
    item['activeBrandID'] = "mNEdFor3G16WDziEXwlV";
    item['onboarding_screen1_line1'] = "Welcome to Brother Jon's.";
    item['created'] = "1609285304281";
    item['document_path'] = "brands/mNEdFor3G16WDziEXwlV";
    item['orderButtonCopy'] = "I'll take it!";
    item['preference_printer'] = "1";
    item['loading_bar_initial_color'] = "#ffffff";
    item['onboarding_screen1_line2'] = "Thanks for stopping by.";
    item['onboarding_screen0_color'] = "#ffffff";
    item['onboarding_screen1_line3'] = "We hope you enjoy your meal";
    item['iconHomeURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:658463,e_colorize:100,f_png,h_96,w_96/v1589822630/yesIcons/heartIcon.svg";
    item['description'] = "Established in 2009, Brother Jon’s is a restaurant and pub located on the west side of Bend, Oregon.";
    item['associatedParent'] = "mNEdFor3G16WDziEXwlV";
    item['primaryColor'] = "#658463";
    item['orderButtonTextColor'] = "#EDDDC4";
    item['brandDefaultTip'] = "18";
    item['bottonNavColor'] = "#EDDDC4";
    item['logoImageURL'] = "https://firebasestorage.googleapis.com/v0/b/sandbox-a15b8.appspot.com/o/images%2Fbrands%2F53c5d0b0-2319-4a05-b6dc-c2de9831839e.png?alt=media";
    item['brandOfferingType'] = "beerPub";
    item['onboarding_screen2_title'] = "Reach out!";
    item['brandImageURL'] = "https://firebasestorage.googleapis.com/v0/b/yesoms-production01.appspot.com/o/images%2Fbrands%2Fc5086ed4-8769-48c0-8222-9cf8603f1dcd.png?alt=media&token=b52e2ce7-30c9-4173-bf02-a7a9af6b14a7";
    item['maintenanceInProgress'] = "";
    item['onboarding_screen3_color'] = "#EDDDC4";
    item['iconMoreURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:9C9F84,e_colorize:100,f_png,h_96,w_96/v1589862715/yesIcons/more-round.svg";
    item['brandBackgroundImageURL'] = "";
    item['loading_screen_color'] = "#658463";
    item['preference_printer_URL'] = {"name":"Brother QL-810W","url":"ipp://BRWD89C675CBEA5.local.:631/ipp/print"};
    item['onboarding_screen1_imageURL'] = "https://firebasestorage.googleapis.com/v0/b/yesoms-production01.appspot.com/o/images%2Fbrands%2Fb55c3c0d-271e-4dc5-b8cf-4dfe84ded421.png?alt=media&token=dcab2086-9f44-4703-93c8-1522a9387888";
    item['stripeProcessor'] = "https://us-central1-stripe-live-firebase.cloudfunctions.net/payWithStripeConnect";
    item['name'] = "BroJo's";
    item['onboarding_screen2_line1'] = "Please let us know if there ";
    item['loading_bar_final_color'] = "#9c9f84";
    item['serviceChargeBasisPoints'] = "490";
    item['iconMenuURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:9C9F84,e_colorize:100,f_png,h_96,w_96/v1589825284/yesIcons/menu-boxedIcon.svg";
    item['onboarding_screen2_line2'] = "is anything you may need";
    item['bottomNavIconActiveColor'] = "#658463";
    item['preference_delivery'] = "0";
    item['iconProfileURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:9C9F84,e_colorize:100,f_png,h_96,w_96/v1589825354/yesIcons/profileIcon.svg";
    item['onboarding_screen1_color'] = "#EDDDC4";
    item['bottomNavIconColor'] = "#9C9F84";
    item['onboarding_screen2_line3'] = "We are happy to help.";
    item['preference_sound_orderup'] = "1";
    item['preference_use_pickup_QRcodes'] = "0";
    item['brandOfferingOther'] = "";
    item['onboarding_screen3_title'] = "Let's get started.";
    item['document_key'] = "mNEdFor3G16WDziEXwlV";
    item['stripeAcctID'] = "acct_1I4A7RRNfxV9iWqI";
    item['textColor'] = "#EDDDC4";
    item['maintenanceInProgressImage'] = "";
    item['currentPickupETA'] = "30";
    item['creator'] = "JEzIkE1AjDbJxaXx7GCGxuc3Nfg2";
    item['iconOrderURL'] = "https://res.cloudinary.com/yesoms/image/upload/c_scale,co_rgb:9C9F84,e_colorize:100,f_png,h_96,w_96/v1589825246/yesIcons/menu-roundIcon.svg";
    item['stripeTestAcctID'] = "acct_1FvYf5IfJ0cqiU5E";
    item['orderButtonColor'] = "#9C9F84";
    item['preference_pickup'] = "1";
    item['updated'] = "1609285304281";
    item['development_mode'] = "0";
    item['contactEmail'] = "brojokevin@gmail.com";
    item['onboarding_screen3_line1'] = "Order head of time..";
    item['secondaryColor'] = "#658463";
    item['brandTicketImageURL'] = "https://firebasestorage.googleapis.com/v0/b/yesoms-development01.appspot.com/o/images%2Fbrands%2Fbrother-jons-public-house-bend-or%20(1).png?alt=media&token=40fe3d75-4128-479f-8942-c02b61585fc0";
    item['onboarding_screen1_title'] = "Welcome!";
    item['onboarding_screen3_line2'] = "..and swing on by.";
    item['isActive'] = "1";
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    if (item.document_key && item.document_key.length > 0) {
      let parsedItem = (({ document_key, ...item }) => (item))( item );
      addItemPromise = collection.doc(item.document_key).set(parsedItem);
    } else {
      addItemPromise = collection.add(item);
    }
    addItemPromise
      .then((docRef) => {
        var addedKey="";
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          addedKey=docRef.id;
          item.document_key = docRef.id;
          item.document_path = docRef.path;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
          addedKey=item.document_key;
        }   
        if (this.onLastAddedDocumentKeyChanged != null) {
          this.onLastAddedDocumentKeyChanged(addedKey);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
  
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
      
    docRef.set((({ key, document_ref, document_key, ...item }) => (item))( item ), {merge: true})
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  

}
